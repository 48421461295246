import { ProfileType, getProfileType } from '@/modules/users/types/ProfileType';
import { isNil } from 'lodash-es';
import { CountryCode, getCountryCode } from '../../users/types/CountryCode';
import { CurrencyCode, getCurrencyCode } from '../../users/types/CurrencyCode';
import { ProfileState, getProfileState } from '../../users/types/ProfileState';
import { Timezone, getTimezone } from '../../users/types/Timezone';
import { ProfileDTO } from '../api/profile.contracts';

export class ProfileModel {
  public id: string;
  public accountId: string;
  public name: string;
  public type: ProfileType;
  public subType: string;
  public countryCode: CountryCode;
  public currencyCode: CurrencyCode;
  public dailyBudget: number;
  public timeZone: Timezone;
  public marketplaceId: string;
  public state: ProfileState;
  public createdAt: string;
  public createSTVReports: boolean;
  public sellerCentralAuthAt?: string;
  public vendorCentralAuthAt?: string;

  public get nameWithMarket() {
    return `${this.name} (${this.countryCode})`;
  }

  public getTruncatedNameWithMarket(maxLength: number) {
    if (this.name.length > maxLength) {
      return `${this.name.substring(0, maxLength) + '...'} (${this.countryCode})`;
    }

    return this.nameWithMarket;
  }

  public get isActive() {
    return this.state === ProfileState.ACTIVE;
  }

  public get isNotAuthorized() {
    return this.state === ProfileState.NOT_AUTHORIZED;
  }

  public get isSeller() {
    return !isNil(this.sellerCentralAuthAt);
  }

  public get isVendor() {
    return !isNil(this.vendorCentralAuthAt);
  }

  constructor(args: ProfileCreateArguments) {
    this.id = args.id;
    this.accountId = args.accountId;
    this.name = args.name;
    this.type = args.type;
    this.subType = args.subType;
    this.countryCode = args.countryCode;
    this.currencyCode = args.currencyCode;
    this.dailyBudget = args.dailyBudget;
    this.timeZone = args.timeZone;
    this.marketplaceId = args.marketplaceId;
    this.state = args.state;
    this.createdAt = args.createdAt;
    this.createSTVReports = args.createSTVReports;
    this.sellerCentralAuthAt = args.sellerCentralAuthAt;
    this.vendorCentralAuthAt = args.vendorCentralAuthAt;
  }

  public static fromDTO(dto: ProfileDTO): ProfileModel {
    return new ProfileModel({
      id: dto.id,
      accountId: dto.account_id,
      name: dto.name,
      type: getProfileType(dto.type),
      subType: dto.sub_type,
      countryCode: getCountryCode(dto.country_code),
      currencyCode: getCurrencyCode(dto.currency_code),
      dailyBudget: dto.daily_budget,
      timeZone: getTimezone(dto.timezone),
      marketplaceId: dto.marketplace_id,
      state: getProfileState(dto.state),
      createdAt: dto.created_at,
      createSTVReports: dto.create_stv_reports,
      sellerCentralAuthAt: dto.seller_central_auth_at,
      vendorCentralAuthAt: dto.vendor_central_auth_at,
    });
  }
}

export interface ProfileCreateArguments {
  id: string;
  accountId: string;
  name: string;
  type: ProfileType;
  subType: string;
  countryCode: CountryCode;
  currencyCode: CurrencyCode;
  dailyBudget: number;
  timeZone: Timezone;
  marketplaceId: string;
  state: ProfileState;
  createdAt: string;
  createSTVReports: boolean;
  sellerCentralAuthAt?: string;
  vendorCentralAuthAt?: string;
}
