import { useTranslation } from '@/lib';
import { AlDate } from '@/lib/date/AlDate';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import {
  AdTypeReportStatusDTO,
  ProfileReportsStatusDTO,
  REPORT_STATUSES_ORDERED_BY_SEVERITY,
  ReportingStatusDTO,
  ReportingStatusType,
} from '@/modules/profiles/api/profile.contracts';
import {
  profileService,
  QUERY_SELLER_CENTRAL_STATUS_REPORT_KEY,
  QUERY_STATUS_REPORT_KEY,
  QUERY_VENDOR_CENTRAL_STATUS_REPORT_KEY,
} from '@/modules/profiles/api/profile.service';
import { defaultProfileReportsStatusInfo, ProfileReportsStatus, SyncStatus, SyncStatusReason } from '@/modules/profiles/types/ProfileStatus';
import { SellerCentralReportsStatusDTO } from '@/modules/seller-central/api/seller-central-contracts';
import { sellerCentralService } from '@/modules/seller-central/api/seller-central-service';
import { useUserContext } from '@/modules/users/contexts/UserContext';
import { VendorCentralReportsStatusDTO } from '@/modules/vendor-central/api/vendor-central-contracts';
import { vendorCentralService } from '@/modules/vendor-central/api/vendor-central-service';
import { Routes } from '@/router/router-paths';
import * as Sentry from '@sentry/react';
import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { isEmpty, isEqual, isNil } from 'lodash-es';
import { createContext, FunctionComponent, PropsWithChildren, useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useActiveTeamContext } from './ActiveTeamContext';

// Wrapper that adds profileId to result. Queries don't have queryKeys. "data" is unreliable because on error it's undefined
interface ReportStatusQueryResult {
  profileId: string;
  data: ProfileReportsStatusDTO | null;
}

interface SellerCentralReportStatusQueryResult {
  profileId: string;
  data: SellerCentralReportsStatusDTO | null;
}

interface VendorCentralReportStatusQueryResult {
  profileId: string;
  data: VendorCentralReportsStatusDTO | null;
}

interface ReportsContextType {
  updateProfileData(profileId: string): Promise<void>;
  profileSyncStatus: (profileId: string | undefined) => SyncStatus;
  activeProfileSyncStatus: SyncStatus;
  activeProfileReportsStatusInfo: ProfileReportsStatus;
  isActiveProfileReportsBeingProcessed: boolean;
  activeProfileHasProcessedReports: boolean;
  refetchProfileStatus: (profileId: string) => void;
  getReportStatusInfoByProfileId: (profileId: string | undefined) => ProfileReportsStatus | undefined;
  getSellerCentralReportStatusInfoByProfileId: (profileId: string | undefined) => ProfileReportsStatus | undefined;
  getVendorCentralReportStatusInfoByProfileId: (profileId: string | undefined) => ProfileReportsStatus | undefined;
  getCombinedUpdatedAtByProfileId: (profileId: string | undefined) => string;
}

// Default values
const ReportsContext = createContext<ReportsContextType>({
  updateProfileData: () => Promise.resolve(),
  profileSyncStatus: () => ({ canClickSync: true, reason: null, isLoading: false }),
  activeProfileSyncStatus: { canClickSync: true, reason: null, isLoading: false },
  activeProfileReportsStatusInfo: defaultProfileReportsStatusInfo,
  isActiveProfileReportsBeingProcessed: false,
  activeProfileHasProcessedReports: false,
  refetchProfileStatus: () => null,
  getReportStatusInfoByProfileId: () => undefined,
  getSellerCentralReportStatusInfoByProfileId: () => undefined,
  getVendorCentralReportStatusInfoByProfileId: () => undefined,
  getCombinedUpdatedAtByProfileId: () => '',
});

export const ReportsProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const locationsWhereFetchAll = new Set([Routes.PROFILES]);
  const currentPage = useLocation().pathname;
  const isFetchReportsForAllProfiles = locationsWhereFetchAll.has(currentPage as Routes);
  const { t } = useTranslation();
  const { fetchCache } = useAlFetchCache();

  const { user, isProfileSeller, isProfileVendor } = useUserContext();
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const [localProfilesInLoadingState, setLocalProfilesInLoadingState] = useState<Set<string>>(new Set());

  function addProfileToLocalLoadingState(profileId: string) {
    setLocalProfilesInLoadingState((prevSet) => new Set(prevSet).add(profileId));
  }

  function removeProfileFromLocalLoadingState(profileId: string) {
    setLocalProfilesInLoadingState((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(profileId);
      return newSet;
    });
  }

  const [localProfilesSellerCentralInLoadingState, setLocalProfilesSellerCentralInLoadingState] = useState<Set<string>>(new Set());
  function addProfileToLocalSellerCentralLoadingState(profileId: string) {
    setLocalProfilesSellerCentralInLoadingState((prevSet) => new Set(prevSet).add(profileId));
  }

  function removeProfileFromLocalSellerCentralLoadingState(profileId: string) {
    setLocalProfilesSellerCentralInLoadingState((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(profileId);
      return newSet;
    });
  }

  const [localProfilesVendorCentralInLoadingState, setLocalProfilesVendorCentralInLoadingState] = useState<Set<string>>(new Set());
  function addProfileToLocalVendorCentralLoadingState(profileId: string) {
    setLocalProfilesVendorCentralInLoadingState((prevSet) => new Set(prevSet).add(profileId));
  }

  function removeProfileFromLocalVendorCentralLoadingState(profileId: string) {
    setLocalProfilesVendorCentralInLoadingState((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(profileId);
      return newSet;
    });
  }

  const reportStatusQueries = useQueries({
    queries:
      activeTeam?.profiles?.map((profile) => ({
        queryKey: [QUERY_STATUS_REPORT_KEY, activeTeam, profile.id],
        queryFn: async (): Promise<ReportStatusQueryResult> => {
          const profileId = profile.id;
          const response = await profileService.getReportsStatus(activeTeam.id, profileId);

          removeProfileFromLocalLoadingState(profileId);

          const result: ReportStatusQueryResult = {
            profileId: profileId,
            data: null,
          };

          if (response.isSuccess) {
            result.data = response.payload;
          } else {
            console.log('Error fetching report status:', response);
          }

          return result;
        },

        refetchInterval: 5 * 60 * 1000,
        enabled: !isNil(activeTeam) && !isNil(activeTeam.profiles) && profile.id == activeProfile?.id,
      })) || [],
  });

  const {
    data: allTeamStatuses,
    isLoading: isAllTeamStatusesLoading,
    isError: isAllTeamStatusesError,
    error: allTeamStatusesError,
    refetch: refetchAllTeamStatuses,
    isFetching: isFetchingAllTeamStatuses,
  } = useQuery({
    queryKey: [QUERY_STATUS_REPORT_KEY, activeTeam],
    queryFn: async () => {
      const result = await profileService.getAllTeamReportsStatus();
      if (result.isSuccess) {
        for (const profileId in result.payload.statuses_per_profile) {
          removeProfileFromLocalLoadingState(profileId);
        }

        return result.payload;
      } else {
        throw new Error('Error loading report statuses\n' + JSON.stringify(result));
      }
    },
    refetchInterval: 5 * 60 * 1000,
    enabled: !isNil(activeTeam) && !isNil(activeTeam.profiles) && isFetchReportsForAllProfiles,
  });

  function getQueryByProfileID(profileId: string | null | undefined): UseQueryResult<ReportStatusQueryResult, Error> | undefined {
    return reportStatusQueries.find((query) => query.data?.profileId === profileId);
  }

  function refetchProfileStatus(profileId: string) {
    getQueryByProfileID(profileId)?.refetch();
  }

  // Vendor Central
  const vendorCentralReportStatusQueries = useQueries({
    queries:
      activeTeam?.profiles?.map((profile) => ({
        queryKey: [QUERY_VENDOR_CENTRAL_STATUS_REPORT_KEY, activeTeam, profile.id],
        queryFn: async (): Promise<VendorCentralReportStatusQueryResult> => {
          const profileId = profile.id;
          const response = await vendorCentralService.getStatus(activeTeam.id, profileId);

          removeProfileFromLocalVendorCentralLoadingState(profileId);

          const result: VendorCentralReportStatusQueryResult = {
            profileId,
            data: null,
          };

          if (response.isSuccess) {
            result.data = response.payload;
          } else {
            console.log('Error fetching vendor central report status:', response);
          }

          return result;
        },

        refetchInterval: 5 * 60 * 1000,
        enabled: !isNil(activeTeam) && !isNil(activeTeam.profiles) && profile.id == activeProfile?.id,
      })) || [],
  });

  function getVendorCentralQueryByProfileID(
    profileId: string | null | undefined,
  ): UseQueryResult<VendorCentralReportStatusQueryResult, Error> | undefined {
    return vendorCentralReportStatusQueries.find((query) => query.data?.profileId === profileId);
  }

  const profileVendorCentralReportStatusCache = useRef<Map<string, ProfileReportsStatus>>(new Map());
  function getVendorCentralReportStatusInfoByProfileId(profileId: string | undefined): ProfileReportsStatus | undefined {
    if (!profileId || !isProfileVendor(profileId)) {
      return undefined;
    }

    let result: ProfileReportsStatus = defaultProfileReportsStatusInfo;

    if (profileId) {
      let vendorSalesReportData: ReportingStatusDTO | undefined;
      let vendorTrafficReportData: ReportingStatusDTO | undefined;
      let isFetching = false;

      if (!isFetchReportsForAllProfiles) {
        const query = getVendorCentralQueryByProfileID(profileId);
        if (query) {
          vendorSalesReportData = query.data?.data?.vendor_sales;
          vendorTrafficReportData = query.data?.data?.vendor_traffic;
          isFetching = query.isFetching;
        }
      } else {
        vendorSalesReportData = allTeamVendorCentralStatuses?.statuses_per_profile[profileId]?.vendor_sales;
        vendorTrafficReportData = allTeamVendorCentralStatuses?.statuses_per_profile[profileId]?.vendor_traffic;
        isFetching = isFetchingAllTeamVendorCentralStatuses;
      }

      let updatedAt = undefined;
      if (vendorSalesReportData?.updated_at && vendorTrafficReportData?.updated_at) {
        if (AlDate.parse(vendorSalesReportData?.updated_at).isBefore(AlDate.parse(vendorTrafficReportData?.updated_at))) {
          updatedAt = vendorSalesReportData?.updated_at;
        } else {
          updatedAt = vendorTrafficReportData?.updated_at;
        }
      }

      let status = vendorSalesReportData?.status;
      if (status && vendorTrafficReportData?.status && vendorTrafficReportData.status != ReportingStatusType.COMPLETED) {
        status = vendorTrafficReportData?.status;
      }

      if (localProfilesVendorCentralInLoadingState.has(profileId) || isFetching) {
        result = {
          status: ReportingStatusType.WAITING_RESPONSE,
          updatedAt: updatedAt,
          nextPossibleUpdate: '',
        };
      } else {
        result = {
          status: status ?? ReportingStatusType.LOADING,
          updatedAt: updatedAt,
          nextPossibleUpdate: '',
        };
      }
    }

    // Check against cache
    const cachedResult = profileVendorCentralReportStatusCache.current.get(profileId!);
    if (cachedResult && isEqual(result, cachedResult)) {
      return cachedResult;
    }

    profileVendorCentralReportStatusCache.current.set(profileId!, result);
    return result;
  }

  const { data: allTeamVendorCentralStatuses, isFetching: isFetchingAllTeamVendorCentralStatuses } = useQuery({
    queryKey: [QUERY_VENDOR_CENTRAL_STATUS_REPORT_KEY, activeTeam],
    queryFn: async () => {
      const result = await vendorCentralService.getAllTeamReportsStatus();
      if (result.isSuccess) {
        for (const profileId in result.payload.statuses_per_profile) {
          removeProfileFromLocalVendorCentralLoadingState(profileId);
        }

        return result.payload;
      } else {
        throw new Error('Error loading vendor central statuses\n' + JSON.stringify(result));
      }
    },
    refetchInterval: 5 * 60 * 1000,
    enabled: !isNil(activeTeam) && !isNil(activeTeam.profiles) && isFetchReportsForAllProfiles,
  });

  // Seller central
  const sellerCentralReportStatusQueries = useQueries({
    queries:
      activeTeam?.profiles?.map((profile) => ({
        queryKey: [QUERY_SELLER_CENTRAL_STATUS_REPORT_KEY, activeTeam, profile.id],
        queryFn: async (): Promise<SellerCentralReportStatusQueryResult> => {
          const profileId = profile.id;
          const response = await sellerCentralService.getStatus(activeTeam.id, profileId);

          removeProfileFromLocalSellerCentralLoadingState(profileId);

          const result: SellerCentralReportStatusQueryResult = {
            profileId: profileId,
            data: null,
          };

          if (response.isSuccess) {
            result.data = response.payload;
          } else {
            console.log('Error fetching seller central report status:', response);
          }

          return result;
        },

        refetchInterval: 5 * 60 * 1000,
        enabled: !isNil(activeTeam) && !isNil(activeTeam.profiles) && profile.id == activeProfile?.id,
      })) || [],
  });

  const { data: allTeamSellerCentralStatuses, isFetching: isFetchingAllTeamSellerCentralStatuses } = useQuery({
    queryKey: [QUERY_SELLER_CENTRAL_STATUS_REPORT_KEY, activeTeam],
    queryFn: async () => {
      const result = await sellerCentralService.getAllTeamReportsStatus();
      if (result.isSuccess) {
        for (const profileId in result.payload.statuses_per_profile) {
          removeProfileFromLocalSellerCentralLoadingState(profileId);
        }

        return result.payload;
      } else {
        throw new Error('Error loading seller central statuses\n' + JSON.stringify(result));
      }
    },
    refetchInterval: 5 * 60 * 1000,
    enabled: !isNil(activeTeam) && !isNil(activeTeam.profiles) && isFetchReportsForAllProfiles,
  });

  function getSellerCentralQueryByProfileID(
    profileId: string | null | undefined,
  ): UseQueryResult<SellerCentralReportStatusQueryResult, Error> | undefined {
    return sellerCentralReportStatusQueries.find((query) => query.data?.profileId === profileId);
  }

  // So that new references aren't created when there's no value change
  const profileReportStatusCache = useRef<Map<string, ProfileReportsStatus>>(new Map());
  function getReportStatusInfoByProfileId(profileId: string | undefined): ProfileReportsStatus {
    let result: ProfileReportsStatus = defaultProfileReportsStatusInfo;

    if (profileId) {
      if (!isFetchReportsForAllProfiles) {
        const query = getQueryByProfileID(profileId);

        if (query) {
          const reportData = query.data?.data;
          const profileReportInfo = getProfileReportInfo(reportData || null);

          if (localProfilesInLoadingState.has(profileId) || query.isFetching) {
            result = {
              ...profileReportInfo,
              status: ReportingStatusType.WAITING_RESPONSE,
            };
          } else {
            result = profileReportInfo;
          }
        }
      } else {
        const reportData = allTeamStatuses?.statuses_per_profile[profileId];

        const profileReportInfo = getProfileReportInfo(reportData || null);

        if (localProfilesInLoadingState.has(profileId) || isFetchingAllTeamStatuses) {
          result = {
            ...profileReportInfo,
            status: ReportingStatusType.WAITING_RESPONSE,
          };
        } else {
          result = profileReportInfo;
        }
      }
    }

    // Check against cache
    const cachedResult = profileReportStatusCache.current.get(profileId!);
    if (cachedResult && isEqual(result, cachedResult)) {
      return cachedResult;
    }

    profileReportStatusCache.current.set(profileId!, result);
    return result;
  }

  const activeProfileReportsStatusInfo = getReportStatusInfoByProfileId(activeProfile?.id);

  const lastNonWaitingResponseProfileStatesRef = useRef<Map<string, ReportingStatusType>>(new Map());
  function triggerProfileSyncStatusAskedEvent(profileId: string | undefined, reportingStatusType: ReportingStatusType) {
    if (!profileId) return;

    // Track profile status going from loading (report is being processed) to completed in a map
    const lastNonWaitingResponseProfileState = lastNonWaitingResponseProfileStatesRef.current.get(profileId);
    const isPreviousStateLoading = !!(
      lastNonWaitingResponseProfileState &&
      [ReportingStatusType.NEVER, ReportingStatusType.LOADING].includes(lastNonWaitingResponseProfileState)
    );
    const isCurrentStateCompleted = reportingStatusType === ReportingStatusType.COMPLETED;

    if (isPreviousStateLoading && isCurrentStateCompleted) {
      fetchCache.removeAllForProfile(profileId);
    }

    const isCurrentStateNonWaitingResponse = reportingStatusType !== ReportingStatusType.WAITING_RESPONSE;
    if (isCurrentStateNonWaitingResponse) {
      lastNonWaitingResponseProfileStatesRef.current.set(profileId, reportingStatusType);
    }
  }

  function profileSyncStatus(profileId: string | undefined): SyncStatus {
    const isInLocalLoadingState = profileId
      ? localProfilesInLoadingState.has(profileId) || localProfilesSellerCentralInLoadingState.has(profileId)
      : false;

    // -- SOMETHING IS WRONG --
    // if reports status cannot be fetched from API
    const query = getQueryByProfileID(profileId);
    if (query?.isFetched && isNil(query?.data?.data)) {
      // Should not happen, but if it does let click sync
      const msg = `ReportsContext: did not receive any profileReportsData after fetching for active team ${activeTeam?.id}, active profile: ${activeProfile?.id}`;
      Sentry.captureMessage(msg, 'info');
      console.log(msg);

      return {
        canClickSync: true,
        reason: null,
        isLoading: isInLocalLoadingState,
      };
    }

    const profileReportsStatus = getReportStatusInfoByProfileId(profileId);
    triggerProfileSyncStatusAskedEvent(profileId, profileReportsStatus.status);
    const isProcessing = [ReportingStatusType.NEVER, ReportingStatusType.WAITING_RESPONSE, ReportingStatusType.LOADING].includes(
      profileReportsStatus.status,
    );

    // -- NO REPORTS (WARMING UP) --

    // If the profile has no reports (warming up) or it is old and all reports have been deleted
    if (profileReportsStatus.status === ReportingStatusType.NEVER) {
      // If an existing profile has existed in our system (i.e., we have a schema)
      // but it is removed from a team and we haven't updated data for months
      // Now, lets say we delete all reports older than a month, which means this profile has no reports
      // Thus, frontend shows warming up, but there is no process that will update it
      const profileCreatedAt = activeTeam?.profiles.find((p) => p.id === profileId)?.createdAt;
      const isOldProfile =
        (!isNil(profileCreatedAt) && AlDate.fromISO(profileCreatedAt)?.isBefore(new AlDate().minus({ hours: 1 }))) || false;

      return {
        canClickSync: isOldProfile && !isInLocalLoadingState,
        reason: isInLocalLoadingState ? SyncStatusReason.WAITING_RESPONSE : isProcessing ? SyncStatusReason.LOADING : null,
        isLoading: isProcessing || isInLocalLoadingState,
      };
    }

    // -- PROCESSING OR WAITING RESPONSE--
    if (isProcessing) {
      return {
        canClickSync: false,
        reason: isInLocalLoadingState ? SyncStatusReason.WAITING_RESPONSE : SyncStatusReason.LOADING,
        isLoading: true,
      };
    }
    if (profileReportsStatus.nextPossibleUpdate && AlDate.fromISO(profileReportsStatus.nextPossibleUpdate)?.isAfter(new AlDate())) {
      return {
        canClickSync: false,
        reason: SyncStatusReason.TOO_EARLY,
        isLoading: false,
      };
    }

    // -- PAYWALL --
    if (!isNil(profileReportsStatus.updatedAt)) {
      // When can't sync due plan restriction, still let click sync button to show paywall. PLAN_RESTRICTION has to be checked after sync is clicked
      const updatedAt = AlDate.parse(profileReportsStatus.updatedAt);
      if (!isNil(activeTeam) && !activeTeam.canSyncProfileByLastSync(updatedAt)) {
        return {
          canClickSync: activeTeam.subscriptionPlan.canAlwaysClickSync,
          reason: activeTeam.subscriptionPlan.canAlwaysClickSync ? SyncStatusReason.PLAN_RESTRICTION : SyncStatusReason.TOO_EARLY,
          isLoading: isProcessing || isInLocalLoadingState,
        };
      }
    }

    // -- ANYTHING ELSE --
    return {
      canClickSync: true,
      reason: null,
      isLoading: isProcessing || isInLocalLoadingState,
    };
  }

  async function updateProfileData(profileId: string): Promise<void> {
    const vendorProfileIds = user?.teams.flatMap((team) => team.profiles).map((profile) => profile.id);

    try {
      const reportsPromises = [profileService.createAllReports(profileId)];
      addProfileToLocalLoadingState(profileId);

      const profile = user?.teams.flatMap((team) => team.profiles).find((profile) => profile.id === profileId);
      if (profile) {
        // profile can be a seller or a vendor, not both
        if (profile.isSeller) {
          reportsPromises.push(sellerCentralService.createReport(profileId));
          addProfileToLocalSellerCentralLoadingState(profileId);
        } else if (profile.isVendor && activeTeam) {
          reportsPromises.push(vendorCentralService.createReport(activeTeam.id, profileId));

          vendorProfileIds?.forEach((profileId) => {
            addProfileToLocalVendorCentralLoadingState(profileId);
          });
        }
      }
      await Promise.all(reportsPromises);
      refetchProfileStatus(profileId);
    } catch (error) {
      console.error('Error updating profile data:', error);
      throw error;
    } finally {
      removeProfileFromLocalLoadingState(profileId);
      removeProfileFromLocalSellerCentralLoadingState(profileId);

      vendorProfileIds?.forEach((profileId) => {
        removeProfileFromLocalVendorCentralLoadingState(profileId);
      });
    }
  }

  const profileSellerCentralReportStatusCache = useRef<Map<string, ProfileReportsStatus>>(new Map());
  function getSellerCentralReportStatusInfoByProfileId(profileId: string | undefined): ProfileReportsStatus | undefined {
    if (!profileId || !isProfileSeller(profileId)) {
      return undefined;
    }

    let result: ProfileReportsStatus = defaultProfileReportsStatusInfo;

    if (profileId) {
      if (!isFetchReportsForAllProfiles) {
        const query = getSellerCentralQueryByProfileID(profileId);

        if (query) {
          const reportData = query.data?.data?.seller_central;

          if (localProfilesSellerCentralInLoadingState.has(profileId) || query.isFetching) {
            result = {
              status: ReportingStatusType.WAITING_RESPONSE,
              updatedAt: reportData?.updated_at,
              nextPossibleUpdate: '', // TODO
            };
          } else {
            result = {
              status: reportData?.status ?? ReportingStatusType.LOADING,
              updatedAt: reportData?.updated_at,
              nextPossibleUpdate: '', // TODO
            };
          }
        }
      } else {
        const reportData = allTeamSellerCentralStatuses?.statuses_per_profile[profileId]?.seller_central;

        if (localProfilesInLoadingState.has(profileId) || isFetchingAllTeamSellerCentralStatuses) {
          result = {
            status: ReportingStatusType.WAITING_RESPONSE,
            updatedAt: reportData?.updated_at,
            nextPossibleUpdate: '', // TODO
          };
        } else {
          result = {
            status: reportData?.status ?? ReportingStatusType.LOADING,
            updatedAt: reportData?.updated_at,
            nextPossibleUpdate: '', // TODO
          };
        }
      }
    }

    // Check against cache
    const cachedResult = profileSellerCentralReportStatusCache.current.get(profileId!);
    if (cachedResult && isEqual(result, cachedResult)) {
      return cachedResult;
    }

    profileSellerCentralReportStatusCache.current.set(profileId!, result);
    return result;
  }

  function getCombinedUpdatedAtByProfileId(profileId: string | undefined): string {
    const dataStatus = getReportStatusInfoByProfileId(profileId);
    let updatedAt = dataStatus?.updatedAt;

    if (dataStatus?.status == ReportingStatusType.NEVER) {
      return t(`enums.profile_status_labels.${ReportingStatusType.NEVER}`);
    }

    const sellerCentralDataStatus = getSellerCentralReportStatusInfoByProfileId(profileId);
    const sellerCentralUpdatedAt = sellerCentralDataStatus?.updatedAt;

    if (sellerCentralDataStatus) {
      if (!sellerCentralUpdatedAt || !updatedAt) {
        updatedAt = undefined;
      } else if (AlDate.parse(sellerCentralUpdatedAt) < AlDate.parse(updatedAt)) {
        updatedAt = sellerCentralUpdatedAt;
      }
    }

    const vendorCentralDataStatus = getVendorCentralReportStatusInfoByProfileId(profileId);
    const vendorCentralUpdatedAt = vendorCentralDataStatus?.updatedAt;
    if (vendorCentralDataStatus) {
      if (!vendorCentralUpdatedAt || !updatedAt) {
        updatedAt = undefined;
      } else if (AlDate.parse(vendorCentralUpdatedAt) < AlDate.parse(updatedAt)) {
        updatedAt = vendorCentralUpdatedAt;
      }
    }

    return isNil(updatedAt) ? '24h+ ago' : updatedAt;
  }

  return (
    <ReportsContext.Provider
      value={{
        updateProfileData,
        profileSyncStatus,
        activeProfileSyncStatus: profileSyncStatus(activeProfile?.id),
        activeProfileReportsStatusInfo,
        isActiveProfileReportsBeingProcessed: profileSyncStatus(activeProfile?.id).isLoading,
        activeProfileHasProcessedReports: activeProfileReportsStatusInfo.status != ReportingStatusType.NEVER,
        refetchProfileStatus,
        getReportStatusInfoByProfileId,
        getSellerCentralReportStatusInfoByProfileId,
        getVendorCentralReportStatusInfoByProfileId,
        getCombinedUpdatedAtByProfileId,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export function useReportsContext(): ReportsContextType {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReportsContext must be used within a ReportsProvider');
  }
  return context;
}

function getProfileReportInfo(profileReport: ProfileReportsStatusDTO | null): ProfileReportsStatus {
  if (!profileReport) {
    return {
      status: ReportingStatusType.ERROR,
      updatedAt: undefined,
      nextPossibleUpdate: undefined,
    };
  }
  let updatedAt: string | undefined = undefined;
  let isFirstUpdatedAtSet = false;
  const statuses = new Set<ReportingStatusType>();
  // Loop over all top-level keys in ProfileReportsStatusDTO
  for (const key of Object.keys(profileReport) as Array<keyof ProfileReportsStatusDTO>) {
    // TODO: Refactor
    if (key == 'next_possible_update' || key == 'update_ongoing') {
      continue;
    }

    const adTypeReport = profileReport[key];

    for (const subKey of Object.keys(adTypeReport) as Array<keyof AdTypeReportStatusDTO>) {
      const reportingStatus = adTypeReport[subKey];

      if (reportingStatus?.status === ReportingStatusType.UNUSED) {
        continue;
      }

      // Updated at should be based on oldest processed report
      if (!isFirstUpdatedAtSet) {
        updatedAt = reportingStatus?.updated_at;
        isFirstUpdatedAtSet = true;
      } else if (updatedAt && reportingStatus?.updated_at && AlDate.parse(reportingStatus.updated_at) < AlDate.parse(updatedAt)) {
        updatedAt = reportingStatus?.updated_at;
      } else if (!reportingStatus?.updated_at) {
        updatedAt = reportingStatus?.updated_at;
      }

      if (!isNil(reportingStatus?.status) && !isEmpty(reportingStatus.status)) {
        statuses.add(reportingStatus.status);
      }
    }
  }

  let reportsCollectiveStatus = ReportingStatusType.COMPLETED;
  // Order collective status by severity
  for (const status of REPORT_STATUSES_ORDERED_BY_SEVERITY) {
    if (statuses.has(status)) {
      reportsCollectiveStatus = status;
      break;
    }
  }

  return {
    status: profileReport.update_ongoing ? ReportingStatusType.LOADING : reportsCollectiveStatus,
    updatedAt,
    nextPossibleUpdate: profileReport.next_possible_update,
  };
}
