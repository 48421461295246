import OppositeModeOverlayBar from '@/components/OppositeModeOverlayBar';
import useFormatting from '@/hooks/useFormatting';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { SelectedNegativeTarget } from '../models/NegativeTargetsModel';
import NegativeTargetingBulkEditPopover from './NegativeTargetingBulkEditPopover';

interface ActionsBarProps {
  selectedNegativeTargeting: SelectedNegativeTarget[];
  totalNegativeTargetingCount: number;
  onDiscardClicked: () => void;
}

const NegativeTargetingSelectionActionsBar: FunctionComponent<ActionsBarProps> = ({
  selectedNegativeTargeting: selectedNegativeTargets,
  totalNegativeTargetingCount,
  onDiscardClicked,
}) => {
  const { formatWithThousandsSeparator } = useFormatting();

  // Bulk Edit
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isBulkEditPopoverOpen, setIsBulkEditPopoverOpen] = useState(false);
  const handleBulkEditPopoverClose = () => setIsBulkEditPopoverOpen(false);

  const onBulkEditClicked = () => {
    setIsBulkEditPopoverOpen(true);
  };

  //   const { preBulkEditInfo } = useNegativeTargetingWarnings({ selectedNegativeTargeting });

  //   const warningTooltipContent = preBulkEditInfo.warnings ? (
  //     <>
  //       {preBulkEditInfo.warnings.map((warning, index) => (
  //         <Fragment key={index}>
  //           {'\u24D8'} {warning}
  //           <br />
  //         </Fragment>
  //       ))}
  //     </>
  //   ) : null;

  return (
    <>
      <OppositeModeOverlayBar visible={selectedNegativeTargets.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedNegativeTargets.length)} of {formatWithThousandsSeparator(totalNegativeTargetingCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            <Button
              ref={bulkEditButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={onBulkEditClicked}
              variant={isBulkEditPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AssignmentOutlinedIcon />}
            >
              Bulk Actions
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <NegativeTargetingBulkEditPopover
        buttonRef={bulkEditButtonRef}
        isOpen={isBulkEditPopoverOpen}
        onClose={handleBulkEditPopoverClose}
        selectedNegativeTargets={selectedNegativeTargets}
      />
    </>
  );
};

export default NegativeTargetingSelectionActionsBar;
