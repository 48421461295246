import { VideoUrl } from '@/config/urls';
import useFormatting from '@/hooks/useFormatting';
import { DataGroupsPopover } from '@/modules/data-groups/components/DataGroupsPopover';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Button, Checkbox, Divider, Tooltip, useTheme } from '@mui/material';
import { isNil } from 'lodash-es';
import { Fragment, FunctionComponent, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import useTargetingWarnings from '../hooks/useTargetingWarnings';
import { SelectedTarget } from '../models/TargetsModel';
import TargetingBulkEditPopover from './TargetingBulkEditPopover';

interface TargetingSelectionActionsBarProps {
  selectedTargets: SelectedTarget[];
  totalTargetsCount: number;
  onDiscardClicked: () => void;
  pageVariables: MetricsGraphTablePageContext;
}

const TargetingSelectionActionsBar: FunctionComponent<TargetingSelectionActionsBarProps> = ({
  selectedTargets,
  totalTargetsCount,
  onDiscardClicked,
  pageVariables,
}) => {
  const { activeTeam } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  // Paywall
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  // Bulk Edit
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isBulkEditPopoverOpen, setIsBulkEditPopoverOpen] = useState(false);
  const handleBulkEditPopoverClose = () => setIsBulkEditPopoverOpen(false);
  const theme = useTheme();

  const onBulkEditClicked = () => {
    setIsBulkEditPopoverOpen(true);
  };

  const { warnings } = useTargetingWarnings({ selectedTargets });

  const warningTooltipContent = warnings ? (
    <>
      {warnings.map((warning, index) => (
        <Fragment key={index}>
          {'\u24D8'} {warning}
          <br />
        </Fragment>
      ))}
    </>
  ) : null;

  // Tags
  const dataGroupsButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDataGroupsPopoverOpen, setIsDataGroupsPopoverOpen] = useState(false);
  const onDataGroupsClicked = () => {
    if (isNil(activeTeam)) {
      toastService.error('Active team is not set');
      return;
    }
    if (!activeTeam.subscriptionPlan.canAssignDataGroups) {
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDataGroupsPopoverOpen(true);
  };

  return (
    <>
      <OppositeModeOverlayBar visible={selectedTargets.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedTargets.length)} of {formatWithThousandsSeparator(totalTargetsCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            {warnings.length > 0 && (
              <Tooltip title={warningTooltipContent}>
                <span className="flex items-center font-medium">
                  <InfoIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.info.light }} />
                  {formatWithThousandsSeparator(warnings.length)} {warnings.length == 1 ? 'Notice' : 'Notices'}
                </span>
              </Tooltip>
            )}

            <Button
              ref={bulkEditButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={onBulkEditClicked}
              variant={isBulkEditPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AssignmentOutlinedIcon />}
            >
              Bulk Actions
            </Button>
            <Button
              ref={dataGroupsButtonRef}
              size="small"
              className="whitespace-nowrap "
              onClick={onDataGroupsClicked}
              variant={isDataGroupsPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AccountTreeIcon />}
            >
              Tags
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <TargetingBulkEditPopover
        buttonRef={bulkEditButtonRef}
        isOpen={isBulkEditPopoverOpen}
        onClose={handleBulkEditPopoverClose}
        selectedTargets={selectedTargets}
        pageVariables={pageVariables}
      />

      <DataGroupsPopover
        buttonRef={dataGroupsButtonRef}
        isOpen={isDataGroupsPopoverOpen}
        setIsOpen={setIsDataGroupsPopoverOpen}
        selectedItems={selectedTargets}
        dataGroupType={DataGroupType.TARGET}
      />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.OPTIMIZER}
        headerText="Upgrade to Pro to Access Tags"
        videoUrl={VideoUrl.DATA_GROUPS}
      ></PaywallModal>
    </>
  );
};

export default TargetingSelectionActionsBar;
