import { useDataGroups } from '@/hooks/useDataGroups';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { SelectedTarget } from '@/modules/targeting/models/TargetsModel';
import { toastService } from '@/services/toast.service';
import { Check, DeleteSweep } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isFinite } from 'lodash-es';
import { FunctionComponent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { dataGroupsService } from '../api/data-groups-service';
import { DataGroupModel } from '../models/DataGroup';
import { DataItemModel, SelectedEntityForDataGroup } from '../models/DataItem';
import { DataGroupEntityType, DataGroupType, DeletedAssignment, EntityIdAndType } from '../models/data-groups-contracts';

interface AssignRemoveDataGroupsProps {
  selectedItems: SelectedEntityForDataGroup[];
  existingGroups: DataGroupModel[];
  onClose: () => void;
  onCreateClicked: () => void;
  dataGroupType: DataGroupType;
  preferredAssignDataGroup: DataGroupModel | undefined;
}

export const AssignRemoveDataGroups: FunctionComponent<AssignRemoveDataGroupsProps> = ({
  selectedItems,
  existingGroups,
  onClose,
  onCreateClicked,
  dataGroupType,
  preferredAssignDataGroup,
}) => {
  const [selectedDataGroupId, setSelectedDataGroupId] = useState<number | undefined>(preferredAssignDataGroup?.id || undefined);
  const [defaultSelectedDataGroup] = useState<DataGroupModel | undefined>(preferredAssignDataGroup || undefined); // From just created
  const [selectedItem, setSelectedItem] = useState<DataItemModel | null>(null);
  const [isApplyInProgress, setIsApplyInProgress] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createGroupIdToItemSetMap } = useDataGroups([]);

  const existingGroupsWithCurrentType = existingGroups.filter((group) => group.type == dataGroupType);

  const groupIdToItemSetMap = useMemo(() => createGroupIdToItemSetMap(existingGroupsWithCurrentType), [existingGroupsWithCurrentType]);

  const removableEntities = useMemo(() => {
    if (!selectedDataGroupId) {
      return [];
    }
    return createDeletedAssignments(selectedItems, groupIdToItemSetMap.get(selectedDataGroupId) ?? new Set<number>());
  }, [selectedItems, selectedDataGroupId]);

  const entitiesWithAssignedGroupCount = removableEntities.length;
  async function onApplyClicked() {
    setIsApplyInProgress(true);

    if (!selectedDataGroupId || !selectedItem?.id) {
      return;
    }

    const entities = createEntityIdAndTypeArray(selectedItems);

    const res = await dataGroupsService.assignItemToEntities(entities, selectedDataGroupId, selectedItem.id);
    if (res.isSuccess) {
      dataGroupsService.invalidateEntitiesContainingDataGroupsByType(dataGroupType, queryClient);
      toastService.success(`Assignment successful`);
    } else {
      toastService.error(`Error assigning tags: '${res.message}'`);
    }

    setIsApplyInProgress(false);
    onClose();
  }

  async function handleRemoveAll() {
    setIsApplyInProgress(true);

    const res = await dataGroupsService.removeAssignments(removableEntities);
    if (res.isSuccess) {
      toastService.success(`Assignments removed successfully`);
    } else {
      toastService.error(`Error removing assignments: '${res.message}'`);
    }

    if (removableEntities.length > 0) {
      dataGroupsService.invalidateEntitiesContainingDataGroupsByType(dataGroupType, queryClient);
    }

    setIsApplyInProgress(false);
    onClose();
  }

  const getApplyButtonText = () => {
    if (selectedItems.length == 0) {
      return 'Nothing Selected';
    } else if (!selectedDataGroupId) {
      return 'No Tags Selected';
    }

    return `Assign value to ${selectedItems.length} ` + (selectedItems.length > 1 ? 'Entities' : 'Entity');
  };

  return (
    <>
      <DialogTitle className="text-md">Assign/Remove Tags</DialogTitle>
      <DialogContent>
        <>
          {selectedItems.length > 0 && (
            <div className="flex flex-row items-center justify-between font-bold mb-2 text-sm">
              <div>
                {selectedItems.length} {selectedItems.length > 1 ? 'Entities' : 'Entity'} Selected
              </div>
              <LoadingButton
                variant="text"
                disabled={entitiesWithAssignedGroupCount == 0}
                startIcon={<DeleteSweep />}
                onClick={handleRemoveAll}
                color="error"
                loading={isApplyInProgress}
                loadingPosition="start"
              >
                {entitiesWithAssignedGroupCount == 0
                  ? 'No selected entities with selected tag'
                  : `Remove selected Tags from ${entitiesWithAssignedGroupCount} Entities`}
              </LoadingButton>
            </div>
          )}
        </>

        <Autocomplete
          id="select-data-group-name"
          defaultValue={defaultSelectedDataGroup ?? undefined}
          onChange={(event, newValue) => {
            setSelectedDataGroupId(newValue ? newValue.id : 0);
            setSelectedItem(null);
          }}
          options={existingGroupsWithCurrentType}
          getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Tag to Assign/Remove"
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
          )}
        />
        <Autocomplete
          id="select-data-group-value"
          disabled={!selectedDataGroupId}
          value={selectedItem}
          onChange={(event, newValue) => {
            setSelectedItem(newValue);
          }}
          options={existingGroupsWithCurrentType.filter((group) => group.id === selectedDataGroupId)[0]?.items ?? []}
          getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Value to Assign"
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
          )}
        />
        <div className="flex flex-row justify-between">
          <Button autoFocus variant="text" startIcon={<AddIcon />} onClick={onCreateClicked}>
            Create New Tag
          </Button>
          <Button variant="text" startIcon={<AutoAwesomeMotionIcon />} onClick={() => navigate('/data-groups')}>
            Manage Tags
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={'text'}>
          Cancel
        </Button>

        <LoadingButton
          onClick={onApplyClicked}
          loading={isApplyInProgress}
          loadingPosition="start"
          startIcon={<Check />}
          variant="contained"
          disabled={
            selectedItems.length == 0 ||
            existingGroups.length == 0 ||
            selectedDataGroupId === undefined ||
            selectedDataGroupId === 0 ||
            !isFinite(selectedItem?.id) ||
            !selectedItem?.id
          }
        >
          <span>{getApplyButtonText()}</span>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

function createDeletedAssignments(selectedItems: SelectedEntityForDataGroup[], selectedGroupItemIdSet: Set<number>): DeletedAssignment[] {
  const deletedAssignments: DeletedAssignment[] = [];

  selectedItems.forEach((item) => {
    // Find the first matching dataItemId in selectedGroupItemIdSet
    const matchingItemId = item.dataItemIds.find((id) => selectedGroupItemIdSet.has(id));
    const type = getEntityType(item);
    // If a matching item is found, create a DeletedAssignment object
    if (matchingItemId !== undefined) {
      deletedAssignments.push({
        entity_id: item.id,
        entity_type: type,
        item_id: matchingItemId,
      });
    }
  });

  return deletedAssignments;
}

function createEntityIdAndTypeArray(selectedItems: SelectedEntityForDataGroup[]): EntityIdAndType[] {
  return selectedItems.map((item) => ({
    id: item.id,
    entity_type: getEntityType(item),
  }));
}

function getEntityType(item: SelectedEntityForDataGroup): DataGroupEntityType {
  if (item.dataGroupType === DataGroupType.TARGET) {
    switch ((item as SelectedTarget).entityType) {
      case TargetEntityType.KEYWORD:
        return DataGroupEntityType.KEYWORD;
      case TargetEntityType.PRODUCT_TARGET:
      default:
        return DataGroupEntityType.TARGET;
    }
  } else if (item.dataGroupType === DataGroupType.CAMPAIGN) {
    return DataGroupEntityType.CAMPAIGN;
  } else if (item.dataGroupType === DataGroupType.SEARCHTERM) {
    return DataGroupEntityType.SEARCHTERM;
  } else if (item.dataGroupType === DataGroupType.PRODUCT) {
    return DataGroupEntityType.PRODUCT;
  } else {
    // This will cause a compile-time error if any case is not handled
    const exhaustiveCheck: never = item.dataGroupType;
    throw new Error(`Unhandled tag type: ${exhaustiveCheck}`);
  }
}
