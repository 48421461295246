import { IAggFuncParams, IRowNode } from 'ag-grid-enterprise';
import {
  CommonMetricField,
  MetricField,
  ProfileStatsSellerMetricField,
  SellerMetricField,
  VendorMetricField,
} from '../metrics/types/MetricField';

export type EnumToCount<E extends Record<string, string | number>, K extends keyof E = keyof E> = Partial<Record<K, number>>;

export type StringToCount = Record<string, number>;

export type BooleanCountsType = {
  trueCount: number;
  falseCount: number;
};

export function isStringToCount(obj: unknown): obj is StringToCount {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const recordObj = obj as Record<string, unknown>;

  for (const key in recordObj) {
    if (!Object.prototype.hasOwnProperty.call(recordObj, key) || typeof recordObj[key] !== 'number') {
      return false;
    }

    // Check only first
    return true;
  }

  return true;
}

export const NONE_LABEL = '(None)';

export function countEnumValues<T extends Record<string, string | number>>(enumValues: T, states: T[keyof T][]): EnumToCount<T> {
  // Initialize counts for all enum values
  const initialState: EnumToCount<T> = Object.values(enumValues).reduce((acc, value) => {
    acc[value as keyof EnumToCount<T>] = 0;
    return acc;
  }, {} as EnumToCount<T>);

  // Count occurrences of each enum value
  return states.reduce((acc, state) => {
    if ((state as keyof EnumToCount<T>) in acc) {
      const key = state as keyof EnumToCount<T>;
      acc[key] = (acc[key] || 0) + 1;
    }
    return acc;
  }, initialState);
}

export function collectLeafNodeValues<T>(params: IAggFuncParams): T[] {
  const leafNodeValues: T[] = [];
  const field = params.colDef.field;

  // Function to recursively collect leaf node values
  function collectLeafNodeValues_recursive(rowNode: IRowNode) {
    if (rowNode.group && rowNode.childrenAfterGroup) {
      // Iterate over child nodes if it's a group node and children are not null
      rowNode.childrenAfterGroup.forEach((childNode) => collectLeafNodeValues_recursive(childNode));
    } else {
      // Collect the value for leaf nodes
      if (field && rowNode.data && rowNode.data[field] !== undefined) {
        leafNodeValues.push(rowNode.data[field]);
      }
    }
  }

  // Start collecting values from the current group node
  collectLeafNodeValues_recursive(params.rowNode);

  return leafNodeValues;
}

function safeDivide(numerator: number, denominator: number): number {
  return denominator === 0 ? 0 : numerator / denominator;
}

function calculateMetricDataHelper(
  aggData: Record<string, number[]>,
  numeratorKey: MetricField,
  denominatorKey: MetricField,
  multiplier = 1,
): number[] {
  const numerator = aggData[numeratorKey] || [];
  const denominator = aggData[denominatorKey] || [];

  // Ensure we calculate for the length of the larger array
  const length = Math.max(numerator.length, denominator.length);

  return Array.from({ length }, (_, i) => safeDivide(numerator[i] || 0, denominator[i] || 0) * multiplier);
}

export function calculateMetricDataCTR(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.CLICKS, CommonMetricField.IMPRESSIONS);
}

export function calculateMetricDataCVR(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.ORDERS, CommonMetricField.CLICKS);
}

export function calculateMetricDataCPC(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.CLICKS);
}

export function calculateMetricDataACOS(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.SALES);
}

export function calculateMetricDataACTC(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.CLICKS, CommonMetricField.ORDERS);
}

export function calculateMetricDataROAS(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SALES, CommonMetricField.SPEND);
}

export function calculateMetricDataRPC(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SALES, CommonMetricField.CLICKS);
}

export function calculateMetricDataCPA(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.ORDERS);
}

export function calculateMetricDataAOV(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SALES, CommonMetricField.ORDERS);
}

export function calculateMetricDataCPM(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.IMPRESSIONS, 1000);
}

// Seller metrics
export function calculateMetricDataACOTS(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SPEND, SellerMetricField.SELLER_SALES);
}

export function calculateMetricDataTACTC(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, SellerMetricField.SELLER_CLICKS, SellerMetricField.SELLER_ORDERS);
}

export function calculateMetricDataASP(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_UNITS);
}

export function calculateMetricDataTCVR(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, SellerMetricField.SELLER_ORDERS, SellerMetricField.SELLER_CLICKS);
}

export function calculateMetricDataUSP(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_CLICKS);
}

export function calculateMetricDataAdSalesOfTotal(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SALES, SellerMetricField.SELLER_SALES);
}

export function calculateMetricDataTotalAOV(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_ORDERS);
}

export function calculateMetricDataTotalCPA(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SPEND, SellerMetricField.SELLER_ORDERS);
}

export function calculateMetricDataTotalROAS(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, SellerMetricField.SELLER_SALES, CommonMetricField.SPEND);
}

export function calculateMetricDataUnitView(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_VIEWS);
}

export function calculateMetricDataUnitRefundRate(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED, SellerMetricField.SELLER_UNITS);
}

// Vendor Metrics
export function calculateMetricDataVendorACOTS(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SPEND, VendorMetricField.VENDOR_SALES);
}

export function calculateMetricDataVendorASP(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_SALES, VendorMetricField.VENDOR_UNITS);
}

export function calculateMetricDataVendorAdSalesOfTotal(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, CommonMetricField.SALES, VendorMetricField.VENDOR_SALES);
}

export function calculateMetricDataVendorTotalROAS(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_SALES, CommonMetricField.SPEND);
}

export function calculateMetricDataVendorUnitView(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_UNITS, VendorMetricField.VENDOR_VIEWS);
}

export function calculateMetricDataVendorReturnRate(aggData: Record<string, number[]>): number[] {
  return calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_CUSTOMER_RETURNS, VendorMetricField.VENDOR_UNITS);
}
