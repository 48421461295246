import { IHeaderParams } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useState } from 'react';

const NUMERIC_COLUMN = 'numericColumn';

const DefaultHeaderRenderer: FunctionComponent<IHeaderParams> = (props) => {
  const { displayName, column, api, progressSort, enableSorting, showColumnMenu } = props;

  const columnType = column.getColDef().type;

  const handleLabelClick = () => {
    if (!enableSorting) return;

    progressSort();
    setIsSortDescending(column.getSort() === 'desc');
    setIsSortAscending(column.getSort() === 'asc');
  };

  const showColumnMenuLocal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    showColumnMenu(event.currentTarget);
  };

  const [isSortDescending, setIsSortDescending] = useState(column.getSort() === 'desc');
  const [isSortAscending, setIsSortAscending] = useState(column.getSort() === 'asc');

  useEffect(() => {
    const sortChangedHandler = () => {
      setIsSortDescending(column.getSort() === 'desc');
      setIsSortAscending(column.getSort() === 'asc');
    };

    api.addEventListener('sortChanged', sortChangedHandler);

    return () => {
      if (!api.isDestroyed()) api.removeEventListener('sortChanged', sortChangedHandler);
    };
  }, []);

  const showMenu = !column.getColDef().suppressColumnsToolPanel;
  return (
    <div className="w-full flex flex-row items-center justify-start relative">
      {showMenu && (
        <div
          className={`ag-header-cell-menu-button ag-header-menu-icon flex items-center z-2 h-full  absolute ${
            columnType === NUMERIC_COLUMN ? 'left-0' : 'right-0'
          }`}
        >
          <div onClick={showColumnMenuLocal} className="cursor-pointer ag-icon ag-icon-menu-alt"></div>
        </div>
      )}
      <div
        onClick={handleLabelClick}
        className={`flex whitespace-normal cursor-pointer flex-row items-center ${
          columnType === NUMERIC_COLUMN ? 'justify-end' : 'justify-start'
        } w-full`}
      >
        <span className="text-wrap line-clamp-3">{displayName}</span>
        {isSortAscending === true && <span className="ag-icon ag-icon-asc"></span>}
        {isSortDescending === true && <span className="ag-icon ag-icon-desc"></span>}
      </div>
    </div>
  );
};

export default DefaultHeaderRenderer;
