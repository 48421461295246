import { getSeparatedValuesFromText, truncateString } from '@/modules/application/utils';
import { Autocomplete, AutocompleteInputChangeReason, Chip, TextField, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { ClipboardEvent, KeyboardEvent, FunctionComponent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel, LogicalOperatorType, OperatorType } from '../models/AlFilterModel';
import { StringComparisonOperatorSelect, StringComparisonOperatorSelectOption } from './StringComparisonOperatorSelect';

interface FilterStringComparisonProps {
  filter: AlFilterModel;
  label: string;
}

export const FilterStringComparison: FunctionComponent<FilterStringComparisonProps> = ({ filter }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const filterKey = filter.key;

  const [tags, setTags] = useState<string[]>(
    filter.conditions && Array.isArray(filter.conditions[0].values) ? filter.conditions[0].values.map(String) : [],
  );
  const [inputValue, setInputValue] = useState<string>('');

  const defaultStringComparisonOperator = filter.conditions ? filter.conditions[0].operator : filter.defaultConditionOperators[0];

  const [stringComparisonOperator, setStringComparisonOperator] = useState(defaultStringComparisonOperator);

  const getTextFieldLabel = () => {
    switch (stringComparisonOperator) {
      case OperatorType.LIKE:
        return 'Contains';
      case OperatorType.NOT_LIKE:
        return "Doesn't Contain";
      default:
        return 'Partial Name';
    }
  };

  const [logicalOperator, setLogicalOperator] = useState(
    filter.logicalOperator ? filter.logicalOperator : filter.isInverseFilter ? LogicalOperatorType.AND : LogicalOperatorType.OR,
  );

  const handleInputChange = (event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      const inputValueTrimmed = inputValue.trim();
      if (isValueValid(inputValueTrimmed)) {
        setTags([...tags, inputValueTrimmed]);
        setInputValue('');
        event.preventDefault(); // Prevent form submission if inside a form
      }
    } else if (event.key === 'Backspace' && !inputValue) {
      setTags((prevTags) => prevTags.slice(0, -1));
    }
  };

  const handleBlur = () => {
    if (isValueValid(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const isValueValid = (value: string): boolean => {
    if (isEmpty(value)) {
      return false;
    }

    const isTagDuplicate = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
    if (isTagDuplicate) {
      toastService.error(value + ' is already in the list');
    }

    return !isTagDuplicate;
  };

  const handleDelete = (indexToDelete: number) => {
    setTags((prevTags) => prevTags.filter((_, index) => index !== indexToDelete));
  };

  const handleOperatorChange = (newSelection: StringComparisonOperatorSelectOption) => {
    setStringComparisonOperator(newSelection.operator);
    setLogicalOperator(newSelection.logicalOperator);
  };

  useEffect(() => {
    filter.logicalOperator = logicalOperator;
    filter.conditions = [
      {
        values: tags,
        operator: stringComparisonOperator,
      },
    ];

    setFilterValue(filter);
  }, [tags, stringComparisonOperator, logicalOperator]);

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the default paste behavior
    const newTags = getSeparatedValuesFromText(event.clipboardData.getData('Text'), tags);

    setTags((prevTags) => [...new Set([...prevTags, ...newTags])]); // Combine new and existing tags, removing duplicates
    setInputValue(''); // Clear the input field after processing the paste
  };

  return (
    <div className="flex flex-row gap-2 w-full">
      <div className="flex-0">
        <StringComparisonOperatorSelect
          label={'Operator'}
          filter={filter}
          handleChange={handleOperatorChange}
          defaultOperatorValue={stringComparisonOperator}
          defaultLogicalOperatorValue={logicalOperator}
        />
      </div>

      <div className="flex-1 w-full">
        <Autocomplete
          renderInput={(params) => (
            <TextField
              label={getTextFieldLabel()}
              {...params}
              placeholder="Type the phrase and press [ENTER] to add it to the list"
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
          )}
          id={'input-' + filterKey}
          clearIcon={false}
          options={[]}
          freeSolo
          multiple
          value={tags} // Setting value to the existing tags
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          clearOnBlur
          renderTags={(value) =>
            value.map((tag, index) => (
              <Tooltip key={index} title={tag}>
                <Chip className="m-0.5" size="small" label={truncateString(tag)} onDelete={() => handleDelete(index)} />
              </Tooltip>
            ))
          }
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onPaste={handlePaste}
        />
      </div>
    </div>
  );
};
