import useFormatting, { CurrencyPosition } from '@/hooks/useFormatting';
import { assertUnhandledCase } from '@/modules/application/utils';
import { BidUpdateData, BidUpdateOption, BidUpdateType } from '@/modules/bids/types/bulk-edit';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { InputAdornment, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import AlSelect from './AlSelect';

interface BidEditRowProps {
  title: ReactNode;
  options: BidUpdateOption[];
  bidUpdateData: BidUpdateData;
  handleBidUpdateTypeChange: (event: SelectChangeEvent<BidUpdateType>) => void;
  isBidEditAllowed: boolean;
  newBidValue: string;
  onNewBidValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  currency: CurrencyCode;
}

const BidEditRow: FunctionComponent<BidEditRowProps> = ({
  title,
  options,
  bidUpdateData,
  handleBidUpdateTypeChange,
  isBidEditAllowed,
  newBidValue,
  onNewBidValueChange,
  currency,
}) => {
  const { getCurrencySymbol, getCurrencySymbolPosition } = useFormatting();

  const currencySymbol = getCurrencySymbol(currency);
  const currencyPosition = getCurrencySymbolPosition(currency);

  function isCurrencyUpdateType(bidUpdateType: BidUpdateType): boolean {
    switch (bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        return true;
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
      case BidUpdateType.SET_CPC_TIMES_X:
      case BidUpdateType.NO_CHANGE:
        return false;
      default:
        // Make sure all cases are covered
        return assertUnhandledCase(bidUpdateType);
    }
  }

  function getLabel(bidUpdateType: BidUpdateType): string {
    switch (bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
        return `New Bid (${currencySymbol})`;
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        return `Bid adjustment (${currencySymbol})`;
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
        return 'Bid adjustment (%) points';
      case BidUpdateType.SET_CPC_TIMES_X:
        return 'Multiplier (X)';
      case BidUpdateType.NO_CHANGE:
        // Should not get here because with no change the field should not be visible
        return '';
      default:
        // Make sure all cases are covered
        return assertUnhandledCase(bidUpdateType);
    }
  }

  function getEndAdornmentSymbol(bidUpdateType: BidUpdateType): string {
    switch (bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        return currencySymbol;
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
        return '%';
      case BidUpdateType.SET_CPC_TIMES_X:
        return 'X';
      case BidUpdateType.NO_CHANGE:
        // Should not get here because with no change the field should not be visible
        return '';
      default:
        // Make sure all cases are covered
        return assertUnhandledCase(bidUpdateType);
    }
  }

  const numberInputLabel = getLabel(bidUpdateData.bidUpdateType);
  const adornmentSymbol = getEndAdornmentSymbol(bidUpdateData.bidUpdateType);

  const putAdornmentAtStart = currencyPosition === CurrencyPosition.LEFT && isCurrencyUpdateType(bidUpdateData.bidUpdateType);
  const adornmentData = putAdornmentAtStart
    ? { startAdornment: <InputAdornment position="start">{adornmentSymbol}</InputAdornment> }
    : { endAdornment: <InputAdornment position="end">{adornmentSymbol}</InputAdornment> };

  return (
    <div>
      <Typography variant="subtitle2">{title}</Typography>
      <div className="flex gap-x-4">
        <AlSelect
          label={'Update action'}
          value={bidUpdateData.bidUpdateType}
          options={options}
          onChange={handleBidUpdateTypeChange}
          renderOption={(item) => item.label}
          valueExtractor={(item) => item.value}
          disabled={!isBidEditAllowed}
        />
        {bidUpdateData.bidUpdateType !== BidUpdateType.NO_CHANGE && (
          <>
            <TextField
              fullWidth
              label={numberInputLabel}
              value={newBidValue}
              onChange={onNewBidValueChange}
              slotProps={{
                input: {
                  ...adornmentData,
                },

                htmlInput: { pattern: '[0-9]*' },
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BidEditRow;
