import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { AllTeamVendorCentralReportStatusesDTO, VendorCentralReportsStatusDTO } from './vendor-central-contracts';

class VendorCentralService {
  public basePath = 'vendorcentral';

  async createReport(teamId: number, profileId: string): Promise<ApiResponse<null>> {
    return await apiClient.post(`teams/${teamId}/profiles/${profileId}/${this.basePath}/reports/create`, {});
  }

  async createReportsForTeam(teamId: number): Promise<ApiResponse<null>> {
    return await apiClient.post(`teams/${teamId}/${this.basePath}/reports/create`, {});
  }

  async getStatus(activeTeamId: number, profileId: string): Promise<ApiResponse<VendorCentralReportsStatusDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<VendorCentralReportsStatusDTO>(`teams/${activeTeamId}/profiles/${profileId}/${this.basePath}/reports/status`);
  }

  async getAllTeamReportsStatus(): Promise<ApiResponse<AllTeamVendorCentralReportStatusesDTO>> {
    return await apiTeamClient.get<AllTeamVendorCentralReportStatusesDTO>(`vendorcentral/reports/status/all`);
  }
}

export const vendorCentralService = new VendorCentralService();
