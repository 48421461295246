import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function generateSearchTermsTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.SEARCH_TERM, hide: false, pinned: 'left' },
    { colId: ColumnId.HARVESTED, hide: false, pinned: 'left' },
    { colId: ColumnId.NEGATIVES, hide: false, pinned: 'left' },
    { colId: ColumnId.WORD_COUNT, hide: false },
    { colId: ColumnId.TARGETING, hide: false, pinned: 'left' },
    { colId: ColumnId.ENTITY_TYPE, hide: false, pinned: 'left' },
    { colId: ColumnId.BID, hide: false, pinned: 'left' },
    { colId: ColumnId.MATCH, hide: false, pinned: false },
    { colId: ColumnId.AD_GROUP, hide: true, pinned: false },
    { colId: ColumnId.AD_GROUP_STATE, hide: true, pinned: false },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false, pinned: false },
    { colId: ColumnId.PORTFOLIO_NAME, hide: false, pinned: false },
    { colId: ColumnId.STATE, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_STATE, hide: false, pinned: false },
    { colId: ColumnId.COST_TYPE, hide: true, pinned: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: true, pinned: false },
    { colId: ColumnId.GROUP_NAME, hide: false, pinned: false },
    { colId: ColumnId.SALES_PREVIOUS_DAYS, hide: false, pinned: false },
    { colId: ColumnId.SPEND_PREVIOUS_DAYS, hide: false, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.UNITS, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SAME_SKU_ORDERS, hide: false, pinned: false },
    { colId: ColumnId.SAME_SKU_SALES, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.ACTC, hide: true, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
  ];
}
