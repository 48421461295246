import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import EditableCellRenderer, { IEditableCellRendererParams } from '@/components/grid/cells/EditableCellRenderer';
import MultiRowCellRenderer from '@/components/grid/cells/MultiRowCellRenderer';
import MultipleChoiceCellRenderer, {
  MultipleChoiceCellRendererOption,
  MultipleChoiceUpdatedValue,
} from '@/components/grid/cells/MultipleChoiceCellRendererOptions';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import { AlColDef } from '@/components/grid/types';
import { TailwindColorVariant } from '@/config/theme/color.type';
import { useLayoutContext } from '@/contexts/LayoutContext';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib/i18n/useTranslate';
import useBidLimits from '@/modules/bids/hooks/useBidLimits';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { TargetEntityExtendedType, TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { TargetEntityTypeColors, getColorForText } from '@/types/colors.enum';
import { Add, Delete } from '@mui/icons-material';
import { Card, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  BodyScrollEvent,
  CellClassParams,
  CellClickedEvent,
  ColDef,
  EditableCallbackParams,
  GetDetailRowDataParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowDataUpdatedEvent,
  RowHeightParams,
  RowSelectedEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BiddingMethod } from '../../api/campaign-mapping-contracts';
import { campaignMappingService, invalidateProfile_campaignMappingQueryKeys } from '../../api/campaign-mapping-service';
import useCampaignToAdGroupsMappingData from '../../hooks/useCampaignToAdGroupsMappingData';
import { CampaignMappingInnerModel } from '../../models/CampaignMappingInnerModel';
import {
  CampaignMappingModel,
  CampaignToCampaignDataWithCampaignMappingAdGroupDataType,
  NewCampaignMappingPreset,
} from '../../models/CampaignMappingModel';
import { NewSingleMappingModal } from '../NewSingleMappingModal';
import { generateCampaignMappingTableColumnState } from './campaign-mapping-table.default-column-state';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 137;
const INNER_GRID_HEADER_HEIGHT = 32;
const INNER_GRID_ROW_HEIGHT = 32;

interface CampaignMappingTableProps {
  rowData: CampaignMappingModel[];
  isLoading: boolean;
  selectedCampaignMappings: CampaignMappingModel[];
  setSelectedCampaignMappings: (selectedCampaignMappings: CampaignMappingModel[]) => void;
  campaignMappingLoadingErrorMessage: string;
  isCampaignMappingLoadingError: boolean;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
}

interface CampaignMappingGridContext {
  campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;
  idsBeingDeleted: Set<string>;
}

interface CampaignMappingInnerGridContext {
  idsBeingDeleted: Set<string>;
  masterCellRendererParams: ICellRendererParams<CampaignMappingModel>;
}

const CampaignMappingTable: FunctionComponent<CampaignMappingTableProps> = ({
  rowData: mappingData,
  isLoading,
  setSelectedCampaignMappings,
  campaignMappingLoadingErrorMessage,
  isCampaignMappingLoadingError,
  noTopBorderRadius = false,
  onGridReadyCallback,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.CAMPAIGN_MAPPING_TABLE_COLUMN_STATE,
    generateCampaignMappingTableColumnState(),
  );
  const gridApiRef = useRef<GridApi<CampaignMappingModel>>();
  const gridContextRef = useRef<CampaignMappingGridContext>();

  const { onSourceScroll } = useLayoutContext();
  const { t } = useTranslation();
  const { formatCurrency, formatDateStringTimeNoHours } = useFormatting();
  const { toastWarnWithSetMessages } = useHelperComponents();
  const { getCampaignAdTypeCellRendererParams } = useColDefsFunctions();

  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();
  const { campaignToAdGroupsMap } = useCampaignToAdGroupsMappingData();

  const { getNewBidValue_byCurrentProfileMarketplaceLimits } = useBidLimits();

  const { checkboxColumnType, matchLongColumnType } = useColumnTypes();

  const [_, setIsLoadingUpdate] = useState(false); // TODO: use, indicate?

  function updateNegativesWithMultipleChoiceValues(
    params: ICellRendererParams<CampaignMappingModel>,
    updatedValues: MultipleChoiceUpdatedValue<CampaignMappingModel>[],
  ) {
    if (!gridApiRef.current || !params.node.data) return;

    const updatedData = params.node.data as CampaignMappingModel & Record<string, unknown>;
    for (const updatedValue of updatedValues) {
      if (updatedValue.id in updatedData) {
        updatedData[updatedValue.id as keyof typeof updatedData] = updatedValue.selected;
      }
    }

    params.node.setData(updatedData);
    updateNegatives(updatedData);
  }

  const emptyCellRenderer = { component: () => <div className="h-full flex items-center pb-1">–</div> };

  const columnDefs: ColDef<CampaignMappingModel>[] = useMemo(() => {
    const colDefs: AlColDef<CampaignMappingModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        type: 'checkboxColumnType',
        editable: false,
        hide: false,
        pinned: false,
        lockPosition: true,
        lockPinned: true,
        lockVisible: true,
        headerClass: 'pl-3',
        cellClass: (params: CellClassParams<CampaignMappingModel>) => {
          const defaultClass = `checkbox-column-cell `;
          if (!params.data) return defaultClass;

          if (params.data.matchTypes.length !== params.data.selectedMatchTypes.length && params.data.selectedMatchTypes.length > 0) {
            return defaultClass + 'force-indeterminate';
          }

          return defaultClass;
        },
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Source',
        width: 200,
        valueGetter: (params: ValueGetterParams<CampaignMappingModel>) =>
          `${params.data?.sourceCampaignName}\n${params.data?.sourceAdGroupName}`,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: MultiRowCellRenderer,
        },
      },
      {
        colId: ColumnId.CAMPAIGN_NAME_DESTINATION,
        headerName: 'Destination',
        width: 200,
        valueGetter: (params: ValueGetterParams<CampaignMappingModel>) =>
          `${params.data?.destinationCampaignName}\n${params.data?.destinationAdGroupName}`,
        cellRenderer: MultiRowCellRenderer,
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Ad Type',
        field: 'destinationCampaignAdType',
        width: 92,
        cellRenderer: TextCellRenderer,
        cellRendererSelector: (params: ICellRendererParams<CampaignMappingModel>) => {
          if (!params.data?.destinationCampaignAdType) {
            return emptyCellRenderer;
          }
          return undefined;
        },
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.ENTITY_TYPE,
        headerName: 'Type',
        field: 'destinationAdGroupEntityType',
        width: 72,
        aggFunc: 'stringToCountAggFunc',
        cellRendererSelector: (params: ICellRendererParams<CampaignMappingModel>) => {
          if (!params.data?.destinationAdGroupEntityType) {
            return emptyCellRenderer;
          }
          return undefined;
        },
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => (key ? t(`enums.bidding_entity_short.${key}`) : '-'),
            valueToColor: (key: string) => TargetEntityTypeColors[key as TargetEntityExtendedType] ?? TailwindColorVariant.GREEN,
            valueToTooltip: (key: string) => t(`enums.bidding_entity.${key}`),
          };
        },
      },
      {
        colId: ColumnId.NEGATIVE_AD_GROUP,
        headerName: 'Neg Src Ad Group',
        width: 130,
        cellRendererSelector: (params: ICellRendererParams<CampaignMappingModel>) => {
          const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType = params.context?.campaignToAdGroupsMap;

          const warningText = params.data?.createNegativeAdGroupsWarning(campaignToAdGroupsMap);
          if (!params.data || !params.data.destinationAdGroupEntityType || !isNil(warningText)) {
            return {
              component: () => (
                <div className="h-full flex items-center pb-1">
                  <Tooltip title={warningText}>
                    <span>–</span>
                  </Tooltip>
                </div>
              ),
            };
          }

          const keywordOptions: MultipleChoiceCellRendererOption<CampaignMappingModel>[] = [
            { id: 'adGroupNegativeExact', label: 'E', selected: params.data.adGroupNegativeExact, tooltip: 'Ad Group Negative Exact' },
            { id: 'adGroupNegativePhrase', label: 'P', selected: params.data.adGroupNegativePhrase, tooltip: 'Ad Group Negative Phrase' },
          ];

          const productTargetOptions: MultipleChoiceCellRendererOption<CampaignMappingModel>[] = [
            {
              id: 'adGroupNegativeProductTarget',
              label: 'PT',
              selected: params.data.adGroupNegativeProductTarget,
              tooltip: 'Ad Group Negative Product Target',
            },
          ];

          const options = params.data.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? keywordOptions : productTargetOptions;

          function updateValues(values: MultipleChoiceUpdatedValue<CampaignMappingModel>[]) {
            updateNegativesWithMultipleChoiceValues(params, values);
          }
          return {
            component: () => <MultipleChoiceCellRenderer options={options} updateValues={updateValues} />,
          };
        },
      },
      {
        colId: ColumnId.NEGATIVE_CAMPAIGN,
        headerName: 'Neg Src Campaign',
        width: 130,
        cellRendererSelector: (params: ICellRendererParams<CampaignMappingModel>) => {
          const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType = params.context?.campaignToAdGroupsMap;
          const warningText = params.data?.createNegativeCampaignWarning(campaignToAdGroupsMap);

          // TODO: move to component
          if (!params.data || !params.data.destinationAdGroupEntityType || !isNil(warningText)) {
            return {
              component: () => (
                <div className="h-full flex items-center pb-1">
                  <Tooltip title={warningText}>
                    <span>–</span>
                  </Tooltip>
                </div>
              ),
            };
          }

          const keywordOptions: MultipleChoiceCellRendererOption<CampaignMappingModel>[] = [
            { id: 'campaignNegativeExact', label: 'E', selected: params.data.campaignNegativeExact, tooltip: 'Campaign Negative Exact' },
            {
              id: 'campaignNegativePhrase',
              label: 'P',
              selected: params.data.campaignNegativePhrase,
              tooltip: 'Campaign Negative Phrase',
            },
          ];

          const productTargetOptions: MultipleChoiceCellRendererOption<CampaignMappingModel>[] = [
            {
              id: 'campaignNegativeProductTarget',
              label: 'PT',
              selected: params.data.campaignNegativeProductTarget,
              tooltip: 'Campaign Negative Product Target',
            },
          ];

          const options = params.data.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? keywordOptions : productTargetOptions;

          function updateValues(values: MultipleChoiceUpdatedValue<CampaignMappingModel>[]) {
            updateNegativesWithMultipleChoiceValues(params, values);
          }
          return {
            component: () => <MultipleChoiceCellRenderer options={options} updateValues={updateValues} />,
          };
        },
      },
      {
        colId: ColumnId.MATCH,
        headerName: 'Match Types',
        width: 125,
        field: 'matchTypes',
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignMappingModel>): IChipArrayCellRendererParams => {
          return {
            chipArrayChips:
              params.data?.matchTypes.map((item: MatchType) => {
                return {
                  value: t(`enums.match_type_short.${item}`),
                  color: getColorForText(item),
                  tooltip: t(`enums.match_type_long.${item}`),
                };
              }) ?? [],
          };
        },
        valueGetter: (params: ValueGetterParams<CampaignMappingModel>) => params.data?.matchTypes.join(', '),
      },
      {
        colId: ColumnId.CREATED_AT,
        headerName: 'Created Date',
        field: 'createdAt',
        width: 105,
        valueFormatter: (params: ValueFormatterParams<CampaignMappingModel>) => formatDateStringTimeNoHours(params.value),
      },
      {
        colId: ColumnId.UPDATED_AT,
        headerName: 'Last Updated',
        field: 'updatedAt',
        width: 105,
        valueFormatter: (params: ValueFormatterParams<CampaignMappingModel>) => formatDateStringTimeNoHours(params.value),
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        flex: 1,
        cellRenderer: ButtonGroupCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<CampaignMappingModel, unknown, CampaignMappingGridContext>,
        ): IButtonGroupCellRendererParams => {
          const isLoadingDelete = params.data && params.context?.idsBeingDeleted.has(params.data.id?.toString() ?? '');

          const buttons = [
            <RowActionButton
              key="delete"
              text="Delete"
              isLoadingText="Deleting..."
              color="red"
              isDisabled={isLoadingDelete}
              tooltipText={`Delete this mapping (all mappings)`}
              isLoading={isLoadingDelete}
              onClick={() => onMainRowDeleteClicked(params)}
              icon={<Delete />}
            ></RowActionButton>,
          ];

          const maxMatchTypes = params.data?.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? 3 : 2;
          const canAddNewMatchType = params.data?.matchTypes && params.data.matchTypes.length < maxMatchTypes;
          if (canAddNewMatchType) {
            buttons.push(
              <RowActionButton
                key="Add"
                text="Add Match"
                color="default"
                tooltipText={`Add new match type`}
                onClick={() => onAddMatchTypeClicked(params)}
                icon={<Add />}
              ></RowActionButton>,
            );
          }
          return {
            buttons,
          };
        },
      },
    ];

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  // INNER
  const detailCellRendererParams = useCallback(
    (params: ICellRendererParams) => ({
      detailGridOptions: {
        columnDefs: [
          {
            colId: ColumnId.CHECKBOX,
            checkboxSelection: true,
            resizable: false,
            width: 50,
            minWidth: 30,
            maxWidth: 50,
            suppressColumnsToolPanel: true,
            suppressHeaderMenuButton: true,
            pinned: 'left',
            lockPosition: 'left',
            headerClass: 'checkbox-column-header',
            cellClass: 'checkbox-column-cell',
            editable: true,
          },
          {
            colId: ColumnId.MATCH,
            headerName: 'Match Type',
            cellClass: 'border-none outline-none',
            width: 120,
            field: 'matchType',
            pinned: 'left',
            type: 'matchLongColumnType',
          },
          {
            colId: ColumnId.BID_METHOD,
            headerName: 'Bid Method',
            field: 'biddingMethod',
            cellEditor: 'agSelectCellEditor',
            width: 120,
            cellClass: (params: CellClassParams<CampaignMappingInnerModel>) => {
              if (!params.value && params.data?.biddingMethod !== BiddingMethod.ADLABS) {
                return;
              }
              return 'flex items-center';
            },
            editable: true,
            singleClickEdit: true,
            cellRenderer: EditableCellRenderer,
            cellEditorParams: {
              values: [BiddingMethod.ADLABS, BiddingMethod.CPC_PLUS, BiddingMethod.CPC_MINUS, BiddingMethod.CPC_TIMES, BiddingMethod.CUSTOM],
            },
            valueFormatter: (params: ValueFormatterParams<CampaignMappingInnerModel>) => {
              return t(`enums.bidding_method.${params.value}`);
            },
            valueSetter: (params: ValueSetterParams<CampaignMappingInnerModel>) => {
              if (params.newValue == params.oldValue) return false;

              params.data.biddingMethod = params.newValue;
              params.data.biddingMethodValue = null;
              if (params.node) {
                params.api.refreshCells({ rowNodes: [params.node], columns: ['biddingMethodValue'] });
              }

              if (params.newValue == BiddingMethod.ADLABS) {
                // Because method ADLABS does not have a value, update it immediately
                updateExistingCampaignMappingViaInner(params.data);
              }

              return true;
            },
          },
          {
            colId: ColumnId.BID_METHOD_VALUE,
            headerName: 'Custom Value',
            field: 'biddingMethodValue',
            width: 150,
            cellClass: (params: CellClassParams<CampaignMappingInnerModel>) => {
              if (!params.value && params.data?.biddingMethod !== BiddingMethod.ADLABS) {
                return 'bg-orange-500 bg-opacity-10';
              }
              return 'flex items-center';
            },
            editable: (params: EditableCallbackParams<CampaignMappingInnerModel>) => params.data?.biddingMethod != BiddingMethod.ADLABS,
            singleClickEdit: true,
            valueFormatter: (params: ValueFormatterParams<CampaignMappingInnerModel>) => {
              if (!params.data) return '';

              if (params.data.biddingMethod === BiddingMethod.ADLABS) return 'Auto';
              if (!params.value) return 'Not Set';

              const biddingMethod = params.data.biddingMethod;

              switch (biddingMethod) {
                case BiddingMethod.CPC_MINUS:
                  return formatCurrency(params.value, {
                    customCurrencyCode: params.context?.masterCellRendererParams?.context.activeProfileCurrencyCode,
                  });
                case BiddingMethod.CPC_PLUS:
                  return formatCurrency(params.value, {
                    customCurrencyCode: params.context?.masterCellRendererParams?.context.activeProfileCurrencyCode,
                  });
                case BiddingMethod.CPC_TIMES:
                  return params.value;
                case BiddingMethod.CUSTOM:
                  return formatCurrency(params.value, {
                    customCurrencyCode: params.context?.masterCellRendererParams?.context.activeProfileCurrencyCode,
                  });
                default:
                  console.error('Unknown biddingMethod:', biddingMethod);
                  return 'Error';
              }
            },
            cellRenderer: EditableCellRenderer,
            cellRendererParams: (params: ICellRendererParams<CampaignMappingInnerModel>): IEditableCellRendererParams => {
              const isEditable = params.data?.biddingMethod !== BiddingMethod.ADLABS;
              return {
                isEditable: isEditable,
                hideTooltip: isEditable,
              };
            },
            valueSetter: (params: ValueSetterParams<CampaignMappingInnerModel>) => {
              try {
                if (params.newValue == '') {
                  return false;
                }

                const newValue = Math.abs(parseFloat(params.newValue));
                if (newValue > 0) {
                  const warnings = new Set<string>();
                  let clampedValue: number | null = null;

                  const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType =
                    params.context?.campaignToAdGroupsMap;
                  const destinationCampaign = campaignToAdGroupsMap?.[params.data?.destinationCampaignId ?? ''];
                  if (
                    params.data?.biddingMethod !== BiddingMethod.CPC_TIMES &&
                    destinationCampaign &&
                    Object.values(CampaignAdType).includes(params.data?.destinationCampaignAdType as CampaignAdType)
                  ) {
                    clampedValue = getNewBidValue_byCurrentProfileMarketplaceLimits(
                      newValue,
                      params.data?.destinationCampaignAdType as CampaignAdType,
                      destinationCampaign.isVideo,
                      warnings,
                    );
                  }

                  if (warnings.size > 0 && clampedValue) {
                    toastWarnWithSetMessages(warnings);
                    params.data.biddingMethodValue = clampedValue;
                  } else {
                    params.data.biddingMethodValue = newValue;
                  }

                  const nodeData = params.node?.data;
                  if (!params.node || !nodeData) return false; // Something is wrong

                  updateExistingCampaignMappingViaInner(nodeData);

                  return true;
                }
              } catch (e) {
                console.error(e);
              }

              toastService.error('Invalid value entered');
              console.error('Invalid value entered: ', params.newValue);

              return false;
            },
          },
          {
            colId: ColumnId.BID_FLOOR,
            headerName: 'Bid Floor',
            field: 'bidFloor',
            width: 100,
            cellClass: () => {
              return 'flex items-center';
            },
            editable: true,
            singleClickEdit: true,
            valueFormatter: (params: ValueFormatterParams<CampaignMappingInnerModel>) => {
              return params.value
                ? formatCurrency(params.value, {
                    customCurrencyCode: params.context?.masterCellRendererParams?.context.activeProfileCurrencyCode,
                  })
                : 'Not Set';
            },
            cellRenderer: EditableCellRenderer,
            valueSetter: (params: ValueSetterParams<CampaignMappingInnerModel>) => {
              // Allow setting to null
              if (params.newValue == null) {
                params.data.bidFloor = null;
                updateExistingCampaignMappingViaInner(params.data);
                return true;
              }

              try {
                if (params.newValue == '') {
                  return false;
                }

                const newValue = Math.abs(parseFloat(params.newValue));
                if (newValue > 0) {
                  const warnings = new Set<string>();
                  let clampedValue: number | null = null;

                  const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType =
                    params.context?.campaignToAdGroupsMap;
                  const destinationCampaign = campaignToAdGroupsMap?.[params.data?.destinationCampaignId ?? ''];
                  if (
                    destinationCampaign &&
                    Object.values(CampaignAdType).includes(params.data?.destinationCampaignAdType as CampaignAdType)
                  ) {
                    clampedValue = getNewBidValue_byCurrentProfileMarketplaceLimits(
                      newValue,
                      params.data?.destinationCampaignAdType as CampaignAdType,
                      destinationCampaign.isVideo,
                      warnings,
                    );
                  }

                  if (warnings.size > 0 && clampedValue) {
                    toastWarnWithSetMessages(warnings);
                    params.data.bidFloor = clampedValue;
                  } else {
                    params.data.bidFloor = newValue;
                  }

                  const nodeData = params.node?.data;
                  if (!params.node || !nodeData) return false; // Something is wrong

                  updateExistingCampaignMappingViaInner(nodeData);

                  return true;
                }
              } catch (e) {
                console.error(e);
              }

              toastService.error('Invalid value entered');
              console.error('Invalid value entered: ', params.newValue);

              return false;
            },
          },
          {
            colId: ColumnId.BID_CEILING,
            headerName: 'Bid Ceiling',
            field: 'bidCeiling',
            width: 120,
            cellClass: () => {
              return 'flex items-center';
            },
            editable: true,
            singleClickEdit: true,
            valueFormatter: (params: ValueFormatterParams<CampaignMappingInnerModel>) => {
              return params.value
                ? formatCurrency(params.value, {
                    customCurrencyCode: params.context?.masterCellRendererParams?.context.activeProfileCurrencyCode,
                  })
                : 'Not Set';
            },
            cellRenderer: EditableCellRenderer,
            valueSetter: (params: ValueSetterParams<CampaignMappingInnerModel>) => {
              // Allow setting to null
              if (params.newValue == null) {
                params.data.bidCeiling = null;
                updateExistingCampaignMappingViaInner(params.data);
                return true;
              }

              try {
                if (params.newValue == '') {
                  return false;
                }

                const newValue = Math.abs(parseFloat(params.newValue));
                if (newValue > 0) {
                  const warnings = new Set<string>();
                  let clampedValue: number | null = null;

                  const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType =
                    params.context?.campaignToAdGroupsMap;
                  const destinationCampaign = campaignToAdGroupsMap?.[params.data?.destinationCampaignId ?? ''];
                  if (
                    destinationCampaign &&
                    Object.values(CampaignAdType).includes(params.data?.destinationCampaignAdType as CampaignAdType)
                  ) {
                    clampedValue = getNewBidValue_byCurrentProfileMarketplaceLimits(
                      newValue,
                      params.data?.destinationCampaignAdType as CampaignAdType,
                      destinationCampaign.isVideo,
                      warnings,
                    );
                  }

                  if (warnings.size > 0 && clampedValue) {
                    toastWarnWithSetMessages(warnings);
                    params.data.bidCeiling = clampedValue;
                  } else {
                    params.data.bidCeiling = newValue;
                  }

                  const nodeData = params.node?.data;
                  if (!params.node || !nodeData) return false; // Something is wrong

                  updateExistingCampaignMappingViaInner(nodeData);

                  return true;
                }
              } catch (e) {
                console.error(e);
              }

              toastService.error('Invalid value entered');
              console.error('Invalid value entered: ', params.newValue);

              return false;
            },
          },
          {
            colId: ColumnId.CREATED_AT,
            headerName: 'Created Date',
            field: 'createdAt',
            width: 120,
            valueFormatter: (params: ValueFormatterParams<CampaignMappingInnerModel>) => formatDateStringTimeNoHours(params.value),
          },
          {
            colId: ColumnId.UPDATED_AT,
            headerName: 'Last Updated',
            field: 'updatedAt',
            width: 130,
            valueFormatter: (params: ValueFormatterParams<CampaignMappingInnerModel>) => formatDateStringTimeNoHours(params.value),
          },
          {
            colId: ColumnId.ACTIONS,
            headerName: 'Actions',
            flex: 1,
            cellClass: 'border-none outline-none',
            cellRenderer: ButtonGroupCellRenderer,
            cellRendererParams: (
              params: ICellRendererParams<CampaignMappingInnerModel, unknown, CampaignMappingGridContext>,
            ): IButtonGroupCellRendererParams => {
              const isLoadingDelete = params.data && params.context?.idsBeingDeleted.has(params.data.id?.toString() ?? '');

              return {
                buttons: [
                  <RowActionButton
                    key="delete"
                    text="Delete"
                    isLoadingText="Deleting..."
                    color="red"
                    isDisabled={isLoadingDelete}
                    tooltipText={`Delete this match`}
                    isLoading={isLoadingDelete}
                    onClick={() => onInnerRowDeleteClicked(params)}
                    icon={<Delete />}
                  ></RowActionButton>,
                ],
              };
            },
          },
        ],
        headerHeight: INNER_GRID_HEADER_HEIGHT,
        rowHeight: INNER_GRID_ROW_HEIGHT,
        getRowId: (params: GetRowIdParams<CampaignMappingInnerModel, unknown>) => params.data.id?.toString() ?? '',
        stopEditingWhenCellsLoseFocus: true,
        context: {
          idsBeingDeleted: new Set(),
          masterCellRendererParams: params,
        } as CampaignMappingInnerGridContext,
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        columnTypes: { checkboxColumnType, matchLongColumnType },
        onSelectionChanged: (event: SelectionChangedEvent<CampaignMappingInnerModel, CampaignMappingInnerGridContext>) => {
          // Master row edits detail grid selection - don't start editing master again here
          if (event.source === 'api') {
            return;
          }

          // UI update, not programmatically
          const masterDetailNode = event.context?.masterCellRendererParams?.node;
          if (!masterDetailNode || !masterDetailNode.id || !gridApiRef.current) return;

          const masterNode = gridApiRef.current.getRowNode(masterDetailNode.id.replace('detail_', ''));
          if (!masterNode || !masterNode.data) return;
          const selectedMatches: MatchType[] = [];
          event.api.forEachNode((detailNode) => {
            if (!detailNode.data || !detailNode.isSelected()) return;

            selectedMatches.push(detailNode.data.matchType);
          });

          const masterModeModel = masterNode.data;
          masterModeModel.selectedMatchTypes = selectedMatches;

          masterNode.setData(masterModeModel);

          masterNode.setSelected(selectedMatches.length > 0);

          handleRowSelection(); // Action bar and other off-grid data update
        },
        onGridReady: (params: GridReadyEvent<CampaignMappingInnerModel, CampaignMappingInnerGridContext>) => {
          const selectedMatchTypes = params.context?.masterCellRendererParams?.node?.data?.selectedMatchTypes;
          if (!selectedMatchTypes) return;

          params.api.forEachNode((node) => {
            if (!node.data) return;
            node.setSelected(selectedMatchTypes.includes(node.data.matchType));
          });
        },
      },
      getDetailRowData: (params: GetDetailRowDataParams<CampaignMappingModel>) => {
        params.successCallback(params.data.inner);
      },
    }),
    [],
  );

  async function updateExistingCampaignMappingViaInner(campaignMappingInnerModel: CampaignMappingInnerModel) {
    setIsLoadingUpdate(true);
    try {
      const response = await campaignMappingService.updateCampaignMappingParams([campaignMappingInnerModel.toCampaignMappingModel()]);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to create campaign mapping: ' + error);
    } finally {
      setIsLoadingUpdate(false);
    }
  }

  async function onInnerRowDeleteClicked(params: ICellRendererParams<CampaignMappingInnerModel>) {
    const rowToDelete = params.node.data;
    const masterNodeData = params.context?.masterCellRendererParams?.node?.data;

    if (!rowToDelete?.id || !masterNodeData) {
      toastService.error('Failed to delete campaign mapping');
      return;
    }
    addIsLoadingDeleteIdToContext(rowToDelete.id, params);

    try {
      // If last match type, delete the whole campaign mapping
      const isLastMatchType = masterNodeData.matchTypes.length === 1;

      const response = isLastMatchType
        ? await campaignMappingService.deleteWholeCampaignMappings([masterNodeData])
        : await campaignMappingService.deleteCampaignMappingMatchType(rowToDelete.toCampaignMappingModel(), rowToDelete.matchType);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to delete campaign mapping: ' + error);
    } finally {
      removeIsLoadingDeleteIdFromContext(rowToDelete.id, params);
    }
  }

  // Adding new modal
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  const [newCampaignMappingPreset, setNewCampaignMappingPreset] = useState<NewCampaignMappingPreset | undefined>(undefined);
  function onAddMatchTypeClicked(params: ICellRendererParams<CampaignMappingModel, unknown, CampaignMappingGridContext>): void {
    if (!params.data) return;

    const existingMatches = params.data.matchTypes;

    setNewCampaignMappingPreset({
      sourceCampaignId: params.data.sourceCampaignId,
      sourceAdGroupId: params.data.sourceAdGroupId,
      destinationCampaignId: params.data.destinationCampaignId,
      destinationAdGroupId: params.data.destinationAdGroupId,
      existingMatches,
      existingNegativeMatches: {
        cne: params.data.campaignNegativeExact,
        cnp: params.data.campaignNegativePhrase,
        cnpt: params.data.campaignNegativeProductTarget,
        agne: params.data.adGroupNegativeExact,
        agnp: params.data.adGroupNegativePhrase,
        agnpt: params.data.adGroupNegativeProductTarget,
      },
    });

    setIsAddNewModalOpen(true);
  }

  async function updateNegatives(campaignMappingModel: CampaignMappingModel) {
    setIsLoadingUpdate(true);
    try {
      const response = await campaignMappingService.updateCampaignMappingNegatives(campaignMappingModel);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to create campaign mapping: ' + error);
    } finally {
      setIsLoadingUpdate(false);
    }
  }

  async function onMainRowDeleteClicked(params: ICellRendererParams<CampaignMappingModel>) {
    const rowToDelete = params.node.data;

    if (!rowToDelete?.id) {
      toastService.error('Failed to delete campaign mapping, data is undefined');
      return;
    }
    addIsLoadingDeleteIdToContext(rowToDelete.id);

    try {
      const response = await campaignMappingService.deleteWholeCampaignMappings([rowToDelete]);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to delete campaign mapping: ' + error);
    } finally {
      removeIsLoadingDeleteIdFromContext(rowToDelete.id);
    }
  }

  const handleRowSelection = () => {
    if (!gridApiRef.current || gridApiRef.current.isDestroyed()) return;

    try {
      const selectedRows = gridApiRef.current.getSelectedRows();
      setSelectedCampaignMappings(selectedRows);
    } catch (error) {
      console.error('Error updating selected campaign mappings:', error);
    }
  };

  // TODO: move to AlGrid everywhere
  const onCellClicked = useCallback((params: CellClickedEvent<CampaignMappingModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  function addIsLoadingDeleteIdToContext(id: string, params?: ICellRendererParams) {
    if (!gridApiRef.current || !gridContextRef.current) return;

    // params is present for inner grid calls
    (params?.context ?? gridContextRef.current).idsBeingDeleted.add(id);
    (params?.api ?? gridApiRef.current).refreshCells({
      force: true,
      columns: [ColumnId.ACTIONS],
    });
  }

  function removeIsLoadingDeleteIdFromContext(id: string, params?: ICellRendererParams) {
    if (!gridApiRef.current || !gridContextRef.current) return;

    //params is present for inner grid calls
    (params?.context ?? gridContextRef.current).idsBeingDeleted.delete(id);
    (params?.api ?? gridApiRef.current).refreshCells({
      force: true,
      columns: [ColumnId.ACTIONS],
    });
  }

  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || Object.keys(campaignToAdGroupsMap).length === 0) return;
    gridContextRef.current.campaignToAdGroupsMap = campaignToAdGroupsMap;
    gridApiRef.current.refreshCells({ columns: [ColumnId.NEGATIVE_AD_GROUP, ColumnId.NEGATIVE_CAMPAIGN], force: true });
  }, [campaignToAdGroupsMap]);

  const getRowHeight = (params: RowHeightParams) => {
    if (params.node.detail) {
      // On each row there's a match, inner matches are also listed in parent matchTypes, can use that for height calc
      const bottomTopPadding = 10 + 20; // from css
      const scrollbarHeight = 15;
      const detailGridHeight =
        INNER_GRID_HEADER_HEIGHT + params.data.matchTypes.length * INNER_GRID_ROW_HEIGHT + bottomTopPadding + scrollbarHeight;
      return detailGridHeight;
    }
    return 41; // Default row height for master rows
  };

  // Select/Deselect All handling
  function onSelectionChanged(event: SelectionChangedEvent<CampaignMappingModel>) {
    if (!gridApiRef.current || !['apiSelectAll', 'uiSelectAll'].includes(event.source)) return;
    let allRowsCount = 0;
    gridApiRef.current.forEachNode(() => allRowsCount++);
    const selectedRowsCount = gridApiRef.current.getSelectedNodes().length;

    // These need to be the opposites as it's already happened and we're seeing the result
    const isSelectAllEvent = allRowsCount === selectedRowsCount;
    const isDeselectAllEvent = allRowsCount !== selectedRowsCount;

    gridApiRef.current.forEachNode((node) => {
      if (!gridApiRef.current) return;

      const masterNodeModel = node.data;
      if (!masterNodeModel) return;

      const detailId = `detail_${masterNodeModel.id}`;
      const detailGridApi = gridApiRef.current.getDetailGridInfo(detailId)?.api;

      // Extra match type selection length check so would only change only where actually needed
      if (isSelectAllEvent && masterNodeModel.matchTypes.length != masterNodeModel.selectedMatchTypes.length) {
        // Set selected array to all items - select all
        masterNodeModel.selectedMatchTypes = masterNodeModel.matchTypes;
        node.setData(masterNodeModel);
        detailGridApi?.selectAll();
      } else if (isDeselectAllEvent) {
        // Set selected array to empty - deselect all
        masterNodeModel.selectedMatchTypes = [];
        node.setData(masterNodeModel);
        detailGridApi?.deselectAll();
      }
    });

    handleRowSelection(); // Action bar and other off-grid data update
  }

  // Single node checkbox selection handling
  function onRowSelected(event: RowSelectedEvent<CampaignMappingModel>) {
    if (!gridApiRef.current || event.source !== 'checkboxSelected') return;
    const node = event.node;

    const masterNodeModel = node.data;
    if (!masterNodeModel) return;

    const detailId = `detail_${masterNodeModel.id}`;
    const detailGridApi = gridApiRef.current.getDetailGridInfo(detailId)?.api;

    // All selected:
    if (node.isSelected()) {
      // Set selected array to all items - select all
      masterNodeModel.selectedMatchTypes = masterNodeModel.matchTypes;
      node.setData(masterNodeModel);
      detailGridApi?.selectAll();

      // Indeterminate:
    } else if (!node.isSelected() && masterNodeModel.matchTypes.length != masterNodeModel.selectedMatchTypes.length) {
      masterNodeModel.selectedMatchTypes = masterNodeModel.matchTypes;
      node.setData(masterNodeModel);
      detailGridApi?.selectAll();
      node.setSelected(true); // just got deselected, but select again to indicate indeterminate state

      // None selected:
    } else {
      // Set selected array to empty - deselect all
      masterNodeModel.selectedMatchTypes = [];
      node.setData(masterNodeModel);
      detailGridApi?.deselectAll();
    }

    handleRowSelection();
  }

  function onRowDataUpdated(event: RowDataUpdatedEvent<CampaignMappingModel>) {
    handleRowSelection();
    event.api.resetRowHeights();
  }

  const customGridOptions: GridOptions<CampaignMappingModel> = useMemo(() => {
    const defaultCampaignMappingGridContext: CampaignMappingGridContext = {
      campaignToAdGroupsMap,
      idsBeingDeleted: new Set(),
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      context: defaultCampaignMappingGridContext,
      getRowId: (params) => params.data.id?.toString() ?? '',
      onSelectionChanged: onSelectionChanged,
      onRowSelected: onRowSelected,
      onRowDataUpdated: onRowDataUpdated,
      onCellClicked: onCellClicked,
      maintainColumnOrder: true,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      masterDetail: true,
      detailCellRendererParams: detailCellRendererParams,
      isRowMaster: (data) => data.matchTypes.length > 0,
      getRowHeight: getRowHeight,
      columnTypes: { checkboxColumnType },
      rowSelection: 'multiple',
      suppressCellFocus: true,
      enableRangeSelection: false,
      defaultColDef: {
        ...DEFAULT_GRID_OPTIONS.defaultColDef,
        lockPinned: true,
      } as ColDef<CampaignMappingModel>,
      rowClass: 'bg-slate-400 bg-opacity-10',
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);

    gridContextRef.current = params.context;
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isCampaignMappingLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={campaignMappingLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` }}>
          <AlGrid
            colDefs={columnDefs}
            rowData={mappingData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={true}
            addExtraBottomPadding={true}
          />
          {isAddNewModalOpen && (
            <NewSingleMappingModal
              isOpen={isAddNewModalOpen}
              onClose={() => setIsAddNewModalOpen(false)}
              newCampaignMappingPreset={newCampaignMappingPreset}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CampaignMappingTable;
