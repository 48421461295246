import { CommonMetricsDTO, MetricsDTO } from '@/components/metrics/api/metrics-contracts';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import {
  CampaignAdType,
  CostType,
  EnabledPausedArchivedState,
  TargetingType,
  TimelineDTO,
} from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { SearchTermModel } from '../models/SearchTermModel';

export interface SearchTermWithMetricsWithPreviousDTO extends CommonMetricsDTO {
  /**
   * Search term.
   */
  s: string;
  /**
   * The entity type of the search term.
   */
  et: TargetEntityType;
  /**
   * The entity id
   */
  ei: string;
  /**
   * The bid of the search term.
   */
  b: number;
  /**
   * The match type of the search term.
   */
  mt: MatchType;
  /**
   * The targeting of the target.
   */
  t: string;
  /**
   * The ad group name of the search term.
   */
  agn: string;
  /**
   * The ad group state of the search term.
   */
  ags: string;
  /**
   * The campaign name of the search term.
   */
  cn: string;
  /**
   * The campaign id
   */
  ci: string;
  /**
   * The ad group id
   */
  ai: string;
  /**
   * The campaign ad type of the search term.
   */
  ct: CampaignAdType;
  /**
   * The campaign state
   */
  cs: EnabledPausedArchivedState;
  /**
   * The target state
   */
  ts: EnabledPausedArchivedState;
  /**
   * The cost type
   */
  co: CostType;
  /**
   * Campaign targeting type.
   */
  tt: TargetingType;
  v: boolean | null; // is video or brands video
  /**
   * The group name of the search term.
   */
  gn: string;
  /**
   * Portfolio name
   */
  p: string;
  /**
   * Search term Data Group item IDs
   */
  sd: number[] | null;
  /**
   * Campaign Data Group item IDs
   */
  cd: number[] | null;
  /**
   * Target Data Group item IDs
   */
  td: number[] | null;
  /**
   * Is search term harvested
   */
  h: boolean | null;
  /**
   * Negative types added
   */
  ng: NegativeTypeShort[] | null;
}

export interface SearchTermsWithTimelineDTO {
  search_terms: SearchTermWithMetricsWithPreviousDTO[];
  timeline: TimelineDTO;
  metrics: MetricsDTO;
  comparison_missing: boolean;
}

export interface SearchTermsWithTimeline {
  searchTerms: SearchTermModel[];
  timeline: TimelineModel;
  metrics: MetricModel[];
  isComparisonDataMissing: boolean;
}

export enum NegativeTypeShort {
  CAMPAIGN_NEG_EXACT = 'ce',
  CAMPAIGN_NEG_PHRASE = 'ch',
  CAMPAIGN_NEG_PRODUCT = 'cp',
  AD_GROUP_NEG_EXACT = 'ae',
  AD_GROUP_NEG_PHRASE = 'ah',
  AD_GROUP_NEG_PRODUCT = 'ap',
}

export enum SearchTermNegatedType {
  NEGATED = 'NEGATED',
  NOT_NEGATED = 'NOT_NEGATED',
}

export enum SearchTermHarvestedType {
  HARVESTED = 'HARVESTED',
  NOT_HARVESTED = 'NOT_HARVESTED',
}
