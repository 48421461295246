import { createContext, FC, PropsWithChildren, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { DashboardWidgetConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';

interface DashboardWidgetContextType {
  isConfiguring: boolean;
  setIsConfiguring: (isConfiguring: boolean) => void;
  widgetId?: string;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  modifiedConfiguration?: DashboardWidgetConfiguration;
  setModifiedConfiguration: Dispatch<SetStateAction<DashboardWidgetConfiguration | undefined>>;
  isConfigured?: boolean;
}

export const DashboardWidgetContext = createContext<DashboardWidgetContextType>({
  isConfiguring: false,
  setIsConfiguring: () => null,
  widgetId: undefined,
  isLoading: false,
  setIsLoading: () => null,
  modifiedConfiguration: undefined,
  setModifiedConfiguration: () => null,
  isConfigured: false,
});

interface WidgetProviderProps extends PropsWithChildren {
  widgetId: string;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  isConfigured?: boolean;
}

const DashboardWidgetProvider: FC<WidgetProviderProps> = ({ children, widgetId, isLoading, setIsLoading, isConfigured }) => {
  const [isConfiguring, setIsConfiguring] = useState<boolean>(false);
  const [modifiedConfiguration, setModifiedConfiguration] = useState<DashboardWidgetConfiguration | undefined>();

  const contextValue = useMemo(
    () => ({
      isConfiguring,
      setIsConfiguring,
      widgetId,
      isLoading,
      setIsLoading,
      modifiedConfiguration,
      setModifiedConfiguration,
      isConfigured,
    }),
    [isConfiguring, setIsConfiguring, widgetId, isLoading, setIsLoading, modifiedConfiguration, setModifiedConfiguration, isConfigured],
  );

  return <DashboardWidgetContext.Provider value={contextValue}>{children}</DashboardWidgetContext.Provider>;
};

DashboardWidgetProvider.displayName = 'DashboardWidgetProvider'; // Add display name

export default DashboardWidgetProvider;
