import { Environment } from '@/config/Environment';
import { apiProfileClient, apiTeamClient } from '@/lib/api/base-client';
import { GleapTicketAttributeName } from '@/lib/gleap';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { UserSettingKey, useUserContext, useUserSettingsContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import * as Sentry from '@sentry/react';
import Gleap from 'gleap';
import { isNil, isNumber } from 'lodash-es';
import { createContext, FunctionComponent, MutableRefObject, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TeamModel } from '../types/TeamModel';

interface ActiveProfileIdChange {
  previousProfileId: string | undefined;
  newProfileId: string | undefined;
  hasChanged: boolean;
}
interface ActiveTeamContextType {
  setActiveTeam: (args: {
    teamId: number | undefined;
    profileId?: string; // On initial load profileId is also provided
    userTeams?: TeamModel[];
  }) => void;
  activeTeam: TeamModel | undefined;
  setActiveProfile: (profileId: string) => void;
  activeProfile: ProfileModel | undefined;
  removeProfileFromTeam: (profileId: string) => void;
  activeProfileIdChange?: ActiveProfileIdChange;
  hasUserOverMaxAllowedFreeProfiles: () => boolean;
}

// Default values
const ActiveTeamContext = createContext<ActiveTeamContextType>({
  setActiveTeam: () => null,
  activeTeam: undefined,
  setActiveProfile: () => null,
  activeProfile: undefined,
  removeProfileFromTeam: () => null,
  activeProfileIdChange: undefined,
  hasUserOverMaxAllowedFreeProfiles: () => false,
});

interface ActiveTeamProviderProps extends PropsWithChildren {
  initialActiveTeamIdRef: MutableRefObject<number | undefined>;
  initialActiveProfileIdRef: MutableRefObject<string | undefined>;
}

export const ActiveTeamProvider: FunctionComponent<ActiveTeamProviderProps> = ({
  children,
  initialActiveTeamIdRef,
  initialActiveProfileIdRef,
}) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const { upsertUserSetting } = useUserSettingsContext();

  const [activeTeamId, setActiveTeamId] = useState<number | undefined>(initialActiveTeamIdRef.current);
  const [activeProfileId, setActiveProfileId] = useState<string | undefined>(initialActiveProfileIdRef.current);

  const activeTeam = user?.teams.find((team) => team.id === activeTeamId);
  const activeProfile = activeTeam?.profiles.find((profile) => profile.id === activeProfileId);

  if (activeTeamId) {
    apiProfileClient.setTeamId(activeTeamId);
    apiTeamClient.setTeamId(activeTeamId);
  }

  try {
    Gleap.setTicketAttribute(GleapTicketAttributeName.ProfileName, activeProfile?.name ?? '');
    Gleap.setTicketAttribute(GleapTicketAttributeName.ProfileId, activeProfileId ?? '');
    Gleap.setTicketAttribute(GleapTicketAttributeName.TeamName, activeTeam?.name ?? '');
    Gleap.setTicketAttribute(GleapTicketAttributeName.TeamId, activeTeamId?.toString() ?? '');
  } catch (error) {
    console.error('Error setting Gleap ticket attribute', error);
  }

  apiProfileClient.setProfileId(activeProfileId ?? '');

  const [activeProfileIdChange, setActiveProfileIdChange] = useState<ActiveProfileIdChange>();

  // Initial load on mount
  useEffect(() => {
    setActiveTeam({ teamId: initialActiveTeamIdRef.current, profileId: initialActiveProfileIdRef.current });
  }, []);

  //When user makes changes to active team. For example adds profiles
  useEffect(() => {
    if (isNil(activeProfileId) && activeTeam && activeTeam.profiles.length > 0) {
      setActiveProfile(activeTeam.profiles[0].id);
    }
  }, [activeTeam?.profiles]);

  useEffect(() => {
    if (user && user.teams.length == 0 && currentPath != Routes.TEAMS) {
      toastService.error('Team not found. Please create a new team to use AdLabs.');
      setTimeout(() => {
        navigate(Routes.TEAMS);
      }, 100);
    }
  }, [user, currentPath]);

  // teams is provided to fight against teams race condition
  function setActiveTeam({
    teamId,
    profileId,
    userTeams: providedUserTeams,
  }: {
    teamId: number | undefined;
    profileId?: string; // On initial load profileId is also provided
    userTeams?: TeamModel[];
  }) {
    let userTeams = user ? user.teams : [];
    if (providedUserTeams) {
      userTeams = providedUserTeams;
    }

    // This happens when user is logged out or all teams are removed
    if (userTeams.length == 0) {
      return;
    }

    if ((!isNumber(teamId) || teamId == 0) && userTeams && userTeams.length > 0) {
      teamId = userTeams[0].id; // Load user's first team
    }

    if (isNil(teamId)) {
      if (Environment.isDev()) {
        console.log('Active team was not set. TeamId is null');
      }
      Sentry.captureMessage(`Active team was not set for user ${user?.firebaseId}. TeamId is null`, 'info');
      return;
    }

    const team = userTeams.find((team) => team.id === teamId);
    if (isNil(team)) {
      Sentry.captureMessage(`Team with id ${teamId} was not found for user ${user?.firebaseId}`, 'info');
      if (Environment.isDev()) {
        console.log('Active team was not set. Team not found');
      }

      teamId = userTeams[0].id; // Load user's first team
    }

    setActiveTeamId(teamId);
    setActiveTeamInUserSettings(teamId);
    if (Environment.isDev()) {
      console.log('Active team was set to: ', teamId);
    }

    if (profileId) {
      setActiveProfile(profileId);
    } else if (team && team.profiles.length > 0) {
      // If user changes a team then select first profile from the team profiles
      setActiveProfile(team.profiles[0].id);
    } else {
      clearActiveProfile();
    }
  }

  function clearActiveProfile() {
    setActiveProfileInUserSettings('');
    setActiveProfileId(undefined);
  }

  function setActiveProfile(profileId: string) {
    setActiveProfileId(profileId);
    setActiveProfileInUserSettings(profileId);

    setActiveProfileIdChange((prev) => ({
      previousProfileId: prev?.newProfileId,
      newProfileId: profileId,
      hasChanged: prev?.newProfileId !== profileId,
    }));
  }

  function setActiveTeamInUserSettings(teamId: number) {
    upsertUserSetting(UserSettingKey.ACTIVE_TEAM_ID, teamId);
  }

  function setActiveProfileInUserSettings(profileId: string) {
    upsertUserSetting(UserSettingKey.ACTIVE_PROFILE_ID, profileId);
  }

  function removeProfileFromTeam(profileId: string) {
    const profiles = activeTeam?.profiles.filter((profile) => profile.id !== profileId);
    if (activeTeam && profiles) {
      activeTeam.profiles = profiles;
    }

    if (activeTeam && activeTeam.profiles.length > 0) {
      setActiveProfile(activeTeam.profiles[0].id);
    } else {
      clearActiveProfile();
    }
  }

  // We check all teams not only those where you are owner
  // Reason: In the future you can create new adlabs accounts and add yourself into many teams
  function hasUserOverMaxAllowedFreeProfiles(): boolean {
    if (user && activeTeam && !activeTeam.hasProPlan) {
      const profilesInTeamsWithFreePlan = user.teams
        .filter((team) => !team.hasProPlan)
        .map((team) => {
          return team.profiles;
        })
        .flat();

      return profilesInTeamsWithFreePlan.length > activeTeam.subscriptionPlan.maxProfiles;
    }

    return false;
  }

  return (
    <ActiveTeamContext.Provider
      value={{
        setActiveTeam,
        activeTeam,
        setActiveProfile,
        activeProfile,
        removeProfileFromTeam,
        activeProfileIdChange,
        hasUserOverMaxAllowedFreeProfiles,
      }}
    >
      {children}
    </ActiveTeamContext.Provider>
  );
};

// Instead of doing this useContext(ActiveTeamContext);
// We can create a custom hook named useActiveTeamContext
export function useActiveTeamContext(): ActiveTeamContextType {
  const context = useContext(ActiveTeamContext);
  if (!context) {
    throw new Error('useActiveTeamContext must be used within a ActiveTeamProvider');
  }
  return context;
}
