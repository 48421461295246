import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import TeamsTable from '@/modules/teams/components/teams-table/TeamsTable';

import { FunctionComponent } from 'react';

export const TeamSettingsPage: FunctionComponent = () => {
  return (
    <PageLayout>
      <PageLayoutTopBar header="Manage Teams"></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="mt-6 flex flex-grow flex-1 flex-col">
          <TeamsTable />
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};
