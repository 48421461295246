import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { QueryClient } from '@tanstack/react-query';
import { SearchTermModel } from '../models/SearchTermModel';
import { SearchTermsWithTimeline, SearchTermsWithTimelineDTO } from './search-terms-contracts';

// Don't expose this and only use creators
const _SEARCH_TERMS_WITH_TIMELINE_QUERY_KEY = 'search-terms-with-timeline';

// Main key to use
export function createSearchTermsWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const withTimelineQueryKey_withoutFilters = createSearchTermsWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...withTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

export function invalidateAll_searchTermsWithTimelineQueryKeys(queryClient: QueryClient) {
  const key = [_SEARCH_TERMS_WITH_TIMELINE_QUERY_KEY];
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

function createSearchTermsWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_SEARCH_TERMS_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

export function invalidateProfile_searchTermsWithTimelineQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createSearchTermsWithTimelineQueryKey_withoutFilters(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

class SearchTermsService {
  public basePath = 'search-terms';

  async getSearchTermsWithTimeline(filters: AlFilterModel[]): Promise<ApiResponse<SearchTermsWithTimeline>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<SearchTermsWithTimelineDTO>(`${this.basePath}/with-timeline`, requestData);

      return applicationResponse.processPayload((payload) => ({
        searchTerms: SearchTermModel.fromResponseArray(payload.search_terms),
        timeline: TimelineModel.fromResponse(payload.timeline),
        metrics: MetricModel.fromDTO(payload.metrics),
        isComparisonDataMissing: payload.comparison_missing,
      }));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const searchTermsService = new SearchTermsService();
