import InfoIconWithTooltip from '@/components/feedback/InfoIconWithTooltip';
import {
  getMetricConfigByColId,
  getSellerMetricColIds,
  getSellerVendorSharedMetricColIds,
  getSentimentByMetric,
  getVendorMetricColIds,
} from '@/components/metrics/MetricsConfig';
import { MetricData } from '@/components/metrics/types/MetricData';
import {
  CommonMetricField,
  MetricField,
  ProfileStatsSellerMetricField,
  SellerMetricField,
  VendorMetricField,
} from '@/components/metrics/types/MetricField';
import { TailwindColorVariant } from '@/config/theme/color.type';
import useFormatting, { FormattingParams } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { CampaignAdType, PlacementType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { BiddingEntity } from '@/modules/optimizer/components/optimization/models/OptimizationModel';
import { useUserContext } from '@/modules/users';
import { getCampaignAdTypeColor, getPlacementTypeColor } from '@/types/colors.enum';
import { SentimentDirection } from '@/types/SentimentDirection';
import { Tooltip } from '@mui/material';
import { CellClassParams, ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import { ITextCellRendererParams } from '../cells/TextCellRenderer';
import { ColumnId } from '../columns/columns.enum';
import {
  calculateMetricDataACOS,
  calculateMetricDataACOTS,
  calculateMetricDataACTC,
  calculateMetricDataAdSalesOfTotal,
  calculateMetricDataAOV,
  calculateMetricDataASP,
  calculateMetricDataCPA,
  calculateMetricDataCPC,
  calculateMetricDataCPM,
  calculateMetricDataCTR,
  calculateMetricDataCVR,
  calculateMetricDataROAS,
  calculateMetricDataRPC,
  calculateMetricDataTCVR,
  calculateMetricDataTotalAOV,
  calculateMetricDataTotalCPA,
  calculateMetricDataTotalROAS,
  calculateMetricDataUnitRefundRate,
  calculateMetricDataUnitView,
  calculateMetricDataUSP,
  calculateMetricDataVendorACOTS,
  calculateMetricDataVendorAdSalesOfTotal,
  calculateMetricDataVendorASP,
  calculateMetricDataVendorReturnRate,
  calculateMetricDataVendorTotalROAS,
  calculateMetricDataVendorUnitView,
} from '../helpers';
import { FormatterType } from '../types';

const useColDefsFunctions = () => {
  const { formatWithThousandsSeparator, getLongFormatterForMetricField, getShortFormatterForMetricField } = useFormatting();
  const { t } = useTranslation();
  const { isProfileSeller, isProfileVendor } = useUserContext();

  const metricsDataComparator = (valueA: MetricData, valueB: MetricData): number => {
    if (valueA[0] > valueB[0]) {
      return 1;
    } else if (valueA[0] < valueB[0]) {
      return -1;
    } else {
      return 0;
    }
  };

  const metricsDataDeltaComparator = (valueA: MetricData, valueB: MetricData): number => {
    // [0] = current value, [1] = previous value. Compare the delta between the two
    const deltaA = valueA[0] - valueA[1];
    const deltaB = valueB[0] - valueB[1];

    if (deltaA > deltaB) {
      return 1;
    } else if (deltaA < deltaB) {
      return -1;
    } else {
      return 0;
    }
  };

  const metricsDataDeltaPercentageComparator = (valueA: MetricData, valueB: MetricData): number => {
    // [0] = current value, [1] = previous value. Compare the delta in the percentage of change between the two
    const deltaA = valueA[0] / valueA[1];
    const deltaB = valueB[0] / valueB[1];

    if (deltaB === Infinity) {
      return 1;
    } else if (deltaA === Infinity) {
      return -1;
    }

    if (deltaA > deltaB) {
      return 1;
    } else if (deltaA < deltaB) {
      return -1;
    } else {
      return 0;
    }
  };

  function getCalculatedMetricAggDataForMetricData(metricField: MetricField, aggData: Record<string, MetricData>): MetricData {
    const data = getCalculatedMetricAggDataForArrays(metricField, aggData);

    const current = data[0] ?? 0;
    const previous = data[1] ?? 0;

    return [current, previous];
  }

  function getCalculatedMetricAggDataForArrays(metricField: MetricField, aggData: Record<string, number[]>): number[] {
    switch (metricField) {
      case CommonMetricField.CTR:
        return calculateMetricDataCTR(aggData);
      case CommonMetricField.CVR:
        return calculateMetricDataCVR(aggData);
      case CommonMetricField.CPC:
        return calculateMetricDataCPC(aggData);
      case CommonMetricField.ACOS:
        return calculateMetricDataACOS(aggData);
      case CommonMetricField.ACTC:
        return calculateMetricDataACTC(aggData);
      case CommonMetricField.ROAS:
        return calculateMetricDataROAS(aggData);
      case CommonMetricField.RPC:
        return calculateMetricDataRPC(aggData);
      case CommonMetricField.CPA:
        return calculateMetricDataCPA(aggData);
      case CommonMetricField.AOV:
        return calculateMetricDataAOV(aggData);
      case CommonMetricField.CPM:
        return calculateMetricDataCPM(aggData);
      // Seller metrics
      case SellerMetricField.SELLER_ACOS:
        return calculateMetricDataACOTS(aggData);
      // case SellerMetricField.SELLER_ACTC:
      //   return calculateMetricDataTACTC(aggData);
      case SellerMetricField.SELLER_ASP:
        return calculateMetricDataASP(aggData);
      case SellerMetricField.SELLER_CVR:
        return calculateMetricDataTCVR(aggData);
      case SellerMetricField.SELLER_UNIT_SESS:
        return calculateMetricDataUSP(aggData);
      case SellerMetricField.SELLER_AD_SALES_OF_TOTAL:
        return calculateMetricDataAdSalesOfTotal(aggData);
      case SellerMetricField.SELLER_AOV:
        return calculateMetricDataTotalAOV(aggData);
      case SellerMetricField.SELLER_CPA:
        return calculateMetricDataTotalCPA(aggData);
      case SellerMetricField.SELLER_ROAS:
        return calculateMetricDataTotalROAS(aggData);
      case SellerMetricField.SELLER_UNIT_VIEW:
        return calculateMetricDataUnitView(aggData);
      case ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE:
        return calculateMetricDataUnitRefundRate(aggData);
      case VendorMetricField.VENDOR_ASP:
        return calculateMetricDataVendorASP(aggData);
      case VendorMetricField.VENDOR_AD_SALES_OF_TOTAL:
        return calculateMetricDataVendorAdSalesOfTotal(aggData);
      case VendorMetricField.VENDOR_ROAS:
        return calculateMetricDataVendorTotalROAS(aggData);
      case VendorMetricField.VENDOR_ACOS:
        return calculateMetricDataVendorACOTS(aggData);
      case VendorMetricField.VENDOR_UNIT_VIEW:
        return calculateMetricDataVendorUnitView(aggData);
      case VendorMetricField.VENDOR_RETURN_RATE:
        return calculateMetricDataVendorReturnRate(aggData);
      default:
        return [0, 0];
    }
  }

  const getMetricFieldChangePercentageCellRendererParams = (params: ICellRendererParams, formattingParams?: FormattingParams) => {
    const columnId = params.colDef?.colId as ColumnId;
    const metricField = columnId ? (getMetricConfigByColId(columnId)?.key ?? CommonMetricField.IMPRESSIONS) : CommonMetricField.IMPRESSIONS;

    const formatter = params.colDef
      ? (number: number | null | undefined) => {
          if (params.context?.formatterType === FormatterType.SHORT) {
            return getShortFormatterForMetricField(metricField)(number, formattingParams);
          }
          return getLongFormatterForMetricField(metricField)(number, formattingParams);
        }
      : formatWithThousandsSeparator;

    return {
      currentValueFormatter: formatter,
      sentimentDirection: params.colDef ? getSentimentByMetric(metricField) : SentimentDirection.SYNCED,
      value: params.value,
      comparisonUnit: params.context?.comparisonUnit,
      isComparisonDataMissing: params.context?.isComparisonDataMissing,
    };
  };

  const getCampaignAdTypeCellRendererParams = (): ITextCellRendererParams => {
    return {
      valueToString: (key: string) => (key ? t(`enums.ad_type.${key}`) : ''),
      valueToColor: (key: string) => getCampaignAdTypeColor(CampaignAdType[key as keyof typeof CampaignAdType]),
      valueToTooltip: (key: string) => {
        const type = CampaignAdType[key as keyof typeof CampaignAdType];
        switch (type) {
          case CampaignAdType.PRODUCTS:
            return t(`enums.ad_type_long.${type}`);
          case CampaignAdType.BRANDS:
            return t(`enums.ad_type_long.${type}`);
          case CampaignAdType.DISPLAY:
            return t(`enums.ad_type_long.${type}`);
          case CampaignAdType.TV:
            return t(`enums.ad_type_long.${type}`);
          default:
            return '';
        }
      },
    };
  };

  const getBiddingEntityCellRendererParams = (): ITextCellRendererParams => {
    return {
      valueToString: (key: string) => (key ? t(`enums.bidding_entity.${key}`) : ''),
      valueToColor: (key: string) => {
        let color: TailwindColorVariant;

        switch (key) {
          case BiddingEntity.PLACEMENT_PRODUCT_PAGE:
            color = TailwindColorVariant.BLUE;
            break;
          case BiddingEntity.PLACEMENT_REST_OF_SEARCH:
            color = TailwindColorVariant.BLUE;
            break;
          case BiddingEntity.PLACEMENT_TOP:
            color = TailwindColorVariant.BLUE;
            break;
          case BiddingEntity.KEYWORD:
            color = TailwindColorVariant.GREEN;
            break;
          case BiddingEntity.PRODUCT_TARGET:
            color = TailwindColorVariant.GREEN;
            break;
          case 'AUDIENCE': // Product targets where targeting begins with audience=, views=, purchases=
            color = TailwindColorVariant.VIOLET;
            break;
          case BiddingEntity.HOME:
            color = TailwindColorVariant.BLUE;
            break;
          case BiddingEntity.DETAIL_PAGE:
            color = TailwindColorVariant.BLUE;
            break;
          case BiddingEntity.OTHER:
            color = TailwindColorVariant.BLUE;
            break;
          case BiddingEntity.BRANDS_PLACEMENT_TOP:
            color = TailwindColorVariant.BLUE;
            break;
          default:
            color = TailwindColorVariant.GREEN;
        }
        return color;
      },
    };
  };

  const getPlacementTypeCellRendererParams = (): ITextCellRendererParams => {
    return {
      valueToString: (key: string) => (key ? t(`enums.bidding_entity.${key}`) : ''),
      valueToColor: (key: string) => getPlacementTypeColor(PlacementType[key as keyof typeof PlacementType]),
      // valueToTooltip: (key: string) => {
      //   const type = PlacementType[key as keyof typeof PlacementType];
      //   switch (type) {
      //     case PlacementType.PLACEMENT_PRODUCT_PAGE:
      //       return 'Product Page';
      //     case PlacementType.PLACEMENT_REST_OF_SEARCH:
      //       return 'Rest of Search';
      //     case PlacementType.PLACEMENT_TOP:
      //       return 'Top of Search';
      //     default:
      //       return '';
      //   }
      // Missing Home etc
      // },
    };
  };

  interface ShowEmptyInfo {
    showEmpty: boolean;
    warningTextOnEmpty: string;
  }

  // CONDITIONAL EMPTY SELECTOR TO METRIC COLDEFS
  function metricColDefsConditionallyAttachEmptyCellRendererSelectorByProfileField<T>(
    colDefs: ColDef<T>[],
    profileIdFieldName: keyof T,
  ): void {
    const sellerMetricColIds = getSellerMetricColIds();
    const vendorMetricColIds = getVendorMetricColIds();
    const sellerVendorSharedMetricColIds = getSellerVendorSharedMetricColIds();

    for (const colDef of colDefs) {
      // Columns that are not seller or vendor columns will not get cell renderer selector
      if (
        !sellerMetricColIds.includes(colDef.colId as ColumnId) &&
        !vendorMetricColIds.includes(colDef.colId as ColumnId) &&
        !sellerVendorSharedMetricColIds.includes(colDef.colId as ColumnId)
      )
        continue;

      // Conditional selector
      const showEmptyCellRenderer = (params: ICellRendererParams<T>): ShowEmptyInfo => {
        const profileId = params.data?.[profileIdFieldName] as string;

        const isSellingPartnerNotConnected = !isProfileSeller(profileId) && !isProfileVendor(profileId); // Is selling partner connected

        const isSellerCol = [...sellerMetricColIds, ...sellerVendorSharedMetricColIds].includes(colDef.colId as ColumnId);
        const isProfileSellerColNotSeller = isProfileSeller(profileId) && !isSellerCol;

        const isVendorCol = [...vendorMetricColIds, ...sellerVendorSharedMetricColIds].includes(colDef.colId as ColumnId);
        const isProfileVendorColNotVendor = isProfileVendor(profileId) && !isVendorCol;

        return {
          showEmpty:
            (isSellingPartnerNotConnected || isProfileSellerColNotSeller || isProfileVendorColNotVendor) && params.node.group === false,
          warningTextOnEmpty: isSellingPartnerNotConnected ? 'Selling partner not connected' : '',
        };
      };

      updateColDefWithEmptyCellRenderer(colDef, showEmptyCellRenderer);
    }
  }

  function updateColDefWithEmptyCellRenderer<T>(
    colDef: ColDef<T>,
    showEmptyCellRenderer: (params: ICellRendererParams<T>) => ShowEmptyInfo,
  ): void {
    if (!colDef.colId) return; // Skip if colId is not defined

    colDef.cellRendererSelector = (params: ICellRendererParams<T>) => {
      const showEmptyInfo = showEmptyCellRenderer(params);

      if (showEmptyInfo.showEmpty) {
        return {
          component: () => (
            <Tooltip title={showEmptyInfo.warningTextOnEmpty}>
              <div className="flex flex-1 h-full justify-end opacity-50">–</div>
            </Tooltip>
          ),
        };
      }
      return undefined;
    };
  }

  // Works together with "isRowSelectable" grid option
  function getCheckboxWithConditionalWarningColDefProperties<T>(field: keyof T): Partial<ColDef<T>> {
    return {
      cellClass: (params: CellClassParams<T>) => {
        if (!params.data?.[field]) return 'ml-1';

        // Hack: even when using cellRendererSelector, the checkbox is still there but invisible
        // this shifts info icon to the right. Current solution just adds "absolute" to
        // the checkbox div so it moves out of the way
        return `ml-[2px] [&_.ag-selection-checkbox]:absolute`;
      },
      checkboxSelection: (params) => !params.data?.[field],
      cellRendererSelector: (params: ICellRendererParams<T>) => {
        if (!params.data?.[field]) return undefined;
        return {
          component: () => <InfoIconWithTooltip text={params.data?.[field] as string} />,
        };
      },
    };
  }

  return {
    metricsDataComparator,
    metricsDataDeltaComparator,
    metricsDataDeltaPercentageComparator,
    getMetricFieldChangePercentageCellRendererParams,
    getCampaignAdTypeCellRendererParams,
    getBiddingEntityCellRendererParams,
    getPlacementTypeCellRendererParams,
    getCalculatedMetricAggDataForMetricData,
    getCalculatedMetricAggDataForArrays,
    metricColDefsConditionallyAttachEmptyCellRendererSelectorByProfileField,
    getCheckboxWithConditionalWarningColDefProperties,
  };
};

export default useColDefsFunctions;
