import { DataGroupColumnId } from '@/modules/data-groups/models/DataGroup';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CountryCode } from '@/modules/users/types/CountryCode';
import { ColDef, GridOptions, RowClassParams } from 'ag-grid-enterprise';
import { CSSProperties } from 'react';
import { StartEndDatePair } from '../filter-builder/types/StartEndDatePair';
import { MetricAggregates } from '../metrics/types/MetricField';
import { ColumnId } from './columns/columns.enum';

export interface AlColDef<T> extends ColDef<T> {
  colId: ColumnId | DataGroupColumnId;
  headerStyle?: CSSProperties;
  children?: AlColDef<T>[];
}

// TODO: rename to ExternalDataSourcesContext and move logic in table to hook, don't extend toggles, but make a union type
export interface ExpandedGridContext extends GridTogglesContext {
  metricColumnAggregates: MetricAggregates | undefined;
  campaignGroupIdToCampaignGroupMap?: Record<number, CampaignGroupModel> | undefined;
  isComparisonDataMissing: boolean;
}

export interface GridTogglesContext {
  comparisonUnit?: ComparisonUnit;
  // sortByUnit?: SortByUnit; // Not in context because handled in another way because grid doesn't yet have params access in comparator
  formatterType?: FormatterType;
}

export interface GridToggles {
  comparisonUnit?: ComparisonUnit;
  sortByUnit?: SortByUnit;
  formatterType?: FormatterType;
}

export type ComparisonUnit = 'nominal' | 'percent' | 'hidden';

export enum FormatterType {
  SHORT = 'short',
  LONG = 'long',
}

export enum SortByUnit {
  Count = 'count',
  Delta = 'delta',
}

export interface WithFiltersGridContext {
  filterCurrentStartEndDate: StartEndDatePair | undefined;
}

export interface WithActiveProfileGridContext {
  countryCode: CountryCode | undefined;
}

export const CELL_CLASS_CONTENTS_RIGHT = 'flex flex-row items-center justify-end';

// TODO: use this everywhere where grouped rows are used
export const DEFAULT_GRID_OPTIONS_ROW_GROUPS: GridOptions = {
  //groupAllowUnbalanced: true, // Allows some rows to be ungrouped instead of being in auto group Blanks
  rowGroupPanelShow: 'always', // The place you can drag headers for row grouping
  groupDefaultExpanded: 0,
  // What to show in the auto created Group column on non aggregated rows
  autoGroupColumnDef: {
    pinned: 'left',
    cellRendererParams: {
      suppressCount: true, // Suppress the default (count)
    },
  },
  suppressAggFuncInHeader: true, // without this aggregated Impressions header would be func(Impressions)
  groupSelectsChildren: true,
  getRowStyle: (params: RowClassParams<unknown, unknown>) => {
    // Add custom style to group rows
    if (params.node.group) {
      return {
        background: 'linear-gradient(to bottom, rgba(96,165,250, 0.2), rgba(96,165,250, 0.05))',
      };
    }
  },
};
