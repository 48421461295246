import { sleep } from '@/lib/api/api-utils';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { useFirebaseAuth } from '@/modules/auth';
import { userService, useUserContext } from '@/modules/users';
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_FIRST_NAME_LENGTH,
  MIN_ORGANIZATION_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_SURNAME_LENGTH,
} from '@/modules/users/constants';
import { toastService } from '@/services/toast.service';
import { TextField } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { SettingsCard } from '../components/SettingsCard';

export const UserSettingsPage: FunctionComponent = () => {
  const { user, setUser, refetchUser } = useUserContext();
  const auth = useFirebaseAuth();

  const [firstName, setFirstName] = useState(user?.name || '');
  const [surname, setSurname] = useState(user?.surname || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phone || '');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [organizationName, setOrganizationName] = useState(user?.organizationName || '');

  const [isUpdatingUserDetails, setIsUpdatingUserDetails] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [isUpdatingOrganization, setIsUpdatingOrganization] = useState(false);

  // Validation
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  useEffect(() => {
    if (firstName.length < MIN_FIRST_NAME_LENGTH || firstName.length > MAX_FIRST_NAME_LENGTH) {
      setFirstNameErrorMessage('First name must be between ' + MIN_FIRST_NAME_LENGTH + ' and ' + MAX_FIRST_NAME_LENGTH + ' characters long.');
    } else {
      setFirstNameErrorMessage('');
    }
  }, [firstName]);

  const [surnameErrorMessage, setSurnameErrorMessage] = useState('');
  useEffect(() => {
    if (surname.length < MIN_SURNAME_LENGTH || surname.length > MAX_SURNAME_LENGTH) {
      setSurnameErrorMessage('Last name must be between ' + MIN_SURNAME_LENGTH + ' and ' + MAX_SURNAME_LENGTH + ' characters long.');
    } else {
      setSurnameErrorMessage('');
    }
  }, [surname]);

  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
  useEffect(() => {
    if (!matchIsValidTel(phoneNumber)) {
      setPhoneNumberErrorMessage('Phone Number Not Valid');
    } else {
      setPhoneNumberErrorMessage('');
    }
  }, [phoneNumber]);

  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
  useEffect(() => {
    if (newPassword.length < MIN_PASSWORD_LENGTH) {
      setNewPasswordErrorMessage(`Enter at least ${MIN_PASSWORD_LENGTH} characters`);
    } else {
      setNewPasswordErrorMessage('');
    }
  }, [newPassword]);

  const [organizationNameErrorMessage, setOrganizationNameErrorMessage] = useState('');
  useEffect(() => {
    if (organizationName.length < MIN_ORGANIZATION_NAME_LENGTH) {
      setOrganizationNameErrorMessage(`Enter at least ${MIN_ORGANIZATION_NAME_LENGTH} characters`);
    } else {
      setOrganizationNameErrorMessage('');
    }
  }, [organizationName]);

  const onPasswordUpdateClicked = async () => {
    if (!isEmpty(newPasswordErrorMessage)) {
      toastService.error(newPasswordErrorMessage);
      return;
    }

    if (!user) {
      toastService.error('User not found');
      return;
    }

    try {
      setIsUpdatingPassword(true);
      const firebaseUser = await auth.signIn(user.email, password);
      if (firebaseUser) {
        const res = await userService.updateUserPassword(user.id, { password: password, new_password: newPassword });
        if (res.isSuccess) {
          toastService.success('Password Updated Successfully');
          setPassword('');
          setNewPassword('');
        } else {
          toastService.error('Error Updating password. Reason: ' + res.message);
        }
      } else {
        toastService.error('Error. Please try again');
      }
    } catch (error) {
      console.error(error);
      toastService.error('Invalid Password');
    } finally {
      setIsUpdatingPassword(false);
    }

    setIsUpdatingPassword(false);
  };

  const onUpdateUserDetailsClicked = async () => {
    if (!isEmpty(firstNameErrorMessage)) {
      toastService.error(firstNameErrorMessage);
      return;
    }

    if (!isEmpty(surnameErrorMessage)) {
      toastService.error(surnameErrorMessage);
      return;
    }

    if (!isEmpty(phoneNumberErrorMessage)) {
      toastService.error(phoneNumberErrorMessage);
      return;
    }

    if (!user) {
      toastService.error('User not found');
      return;
    }

    setIsUpdatingUserDetails(true);
    const res = await userService.updateUserDetails(user.id, { name: firstName, surname, phone: phoneNumber });
    if (res.isSuccess) {
      toastService.success('User Details Updated Successfully');
      user.name = res.payload.name;
      user.surname = res.payload.surname;
      setUser(user);
    } else {
      toastService.error('Error Updating user. Reason: ' + res.message);
    }
    setIsUpdatingUserDetails(false);
  };

  const onUpdateOrganizationClicked = async () => {
    if (!isEmpty(organizationNameErrorMessage)) {
      toastService.error(organizationNameErrorMessage);
      return;
    }

    if (!user) {
      toastService.error('User not found');
      return;
    }

    setIsUpdatingOrganization(true);
    const res = await userService.updateOrganization(user.organizationId, { name: organizationName });
    if (res.isSuccess) {
      toastService.success('Organization name updated successfully');
      user.organizationName = res.payload.name;
      await sleep(100);
      refetchUser(); // Refetch teams table data
    } else {
      toastService.error('Error Updating. Reason: ' + res.message);
    }
    setIsUpdatingOrganization(false);
  };

  return (
    <PageLayout>
      <PageLayoutTopBar header="My Settings"></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="flex flex-row gap-4 mt-2">
          {user?.organizationId && user?.organizationId != 0 && (
            <SettingsCard
              title="Organization Details"
              buttonTitle="Update Organization"
              onClick={onUpdateOrganizationClicked}
              isButtonDisabled={!isEmpty(organizationNameErrorMessage)}
              isUpdating={isUpdatingOrganization}
            >
              <TextField
                label="Organization Name"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                variant="outlined"
                size="small"
                error={!isEmpty(organizationNameErrorMessage)}
                helperText={organizationNameErrorMessage}
              />
            </SettingsCard>
          )}

          <SettingsCard
            title="User Details"
            buttonTitle="Update Details"
            onClick={onUpdateUserDetailsClicked}
            isButtonDisabled={!isEmpty(firstNameErrorMessage) || !isEmpty(surnameErrorMessage) || !isEmpty(phoneNumberErrorMessage)}
            isUpdating={isUpdatingUserDetails}
          >
            <TextField fullWidth disabled id="email" label="E-mail" value={user?.email} />

            <TextField
              fullWidth
              autoComplete="off"
              id="firstName"
              label="First Name"
              placeholder="Enter your new first name"
              value={firstName}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
              error={!isEmpty(firstNameErrorMessage)}
              helperText={firstNameErrorMessage}
            />

            <TextField
              fullWidth
              autoComplete="off"
              id="lastName"
              label="Last Name"
              placeholder="Enter your new last name"
              value={surname}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSurname(event.target.value)}
              error={!isEmpty(surnameErrorMessage)}
              helperText={surnameErrorMessage}
            />

            <MuiTelInput
              id="phone"
              label="Phone Number"
              autoComplete="off"
              forceCallingCode
              focusOnSelectCountry
              defaultCountry="US"
              value={phoneNumber}
              onChange={(value: string) => {
                setPhoneNumber(value);
              }}
              error={!isEmpty(phoneNumberErrorMessage)}
              helperText={phoneNumberErrorMessage}
            />
          </SettingsCard>

          <SettingsCard
            title="Update Password"
            buttonTitle="Update Password"
            onClick={onPasswordUpdateClicked}
            isButtonDisabled={password.length == 0 || !isEmpty(newPasswordErrorMessage)}
            isUpdating={isUpdatingPassword}
          >
            <TextField
              fullWidth
              autoComplete="off"
              id="password"
              label="Current Password"
              type="password"
              placeholder="Enter your current Password"
              value={password}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
            />
            <TextField
              fullWidth
              autoComplete="off"
              id="new-password"
              label="New Password"
              type="password"
              placeholder="Enter your New Password"
              value={newPassword}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
              helperText={newPassword.length > 0 && newPasswordErrorMessage}
              error={newPassword.length > 0 && !isEmpty(newPasswordErrorMessage)}
            />
          </SettingsCard>
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};
