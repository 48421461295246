import { GridApi } from 'ag-grid-enterprise';
import { MutableRefObject, useEffect } from 'react';
import { ExpandedGridContext } from '../types';

interface UseComparisonMissingProps<T> {
  gridApiRef?: MutableRefObject<GridApi<T> | undefined>;
  gridContextRef?: MutableRefObject<ExpandedGridContext | undefined>;
  isComparisonDataMissing?: boolean;
}

const useComparisonMissing = <T>({ gridApiRef, gridContextRef, isComparisonDataMissing }: UseComparisonMissingProps<T>) => {
  function onGridReadyForComparisonMissing() {
    if (!gridApiRef || !gridContextRef || !gridApiRef.current || !gridContextRef.current) return;
    Object.assign(gridContextRef.current, {
      isComparisonDataMissing: isComparisonDataMissing,
    });
  }

  useEffect(() => {
    if (!gridApiRef || !gridContextRef || !gridApiRef.current || !gridContextRef.current) return;
    onGridReadyForComparisonMissing();
  }, [isComparisonDataMissing]);

  function getComparisonDataMissingMessage(isComparisonDataMissing: boolean): string | null {
    if (!isComparisonDataMissing) return null;

    return 'Warning: Comparison data is missing or incomplete';
  }

  return {
    onGridReadyForComparisonMissing,
    getComparisonDataMissingMessage,
  };
};

export default useComparisonMissing;
