import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { planService } from '@/modules/plans/api/plan.service';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { toastService } from '@/services/toast.service';
import { PreferredTimePicker } from '../components/PreferredTimePicker';
import ProfileTable from '../components/ProfileTable';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { SellingPartnerAuthButton } from '../components/SellingPartnerAuthButton';
import { ManageProfilesButton } from '../components/ManageProfilesButton';

const TeamProfilesPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeTeam, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { refetchUser, isAdminModeActive } = useUserContext();
  const {
    ModalComponent: pastDueModalComponent,
    handleOpenModal: handleOpenPastDueModal,
    setIsLoading,
  } = useConfirmationModal({
    questionText: 'Your payment is past due. Please update your payment information to avoid service interruption.',
    onConfirm: () => {
      redirectToBillingPortal();
    },
    questionTitle: 'Payment Past Due',
    cancelButtonText: 'Close',
    confirmButtonText: 'Go to Billing Portal',
    closeOnConfirm: false,
  });
  // Amazon redirects back to this page including code and state GET params
  const [searchParams] = useSearchParams();

  const errorDescription = searchParams.get('error_description');

  async function redirectToBillingPortal() {
    try {
      setIsLoading(true);

      const billingPortalLinkResponse = await planService.getBillingLinkForTeam(Routes.BILLING);
      if (billingPortalLinkResponse.isSuccess) {
        // Navigate to billing portal
        window.location.href = billingPortalLinkResponse.payload.session_url;
      } else {
        toastService.error(`Something went wrong. Please try again later. ${billingPortalLinkResponse.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Something went wrong. Please try again later.');
      setIsLoading(false);
    }
  }

  //If error_description is present in GET params, show error
  useEffect(() => {
    if (!isEmpty(errorDescription)) {
      toastService.error(errorDescription + ' Please try again!');
      navigate(Routes.PROFILES, { replace: true });
    }

    if (activeTeam?.isPastDueAndGracePeriodHasPassed) {
      handleOpenPastDueModal();
    } else if (hasUserOverMaxAllowedFreeProfiles() && !isAdminModeActive) {
      setIsPaywallModalOpen(true);
    }
  }, []);

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={t('profiles')}
          actions={
            <div className="flex flex-row items-center gap-2">
              <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <TeamSelect />
            </div>
          }
        ></PageLayoutTopBar>
        <PageLayoutBody>
          <div className="my-4 flex items-center justify-start">
            <div className="flex items-center gap-4">
              <ManageProfilesButton />

              <SellingPartnerAuthButton />
            </div>
          </div>

          <AlErrorBoundary>
            <ProfileTable />
          </AlErrorBoundary>
        </PageLayoutBody>
      </PageLayout>

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.PROFILES}
        headerText="Upgrade to Pro to use more than 3 profiles or kindly remove any profiles exceeding this limit"
      ></PaywallModal>
      {pastDueModalComponent}
    </>
  );
};

export default TeamProfilesPage;
