import { safeJSONStringify } from '@/modules/application/utils';
import * as Sentry from '@sentry/react';
import { Component, ErrorInfo, ReactNode } from 'react';
import TinyAccordion from './TinyAccordion';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface AlErrorBoundaryState {
  hasError: boolean;
  errorMessage?: string;
}

// Won't catch async errors because they're not a part of the React lifecycle
class AlErrorBoundary extends Component<ErrorBoundaryProps, AlErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): AlErrorBoundaryState {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, error?.stack, safeJSONStringify(errorInfo));
    Sentry.captureMessage(`ErrorBoundary componentDidCatch: ${error} ${safeJSONStringify(errorInfo)} ${error?.stack}`, 'error');
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI with error message
      return (
        <div>
          <h3>Something went wrong</h3>
          <TinyAccordion headerText={'Details'}>
            <p>Error: {this.state.errorMessage}</p>
          </TinyAccordion>
        </div>
      );
    }

    return this.props.children;
  }
}

export default AlErrorBoundary;
