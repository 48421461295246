import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FormatterType, GridToggles, SortByUnit } from '@/components/grid/types';
import { MetricSelectionColorIndex } from '@/components/metrics/MetricsConfig';
import { CommonMetricField, MetricField } from '@/components/metrics/types/MetricField';
import { UserSettingKey } from '@/modules/users';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';

// INTERFACES
export interface ContextWithFilters {
  filters: AlFilterModel[];
  setFilters: (newFilters: AlFilterModel[] | ((prevFilters: AlFilterModel[]) => AlFilterModel[])) => void;
  setFilterValue: (filter: AlFilterModel) => void;
  setFilterValues: (filters: AlFilterModel[]) => void;
  isFiltersEnabled?: boolean;
  disableFilters?: () => void;
}

interface ContextWithMetricSelection {
  visibleMetrics: MetricSelectionColorIndex[];
  setVisibleMetrics: (metrics: MetricSelectionColorIndex[]) => void;
  availableMetrics?: MetricField[];
}

export interface ContextWithGridToggles {
  setGridToggles: Dispatch<SetStateAction<GridToggles>>;
  gridToggles: GridToggles;
}

export interface MetricsGraphTablePageContext extends ContextWithFilters, ContextWithMetricSelection, ContextWithGridToggles {}

// DEFAULT VALUES
// ContextWithFilters default values
export const defaultFiltersContext: ContextWithFilters = {
  filters: [],
  setFilters: () => {},
  setFilterValue: () => {},
  setFilterValues: () => {},
};

// ContextWithMetricSelection default values
const defaultMetricSelectionContext: ContextWithMetricSelection = {
  visibleMetrics: [],
  setVisibleMetrics: () => {},
};

export const DEFAULT_AVAILABLE_METRICS = Object.values(CommonMetricField).filter(
  (key) => ![CommonMetricField.SAME_SKU_ORDERS, CommonMetricField.SAME_SKU_SALES].includes(key),
) as MetricField[];

// ContextWithTableToggles default values
export const DEFAULT_GRID_TOGGLES: GridToggles = {
  comparisonUnit: 'nominal',
  sortByUnit: SortByUnit.Count,
  formatterType: FormatterType.LONG,
};

export const defaultTableTogglesContext: ContextWithGridToggles = {
  gridToggles: DEFAULT_GRID_TOGGLES,
  setGridToggles: () => {},
};

// Combined
export const defaultMetricsGraphTablePageContext: MetricsGraphTablePageContext = {
  ...defaultFiltersContext,
  ...defaultMetricSelectionContext,
  ...defaultTableTogglesContext,
};

// PROPS
export interface FiltersPropsWithChildren extends PropsWithChildren {
  filters: AlFilterModel[];
  setFilters: Dispatch<SetStateAction<AlFilterModel[]>>;
}

export const enum ContextKey {
  SEARCH_TERMS = 'search-terms-context',
  TARGETING = 'targeting-context',
  OPTIMIZER = 'optimizer-context',
  PRODUCTS = 'products-context',
  CAMPAIGN_GROUPS = 'campaign-groups-context',
  CAMPAIGN_MAPPING = 'campaign-mapping-context',
  PLACEMENTS = 'placements-context',
  ADVERTISED_PRODUCTS = 'advertised-products-context',
  NEGATIVE_TARGETS = 'negative-targets-context',
}

export interface PageConfig {
  contextKey: ContextKey;
  metricsUserSettingKey: UserSettingKey;
  gridToggles: GridToggles;
  defaultFilters: AlFilterModel[];
  availableMetrics?: MetricField[];
}
