import { AlDate, TIMELINE_DATE_FORMAT } from '@/lib/date/AlDate';
import { Box, Tooltip } from '@mui/material';
import { Instance } from '@popperjs/core';
import { FunctionComponent, useEffect, useMemo, useRef, useState, MouseEvent as ReactMouseEvent } from 'react';

interface SparklineDataPoint {
  date: string;
  value: number | null; // Null values will account for width, but won't be drawn
}

interface SparklineProps {
  data: SparklineDataPoint[];
  color?: 'green' | 'amber' | 'blue';
  valueFormatter?: (value: number) => string;
}

const Sparkline: FunctionComponent<SparklineProps> = ({ data, color = 'green', valueFormatter }) => {
  const validValues = data.filter((p) => p.value !== null).map((p) => p.value as number);
  const maxValue = Math.max(...validValues);
  const minValue = Math.min(...validValues);
  const padding = 3; // Padding inside the SVG
  const [size, setSize] = useState({ width: 0, height: 0 });
  const positionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const popperRef = useRef<Instance | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const [tooltipContent, setTooltipContent] = useState('');
  const [open, setOpen] = useState(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const scaleX = (index: number) => padding + (index / (data.length - 1)) * (size.width - 2 * padding);

  const scaleY = useMemo(() => {
    // Check if maxValue equals minValue to avoid division by zero
    if (maxValue === minValue) {
      // Return the middle of the graph when all values are equal
      return (_: number) => size.height / 2;
    }
    return (value: number) =>
      padding + (size.height - 2 * padding) - ((value - minValue) / (maxValue - minValue)) * (size.height - 2 * padding);
  }, [size.height, minValue, maxValue]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        resizeObserver.unobserve(boxRef.current);
      }
    };
  }, []);

  // Split the data into continuous segments where value is not null
  const segments = useMemo(() => {
    const segments: { point: SparklineDataPoint; index: number }[][] = [];
    let segment: { point: SparklineDataPoint; index: number }[] = [];

    data.forEach((point, i) => {
      if (point.value !== null && point.value !== undefined) {
        segment.push({ point, index: i });
      } else {
        if (segment.length > 0) {
          segments.push(segment);
          segment = [];
        }
      }
    });
    if (segment.length > 0) {
      segments.push(segment);
    }
    return segments;
  }, [data]);

  // Create the line path
  const linePathD = useMemo(() => {
    if (size.width === 0 || size.height === 0) {
      return '';
    }
    return segments
      .map((segment) => {
        return segment
          .map(({ point, index }, i) => {
            const x = scaleX(index);
            const y = scaleY(point.value as number);
            return `${i === 0 ? 'M' : 'L'} ${x},${y}`;
          })
          .join(' ');
      })
      .join(' ');
  }, [segments, scaleX, scaleY, size.width, size.height]);

  // Create area paths for each segment
  const areaPathsD = useMemo(() => {
    if (size.width === 0 || size.height === 0) {
      return [];
    }
    return segments
      .map((segment) => {
        if (segment.length < 2) {
          // Cannot create area for a single point
          return null;
        }
        const path = segment
          .map(({ point, index }, i) => {
            const x = scaleX(index);
            const y = scaleY(point.value as number);
            return `${i === 0 ? 'M' : 'L'} ${x},${y}`;
          })
          .join(' ');

        // Close the area path by drawing a line down to the bottom and back to the start
        const lastIndex = segment[segment.length - 1].index;
        const firstIndex = segment[0].index;
        const pathClosed = `${path} L ${scaleX(lastIndex)},${size.height - padding} L ${scaleX(firstIndex)},${size.height - padding} Z`;
        return pathClosed;
      })
      .filter(Boolean) as string[]; // Remove null entries
  }, [segments, scaleX, scaleY, size.width, size.height, padding]);

  const handleMouseMove = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!boxRef.current) return;

    const boxRect = boxRef.current.getBoundingClientRect();
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current) {
      popperRef.current.update();
    }

    const xPosition = ((event.clientX - boxRect.left) / boxRect.width) * data.length;
    const index = Math.min(data.length - 1, Math.max(0, Math.floor(xPosition)));
    const point = data[index];

    if (point.value === null || point.value === undefined) {
      setOpen(false);
      setHoverIndex(null);
      return;
    }

    const formattedDate = AlDate.parse(point.date).toFormat(TIMELINE_DATE_FORMAT);

    setTooltipContent(`${formattedDate}: ${valueFormatter ? valueFormatter(point.value) : point.value.toFixed(2)}`);
    setHoverIndex(index);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setHoverIndex(null);
  };

  return (
    <div className="w-full h-full">
      <Tooltip
        title={tooltipContent}
        open={open}
        placement="top"
        // TODO: update
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => new DOMRect(positionRef.current.x, positionRef.current.y, 0, 0),
          },
        }}
      >
        <Box ref={boxRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} sx={{ width: '100%', height: '100%' }}>
          {/* Render the SVG */}
          {size.width === 0 || size.height === 0 ? null : (
            <svg width={size.width} height={size.height}>
              {/* Render the area fills */}
              {areaPathsD.map((areaPath, i) => (
                <path
                  key={`area-${i}`}
                  d={areaPath}
                  className={`${color === 'green' ? 'fill-green-600' : color === 'amber' ? 'fill-amber-600' : 'fill-blue-600'} opacity-15`}
                />
              ))}
              {/* Render the line */}
              <path
                d={linePathD}
                fill="none"
                className={`${color === 'green' ? 'stroke-green-600' : color === 'amber' ? 'stroke-amber-600' : 'stroke-blue-600'}`}
                strokeWidth="1"
              />
              {/* Render the circle for the current data point */}
              {hoverIndex !== null && data[hoverIndex].value !== null && (
                <circle
                  cx={scaleX(hoverIndex)}
                  cy={scaleY(data[hoverIndex].value as number)}
                  r="3"
                  className={`${color === 'green' ? 'fill-green-600' : color === 'amber' ? 'fill-amber-600' : 'fill-blue-600'}`}
                />
              )}
            </svg>
          )}
        </Box>
      </Tooltip>
    </div>
  );
};

export default Sparkline;
