import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { SelectedEntityForDataGroup } from '@/modules/data-groups/models/DataItem';
import { DTO } from '@/types/dto-wrapper';
import { ProductAvailability, ProductWithMetricsWithPreviousDTO } from '../api/products-contracts';

export class ProductModel extends DTO<ProductWithMetricsWithPreviousDTO> {
  constructor(data: ProductWithMetricsWithPreviousDTO) {
    super(data);
  }

  public get id(): string {
    return this.dto.a;
  }

  public get title(): string {
    return this.dto.t;
  }

  public get availability(): ProductAvailability {
    return this.dto.av;
  }

  public get basisPrice(): number {
    return this.dto.bp;
  }

  public get priceToPay(): number {
    return this.dto.pp;
  }

  public get bestSellerRank(): number {
    return this.dto.b;
  }

  public get imageUrl(): string {
    return this.dto.i;
  }

  public get asin(): string {
    return this.dto.a;
  }

  public get parentAsin(): string {
    return this.dto.pa;
  }

  public get sku(): string {
    return this.dto.s;
  }

  public get category(): string {
    return this.dto.c;
  }

  public get brand(): string {
    return this.dto.bd;
  }

  public get rankChange(): number[] {
    return this.dto.rank_change;
  }

  // Metric getters
  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get units(): MetricData {
    return this.dto.units;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.sales;
  }

  public get sso(): MetricData {
    return this.dto.sso;
  }

  public get sss(): MetricData {
    return this.dto.sss;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get actc(): MetricData {
    return this.dto.actc;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  // Seller and Vendor metrics
  public get acots(): MetricData {
    return this.dto.seller_metrics.acos;
  }

  public get totalActc(): MetricData {
    return this.dto.seller_metrics.actc;
  }

  public get asp(): MetricData {
    return this.dto.seller_metrics.asp;
  }

  public get organicSales(): MetricData {
    return this.dto.seller_metrics.org_sales;
  }

  public get organicTraffic(): MetricData {
    return this.dto.seller_metrics.org_traffic;
  }

  public get totalViews(): MetricData {
    return this.dto.seller_metrics.views;
  }

  public get totalUnits(): MetricData {
    return this.dto.seller_metrics.units;
  }

  // Seller Metrics
  public get adSalesOfTotal(): MetricData {
    return this.dto.seller_metrics.ad_sales_of_total;
  }

  public get totalAov(): MetricData {
    return this.dto.seller_metrics.aov;
  }

  public get totalClicks(): MetricData {
    return this.dto.seller_metrics.clicks;
  }

  public get totalCpa(): MetricData {
    return this.dto.seller_metrics.cpa;
  }

  public get totalCvr(): MetricData {
    return this.dto.seller_metrics.cvr;
  }

  public get totalOrders(): MetricData {
    return this.dto.seller_metrics.orders;
  }

  public get totalRoas(): MetricData {
    return this.dto.seller_metrics.roas;
  }

  public get totalSales(): MetricData {
    return this.dto.seller_metrics.sales;
  }

  public get ups(): MetricData {
    return this.dto.seller_metrics.unit_sess;
  }

  public get unitView(): MetricData {
    return this.dto.seller_metrics.unit_view;
  }

  // Vendor
  public get vendorTotalSales(): MetricData {
    return this.dto.vendor_metrics.ordered_revenue; // Ordered Revenue is called Total Sales
  }
  public get vendorTotalUnits(): MetricData {
    return this.dto.vendor_metrics.ordered_units; // Ordered Units is called Total Units
  }

  public get vendorShippedRevenue(): MetricData {
    return this.dto.vendor_metrics.shipped_revenue;
  }

  public get vendorShippedCogs(): MetricData {
    return this.dto.vendor_metrics.shipped_cogs;
  }

  public get vendorShippedUnits(): MetricData {
    return this.dto.vendor_metrics.shipped_units;
  }

  public get customerReturns(): MetricData {
    return this.dto.vendor_metrics.customer_returns;
  }

  public get vendorTotalViews(): MetricData {
    return this.dto.vendor_metrics.glance_views; //  Changed from "Glance Views" to Total Page Views
  }

  public get vendorOrganicSales(): MetricData {
    return this.dto.vendor_metrics.org_sales;
  }

  public get vendorReturnRate(): MetricData {
    return this.dto.vendor_metrics.return_rate;
  }

  public get vendorTotalProfit(): MetricData {
    return this.dto.vendor_metrics.return_rate;
  }

  public get vendorAsp(): MetricData {
    return this.dto.vendor_metrics.asp;
  }

  public get vendorAdSalesOfTotal(): MetricData {
    return this.dto.vendor_metrics.ad_sales_of_total;
  }

  public get vendorTotalRoas(): MetricData {
    return this.dto.vendor_metrics.roas;
  }

  public get vendorAcots(): MetricData {
    return this.dto.vendor_metrics.acos;
  }

  public get vendorUnitView(): MetricData {
    return this.dto.vendor_metrics.unit_view;
  }

  public get productDataItemIds(): number[] {
    return this.dto.pd ?? [];
  }
  public static fromResponse(dto: ProductWithMetricsWithPreviousDTO): ProductModel {
    return new ProductModel(dto);
  }

  public static fromResponseArray(dtos: ProductWithMetricsWithPreviousDTO[] | null): ProductModel[] {
    return dtos ? dtos.map(ProductModel.fromResponse) : [];
  }
}

export interface SelectedProduct extends SelectedEntityForDataGroup {
  id: string;
}
