import { CSSProperties, FunctionComponent, ReactNode, useEffect, useState } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';

interface PopoverLikePopperProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  children: ReactNode;
  style?: CSSProperties;
  transformOrigin?: string;
  placement?:
    | 'bottom'
    | 'top'
    | 'left'
    | 'right'
    | 'bottom-start'
    | 'bottom-end'
    | 'top-start'
    | 'top-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end';
}

const PopoverLikePopper: FunctionComponent<PopoverLikePopperProps> = ({
  open,
  anchorEl,
  onClose,
  children,
  style,
  transformOrigin = 'center bottom',
  placement = 'bottom',
}) => {
  // State to control the delayed activation of ClickAwayListener
  const [active, setActive] = useState(false);

  useEffect(() => {
    // If open, delay the activation of ClickAwayListener, otherwise the opening click is registred as "click away" and the popover is closed immediately after opening
    if (open) {
      const timeoutId = setTimeout(() => setActive(true), 200);
      return () => clearTimeout(timeoutId);
    }
    setActive(false);
  }, [open]);

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (!active) return; // Ignore click away if not active

    // To prevent closing the popover when clicking on for example a select inside the children
    // https://github.com/mui/material-ui/issues/12034#issuecomment-828240836
    const target = event.target as HTMLElement;
    if (target.localName === 'body') {
      return;
    }
    if (anchorEl && target && anchorEl.contains(target)) {
      return;
    }

    onClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 60 }} placement={placement}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin }}>
            <Paper elevation={8} style={{ maxWidth: '500px', overflowY: 'auto', maxHeight: 'calc(100vh - 120px)', ...style }}>
              {children}
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default PopoverLikePopper;
