import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Dispatch, FunctionComponent, SetStateAction, SyntheticEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface ProfileSearchBoxProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const ProfileSearchBox: FunctionComponent<ProfileSearchBoxProps> = ({ searchTerm, setSearchTerm }) => {
  function handleSearchTermChange(event: SyntheticEvent<Element, Event>) {
    const searchTerm = (event.target as HTMLInputElement).value;
    setSearchTerm(searchTerm);
  }

  function handleClear() {
    setSearchTerm('');
  }

  return (
    <TextField
      fullWidth
      id={'profile-search'}
      value={searchTerm}
      onChange={handleSearchTermChange}
      placeholder="Search Profiles"
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ fontSize: '20px' }} />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} edge="end">
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default ProfileSearchBox;
