import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { Routes } from '@/router/router-paths';
import { userService, useUserContext } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { SellingPartnerAuthorizationDialog } from './SellingPartnerAuthorizationDialog';

export const SellerCentralAuthorizationButton: FunctionComponent = () => {
  const { activeTeam } = useActiveTeamContext();
  const { user } = useUserContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  const onClick = () => {
    // check if license is pro
    if (!activeTeam?.subscriptionPlan.canAuthorizeSellerCentral) {
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDialogOpen(true);
  };

  async function getAuthUrl(market: string): Promise<string> {
    if (user?.id) {
      const res = await userService.getSellerCentralAuthorizationUrl(market);
      if (res.isSuccess) {
        return res.payload;
      } else {
        toastService.error('Unable to get auth url. Please try again later.');
      }
    } else {
      toastService.error('User or active team not set.');
    }

    return '';
  }

  return (
    <>
      <Button variant="contained" onClick={onClick}>
        Authorize Seller
      </Button>

      <SellingPartnerAuthorizationDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} getAuthUrl={getAuthUrl} />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.PROFILES}
        headerText="Upgrade to Pro to unlock metrics like Total Sales, Organic Sales and more!"
      ></PaywallModal>
    </>
  );
};
