import { SellingPartnerAuthButton } from '@/modules/teams/components/SellingPartnerAuthButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { FunctionComponent } from 'react';
import ShowXTimesAlertById from './ShowXTimesAlertById';

const SellingPartnerNotConnectedAlert: FunctionComponent = () => {
  const { isSellingPartnerConnected } = useUserContext();
  const { activeProfile } = useActiveTeamContext();

  return (
    <ShowXTimesAlertById
      id={activeProfile?.id}
      timesToDisplay={6}
      title="Selling Partner account not connected"
      content={
        <>
          <p>
            Unlock metrics like Total Sales, Organic Sales, Total ACOS and more by connecting the Selling Partner account to the advertising
            profile.
          </p>
          <SellingPartnerAuthButton />
        </>
      }
      userSettingKey={UserSettingKey.SELLING_PARTNER_NOT_CONNECTED_DISPLAY_COUNT}
      isAlertEnabled={!!activeProfile?.id && !isSellingPartnerConnected(activeProfile.id)}
    />
  );
};

export default SellingPartnerNotConnectedAlert;
