import { FunctionComponent, useState } from 'react';
import { ToggleButton, Tooltip } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ColumnConfigurationModal from './ColumnConfigurationModal';
import { ColumnConfiguration } from './column-categories';

interface ColumnConfigurationButtonProps {
  selectedColumns: ColumnConfiguration[];
  onSelectedColumnsChange: (columns: ColumnConfiguration[]) => void;
  onColumnConfigurationModalOpen: () => void;
}

const ColumnConfigurationButton: FunctionComponent<ColumnConfigurationButtonProps> = ({
  selectedColumns,
  onSelectedColumnsChange,
  onColumnConfigurationModalOpen,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    onColumnConfigurationModalOpen();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={'Customize Columns'} placement="left">
        <ToggleButton value={''} onClick={handleOpen} className="pt-[7px] pb-[8px] bg-white border-slate-300">
          <SettingsOutlinedIcon sx={{ fontSize: 15, marginRight: 4 }} />
          <span className="text-xxs leading-none">Columns</span>
        </ToggleButton>
      </Tooltip>

      <ColumnConfigurationModal
        open={open}
        onClose={handleClose}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={onSelectedColumnsChange}
      />
    </div>
  );
};

export default ColumnConfigurationButton;
