import { MetricsDTO, ProductMetricsDTO } from '../api/metrics-contracts';
import {
  isValidMetricFieldKey,
  MetricAggregates,
  MetricField,
  SellerMetricField,
  stringToSellerMetricFieldString,
  stringToVendorMetricFieldString,
  VendorMetricField,
} from '../types/MetricField';

export class MetricModel {
  public key: MetricField;
  public current: number;
  public previous: number;

  constructor(args: MetricsCreateArguments) {
    this.key = args.key;
    this.current = args.current;
    this.previous = args.previous;
  }

  public static fromProductDTO(response: ProductMetricsDTO): MetricModel[] {
    const metrics: MetricModel[] = [];

    for (const [key, value] of Object.entries(response.seller_metrics)) {
      const sellerMetricKey = stringToSellerMetricFieldString(key);

      if (!Object.values(SellerMetricField).includes(sellerMetricKey as SellerMetricField)) {
        continue;
      }
      metrics.push(
        new MetricModel({
          key: sellerMetricKey as SellerMetricField,
          current: value ? value[0] : 0,
          previous: value ? value[1] : 0,
        }),
      );
    }

    for (const [key, value] of Object.entries(response.vendor_metrics)) {
      const vendorMetricKey = stringToVendorMetricFieldString(key);
      if (!Object.values(VendorMetricField).includes(vendorMetricKey as VendorMetricField)) {
        continue;
      }
      metrics.push(
        new MetricModel({
          key: vendorMetricKey as VendorMetricField,
          current: value ? value[0] : 0,
          previous: value ? value[1] : 0,
        }),
      );
    }

    metrics.push(...MetricModel.fromDTO(response));

    return metrics;
  }

  public static fromMetricAggregates(aggregates: MetricAggregates): MetricModel[] {
    const models: MetricModel[] = [];
    for (const [key, value] of Object.entries(aggregates)) {
      models.push(
        new MetricModel({
          key: key as MetricField,
          current: value.current,
          previous: value.previous ?? 0,
        }),
      );
    }

    return models;
  }
  public static arrayToMetricAggregates(metrics: MetricModel[]): MetricAggregates {
    return metrics.reduce((aggregates, metric) => {
      aggregates[metric.key] = {
        current: metric.current,
        previous: metric.previous,
      };
      return aggregates;
    }, {} as MetricAggregates);
  }

  public static fromDTO(response: MetricsDTO): MetricModel[] {
    const metrics: MetricModel[] = [];
    for (const [key, value] of Object.entries(response)) {
      if (!isValidMetricFieldKey(key)) {
        continue;
      }
      metrics.push(
        new MetricModel({
          key: key as MetricField,
          current: value ? value[0] : 0,
          previous: value ? value[1] : 0,
        }),
      );
    }
    return metrics;
  }
}

interface MetricsCreateArguments {
  key: MetricField;
  current: number;
  previous: number;
}
