import {
  ComparisonDateFilterModel,
  DateFilterModel,
  FilterCondition,
  getDefaultStartEndDate,
  LogicalOperatorType,
  OperatorType,
} from '@/components/filter-builder/models/AlFilterModel';
import { AlDate, AlDateRawType } from '@/lib/date/AlDate';
import {
  comparisonCalenderDefaultShortcutsItems,
  getCalenderShortcutsItems,
} from '@/modules/application/components/date-range-picker/DateRangePickerConfig';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { Button, Paper } from '@mui/material';
import { DateRange, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { floor } from 'lodash-es';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface DateRangePickerProps {
  title: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (filters: DateFilterModel[]) => void;
  defaultDateRange: FilterCondition[] | undefined;
  defaultComparisonDateRange: FilterCondition[] | undefined;
  disablePaywall?: boolean;
  hideComparison?: boolean;
  minDateString?: string;
}

export default function DateRangePicker({
  title,
  setIsOpen,
  onSubmit,
  defaultDateRange,
  defaultComparisonDateRange,
  disablePaywall,
  hideComparison = false,
  minDateString,
}: DateRangePickerProps) {
  const { activeTeam } = useActiveTeamContext();
  const { isAdminModeActive } = useUserContext();

  const { startDate, endDate, comparisonStartDate, comparisonEndDate } = getDefaultStartEndDate();

  const [mainCalendarDateRange, setMainCalendarDateRange] = useState<DateRange<AlDateRawType>>(
    defaultDateRange
      ? [AlDate.parse(defaultDateRange[0].values[0].toString()).toRaw(), AlDate.parse(defaultDateRange[1].values[0].toString()).toRaw()]
      : [startDate.toRaw(), endDate.toRaw()],
  );

  const [comparisonCalendarDateRange, setComparisonCalendarDateRange] = useState<DateRange<AlDateRawType>>(
    defaultComparisonDateRange
      ? [
          AlDate.parse(defaultComparisonDateRange[0].values[0].toString()).toRaw(),
          AlDate.parse(defaultComparisonDateRange[1].values[0].toString()).toRaw(),
        ]
      : [comparisonStartDate.toRaw(), comparisonEndDate.toRaw()],
  );

  const handleMainCalendarDateChange = (newValue: DateRange<AlDateRawType>) => {
    setMainCalendarDateRange(newValue);

    const startDate = AlDate.fromRaw(newValue[0]);
    const endDate = AlDate.fromRaw(newValue[1]);

    // Check if both start and end dates are defined before updating the comparison date
    if (startDate && endDate) {
      const periodLengthInDays = endDate.diff(startDate, 'days');
      const newComparisonStart = startDate.subtractDays(periodLengthInDays + 1);
      const newComparisonEnd = endDate.subtractDays(periodLengthInDays + 1);
      setComparisonCalendarDateRange([newComparisonStart.toRaw(), newComparisonEnd.toRaw()]);
    } else {
      // If either date is undefined, set the comparison value to [null, null]
      setComparisonCalendarDateRange([null, null]);
    }
  };

  const handleComparisonCalendarDateChange = (newValue: DateRange<AlDateRawType>) => {
    const startDate = newValue[0];
    const endDate = newValue[1];
    if (startDate && endDate) {
      setComparisonCalendarDateRange([startDate, endDate]);
    }
  };

  const [comparisonCalenderShortcutsItems, setComparisonCalenderShortcutsItems] = useState<PickersShortcutsItem<DateRange<AlDateRawType>>[]>(
    comparisonCalenderDefaultShortcutsItems,
  );

  useEffect(() => {
    setComparisonCalenderShortcutsItems([
      {
        label: 'Previous Period',
        getValue: () => {
          const mainCalendarStartDate = AlDate.fromRaw(mainCalendarDateRange[0]);
          const mainCalendarEndDate = AlDate.fromRaw(mainCalendarDateRange[1]);
          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];

          const periodLengthInDays = mainCalendarEndDate.diff(mainCalendarStartDate, 'days');
          const newDays = periodLengthInDays + 1;
          const start = mainCalendarStartDate.subtractDays(newDays);
          const end = mainCalendarEndDate.subtractDays(newDays);
          return [start.toRaw(), end.toRaw()];
        },
      },
      {
        label: 'Previous Period (Day of Week)',
        getValue: () => {
          const mainCalendarStartDate = AlDate.fromRaw(mainCalendarDateRange[0]);
          const mainCalendarEndDate = AlDate.fromRaw(mainCalendarDateRange[1]);
          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];
          const periodLengthInDays = mainCalendarEndDate.diff(mainCalendarStartDate, 'days');
          const fullWeeksInDays = floor(periodLengthInDays / 7) * 7 + 7;
          const start = mainCalendarStartDate.subtractDays(fullWeeksInDays);
          const end = mainCalendarEndDate.subtractDays(fullWeeksInDays);
          return [start.toRaw(), end.toRaw()];
        },
      },
      {
        label: 'Previous Month',
        getValue: () => {
          const mainCalendarStartDate = AlDate.fromRaw(mainCalendarDateRange[0]);
          const mainCalendarEndDate = AlDate.fromRaw(mainCalendarDateRange[1]);

          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];

          const duration = mainCalendarEndDate.diff(mainCalendarStartDate, 'days'); // Duration in days

          const start = mainCalendarStartDate.minus({ months: 1 }); // Previous month's same day
          const end = start.plus({ days: duration }); // Ensure the same duration

          return [start.toRaw(), end.toRaw()];
        },
      },
      {
        label: 'Previous Year',
        getValue: () => {
          const mainCalendarStartDate = AlDate.fromRaw(mainCalendarDateRange[0]);
          const mainCalendarEndDate = AlDate.fromRaw(mainCalendarDateRange[1]);

          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];

          const duration = mainCalendarEndDate.diff(mainCalendarStartDate, 'days'); // Duration in days

          const start = mainCalendarStartDate.minus({ years: 1 }); // Previous year's same day
          const end = start.plus({ days: duration }); // Ensure the same duration

          return [start.toRaw(), end.toRaw()];
        },
      },
      { label: 'Custom', getValue: () => [null, null] },
    ]);
  }, [mainCalendarDateRange]);

  function onApplyDateRangeFilterClicked() {
    const startDate = AlDate.fromRaw(mainCalendarDateRange[0]);
    const endDate = AlDate.fromRaw(mainCalendarDateRange[1]);

    const newFilterValues: DateFilterModel[] = [];

    if (startDate && endDate) {
      const periodLengthInDays = endDate.diff(startDate, 'days');

      if (
        !isAdminModeActive &&
        activeTeam &&
        periodLengthInDays > activeTeam.subscriptionPlan.dateRangeLengthLimitInDays &&
        !disablePaywall
      ) {
        setIsPaywallModalOpen(true);
        return;
      }

      const newFilter = new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [startDate.toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [endDate.toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      newFilterValues.push(newFilter);
    }

    if (comparisonCalendarDateRange[0] && comparisonCalendarDateRange[1]) {
      const newFilter = new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [AlDate.fromRaw(comparisonCalendarDateRange[0])?.toDefaultFormat() ?? ''],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [AlDate.fromRaw(comparisonCalendarDateRange[1])?.toDefaultFormat() ?? ''],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      newFilterValues.push(newFilter);
    }

    if (newFilterValues.length > 0) onSubmit(newFilterValues);
    setIsOpen(false);
  }

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  const minDate = minDateString ? AlDate.parse(minDateString).toRaw() : undefined;

  return (
    <LocalizationProvider dateAdapter={AlDate.adapter}>
      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal} returnURLPath={Routes.BILLING}>
        {`Free tier limit of ${activeTeam?.subscriptionPlan.dateRangeLengthLimitInDays} days reached. Upgrade to access unlimited data retention.`}
      </PaywallModal>

      <div className="flex h-full flex-col justify-between px-2 pt-4">
        <div>
          <div className="my-0 ml-4 text-lg font-bold">{title}</div>
          <StaticDateRangePicker
            className="bg-transparent"
            slots={{
              toolbar: () => <div> </div>,
            }}
            slotProps={{
              shortcuts: {
                items: getCalenderShortcutsItems(minDate),
                sx: {
                  p: 2,
                  width: 250,
                },
              },
              actionBar: { actions: [] },
            }}
            disableFuture
            minDate={minDate}
            calendars={2}
            value={mainCalendarDateRange}
            onChange={handleMainCalendarDateChange}
          />
        </div>
        {!hideComparison && (
          <div>
            <div className="my-0 ml-4 text-lg font-bold">Comparison Period</div>

            <StaticDateRangePicker
              className="bg-transparent"
              slots={{
                toolbar: () => <div> </div>,
              }}
              slotProps={{
                shortcuts: {
                  items: comparisonCalenderShortcutsItems,
                  sx: {
                    p: 2,
                    width: 250,
                  },
                  dense: true,
                  disablePadding: true,
                },
                actionBar: { actions: [] },
              }}
              disableFuture
              calendars={2}
              minDate={minDate}
              value={comparisonCalendarDateRange}
              onChange={handleComparisonCalendarDateChange}
            />
          </div>
        )}
      </div>
      <Paper
        square
        className="sticky bottom-0 left-0 right-0 flex flex-row justify-end  gap-2 border-t px-3  py-2 dark:border-gray-700 dark:bg-gray-700"
      >
        <Button variant="text" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={onApplyDateRangeFilterClicked}>Apply Date Ranges</Button>
      </Paper>
    </LocalizationProvider>
  );
}
