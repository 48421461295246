import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { QueryClient } from '@tanstack/react-query';
import {
  CampaignDataWithCampaignMappingAdGroupData_toMap,
  CampaignMappingModel,
  CampaignToCampaignDataWithCampaignMappingAdGroupDataType,
  MappingCampaignAdGroupsDTO,
} from '../models/CampaignMappingModel';
import { CampaignMappingResponseDTO, DeleteCampaignMappingDTO, UpsertCampaignMappingDTO } from './campaign-mapping-contracts';

// Don't expose this and only use creators
const _CAMPAIGN_MAPPING_QUERY_KEY = 'campaign-mapping';

// Main key to use
export function createCampaignMappingQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const queryKey_withoutFilters = createCampaignMappingQueryKey_withoutFilters(activeProfileId);
  return [...queryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

function createCampaignMappingQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_CAMPAIGN_MAPPING_QUERY_KEY, activeProfileId];
}

export function invalidateProfile_campaignMappingQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createCampaignMappingQueryKey_withoutFilters(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });

  CampaignMappingService.invalidate_getCampaignsToCampaignMappingAdGroupDataQueryKey(queryClient, activeProfileId);
}

export class CampaignMappingService {
  public basePath = 'harvesting/mapping';

  async getCampaignMappingData(filters?: AlFilterModel[]): Promise<ApiResponse<CampaignMappingModel[]>> {
    const filterData = filters?.map((filter) => filter.toDTO());

    const payload: PayloadWithFiltersDTO = {};
    if (filterData) {
      payload.filters = filterData;
    }

    const applicationResponse = await apiProfileClient.post<CampaignMappingResponseDTO>(`${this.basePath}`, payload);
    return applicationResponse.processPayload(CampaignMappingModel.fromResponseObject);
  }

  static getCampaignsToCampaignMappingAdGroupDataQueryKey = (activeProfileId: string | undefined) => [
    _CAMPAIGN_MAPPING_QUERY_KEY,
    'campaigns-to-campaign-mapping-adgroup-data',
    activeProfileId,
  ];
  static invalidate_getCampaignsToCampaignMappingAdGroupDataQueryKey(queryClient: QueryClient, activeProfileId: string | undefined) {
    const key = CampaignMappingService.getCampaignsToCampaignMappingAdGroupDataQueryKey(activeProfileId);
    queryClient.invalidateQueries({
      predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
    });
  }
  async getCampaignToAdGroups(): Promise<ApiResponse<CampaignToCampaignDataWithCampaignMappingAdGroupDataType>> {
    const applicationResponse = await apiProfileClient.get<MappingCampaignAdGroupsDTO>(`${this.basePath}/campaign-adgroups`);
    return applicationResponse.processPayload(CampaignDataWithCampaignMappingAdGroupData_toMap);
  }

  async createCampaignMapping(campaignMapping: CampaignMappingModel): Promise<ApiResponse<unknown>> {
    const payload: UpsertCampaignMappingDTO = {
      mappings: [campaignMapping.toUpsertCampaignMappingItemDTO()],
    };

    return await apiProfileClient.post<UpsertCampaignMappingDTO>(`${this.basePath}/upsert`, payload);
  }

  async createCampaignMappings(campaignMappings: CampaignMappingModel[]): Promise<ApiResponse<unknown>> {
    const payload: UpsertCampaignMappingDTO = {
      mappings: campaignMappings.map((campaignMapping) => campaignMapping.toUpsertCampaignMappingItemDTO()),
    };

    return await apiProfileClient.post<UpsertCampaignMappingDTO>(`${this.basePath}/upsert`, payload);
  }

  async updateCampaignMappingParams(campaignMappings: CampaignMappingModel[]): Promise<ApiResponse<unknown>> {
    const payload: UpsertCampaignMappingDTO = {
      mappings: campaignMappings.map((campaignMapping) => campaignMapping.toUpsertCampaignMappingItemDTO()),
    };
    return await apiProfileClient.post<UpsertCampaignMappingDTO>(`${this.basePath}/upsert`, payload);
  }

  async updateCampaignMappingNegatives(campaignMapping: CampaignMappingModel): Promise<ApiResponse<unknown>> {
    const mapping = campaignMapping.toUpsertCampaignMappingItemDTO();
    mapping.mi = undefined;
    const payload: UpsertCampaignMappingDTO = {
      mappings: [mapping],
    };
    return await apiProfileClient.post<UpsertCampaignMappingDTO>(`${this.basePath}/upsert`, payload);
  }

  // Can be a mix of whole rows and rows with only some matches selected
  async deleteSelectedCampaignMappings(campaignMappings: CampaignMappingModel[]): Promise<ApiResponse<unknown>> {
    const payload: DeleteCampaignMappingDTO = {
      mappings: campaignMappings.map((campaignMapping) => campaignMapping.toDeleteCampaignMappingsOrItemsBySelectedDTO()),
    };

    return await apiProfileClient.post<DeleteCampaignMappingDTO>(`${this.basePath}/delete`, payload);
  }

  /* Deletes whole mapping with all its matches */
  async deleteWholeCampaignMappings(campaignMappings: CampaignMappingModel[]): Promise<ApiResponse<unknown>> {
    const payload: DeleteCampaignMappingDTO = {
      mappings: campaignMappings.map((campaignMapping) => campaignMapping.toDeleteCampaignMappingItemDTO(null)),
    };

    return await apiProfileClient.post<DeleteCampaignMappingDTO>(`${this.basePath}/delete`, payload);
  }

  async deleteCampaignMappingMatchType(campaignMapping: CampaignMappingModel, matchType: MatchType): Promise<ApiResponse<unknown>> {
    const mapping = campaignMapping.toDeleteCampaignMappingItemDTO(matchType);
    const payload: DeleteCampaignMappingDTO = {
      mappings: [mapping],
    };

    return await apiProfileClient.post<DeleteCampaignMappingDTO>(`${this.basePath}/delete`, payload);
  }

  // async deleteCampaignMappingNegativeMatchType(
  //   campaignMapping: CampaignMappingModel,
  //   negativeMatchType: NegativeMatchType,
  // ): Promise<ApiResponse<unknown>> {
  //   const mapping = campaignMapping.toDeleteCampaignMappingNegativeMatchItemDTO(negativeMatchType);
  //   const payload: DeleteCampaignMappingDTO = {
  //     mappings: [mapping],
  //   };

  //   return await apiProfileClient.post<DeleteCampaignMappingDTO>(`${this.basePath}/delete`, payload);
  // }

  async deleteAllCampaignMappings(): Promise<ApiResponse<unknown>> {
    const payload: DeleteCampaignMappingDTO = {
      delete_all: true,
    };
    return await apiProfileClient.post<DeleteCampaignMappingDTO>(`${this.basePath}/delete`, payload);
  }
}

export const campaignMappingService = new CampaignMappingService();
