import InfoMessage from '@/components/feedback/InfoMessage';
import { CREATE_NEGATIVES_DEFAULT_PARAMS, CreateNegativesParams, SelectedSearchTerm } from '@/modules/search-terms/models/SearchTermModel';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import { NegativeMatchType } from '../../api/negative-targets-contracts';
import { CreateNegativeModel } from '../../models/CreateNegativeModel';

interface CreateNegativesParamsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSearchTerms: SelectedSearchTerm[];
  onCreateNegativesParamsConfirm: (createNegativesParams: CreateNegativesParams) => void;
}

export const CreateNegativesParamsModal: FunctionComponent<CreateNegativesParamsModalProps> = ({
  isOpen,
  onClose,
  selectedSearchTerms,
  onCreateNegativesParamsConfirm,
}) => {
  if (!isOpen) return null; // Renders nothing if not open

  // User set checkbox state
  const [createNegativesParams, setCreateNegativesParams] = useState<CreateNegativesParams>(CREATE_NEGATIVES_DEFAULT_PARAMS);

  const handleCreateNegativesParamsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      setCreateNegativesParams({
        ...createNegativesParams,
        [name]: checked,
      });
    } else {
      setCreateNegativesParams({
        ...createNegativesParams,
        [name]: value,
      });
    }
  };

  // Confirmation
  const onPreviewActionClicked = () => {
    onClose();
    onCreateNegativesParamsConfirm(createNegativesParams);
  };

  // Checkbox enabled state
  const enabledNegativeMatches = useMemo(() => {
    return new Set(
      CreateNegativeModel.fromSelectedSearchTermArrayAndParams(selectedSearchTerms, {
        campaignNegativeExact: true,
        campaignNegativePhrase: true,
        campaignNegativeProductTarget: true,
        adGroupNegativeExact: true,
        adGroupNegativePhrase: true,
        adGroupNegativeProductTarget: true,
      }).map((negative) => negative.negativeMatchType),
    );
  }, [selectedSearchTerms]);

  const isSomeKeywordsChecked = [
    createNegativesParams.campaignNegativeExact,
    createNegativesParams.campaignNegativePhrase,
    createNegativesParams.adGroupNegativeExact,
    createNegativesParams.adGroupNegativePhrase,
  ].some(Boolean);
  const isSomeProductTargetsChecked = [
    createNegativesParams.campaignNegativeProductTarget,
    createNegativesParams.adGroupNegativeProductTarget,
  ].some(Boolean);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle style={{ paddingBottom: '0px' }}>Add Negative Targets</DialogTitle>
      <DialogContent>
        <div className="flex justify-between p-4">
          <div>
            <Typography variant="h6">Campaign Negatives</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createNegativesParams.campaignNegativeExact}
                    onChange={handleCreateNegativesParamsChange}
                    name="campaignNegativeExact"
                    disabled={!enabledNegativeMatches.has(NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT)}
                  />
                }
                label="Negative Exact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createNegativesParams.campaignNegativePhrase}
                    onChange={handleCreateNegativesParamsChange}
                    name="campaignNegativePhrase"
                    disabled={!enabledNegativeMatches.has(NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE)}
                  />
                }
                label="Negative Phrase"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createNegativesParams.campaignNegativeProductTarget}
                    onChange={handleCreateNegativesParamsChange}
                    name="campaignNegativeProductTarget"
                    disabled={!enabledNegativeMatches.has(NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET)}
                  />
                }
                label="Negative Product Target"
              />
            </FormGroup>
          </div>
          <div>
            <Typography variant="h6">Ad Group Negatives</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createNegativesParams.adGroupNegativeExact}
                    onChange={handleCreateNegativesParamsChange}
                    name="adGroupNegativeExact"
                    disabled={!enabledNegativeMatches.has(NegativeMatchType.AD_GROUP_NEGATIVE_EXACT)}
                  />
                }
                label="Negative Exact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createNegativesParams.adGroupNegativePhrase}
                    onChange={handleCreateNegativesParamsChange}
                    name="adGroupNegativePhrase"
                    disabled={!enabledNegativeMatches.has(NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE)}
                  />
                }
                label="Negative Phrase"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createNegativesParams.adGroupNegativeProductTarget}
                    onChange={handleCreateNegativesParamsChange}
                    name="adGroupNegativeProductTarget"
                    disabled={!enabledNegativeMatches.has(NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET)}
                  />
                }
                label="Negative Product Target"
              />
            </FormGroup>
          </div>
        </div>
      </DialogContent>
      <Divider />
      {enabledNegativeMatches.size === 0 && (
        <div className="flex flex-col mx-5">
          <InfoMessage text={`Cannot create any negative targets for selected search terms`} />
        </div>
      )}
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          onClick={onPreviewActionClicked}
          endIcon={<EastRoundedIcon />}
          disabled={!isSomeKeywordsChecked && !isSomeProductTargetsChecked}
        >
          Preview Negative Targets
        </Button>
      </DialogActions>
    </Dialog>
  );
};
