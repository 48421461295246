import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiProfileClient } from '@/lib/api/base-client';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { CampaignToAdGroups } from '@/modules/negative-targets/models/CampaignToAdGroupModel';
import * as Sentry from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { isNumber } from 'lodash-es';
import { AlFilterModel } from '../../../../components/filter-builder/models/AlFilterModel';
import { OptimizationPreset } from '../../components/optimization/OptimizerConfig';
import { TimelineModel } from '../../components/timeline/models/TimelineModel';
import {
  AdGroupDTO,
  CampaignGroupDTO,
  CampaignGroupsWithMetrics,
  CampaignGroupsWithMetricsDTO,
  CampaignUpdateDTO,
  CampaignWithTimelineDTO,
  CampaignsWithTimeline,
  ChangeCampaignGroupDTO,
  CreateNewCampaignGroupsDTO,
  GroupsMetricsRequest,
  IdNameDTO,
  NewCampaignGroupDTO,
  UpdateCampaignGroups,
  UpdateCampaignsDTO,
  UpdatedCampaignsDTO,
} from './campaign-contracts';
import { AdGroupModel } from './models/AdGroupModel';
import { CampaignGroupMetricsModel } from './models/CampaignGroupMetricsModel';
import { CampaignGroupModel } from './models/CampaignGroupModel';
import { CampaignModel, CampaignToAdGroupsDTO } from './models/CampaignModel';

const _CAMPAIGN_GROUPS_QUERY_KEY = 'campaign-groups';
export function createCampaignGroupsQueryKey(activeProfileId: string | undefined) {
  return [_CAMPAIGN_GROUPS_QUERY_KEY, activeProfileId];
}

export function createCampaignGroupsWithMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [...createCampaignGroupsQueryKey(activeProfileId), ...filters.map((filter) => filter.toQueryKey())];
}

export function invalidateProfile_campaignGroupsQueryKey(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createCampaignGroupsQueryKey(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

// Don't expose this and only use creators
const _CAMPAIGNS_WITH_TIMELINE_QUERY_KEY = 'campaigns-with-timeline';
function createCampaignsWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_CAMPAIGNS_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

// TODO: create this key in useMetricChartTablePageVariables hook and also move invalidation there
// Main key to use when fetching campaigns with timeline
export function createCampaignsWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const campaignsWithTimelineQueryKey_withoutFilters = createCampaignsWithTimelineQueryKey_withoutFilters(activeProfileId);

  // TODO: remove this Sentry check, added on 22 jan 2025
  const hasInvalidEntries = filters.some((filter) => !filter || typeof filter.toQueryKey !== 'function');
  if (hasInvalidEntries) {
    Sentry.captureMessage(
      `Invalid filter in createCampaignsWithTimelineQueryKey: ${filters} Stringified:${JSON.stringify(filters)}`,
      'error',
    );
  }
  return [...campaignsWithTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

export function invalidateProfile_campaignsWithTimelineQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const campaignsWithTimelineQueryKey_withoutFilters = createCampaignsWithTimelineQueryKey_withoutFilters(activeProfileId);
  // Invalidate campaignsWithTimeline query for current profile regardless of filters
  queryClient.invalidateQueries({
    predicate: (query) => campaignsWithTimelineQueryKey_withoutFilters.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

export function invalidateAll_campaignsWithTimelineQueryKeys(queryClient: QueryClient) {
  const campaignsWithTimelineQueryKey_withoutFilters = [_CAMPAIGNS_WITH_TIMELINE_QUERY_KEY];
  // Invalidate all campaignsWithTimeline queries regardless of profile and filters
  queryClient.invalidateQueries({
    predicate: (query) => campaignsWithTimelineQueryKey_withoutFilters.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

const _AD_GROUPS_QUERY_KEY = 'ad-groups-groups';
export function createAdGroupsQueryKey(activeProfileId: string | undefined) {
  return [_AD_GROUPS_QUERY_KEY, activeProfileId];
}

const _CAMPAIGNS_IDS_QUERY_KEY = 'campaign-ids';
export function createCampaignIdsQueryKey(activeProfileId: string | undefined) {
  return [_CAMPAIGNS_IDS_QUERY_KEY, activeProfileId];
}

export class CampaignService {
  public static basePath = 'campaigns';

  async getCampaignsWithTimeline(filters?: AlFilterModel[]): Promise<ApiResponse<CampaignsWithTimeline>> {
    try {
      const filterData = filters?.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<CampaignWithTimelineDTO>(
        `${CampaignService.basePath}/with-timeline`,
        requestData,
      );

      return applicationResponse.processPayload((payload) => ({
        campaigns: CampaignModel.fromResponseArray(payload.campaigns),
        timeline: TimelineModel.fromResponse(payload.timeline),
        metrics: MetricModel.fromDTO(payload.metrics),
        isComparisonDataMissing: payload.comparison_missing,
      }));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCampaignsWithTimelineForProfiles({
    filters,
    profiles,
  }: {
    filters: AlFilterModel[];
    profiles: {
      teamId: number;
      profileId: number;
    }[];
  }): Promise<ApiResponse<CampaignsWithTimeline[]>> {
    try {
      const filterData = filters?.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }
      // Do a promise all to get all the campaigns for each profile
      const campaignPromises = profiles.map((profile) =>
        apiClient.post<CampaignWithTimelineDTO>(
          `teams/${profile.teamId}/profiles/${profile.profileId}/${CampaignService.basePath}/with-timeline`,
          requestData,
        ),
      );
      const campaignResponses = await Promise.all(campaignPromises);

      if (campaignResponses.some((response) => !response.isSuccess)) {
        return ApiResponse.UnknownErrorResponse();
      }

      // Map to a list of CampaignsWithTimeline
      const campaignsWithTimeline: CampaignsWithTimeline[] = campaignResponses.map((response, index) => {
        return {
          profileId: profiles[index].profileId.toString(),
          campaigns: CampaignModel.fromResponseArray(response.payload.campaigns),
          timeline: TimelineModel.fromResponse(response.payload.timeline),
          isComparisonDataMissing: response.payload.comparison_missing,
          metrics: MetricModel.fromDTO(response.payload.metrics),
        };
      });

      return ApiResponse.responseWithPayload<CampaignsWithTimeline[]>(campaignsWithTimeline, 200);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getNonArchivedCampaignIds(): Promise<ApiResponse<IdNameDTO[]>> {
    return await apiProfileClient.get<IdNameDTO[]>(`${CampaignService.basePath}/ids`);
  }

  async getNonArchivedCampaignIdsByTeamAndProfile(teamId: number, profileId: string): Promise<ApiResponse<IdNameDTO[]>> {
    // api returns only enabled and paused campaigns
    return await apiClient.get<IdNameDTO[]>(`teams/${teamId}/profiles/${profileId}/${CampaignService.basePath}/ids`);
  }

  async getNonArchivedPortfolioIds(): Promise<ApiResponse<IdNameDTO[]>> {
    // api returns only enabled and paused portfolios
    return await apiProfileClient.get<IdNameDTO[]>(`${CampaignService.basePath}/portfolios/ids`);
  }

  async getAdGroups(): Promise<ApiResponse<AdGroupModel[]>> {
    const applicationResponse = await apiProfileClient.get<AdGroupDTO[]>(`${CampaignService.basePath}/ad-groups`);
    return applicationResponse.processPayload(AdGroupModel.fromResponseArray);
  }

  async getAdGroupsByTeamAndProfile(teamId: number, profileId: string): Promise<ApiResponse<AdGroupModel[]>> {
    const applicationResponse = await apiClient.get<AdGroupDTO[]>(
      `teams/${teamId}/profiles/${profileId}/${CampaignService.basePath}/ad-groups`,
    );
    return applicationResponse.processPayload(AdGroupModel.fromResponseArray);
  }

  static getCampaignsToAdGroupsQueryKey = (activeProfileId: string | undefined) => ['campaigns-to-ad-groups', activeProfileId];
  async getCampaignToAdGroups(): Promise<ApiResponse<CampaignToAdGroups[]>> {
    const applicationResponse = await apiProfileClient.get<CampaignToAdGroupsDTO[]>(`${CampaignService.basePath}/with-ad-groups`);
    return applicationResponse.processPayload(CampaignToAdGroups.fromDTOArray);
  }

  async getCampaignIdsByTeamAndProfile(teamId: number, profileId: string): Promise<ApiResponse<IdNameDTO[]>> {
    return await apiClient.get<IdNameDTO[]>(`teams/${teamId}/profiles/${profileId}/${CampaignService.basePath}/ids`);
  }

  async getGroups(): Promise<ApiResponse<CampaignGroupModel[]>> {
    const applicationResponse = await apiProfileClient.get<CampaignGroupDTO[]>(`${CampaignService.basePath}/groups`);
    return applicationResponse.processPayload(CampaignGroupModel.fromResponseArray);
  }

  async getGroupsByTeamAndProfile(teamId: number, profileId: string): Promise<ApiResponse<CampaignGroupModel[]>> {
    const applicationResponse = await apiClient.get<CampaignGroupDTO[]>(
      `teams/${teamId}/profiles/${profileId}/${CampaignService.basePath}/groups`,
    );
    return applicationResponse.processPayload(CampaignGroupModel.fromResponseArray);
  }

  async getGroupsWithMetrics(filters: AlFilterModel[]): Promise<ApiResponse<CampaignGroupsWithMetrics>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const payload: GroupsMetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<CampaignGroupsWithMetricsDTO>(
        `${CampaignService.basePath}/groups/with-metrics`,
        payload,
      );

      return applicationResponse.processPayload((payload) => ({
        campaignGroups: CampaignGroupMetricsModel.fromResponseArray(payload.campaign_groups),
        metrics: MetricModel.fromDTO(payload.metrics),
        isComparisonDataMissing: payload.comparison_missing,
      }));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateCampaigns(campaignUpdates: CampaignUpdateDTO[], flowType: FlowType): Promise<ApiResponse<UpdatedCampaignsDTO>> {
    try {
      const payload: UpdateCampaignsDTO = {
        flow_type: flowType,
        updates: campaignUpdates,
      };

      return await apiProfileClient.post<UpdatedCampaignsDTO>(`${CampaignService.basePath}/update`, payload);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async createGroups(groups: NewCampaignGroupDTO[], flowType: FlowType): Promise<ApiResponse<CampaignGroupModel[]>> {
    const payload: CreateNewCampaignGroupsDTO = {
      flow_type: flowType,
      groups,
    };

    const applicationResponse = await apiProfileClient.post<CampaignGroupDTO[]>(`${CampaignService.basePath}/groups/create`, payload);
    return applicationResponse.processPayload(CampaignGroupModel.fromResponseArray);
  }

  async updateGroups(groups: CampaignGroupModel[]): Promise<ApiResponse<null>> {
    try {
      const payload: UpdateCampaignGroups = {
        groups: groups.map((group: CampaignGroupModel) => ({
          id: group.id,
          name: group.name,
          tacos: group.tacos && isNumber(group.tacos) ? group.tacos : undefined,
          preset: group.preset && group.preset != OptimizationPreset.NOT_SET ? group.preset : undefined,
          bid_ceiling: group.bidCeiling,
          bid_floor: group.bidFloor,
          total_campaigns: group.totalCampaigns,
          bid_max_increase: group.maxBidIncrease,
          bid_max_decrease: group.maxBidDecrease,
          placement_max_increase: group.placementMaxIncrease,
          placement_max_decrease: group.placementMaxDecrease,
          bid_ceiling_off: group.bidCeilingOff,
          bid_floor_off: group.bidFloorOff,
          bid_max_increase_off: group.bidMaxIncreaseOff,
          bid_max_decrease_off: group.bidMaxDecreaseOff,
          placement_max_increase_off: group.placementMaxIncreaseOff,
          placement_max_decrease_off: group.placementMaxDecreaseOff,
          no_optimize: !group.optimizationEnabled,
        })),
      };

      return apiProfileClient.post(`${CampaignService.basePath}/groups/update`, payload);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async deleteGroups(groupIds: number[]): Promise<ApiResponse<null>> {
    const payload = {
      delete_ids: groupIds,
    };

    return apiProfileClient.post(`${CampaignService.basePath}/groups/delete`, payload);
  }

  async changeGroups(changeCampaignsGroups: ChangeCampaignGroupDTO[]): Promise<ApiResponse<null>> {
    try {
      const payload = {
        changes: changeCampaignsGroups.map((changeCampaignGroup: ChangeCampaignGroupDTO) => ({
          campaign_id: changeCampaignGroup.campaign_id,
          group_id: changeCampaignGroup.group_id == 0 ? null : changeCampaignGroup.group_id,
        })),
      };

      return apiProfileClient.post(`${CampaignService.basePath}/groups/change`, payload);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const campaignService = new CampaignService();
