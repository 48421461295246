import { createContext, Dispatch, SetStateAction } from 'react';
import { AlFilterModel } from '../models/AlFilterModel';

interface PendingFiltersContext {
  filters: AlFilterModel[];
  setFilters: Dispatch<SetStateAction<AlFilterModel[]>>;
  setFilterValue: (filter: AlFilterModel) => void;
}

// Initialize the context variable and also export it.
export const PendingFiltersContext = createContext<PendingFiltersContext>({
  filters: [],
  setFilters: () => null,
  setFilterValue: () => null,
});
