import { CreateNegativesResponseItemDTO, UpdatedNegativeTargetsDTO } from '@/modules/negative-targets/api/negative-targets-contracts';
import { UpdatedCampaignsDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { OptimizedBiddingEntityDTO } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { BiddingEntity } from '@/modules/optimizer/components/optimization/models/OptimizationModel';
import { UpdatedTargetsDTO } from '@/modules/targeting/api/targets-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Dialog } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { CreatedEntityDTO } from '../types/UpdatedEntity';
import { UpsertResponseAlert } from './UpsertResponseAlert';

// TODO: use inheritance instead of listing all these types
type UpsertResponsePayloadDTOBase =
  | UpdatedNegativeTargetsDTO
  | UpdatedTargetsDTO
  | UpdatedCampaignsDTO
  | OptimizedBiddingEntityDTO
  | CreateNegativesResponseItemDTO
  | CreatedEntityDTO;

// Add extra field for front end usage
export type UpsertResponsePayloadDTO = UpsertResponsePayloadDTOBase & { already_exists_count?: number };

export interface UpdateResponseDTO {
  responseErrorMsg: string | null;
  payload: UpsertResponsePayloadDTO[] | undefined;
}

interface UpdateResponseProps {
  isOpen: boolean;
  onClose: () => void;
  updateResponse: UpdateResponseDTO | undefined;
}

export const UpdateResponseModal: FunctionComponent<UpdateResponseProps> = ({ isOpen, onClose, updateResponse }) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();

  let totalErrorCount = 0;

  const errorMessages = [];
  if (updateResponse?.responseErrorMsg) {
    errorMessages.push(updateResponse.responseErrorMsg);
  }

  // Pick out "already exists" errors and treat them separately
  const ALREADY_EXISTS_SUBSTR_IN_ERROR_MESSAGE = '"reason":"DUPLICATE_VALUE"';

  updateResponse?.payload?.forEach((payload) => {
    let alreadyExistsCount = 0;
    totalErrorCount += payload.error_count;
    if (payload.error_details) {
      payload.error_details.forEach((errorDetail) => {
        if (errorDetail.error.includes(ALREADY_EXISTS_SUBSTR_IN_ERROR_MESSAGE)) {
          alreadyExistsCount += errorDetail.count;
          totalErrorCount -= errorDetail.count;
        } else {
          errorMessages.push(`${errorDetail.error}, count: ${errorDetail.count}`);
        }
      });

      if (alreadyExistsCount > 0) {
        payload.error_count -= alreadyExistsCount;
        payload.already_exists_count = alreadyExistsCount;
      }
    }
  });

  if (errorMessages.length > 0) {
    errorMessages.push(`Team: ${activeTeam?.id}`);
    errorMessages.push(`Profile: ${activeProfile?.id}`);
  }

  const errorMessagesJoined = errorMessages.join('\n');
  const groupedUpsertResponsePayload = useMemo(() => {
    const grouped = new Map<string, UpsertResponsePayloadDTO[]>();

    updateResponse?.payload?.forEach((payload) => {
      let key = ''; // for entities that do not have bidding_entity

      if ('bidding_entity' in payload) {
        const biddingEntity = payload.bidding_entity;

        switch (biddingEntity) {
          case BiddingEntity.PLACEMENT_TOP:
          case BiddingEntity.PLACEMENT_PRODUCT_PAGE:
          case BiddingEntity.PLACEMENT_REST_OF_SEARCH:
            key = 'Placement';
            break;
          case BiddingEntity.KEYWORD:
          case BiddingEntity.PRODUCT_TARGET:
            key = 'Target';
            break;
          default:
            key = 'Other';
        }
      }

      grouped.set(key, [...(grouped.get(key) ?? []), payload]);
    });

    return grouped;
  }, [updateResponse]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <>
        {totalErrorCount > 0 || errorMessages.length > 0 ? (
          <UpsertResponseAlert
            title={'Not all entities were processed'}
            severity="warning"
            errorMessage={errorMessagesJoined}
            isOpen={isOpen}
            onClose={onClose}
            groupedUpsertResponsePayload={groupedUpsertResponsePayload}
          />
        ) : (
          <UpsertResponseAlert
            title={'Success!'}
            severity="success"
            isOpen={isOpen}
            onClose={onClose}
            groupedUpsertResponsePayload={groupedUpsertResponsePayload}
          />
        )}
      </>
    </Dialog>
  );
};
