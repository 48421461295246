import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import FilterListEditor from '@/modules/campaigns/components/FilterListEditor';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import useMultiProfileCampaignFilters from '../hooks/useMultiProfileCampaignFilters';

interface CampaignFilterListEditorProps {
  teamProfiles: TeamProfile[];
  filters: AlFilterModel[];
  onFiltersChange: (filters: AlFilterModel[]) => void;
}

const CampaignFilterListEditor: FunctionComponent<CampaignFilterListEditorProps> = ({ filters, onFiltersChange, teamProfiles }) => {
  const { availableCampaignFilterBuilderFilters, isLoading, error } = useMultiProfileCampaignFilters(teamProfiles);

  if (isLoading) {
    return <Skeleton variant="rounded" height={100} />;
  }

  if (error) {
    return <div>Error loading filters: {error.message}</div>;
  }

  return <FilterListEditor availableFilters={availableCampaignFilterBuilderFilters} filters={filters} onFiltersChange={onFiltersChange} />;
};

export default CampaignFilterListEditor;
