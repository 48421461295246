import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { ProfileWithMetricsModel } from '../../profiles-stats/models/ProfileWithMetricsModel';
import { StartEndDate } from '../types/StartEndDate';
import {
  AllTeamReportStatusesDTO,
  ProfileReportsDTO,
  ProfileReportsStatusDTO,
  ProfilesMetricsWithTeamDTO,
  ProfilesWithMetricsRequestDTO,
  UpdateProfilePerformanceTargetsDTO,
} from './profile.contracts';

export const QUERY_STATUS_REPORT_KEY = 'query_status_report';
export const QUERY_SELLER_CENTRAL_STATUS_REPORT_KEY = 'query_seller_central_status_report';
export const QUERY_VENDOR_CENTRAL_STATUS_REPORT_KEY = 'query_vendor_central_status_report';

class ProfileService {
  public basePath = 'profiles';

  async getReports(activeTeamId: number, profileId: string): Promise<ApiResponse<ProfileReportsDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<ProfileReportsDTO>(`teams/${activeTeamId}/profiles/${profileId}/reports`, {
      limit: 15,
    });
  }

  async getReportsStatus(activeTeamId: number, profileId: string): Promise<ApiResponse<ProfileReportsStatusDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<ProfileReportsStatusDTO>(`teams/${activeTeamId}/profiles/${profileId}/reports/status`);
  }

  async getAllTeamReportsStatus(): Promise<ApiResponse<AllTeamReportStatusesDTO>> {
    return await apiTeamClient.get<AllTeamReportStatusesDTO>(`reports/status/all`);
  }

  async createAllReports(profileId: string): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`${this.basePath}/${profileId}/reports/create`, {});
  }

  async restartInitialReports(profileId: string): Promise<ApiResponse<null>> {
    return await apiClient.post(`admin/reports/profiles/${profileId}/restart-initial-reports`, {});
  }

  async downloadAndProcessReport(profileId: string, reportId: string): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`${this.basePath}/${profileId}/reports/download?reportid=` + reportId, {});
  }

  async getUserProfilesWithMetrics({
    userId,
    dates,
    compareDates,
  }: {
    userId: number;
    dates: StartEndDate;
    compareDates: StartEndDate;
  }): Promise<ApiResponse<ProfileWithMetricsModel[]>> {
    const requestData: ProfilesWithMetricsRequestDTO = {
      dates: {
        start_date: dates.startDate,
        end_date: dates.endDate,
      },
      compare_dates: {
        start_date: compareDates.startDate,
        end_date: compareDates.endDate,
      },
    };

    const applicationResponse = await apiClient.post<ProfilesMetricsWithTeamDTO[]>(`/users/${userId}/metrics-by-profile`, requestData);
    return applicationResponse.processPayload(ProfileWithMetricsModel.fromUserProfilesWithMetricsResponse);
  }

  // Performance target update
  async updateProfilePerformanceTargets(teamId: number, profileId: string, payload: UpdateProfilePerformanceTargetsDTO) {
    return await apiClient.post(`/teams/${teamId}/profiles/${profileId}/update-profile-settings`, payload);
  }
}

export const profileService = new ProfileService();
