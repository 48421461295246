import { GridApi } from 'ag-grid-enterprise';
import { useCallback, useEffect, useState } from 'react';
import { KeywordHarvestingPreviewDataRow } from '../models/KeywordHarvestingPreviewDataRow';
import { ErrorDetails } from '../types';

const useKeywordHarvestingErrors = (gridApi: GridApi<KeywordHarvestingPreviewDataRow> | null) => {
  // Error count
  const [errorDetails, setErrorDetails] = useState<ErrorDetails>({
    errorCount: 0,
    firstErrorRowId: null,
  });

  const updateErrorDetails = useCallback(() => {
    const errorDetails: ErrorDetails = {
      errorCount: 0,
      firstErrorRowId: null,
    };

    if (!gridApi) return;

    gridApi.forEachNode((node) => {
      if (node.data?.rowSelectWarning) {
        errorDetails.errorCount++;

        if (errorDetails.firstErrorRowId == null) {
          errorDetails.firstErrorRowId = node.id ?? null;
        }
      }
    });

    setErrorDetails(errorDetails);
  }, [gridApi]);

  // Attach grid update listener
  useEffect(() => {
    if (!gridApi) return;
    const onRowDataUpdated = () => {
      updateErrorDetails();
    };

    const onCellValueChanged = (event: any) => {
      updateErrorDetails();
    };

    gridApi.addEventListener('rowDataUpdated', onRowDataUpdated);
    gridApi.addEventListener('cellValueChanged', onCellValueChanged);

    // Cleanup listeners when the component unmounts
    return () => {
      if (!gridApi || gridApi.isDestroyed()) return;
      gridApi.removeEventListener('rowDataUpdated', onRowDataUpdated);
      gridApi.removeEventListener('cellValueChanged', onCellValueChanged);
    };
  }, [gridApi]);

  return { errorDetails };
};

export default useKeywordHarvestingErrors;
