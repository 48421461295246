import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FunctionComponent, JSX } from 'react';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  questionText: string | JSX.Element;
  questionTitle: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  isLoading?: boolean;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  questionText,
  questionTitle,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  isLoading,
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{questionTitle}</DialogTitle>
      <DialogContent className="max-w-lg">
        <Typography>{questionText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="text" disabled={isLoading}>
          {cancelButtonText}
        </Button>
        <LoadingButton loading={isLoading} onClick={onConfirm} color="primary" variant="contained">
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
