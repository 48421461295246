import AlSelect from '@/components/form/AlSelect';
import BidEditRow from '@/components/form/BidEditRow';
import useBidLimits from '@/modules/bids/hooks/useBidLimits';
import { BidUpdateData, BidUpdateType, PlacementUpdateData, PlacementUpdateType, TotalUpdateData } from '@/modules/bids/types/bulk-edit';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { Button, DialogActions, DialogContent, DialogTitle, Popover, SelectChangeEvent, TextField } from '@mui/material';
import { ChangeEvent, FunctionComponent, RefObject, useState } from 'react';
import { BiddingEntity } from './models/OptimizationModel';
import { OptimizationApplyData } from './OptimizerConfig';

interface OptimizationEditSelectionPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedItems: OptimizationApplyData[];
  isOpen: boolean;
  onClose: () => void;
  onApply: (updateData: TotalUpdateData) => void;
}

const OptimizationEditSelectionPopover: FunctionComponent<OptimizationEditSelectionPopoverProps> = ({
  selectedItems,
  isOpen,
  onClose,
  onApply,
  buttonRef,
}) => {
  const { activeProfile } = useActiveTeamContext();
  const { getBidUpdateOptions } = useBidLimits();

  const [placementUpdateData, setPlacementUpdateData] = useState<PlacementUpdateData>({
    placementUpdateType: PlacementUpdateType.NO_CHANGE,
    newPlacementValue: 0,
  });

  const [bidUpdateData, setBidUpdateData] = useState<BidUpdateData>({ bidUpdateType: BidUpdateType.NO_CHANGE, newBidValue: 0 });

  const handlePlacementUpdateTypeChange = (event: SelectChangeEvent<PlacementUpdateType>) => {
    setPlacementUpdateData({ ...placementUpdateData, placementUpdateType: event.target.value as PlacementUpdateType });
  };

  const handleBidUpdateTypeChange = (event: SelectChangeEvent<BidUpdateType>) => {
    setBidUpdateData({ ...bidUpdateData, bidUpdateType: event.target.value as BidUpdateType });
  };

  const PLACEMENT_UPDATE_OPTIONS = [
    { value: PlacementUpdateType.NO_CHANGE, label: 'No change' },
    { value: PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE, label: 'Set placement to (%)' },
    { value: PlacementUpdateType.INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS, label: 'Increase by (%) points' },
    { value: PlacementUpdateType.DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS, label: 'Decrease by (%) points' },
  ];

  const [newPlacementValue, setNewPlacementValue] = useState<string>('');
  const onNewPlacementValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = String(event.target.value).replace(/%/g, '');
    // Allow only numeric input
    if (!value || /^[0-9\b]+$/.test(value)) {
      // Update your state or handle the change here
      setNewPlacementValue(value);
      setPlacementUpdateData({ ...placementUpdateData, newPlacementValue: parseFloat(value) });
    }
  };

  const [newBidValue, setNewBidValue] = useState<string>('');
  const onNewBidValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input with decimals
    if (!value || /^\d*\.?\d*$/.test(value)) {
      // Update your state or handle the change here
      setNewBidValue(value);
      setBidUpdateData({ ...bidUpdateData, newBidValue: parseFloat(value) });
    }
  };

  const handleApplyChanges = () => {
    onApply({
      placementUpdateData,
      bidUpdateData,
    });

    // Close popover
    onClose();
  };

  const numKeywords = selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.KEYWORD).length;
  const numProductTargets = selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.PRODUCT_TARGET).length;
  const numPlacements = selectedItems.filter(
    (selectedItem) => selectedItem.bidding_entity !== BiddingEntity.KEYWORD && selectedItem.bidding_entity !== BiddingEntity.PRODUCT_TARGET,
  ).length;

  // TODO: extract placements row like bids row
  return (
    <Popover
      id={'optimization-preview-bulk-edit-popover'}
      open={isOpen}
      anchorEl={buttonRef.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{ paper: { style: { width: 500 } } }}
      aria-labelledby="edit-selection-popover-title"
    >
      <DialogTitle id="edit-selection-popover-title">Edit selected items</DialogTitle>

      <DialogContent className="min-w-[500px]">
        <div className="flex max-w-96 ">
          You have selected {selectedItems.length} items to edit. Please select the type of edits you would like to apply to these items.
        </div>
        <div className="flex flex-col gap-y-6 mt-8 mb-4 min-w-100 ">
          {numPlacements > 0 && (
            <div>
              <div className="mb-2 flex items-center font-medium text-sm leading-none pb-0 ">Edit {numPlacements} Placements</div>

              <div className="flex gap-x-4">
                <AlSelect
                  label={'Update action'}
                  value={placementUpdateData.placementUpdateType}
                  options={PLACEMENT_UPDATE_OPTIONS}
                  onChange={handlePlacementUpdateTypeChange}
                  renderOption={(item) => item.label}
                  valueExtractor={(item) => item.value}
                />
                {placementUpdateData.placementUpdateType !== PlacementUpdateType.NO_CHANGE && (
                  <TextField
                    fullWidth
                    label={
                      placementUpdateData.placementUpdateType === PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE
                        ? 'New Placement (%)'
                        : 'Placement (%) points'
                    }
                    value={newPlacementValue}
                    onChange={onNewPlacementValueChange}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <div className="text-sm flex flex-nowrap whitespace-nowrap">
                            % {placementUpdateData.placementUpdateType !== PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE && 'pts'}
                          </div>
                        ),
                      },

                      htmlInput: { pattern: '[0-9]*' },
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {(numKeywords > 0 || numProductTargets > 0) && (
            <BidEditRow
              title={`Edit Bids (${selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.KEYWORD).length} Keyword Bids
                & ${selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.PRODUCT_TARGET).length} Product Target
                Bids)`}
              options={getBidUpdateOptions(activeProfile?.currencyCode ?? CurrencyCode.USD)}
              bidUpdateData={bidUpdateData}
              handleBidUpdateTypeChange={handleBidUpdateTypeChange}
              isBidEditAllowed={true}
              newBidValue={newBidValue}
              onNewBidValueChange={onNewBidValueChange}
              currency={activeProfile?.currencyCode ?? CurrencyCode.USD}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={handleApplyChanges}>Apply Changes</Button>
      </DialogActions>
    </Popover>
  );
};

export default OptimizationEditSelectionPopover;
