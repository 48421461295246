import { useEffect } from 'react';
import { toastService } from '@/services/toast.service';
import { isNil } from 'lodash-es';
import { ShowPaywallParams, usePaywall } from '@/modules/plans/hooks/usePaywall';
import { Routes } from '@/router/router-paths';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { VideoUrl } from '@/config/urls';
import { useUserContext } from '@/modules/users';
import { TeamModel } from '@/modules/teams/types/TeamModel';

const DEFAULT_PAYWALL_DELAY_IN_SECONDS = 10;

interface UsePaywallManagerParams {
  route: Routes; // The route where this paywall is displayed
  message: string; // Custom message for the paywall
  delayInSeconds?: number; // Delay in seconds before displaying the paywall (default: 10)
  videoUrl?: VideoUrl; // Optional tutorial video URL
  canUsePage?: (team: TeamModel) => boolean; // Callback to evaluate if the user can access the page
}

const displayedPaywalls: Set<Routes> = new Set();

export const usePaywallManager = ({
  route,
  message,
  delayInSeconds = DEFAULT_PAYWALL_DELAY_IN_SECONDS,
  videoUrl,
  canUsePage,
}: UsePaywallManagerParams) => {
  const { showPaywallWithDelay } = usePaywall();
  const { activeTeam, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { userHasAtLeastOneProLicense } = useUserContext();

  useEffect(() => {
    const paywallDelay = displayedPaywalls.has(route) || userHasAtLeastOneProLicense ? 0 : delayInSeconds;

    const paywallParams: ShowPaywallParams = {
      message,
      videoUrl,
      delayInSeconds: paywallDelay,
    };

    const showPaywall = () => {
      showPaywallWithDelay(paywallParams);
      displayedPaywalls.add(route); // Mark the route as displayed
    };

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywall();
      return;
    }

    if (isNil(activeTeam)) {
      toastService.error('Active team is not set');
      return;
    }

    // Evaluate access using the provided callback
    if (canUsePage && !canUsePage(activeTeam)) {
      showPaywall();
      return;
    }
  }, [route, message, delayInSeconds, videoUrl, activeTeam, hasUserOverMaxAllowedFreeProfiles, userHasAtLeastOneProLicense, canUsePage]);
};
