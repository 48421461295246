import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { ComponentType, FunctionComponent } from 'react';

export interface IErrorWarningCellRendererParams<T = any> extends ICellRendererParams<T> {
  CellRenderer: ComponentType<ICellRendererParams<T>>;
  cellRendererParams?: T;
  errorMessage?: string;
  warningMessage?: string;
}

export const ErrorWarningWrapperCellRenderer: FunctionComponent<IErrorWarningCellRendererParams> = (props) => {
  const { CellRenderer, errorMessage, warningMessage, cellRendererParams } = props;
  let iconElement = null;
  if (errorMessage) {
    // Show error icon with error message if there's an error
    iconElement = (
      <Tooltip title={errorMessage}>
        <ErrorOutlineIcon className="mr-1" />
      </Tooltip>
    );
  } else if (warningMessage) {
    // Show warning icon with warning message if there's no error but a warning
    iconElement = (
      <Tooltip title={warningMessage}>
        <WarningIcon className="mr-1" />
      </Tooltip>
    );
  }

  const params = { ...cellRendererParams, ...props };
  return (
    <>
      {iconElement}
      <CellRenderer {...params} />
    </>
  );
};
