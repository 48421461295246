import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import BigTooltip from './BigTooltip';
import { isEmpty } from 'lodash-es';

interface InfoIconWithBigTooltipProps {
  text: ReactNode;
}

const InfoIconWithBigTooltip: FunctionComponent<InfoIconWithBigTooltipProps> = ({ text }) => {
  const theme = useTheme();

  if (isEmpty(text)) {
    return null;
  }

  return (
    <BigTooltip title={text} placement="right">
      <InfoOutlinedIcon className="flex" fontSize="inherit" style={{ color: theme.palette.info.main, cursor: 'pointer' }} />
    </BigTooltip>
  );
};

export default InfoIconWithBigTooltip;
