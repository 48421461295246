import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortablePlaceholderProps {
  id: string;
  title: string;
  textClassName?: string;
  bgClassName?: string;
  borderClassName?: string;
}

export const SortablePlaceholder: React.FC<SortablePlaceholderProps> = ({
  id,
  title,
  textClassName = 'text-fuchsia-800',
  bgClassName = 'bg-fuchsia-100',
  borderClassName = 'border-fuchsia-200',
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`flex justify-center items-center p-2 h-8 rounded border border-dashed bg-opacity-40 ${bgClassName} ${borderClassName}`}
      {...attributes}
      {...listeners}
    >
      <span className={`text-sm font-medium ${textClassName}`}>{title}</span>
    </div>
  );
};
