import { createTargetingFiltersForMultipleProfiles } from '@/components/filter-builder/models/AlFilterModel';
import { ApiResponse } from '@/lib/api/api-response';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { createGetAllGroupsForMultipleTeamsQueryKey, dataGroupsService } from '@/modules/data-groups/api/data-groups-service';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { campaignService, createAdGroupsQueryKey, createCampaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { emptyUnAssignedCampaignGroup } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { toastService } from '@/services/toast.service';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

const useMultiProfileTargetingFilters = (teamProfiles: TeamProfile[]) => {
  const uniqueTeamIds = Array.from(new Set(teamProfiles.map((profile) => profile.teamId)));
  const { fetchCache } = useAlFetchCache();

  const {
    data: adGroups,
    isLoading: isLoadingAdGroups,
    error: errorAdGroups,
  } = useQuery({
    queryKey: createAdGroupsQueryKey(uniqueTeamIds.join('_')),
    queryFn: async () => {
      const results = await Promise.all(teamProfiles.map((tp) => campaignService.getAdGroupsByTeamAndProfile(tp.teamId, tp.profileId)));

      return results.flatMap((result) => {
        if (result.isSuccess) {
          return result.payload;
        } else {
          toastService.error('Error loading ad groups');
          return []; // Return empty array to prevent crashes
        }
      });
    },
    enabled: !isEmpty(teamProfiles),
  });

  const {
    data: campaignGroups,
    isLoading: isLoadingCampaignGroups,
    error: errorCampaignGroups,
  } = useQuery({
    queryKey: createCampaignGroupsQueryKey(uniqueTeamIds.join('_')),
    queryFn: async () => {
      const results = await Promise.all(teamProfiles.map((tp) => campaignService.getGroupsByTeamAndProfile(tp.teamId, tp.profileId)));

      const campaignGroups = results.flatMap((result) => {
        if (result.isSuccess) {
          return result.payload;
        } else {
          toastService.error('Error loading Opt Groups');
          return []; // Return empty array to prevent crashes
        }
      });

      if (!campaignGroups.find((group) => group.id === 0)) {
        campaignGroups.unshift(emptyUnAssignedCampaignGroup);
      }

      return campaignGroups;
    },
    enabled: !isEmpty(teamProfiles),
  });

  const { data: dataGroups, isLoading: isLoadingDataGroups } = useQuery({
    queryKey: createGetAllGroupsForMultipleTeamsQueryKey(uniqueTeamIds),
    queryFn: async () => {
      const results = await Promise.all(
        uniqueTeamIds.map((uniqueTeamId) => {
          const teamProfile = teamProfiles.find((profile) => profile.teamId === uniqueTeamId);
          if (!teamProfile) {
            return Promise.resolve(ApiResponse.responseWithPayload([], 200));
          }

          return dataGroupsService.getAllGroupsForProfile(teamProfile.teamId, teamProfile.profileId);
        }),
      );
      return results.flatMap((result) => result.payload);
    },
    enabled: !isEmpty(teamProfiles),
  });

  const isLoading = isLoadingAdGroups || isLoadingCampaignGroups || isLoadingDataGroups;
  const error = errorAdGroups || errorCampaignGroups; // You might want to handle errors more precisely in a real application.

  const campaignDataGroups = useMemo(
    () => (dataGroups ? dataGroups.filter((group) => group.type === DataGroupType.CAMPAIGN) : []),
    [dataGroups],
  );

  const targetDataGroups = useMemo(() => (dataGroups ? dataGroups.filter((group) => group.type === DataGroupType.TARGET) : []), [dataGroups]);

  const availableTargetingFilters = createTargetingFiltersForMultipleProfiles(
    fetchCache,
    campaignGroups || [],
    campaignDataGroups,
    targetDataGroups,
    teamProfiles,
  );

  const availableTargetingFilterBuilderFilters = availableTargetingFilters.filter((filter) => filter.isFilterBuilderFilter);

  return {
    availableTargetingFilters,
    availableTargetingFilterBuilderFilters,
    isLoading,
    error,
  };
};

export default useMultiProfileTargetingFilters;
