import { CommonMetricsDTO, MetricsDTO } from '@/components/metrics/api/metrics-contracts';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { UpdatedEntityDTO } from '@/modules/application/types/UpdatedEntity';
import { CampaignAdType, CostType, EnabledPausedArchivedState, TimelineDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { TargetModel } from '../models/TargetsModel';

export enum TargetEntityType {
  KEYWORD = 'KEYWORD',
  PRODUCT_TARGET = 'PRODUCT_TARGET',
  // AUDIENCE = 'AUDIENCE', Do not want to add AUDIENCE to this enum as it is pseudo-type. It does not exist
}

// Front only type
export type TargetEntityExtendedType = TargetEntityType | 'AUDIENCE';

export interface TargetsWithTimelineDTO {
  targets: TargetDTO[] | null;
  timeline: TimelineDTO;
  comparison_missing: boolean;
  metrics: MetricsDTO;
  limit_reached: boolean;
  targets_no_stats: number;
}

export interface TargetsWithTimeline {
  targets: TargetModel[];
  timeline: TimelineModel;
  isComparisonDataMissing?: boolean;
  metrics: MetricModel[];
  limitReached?: boolean;
  targetsNoStats?: number;
}

export interface TargetsPerProfileWithTimelineResponse extends TargetsWithTimeline {
  profileId: string;
}

/**
 * Represents a target DTO with common metrics.
 */
export interface TargetDTO extends CommonMetricsDTO {
  /**
   * The ID of the target.
   */
  i: string;
  /**
   * The bid of the target.
   */
  b: number;
  /**
   * The state of the target.
   */
  s: EnabledPausedArchivedState;
  /**
   * The entity type of the target.
   */
  et: TargetEntityType;
  /**
   * The match type of the target.
   */
  mt: MatchType;
  /**
   * The targeting of the target.
   */
  t: string;
  /**
   * The cost type
   */
  co: CostType;
  /**
   * The last optimized timestamp of the target.
   */
  lo: string | null;
  /**
   * The ad group name of the target.
   */
  agn: string;
  /**
   * The ad group state of the search term.
   */
  ags: string;
  /**
   * The campaign id
   */
  ci: string;
  /**
   * The campaign name of the target.
   */
  cn: string;
  /**
   * The campaign ad type of the target.
   */
  ct: CampaignAdType;
  /**
   * portfolio name
   */
  p: string | null;
  /**
   * The campaign state
   */
  cs: EnabledPausedArchivedState;
  /**
   * The group name of the target.
   */
  gn: string;
  /**
   * Indicates if multi ad groups are enabled for the target.
   */
  mag: boolean | null;
  v: boolean | null; // is video or brands video
  /**
   * Data Group item IDs
   */
  td: number[] | null;
  /**
   * Campaign Data Group item IDs
   */
  cd: number[] | null;
  o: boolean | null; // has opt rule
  /**
   * The last optimized timestamp of the campaign.
   */
  clo: string | null;
}

export interface UpdateTargetsDTO {
  updates: TargetUpdateDTO[];
}

export interface TargetUpdateDTO {
  id: string;
  ad_type: CampaignAdType;
  entity_type: TargetEntityType;
  match_type: MatchType;

  state?: EnabledPausedArchivedState;
  bid?: number;
}

export interface UpdatedTargetsDTO extends UpdatedEntityDTO {
  entity_type: TargetEntityType;
}

export type TargetCreatePayloadType = {
  targets: TargetCreateDTO[];
};

export interface TargetCreateDTO {
  campaign_id: string;
  ad_group_id: string;
  ad_type: CampaignAdType;
  bid: number;
  keyword?: string;
  expression?: string;
  match_type: MatchType;
}
