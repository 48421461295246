import { AlDate } from '@/lib/date/AlDate';
import { toastService } from '@/services/toast.service';
import { CountryCode } from '../users/types/CountryCode';

export function getErrorMessage(e: unknown): string {
  if (e instanceof Error) {
    return e.message;
  } else if (typeof e === 'string') {
    return e;
  }
  return 'An unexpected error occurred';
}

export const truncateName = (name: string): string => {
  return name.length > 35 ? name.substring(0, 15) + ' ... ' + name.substring(name.length - 15, name.length) : name;
};

const DEFAULT_MAX_STRING_LENGTH = 30;
export const truncateString = (s: string, tagLengthLimit = DEFAULT_MAX_STRING_LENGTH): string => {
  return s.length > tagLengthLimit ? s.substring(0, 4) + '...' + s.substring(s.length - 4, s.length) : s;
};

export function getSeparatedValuesFromText(text: string, currentTags: string[]): string[] {
  // Split the pasted text based on newline or tab character
  // \t represents a tab character.
  // \n represents a newline character in Unix/Linux systems.
  // \r represents a carriage return character, used in old Mac systems and part of the Windows newline sequence (\r\n).
  // + quantifier, if the pasted text contains consecutive newline or tab characters, they will be treated as a single delimiter
  // If we want to split on each occurrence of a newline or tab character (even if they are consecutive), we should split(/[\t\n\r]/)
  const pastedTags = text.split(/[\t\n\r]+/).filter((tag) => {
    const trimmedTag = tag.trim();
    return trimmedTag !== '';
  });

  const allTags = currentTags.map((tag) => tag.toLowerCase());
  const newTags = pastedTags.filter((tag) => !allTags.includes(tag.toLowerCase()));
  const duplicatedTags = pastedTags.filter((tag) => allTags.includes(tag.toLowerCase()));

  if (duplicatedTags?.length > 0) {
    if (duplicatedTags.length == 1) {
      toastService.error(duplicatedTags[0] + ' is already in the list');
    } else {
      toastService.error('These existing values not added: ' + duplicatedTags.join(', '));
    }
  }
  return newTags;
}

export function isNonNegativeNumber(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value) && value >= 0;
}

export function isPositiveNumber(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value) && value > 0;
}

export const downloadObjectArrayAsCsv = (rowData: object[], fileName: string, headers?: string[]) => {
  // Create CSV header from keys of the first object, assuming all objects have the same structure
  let csvHeader = Object.keys(rowData[0]).join(',') + '\n';
  if (headers && headers.length > 0) {
    csvHeader = headers.join(',') + '\n';
  }

  // Create CSV rows from rowData
  const csvRows = rowData
    .map((row) =>
      Object.values(row)
        .map(
          (field) => `"${field.toString().replace(/"/g, '""')}"`, // Handle fields that might contain quotes
        )
        .join(','),
    )
    .join('\n');

  const csvString = csvHeader + csvRows;

  // Create a Blob from the CSV String
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a link and trigger the download
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = `${fileName}.csv`;
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function sanitizeFilename(input: string): string {
  const illegalRe = /[/?<>\\:*|"]/g; // Regular expression to match illegal characters in Windows and Unix/Linux
  // eslint-disable-next-line no-control-regex
  const controlRe = /[\x00-\x1f\x80-\x9f]/g; // Control characters
  // eslint-disable-next-line no-control-regex
  const reservedRe = /^\.+$|[\x00-\x1f\x80-\x9f]|^\.+|\.+$|[*?<>|]/g; // Additional problematic characters
  // eslint-disable-next-line no-control-regex
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i; // Windows reserved names

  const sanitized = input
    .replace(illegalRe, '') // Remove illegal characters
    .replace(controlRe, '') // Remove control characters
    .replace(reservedRe, '') // Remove reserved characters problematic for Windows
    .replace(/\s/g, '_') // Replace spaces with underscores, or you can use .replace(/\s+/g, '') to remove spaces
    .replace(windowsReservedRe, ''); // Remove Windows reserved names

  return sanitized;
}

export function roundToPrecision(precisionNumber: number, numberToRound: number) {
  // Determine the number of decimal places in precisionNumber
  const decimalPlaces = (precisionNumber.toString().split('.')[1] || '').length;

  // Round the numberToRound to the specified number of decimal places
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(numberToRound * factor) / factor;
}

export function isValidPositiveNumberOrEmptyString(value: string): boolean {
  return /^(\d+(\.\d*)?|\.\d*|)?$/.test(value);
}

export function countDaysInclusive(startDateString: string, endDateString: string): number {
  const startDate = AlDate.parse(startDateString);
  const endDate = AlDate.parse(endDateString);

  if (!startDate.isValid() || !endDate.isValid()) {
    throw new Error('Invalid date format');
  }

  const diffInDays = startDate.diff(endDate, 'days');
  if (diffInDays < 0) {
    throw new Error('End date must be on or after start date');
  }

  return diffInDays + 1;
}

export const countryDomainMap: Record<CountryCode, string> = {
  [CountryCode.US]: 'amazon.com',
  [CountryCode.CA]: 'amazon.ca',
  [CountryCode.MX]: 'amazon.com.mx',
  [CountryCode.UK]: 'amazon.co.uk',
  [CountryCode.DE]: 'amazon.de',
  [CountryCode.FR]: 'amazon.fr',
  [CountryCode.IT]: 'amazon.it',
  [CountryCode.ES]: 'amazon.es',
  [CountryCode.NL]: 'amazon.nl',
  [CountryCode.SE]: 'amazon.se',
  [CountryCode.PL]: 'amazon.pl',
  [CountryCode.BE]: 'amazon.com.be',
  [CountryCode.JP]: 'amazon.co.jp',
  [CountryCode.IN]: 'amazon.in',
  [CountryCode.AU]: 'amazon.com.au',
  [CountryCode.SG]: 'amazon.sg',
  [CountryCode.AE]: 'amazon.ae',
  [CountryCode.SA]: 'amazon.sa',
  [CountryCode.EG]: 'amazon.eg',
  [CountryCode.TR]: 'amazon.com.tr',
  [CountryCode.BR]: 'amazon.com.br',
};

export const assertUnhandledCase = (x: never): never => {
  throw new Error(`Unhandled case: ${x}`);
};

export function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function sortRecordByKeyAndGetColonDelimitedString(record: Record<string, unknown>): string[] {
  // endValues are optional that should be always at the end of the array
  return Object.keys(record)
    .sort() // Sort the keys alphabetically
    .map((key) => `${key}:${record[key]}`);
}

export function safeJSONStringify(value: any, space: number | string = 2): string {
  const seen = new WeakSet();

  return JSON.stringify(
    value,
    (key, val) => {
      if (typeof val === 'object' && val !== null) {
        if (seen.has(val)) {
          return '[Circular]'; // Handle circular references
        }
        seen.add(val);
      }

      if (typeof val === 'function' || typeof val === 'symbol') {
        return val.toString(); // Serialize functions and symbols as strings
      }

      return val;
    },
    space,
  );
}
