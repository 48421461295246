import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import FilterListEditor from '@/modules/campaigns/components/FilterListEditor';
import { Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import useMultiProfileTargetingFilters from '../hooks/useMultiProfileTargetingFilters';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';

interface TargetingFilterListEditorProps {
  filters: AlFilterModel[];
  onFiltersChange: (filters: AlFilterModel[]) => void;
  teamProfiles: TeamProfile[];
}

const TargetingFilterListEditor: FunctionComponent<TargetingFilterListEditorProps> = ({ filters, onFiltersChange, teamProfiles }) => {
  const { availableTargetingFilterBuilderFilters, isLoading, error } = useMultiProfileTargetingFilters(teamProfiles);

  if (isLoading) {
    return <Skeleton variant="rounded" height={100} />;
  }

  if (error) {
    return <div>Error loading filters: {error.message}</div>;
  }

  return <FilterListEditor availableFilters={availableTargetingFilterBuilderFilters} filters={filters} onFiltersChange={onFiltersChange} />;
};

export default TargetingFilterListEditor;
