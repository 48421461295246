import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Close, Lock } from '@mui/icons-material';

interface SortableItemProps {
  id: string;
  title: string;
  className?: string;
  lockPosition?: boolean | 'left' | 'right';
  lockvisible?: boolean;
  onRemove: () => void;
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, title, lockPosition, onRemove, className, lockvisible }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    disabled: Boolean(lockPosition), // Disable dragging for locked position
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`flex justify-between items-center p-2 bg-gray-50 border border-gray-300 rounded h-8 group ${lockPosition ? 'cursor-not-allowed' : 'cursor-grab'}`}
      {...attributes}
      {...(lockPosition ? {} : listeners)} // Only attach listeners if not locked
    >
      <div className="flex">
        {lockPosition ? (
          <Lock fontSize="small" className="pr-1 text-gray-300" />
        ) : (
          <DragIndicatorIcon fontSize="small" className="pr-1 text-gray-400" />
        )}
        <div className={`flex items-center gap-x-2 text-sm font-medium ${lockPosition ? 'text-gray-400' : 'text-gray-800'}`}>
          <div className={`pl-2 text-sm`}>{title}</div>
          <div className={className + ' flex w-2 bg-transparent'}></div>
        </div>
      </div>
      {/* Only show remove button on hover */}
      {lockvisible ? null : (
        <IconButton onClick={onRemove} size="small" aria-label="remove" className="hidden group-hover:flex" style={{ padding: '4px' }}>
          <Close fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};
