import { GridApi } from 'ag-grid-enterprise';

export function scrollToRowWithId(rowId: string | null, api: GridApi<any> | null) {
  if (!api || !rowId) return;
  const rowNode = api.getRowNode(rowId); // Get the row node by ID
  if (rowNode) {
    api.ensureNodeVisible(rowNode, 'middle'); // Scroll to the row and center it
  } else {
    console.warn(`Row with ID ${rowId} not found`);
  }
}
