import { CommonMetricField, ProfileStatsSellerMetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { BiddingMethod } from '@/modules/campaign-mapping/api/campaign-mapping-contracts';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { ActionEntityType, ActionType, FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { CreateNegativesEntityType, NegativeMatchType } from '@/modules/negative-targets/api/negative-targets-contracts';
import {
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignAdType,
  CostType,
  CreativeType,
  MultiAdGroupsEnabledType,
  PlacementType,
  TargetingType,
} from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType, OptimizationReason } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { BiddingEntity } from '@/modules/optimizer/components/optimization/models/OptimizationModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { BidCeilingType } from '@/modules/optimizer/types/BidCeilingType';
import { BidFloorType } from '@/modules/optimizer/types/BidFloorType';
import { BidLimitType } from '@/modules/optimizer/types/BidLimitType';
import { SmartBidCeilingType } from '@/modules/optimizer/types/SmartBidCeilingType';
import { ProductAvailability } from '@/modules/products/api/products-contracts';
import { ReportingStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';
import { SyncStatusReason } from '@/modules/profiles/types/ProfileStatus';
import { NegativeTypeShort } from '@/modules/search-terms/api/search-terms-contracts';
import { ResourceLanguage } from 'i18next';

export const enTranslations: ResourceLanguage = {
  translation: {
    campaign_data_group: 'Campaign Tag',
    product_data_group: 'Product Tag',
    target_data_group: 'Target Tag',
    searchterm_data_group: 'Search Term Tag',
    dashboards: 'Dashboards',
    select_a_xlsx_file_to_be_imported: 'Select a XLSX file to be imported.',
    upload_a_file: 'Upload a File',
    campaign_mappings: 'Campaign Mappings',
    campaign_mappings_import_description:
      'Download the template, fill it in and upload campaign mappings in bulk. After uploading, you can review and edit the mappings before importing.',
    bid_optimizer: 'Bid Optimizer',
    totals_are_based_on_the_values_currently_shown_in_this_table: 'Table totals are calculated only on the values currently shown in this table.',
    something_went_wrong: 'Something went wrong on our end. If the problem persists, make sure to contact us!',
    advertised_products: 'Advertised Products',
    profiles: 'Profiles',
    time_machine: 'Time Machine',
    optimization_groups: 'Optimization Groups',
    profiles_overview: 'Profiles Overview',
    targeting: 'Targeting',
    data_groups: 'Tags',
    placements: 'Placements',
    negative_targeting: 'Negative Targeting',
    search_terms: 'Search Terms',
    campaign_mapping: 'Campaign Mapping',
    logs: 'Logs',
    products: 'Products',
    ad_metrics: 'Ad Metrics',
    total_metrics: 'Total Metrics',
    change_logs: 'Change Logs',
    enums: {
      placement_type: {
        [PlacementType.PLACEMENT_TOP]: 'Placement Top',
        [PlacementType.PLACEMENT_PRODUCT_PAGE]: 'Placement Product Page',
        [PlacementType.PLACEMENT_REST_OF_SEARCH]: 'Placement Rest Of Search',
        [PlacementType.HOME]: 'Home',
        [PlacementType.DETAIL_PAGE]: 'Detail Page',
        [PlacementType.OTHER]: 'Other',
      } as Record<PlacementType, string>,
      campaign_ad_type: {
        [CampaignAdType.PRODUCTS]: 'SP',
        [CampaignAdType.BRANDS]: 'SB',
        [CampaignAdType.DISPLAY]: 'SD',
        [CampaignAdType.TV]: 'TV',
      } as Record<CampaignAdType, string>,
      optimization_presets: {
        [OptimizationPreset.NOT_SET]: 'Not Set',
        [OptimizationPreset.BALANCED]: 'Balanced',
        [OptimizationPreset.INCREASE_SALES]: 'Increase Sales',
        [OptimizationPreset.REDUCE_ACOS]: 'Reduce ACOS',
      } as Record<OptimizationPreset, string>,
      cost_type: {
        [CostType.CPC]: 'CPC',
        [CostType.VCPM]: 'VCPM',
        [CostType.CPM]: 'CPM',
        [CostType.NONE]: '',
      } as Record<CostType, string>,
      bidding_entity: {
        // HARDCODED CASES IN optimizationBiddingEntityAggFunc
        [BiddingEntity.KEYWORD]: 'Keyword',
        [BiddingEntity.PRODUCT_TARGET]: 'Product Target',
        ['AUDIENCE']: 'Audience', // Product targets where targeting begins with audience=, views=, purchases=
        [BiddingEntity.PLACEMENT_PRODUCT_PAGE]: 'Product Pages',
        [BiddingEntity.PLACEMENT_REST_OF_SEARCH]: 'Rest of Search',
        [BiddingEntity.PLACEMENT_TOP]: 'Top of Search',
        [BiddingEntity.HOME]: 'Home',
        [BiddingEntity.DETAIL_PAGE]: 'Product Pages',
        [BiddingEntity.OTHER]: 'Rest of Search',
        [BiddingEntity.BRANDS_PLACEMENT_TOP]: 'Top of Search',
      } as Record<BiddingEntity, string>,
      bidding_entity_short: {
        [BiddingEntity.KEYWORD]: 'KW',
        [BiddingEntity.PRODUCT_TARGET]: 'PT',
        ['AUDIENCE']: 'AD', // Product targets where targeting begins with audience=, views=, purchases=
        [BiddingEntity.PLACEMENT_PRODUCT_PAGE]: 'PP',
        [BiddingEntity.PLACEMENT_REST_OF_SEARCH]: 'RS',
        [BiddingEntity.PLACEMENT_TOP]: 'TS',
        [BiddingEntity.HOME]: 'HM',
        [BiddingEntity.DETAIL_PAGE]: 'DP',
        [BiddingEntity.OTHER]: 'RS',
        [BiddingEntity.BRANDS_PLACEMENT_TOP]: 'TS',
      } as Record<BiddingEntity, string>,
      ad_type: {
        [CampaignAdType.PRODUCTS]: 'SP',
        [CampaignAdType.BRANDS]: 'SB',
        [CampaignAdType.DISPLAY]: 'SD',
        [CampaignAdType.TV]: 'TV',
      } as Record<CampaignAdType, string>,
      ad_type_long: {
        [CampaignAdType.PRODUCTS]: 'Sponsored Products',
        [CampaignAdType.BRANDS]: 'Sponsored Brands',
        [CampaignAdType.DISPLAY]: 'Sponsored Display',
        [CampaignAdType.TV]: 'Sponsored TV',
      } as Record<CampaignAdType, string>,
      creative_type: {
        [CreativeType.PRODUCT_COLLECTION]: 'Product Collection',
        [CreativeType.STORE_SPOTLIGHT]: 'Store Spotlight',
        [CreativeType.VIDEO]: 'Video',
        [CreativeType.BRAND_VIDEO]: 'Brand Video',
        [CreativeType.NONE]: '',
      } as Record<CreativeType, string>,
      targeting_type: {
        [TargetingType.TARGETING]: 'Targeting',
        [TargetingType.AUDIENCES]: 'Audiences',
        [TargetingType.MANUAL]: 'Manual',
        [TargetingType.AUTO]: 'Auto',
        [TargetingType.CONTEXTUAL]: 'Contextual',
        [TargetingType.NONE]: '',
      } as Record<TargetingType, string>,
      bid_optimization: {
        [AmazonBrandsBidOptimization.AUTO]: 'Auto',
        [AmazonBrandsBidOptimization.MANUAL]: 'Manual',
        [AmazonBrandsBidOptimization.NONE]: '',
      } as Record<AmazonBrandsBidOptimization, string>,
      multi_ad_groups_enabled: {
        [MultiAdGroupsEnabledType.TRUE]: 'Multi Ad Group',
        [MultiAdGroupsEnabledType.FALSE]: 'Legacy',
        [MultiAdGroupsEnabledType.NONE]: '',
      } as Record<MultiAdGroupsEnabledType, string>,
      reason: {
        [OptimizationReason.LOW_VISIBILITY]: 'Low Visibility',
        [OptimizationReason.HIGH_ACOS]: 'High ACOS',
        [OptimizationReason.HIGH_SPEND]: 'High Spend',
        [OptimizationReason.LOW_ACOS]: 'Low ACOS',
        [OptimizationReason.USER_BID_CEILING]: 'Bid Ceiling',
        [OptimizationReason.KEYWORD_BID_CEILING]: 'Keyword Bid Ceiling',
        [OptimizationReason.AD_GROUP_BID_CEILING]: 'Ad Group Bid Ceiling',
        [OptimizationReason.CAMPAIGN_BID_CEILING]: 'Campaign Bid Ceiling',
        [OptimizationReason.CAMPAIGN_GROUP_BID_CEILING]: 'Opt Group Bid Ceiling',
        [OptimizationReason.PROFILE_BID_CEILING]: 'Profile Bid Ceiling',
        [OptimizationReason.LOWEST_POSSIBLE_BID]: 'Lowest Possible Bid',
        [OptimizationReason.HIGHEST_POSSIBLE_BID]: 'Highest Possible Bid',
        [OptimizationReason.SMALLEST_POSSIBLE_INCREASE]: 'Smallest Possible Increase',
        [OptimizationReason.CAMPAIGN_PERFORMANCE]: 'Campaign Performance',
        [OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE]: 'Optimization Group Performance',
        [OptimizationReason.PROFILE_PERFORMANCE]: 'Profile Performance',
        [OptimizationReason.MAX_ONE_TIME_CHANGE]: 'Max One Time Increase', // @Deprecated Keep for backwards compability
        [OptimizationReason.MAX_ONE_TIME_INCREASE]: 'Max One Time Increase',
        [OptimizationReason.MAX_ONE_TIME_DECREASE]: 'Max One Time Decrease',
        [OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT]: 'Lowest Possible Adjustment',
        [OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT]: 'Highest Possible Adjustment',
        [OptimizationReason.MAX_BID_DECREASE]: 'Max Bid Decrease',
        [OptimizationReason.MAX_BID_INCREASE]: 'Max Bid Increase',
        [OptimizationReason.USER_BID_FLOOR]: 'Bid Floor',
      } as Record<OptimizationReason, string>,
      bid_strategy: {
        [BidStrategyType.LEGACY_FOR_SALES]: 'Dynamic Bidding (Down Only)',
        [BidStrategyType.AUTO_FOR_SALES]: 'Dynamic Bidding (Up & Down)',
        [BidStrategyType.MANUAL]: 'Fixed Bids',
        [BidStrategyType.RULE_BASED]: 'Rule Based bidding',
        [BidStrategyType.NONE]: '',
      } as Record<BidStrategyType, string>,
      data_group_type: {
        [DataGroupType.CAMPAIGN]: 'Campaign',
        [DataGroupType.TARGET]: 'Targeting',
        [DataGroupType.SEARCHTERM]: 'Search Term',
        [DataGroupType.PRODUCT]: 'Product',
      } as Record<DataGroupType, string>,
      negative_match_type: {
        [NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT]: 'Campaign Negative Exact',
        [NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE]: 'Campaign Negative Phrase',
        [NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET]: 'Campaign Negative Product Target',
        [NegativeMatchType.AD_GROUP_NEGATIVE_EXACT]: 'Negative Exact',
        [NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE]: 'Negative Phrase',
        [NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET]: 'Negative Product Target',
      } as Record<NegativeMatchType, string>,
      match_type_long: {
        [MatchType.EXACT]: 'Exact',
        [MatchType.BROAD]: 'Broad',
        [MatchType.PHRASE]: 'Phrase',
        [MatchType.INDIVIDUAL]: 'Indiv. PT',
        [MatchType.EXPANDED]: 'Exp. PT',
        [MatchType.AUTO]: 'Auto',
        [MatchType.NONE]: 'None',
        [MatchType.THEME]: 'Theme',
        [MatchType.CATEGORY]: 'Category',
      } as Record<MatchType, string>,
      match_type_short: {
        [MatchType.EXACT]: 'E',
        [MatchType.BROAD]: 'B',
        [MatchType.PHRASE]: 'P',
        [MatchType.INDIVIDUAL]: 'PT',
        [MatchType.EXPANDED]: 'EXP',
        [MatchType.AUTO]: 'A',
        [MatchType.NONE]: '-',
        [MatchType.THEME]: 'T',
        [MatchType.CATEGORY]: 'C',
      } as Record<MatchType, string>,
      metric_description: {
        [SellerMetricField.SELLER_ACOS]: 'Ad Cost of Total Sales (Total Ad Spend dividided by Total Sales)',
        // [SellerMetricField.SELLER_ACTC]: 'Avg. Click-to-Conversion Rate',
        [SellerMetricField.SELLER_CVR]: 'Total Seller Purchases divided by Total Seller Sessions',
        [SellerMetricField.SELLER_ORG_TRAFFIC]: 'Total Seller Sessions minus Total Ad Clicks',
        [SellerMetricField.SELLER_AOV]: 'Total Sales divided Total Orders',
        [CommonMetricField.CPA]: 'Cost Per Acquisition: Ad Spend divided by Ad Orders',
        [SellerMetricField.SELLER_CPA]: 'Total Cost Per Acquisition: Ad Spend divided by Total Orders',
        [ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE]: 'Total Units Refunded divided Total Units',
        [SellerMetricField.SELLER_UNIT_SESS]: 'Total Units divided by Total Sessions',
        [CommonMetricField.RPC]: 'Revenue Per Click: Ad Sales divided by Ad Clicks',
        [SellerMetricField.SELLER_UNIT_VIEW]: 'Total Units divided by Total Page Views',
      } as Record<string, string>,
      labels: {
        [SyncStatusReason.LOADING]: 'Syncing is already in progress',
        [SyncStatusReason.PLAN_RESTRICTION]: 'Upgrade to sync more than once per 24h',
        [SyncStatusReason.TOO_EARLY]: 'You can update data once per hour',
        [SyncStatusReason.WAITING_RESPONSE]: 'Checking current status, please wait...',
      } as Record<SyncStatusReason, string>,
      profile_status_labels: {
        [StatusType.PENDING]: 'Pending Amazon',
        [StatusType.ONGOING]: 'Processing',
        [StatusType.DOWNLOADED]: 'Processing',
        [StatusType.PROCESSED]: 'Ready',
        [StatusType.FAILED]: 'Failed',
        [ReportingStatusType.LOADING]: 'Updating',
        [ReportingStatusType.NEVER]: 'Warming Up',
        [ReportingStatusType.OUTDATED]: 'Outdated',
        [ReportingStatusType.ERROR]: 'Status Error',
        [ReportingStatusType.COMPLETED]: 'Completed',
        [ReportingStatusType.WAITING_RESPONSE]: 'Checking',
      } as Record<StatusType | ReportingStatusType, string>,
      profile_status_descriptions: {
        [StatusType.PENDING]: 'Pending Amazon to generate the data report',
        [StatusType.ONGOING]: 'Processing data from the report',
        [StatusType.DOWNLOADED]: 'Processing data from the report',
        [StatusType.PROCESSED]: 'Data is updated within the last 24 hours',
        [StatusType.FAILED]: 'Data update failed. Try again or contact support',
        [ReportingStatusType.LOADING]: 'Updating data, please wait',
        [ReportingStatusType.NEVER]: 'We are making a home for your data, please wait',
        [ReportingStatusType.OUTDATED]: 'Data is older than 24 hours, we recommend updating it',
        [ReportingStatusType.ERROR]: 'Error getting the current status of the data',
        [ReportingStatusType.COMPLETED]: 'Data is updated within the last 24 hour',
        [ReportingStatusType.WAITING_RESPONSE]: 'Checking status, please wait',
      } as Record<StatusType | ReportingStatusType, string>,
      bidding_method: {
        [BiddingMethod.ADLABS]: 'AdLabs',
        [BiddingMethod.CPC_MINUS]: 'CPC - X',
        [BiddingMethod.CPC_PLUS]: 'CPC + X',
        [BiddingMethod.CPC_TIMES]: 'CPC * X',
        [BiddingMethod.CUSTOM]: 'Custom Bid',
      } as Record<BiddingMethod, string>,
      bidding_method_before_value: {
        [BiddingMethod.ADLABS]: 'AdLabs',
        [BiddingMethod.CPC_MINUS]: 'CPC - ',
        [BiddingMethod.CPC_PLUS]: 'CPC + ',
        [BiddingMethod.CPC_TIMES]: 'CPC * ',
        [BiddingMethod.CUSTOM]: 'Custom ',
      } as Record<BiddingMethod, string>,
      create_negatives_entity_type: {
        [CreateNegativesEntityType.SP_NEG_KEYWORD]: 'SP - Neg. Keyword',
        [CreateNegativesEntityType.SP_NEG_CAMPAIGN_TARGET]: 'SP - Campaign Neg. Target',
        [CreateNegativesEntityType.SP_NEG_TARGET]: 'SP - Neg. Target',
        [CreateNegativesEntityType.SP_NEG_CAMPAIGN_KEYWORD]: 'SP - Campaign Neg. Keyword',
        [CreateNegativesEntityType.SB_NEG_KEYWORD]: 'SB - Neg. Keyword',
        [CreateNegativesEntityType.SB_NEG_TARGET]: 'SB - Neg. Target',
        [CreateNegativesEntityType.SD_NEG_TARGET]: 'SD - Neg. Target',
        ['SP_KEYWORD']: 'SP - Keyword',
        ['SB_KEYWORD']: 'SB - Keyword',
        ['SP_TARGET']: 'SP - Target',
        ['SB_TARGET']: 'SB - Target',
      } as Record<CreateNegativesEntityType, string>,
      negative_type_short: {
        [NegativeTypeShort.CAMPAIGN_NEG_EXACT]: 'Campaign Negative Exact',
        [NegativeTypeShort.CAMPAIGN_NEG_PHRASE]: 'Campaign Negative Phrase',
        [NegativeTypeShort.CAMPAIGN_NEG_PRODUCT]: 'Campaign Negative Product Target',
        [NegativeTypeShort.AD_GROUP_NEG_EXACT]: 'Ad Group Negative Exact',
        [NegativeTypeShort.AD_GROUP_NEG_PHRASE]: 'Ad Group Negative Phrase',
        [NegativeTypeShort.AD_GROUP_NEG_PRODUCT]: 'Ad Group Negative Product Target',
      } as Record<NegativeTypeShort, string>,
      flow_type: {
        [FlowType.NEGATIVE_TARGETING]: 'Negative Target',
        [FlowType.HARVESTING]: 'Harvesting',
        [FlowType.CAMPAIGN_OPTIMIZER]: 'Campaign',
        [FlowType.TARGETING]: 'Targeting',
        [FlowType.PLACEMENT]: 'Placement',
        [FlowType.SEARCH_TERMS]: 'Search Terms',
        [FlowType.CAMPAIGN_MAPPING]: 'Campaign Mapping',
        [FlowType.OPTIMIZATION_GROUPS]: 'Optimization Groups',
        [FlowType.DATA_GROUPS]: 'Tags',
      } as Record<FlowType, string>,
      log_action_type: {
        [ActionType.CREATE]: 'Create',
        [ActionType.UPDATE]: 'Update',
        [ActionType.DELETE]: 'Delete',
      } as Record<ActionType, string>,
      action_entity_type: {
        [ActionEntityType.NEGATIVE]: 'Negative',
        [ActionEntityType.TARGET]: 'Target',
        [ActionEntityType.CAMPAIGN]: 'Campaign',
        [ActionEntityType.AD_GROUP]: 'Ad Group',
        [ActionEntityType.CAMPAIGN_MAP]: 'Campaign Mapping',
        [ActionEntityType.OPTIMIZATION_GROUP]: 'Optimization Group',
        [ActionEntityType.DATA_GROUP]: 'Tag',
      } as Record<ActionEntityType, string>,
      product_availability: {
        [ProductAvailability.IN_STOCK]: 'In Stock',
        [ProductAvailability.IN_STOCK_SCARCE]: 'Low Inventory',
        [ProductAvailability.OUT_OF_STOCK]: 'Out of Stock',
        [ProductAvailability.PREORDER]: 'Pre-order',
        [ProductAvailability.LEADTIME]: 'Lead time',
        [ProductAvailability.AVAILABLE_DATE]: 'Available Date',
        [ProductAvailability.ERROR]: 'Error',
        [ProductAvailability.UNKNOWN]: 'Unknown',
      } as Record<ProductAvailability, string>,
      product_availability_description: {
        [ProductAvailability.IN_STOCK]: 'The item is in stock.',
        [ProductAvailability.IN_STOCK_SCARCE]: 'The item is in stock, but stock levels are limited.',
        [ProductAvailability.OUT_OF_STOCK]: 'The item is currently out of stock.',
        [ProductAvailability.PREORDER]: 'The item is not yet available, but can be pre-ordered.',
        [ProductAvailability.LEADTIME]: 'The item is only available after some amount of lead time.',
        [ProductAvailability.AVAILABLE_DATE]: 'The item is not available, but will be available on a future date.',
        [ProductAvailability.ERROR]: 'Something went wrong',
        [ProductAvailability.UNKNOWN]: 'Unknown',
      } as Record<ProductAvailability, string>,
      bid_ceiling_type: {
        [BidCeilingType.NOT_SET]: 'Not Set',
        [BidCeilingType.SMART]: 'Smart',
        [BidCeilingType.MANUAL]: 'Manual',
        [BidCeilingType.OFF]: 'Off',
      } as Record<BidCeilingType, string>,
      smart_bid_ceiling_type: {
        [SmartBidCeilingType.TARGET_CPC_1X]: '1x Target CPC (Conservative)',
        [SmartBidCeilingType.TARGET_CPC_2X]: '2x Target CPC (Balanced)',
        [SmartBidCeilingType.TARGET_CPC_3X]: '3x Target CPC (Aggressive)',
      } as Record<SmartBidCeilingType, string>,
      bid_floor_type: {
        [BidFloorType.NOT_SET]: 'Not Set',
        [BidFloorType.MANUAL]: 'Manual',
        [BidFloorType.OFF]: 'Off',
      } as Record<BidFloorType, string>,
      bid_limit_type: {
        [BidLimitType.PERCENT]: '%',
        [BidLimitType.CURRENCY]: '$',
        [BidLimitType.OFF]: 'OFF',
      } as Record<BidLimitType, string>,
    },
    lists: {
      log_preview_table_header: {
        i: 'Job ID',
        ad: 'Ad Type',
        et: 'Entity Type',
        cn: 'Campaign Name',
        agn: 'Ad Group Name',
        t: 'Target',
        ov: 'Old Value',
        nv: 'New Value',
        r: 'Reasons',
        s: 'Status',
        e: 'Error',
        cr: 'Created Date',
        av: 'Optimizer Suggested Value',
      },
    },
  },
};
