import useFormatting from '@/hooks/useFormatting';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { LEGACY_PRO_PLAN } from '@/modules/plans/configuration/plans';
import { FREE_PLAN_CAPABILITIES, PlanType } from '@/modules/plans/types/SubscriptionPlan';
import ProfileList from '@/modules/profiles/components/ProfileList';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { ProfileOption } from '@/modules/profiles/types/ProfileOption';
import { teamService } from '@/modules/teams';
import { useUserContext } from '@/modules/users';
import { HookEvent, userService } from '@/modules/users/api/users/users.service';
import { ProfileType } from '@/modules/users/types/ProfileType';
import { Routes } from '@/router/router-paths';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { isEmpty, isNil } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';

export interface ProfileSelectorDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ProfileSelectorDialog({ onClose, open }: ProfileSelectorDialogProps) {
  const { formatCurrency } = useFormatting();
  const { user, refetchUser, updateGleapIdentity, isAdminModeActive } = useUserContext();
  const { activeTeam, removeProfileFromTeam } = useActiveTeamContext();
  const [profiles, setProfiles] = useState<ProfileOption[]>([]);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [removeProfileConfirmationDialogOpen, setRemoveProfileConfirmationDialogOpen] = useState(false);

  const [profilesToBeRemoved, setProfilesToBeRemoved] = useState<ProfileModel[]>([]);
  const [profilesToBeAdded, setProfilesToBeAdded] = useState<ProfileOption[]>([]);
  useEffect(() => {
    if (activeTeam) {
      const existingProfiles = activeTeam.profiles;
      const selectedProfiles = profiles.filter((p) => p.isSelected);
      const profilesToBeRemoved = existingProfiles.filter((existingProfile) => !selectedProfiles.some((p) => p.id === existingProfile.id));
      setProfilesToBeRemoved(profilesToBeRemoved);
      const profilesToBeAdded = selectedProfiles.filter((p) => !existingProfiles.map((p) => p.id).includes(p.id));
      setProfilesToBeAdded(profilesToBeAdded);
    }
  }, [activeTeam, profiles]);

  const profilesToBeRemovedCount = profilesToBeRemoved.length;
  const profilesToBeAddedCount = profilesToBeAdded.length;

  const priceIncreaseLegacy = formatCurrency(profilesToBeAddedCount * LEGACY_PRO_PLAN.price, {
    customCurrencyCode: 'USD',
  });
  const priceDecreaseLegacy = formatCurrency(-profilesToBeRemovedCount * LEGACY_PRO_PLAN.price, {
    customCurrencyCode: 'USD',
  });

  const newMonthlyLegacyPrice = activeTeam
    ? formatCurrency(
        LEGACY_PRO_PLAN.price * activeTeam.profiles.length +
          profilesToBeAddedCount * LEGACY_PRO_PLAN.price -
          profilesToBeRemovedCount * LEGACY_PRO_PLAN.price,
        {
          customCurrencyCode: 'USD',
        },
      )
    : 'Error calculating price';

  const handleClose = () => {
    // As getProfiles() may set some of the profiles not active. One option is to refresh profiles on close
    if (profiles.some((p) => p.isNotAuthorized)) {
      refetchUser();
    }
    onClose();
  };

  useEffect(() => {
    if (user && open) {
      if (!isNil(activeTeam) && !isNil(activeTeam.amazonAccount) && activeTeam.amazonAccount.isAuthorized) {
        loadProfiles();
      }
    }
  }, [user, open]);

  function setError(message: string) {
    setIsError(true);
    setErrorMessage(message);
  }

  function resetError() {
    setIsError(false);
    setErrorMessage('');
  }

  async function loadProfiles() {
    try {
      resetError();
      setIsLoadingProfiles(true);
      const res = await teamService.getProfiles();

      if (res.isSuccess) {
        if (!isNil(res.payload) && res.payload.length > 0) {
          const existingProfileIds = activeTeam?.profiles.map((profile) => profile.id);
          // Keep logic sync with RegistrationStepSelectProfilesPage
          const profiles = res.payload
            .map((profile) => {
              return {
                id: profile.id,
                name: profile.name,
                market: profile.countryCode,
                type: profile.type,
                isSelected: existingProfileIds ? existingProfileIds.includes(profile.id) : false,
                isNotAuthorized: profile.isNotAuthorized,
              } as ProfileOption;
            })
            .filter((p) => p.type != ProfileType.AGENCY)
            .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()) || b.market.localeCompare(a.market));

          if (profiles.length > 0) {
            setProfiles(profiles);
          } else {
            setError('No profiles found to add');
          }
        } else {
          setError('No profiles found.');
        }
      } else {
        setError('Something went wrong. ' + res.message);
      }
    } catch (error) {
      console.error(error);
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoadingProfiles(false);
    }
  }

  function onConfirmClicked() {
    if (isError) {
      setTimeout(() => resetError(), 200); // To avoid modal state change before close
      onClose();
      return;
    }

    if (isNil(activeTeam)) {
      setError('Active team not found');
      return;
    }

    const existingProfiles = activeTeam.profiles;
    const selectedProfiles = profiles.filter((p) => p.isSelected);

    if (existingProfiles.length == 0 && selectedProfiles.length === 0) {
      setError('No profiles selected');
      return;
    }

    // user changed nothing but clicked confirm
    if (profilesToBeAdded.length == 0 && profilesToBeRemoved.length == 0) {
      onClose();
      return;
    }

    // If at least 1 profile is removed show dialog
    if (profilesToBeRemoved.length > 0) {
      setRemoveProfileConfirmationDialogOpen(true);
    } else {
      handleAddRemoveProfiles();
    }

    // Send hook data
    if (existingProfiles.length == 0 && profilesToBeAdded.length > 0) {
      userService.sendNewUserDataToHook({
        event: HookEvent.ADD_PROFILE,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        firebaseId: user?.firebaseId ?? '',
        name: user?.name ?? '' + (user?.surname ? ' ' + user?.surname : ''),
        email: user?.email ?? '',
        organization_id: activeTeam?.organization?.id,
        organization_name: activeTeam?.organization?.name,
      });
    }
  }

  function onProfileSelectionChange(profileId: string, checked: boolean) {
    const newProfiles = profiles.map((p) => {
      if (p.id === profileId) {
        return {
          ...p,
          isSelected: checked,
        };
      }
      return p;
    });
    setProfiles(newProfiles);
  }

  async function onBeforeUpgradePlan() {
    // if user clicks on upgrade button we need to add profiles at first in the backend

    await addRemoveProfiles(false);
  }

  function handleAddRemoveProfiles() {
    resetError();

    if (isNil(activeTeam)) {
      toastService.error('Active team not found');
      return;
    }

    if (isNil(user)) {
      toastService.error('User not found');
      return;
    }

    // We check all teams not only those where you are owner
    // Reason: In the future you can create new adlabs accounts and add yourself into many teams
    const profilesInTeamsWithFreePlan = user?.teams
      .filter((team) => !team.hasProPlan)
      .map((team) => {
        return team.profiles;
      })
      .flat();

    if (
      !isAdminModeActive &&
      !activeTeam.hasProPlan &&
      profilesToBeAdded.length > 0 &&
      profilesInTeamsWithFreePlan.length + profilesToBeAdded.length - profilesToBeRemoved.length > activeTeam.subscriptionPlan.maxProfiles
    ) {
      setIsPaywallModalOpen(true);
      return;
    }

    addRemoveProfiles();
  }

  async function addRemoveProfiles(showToast = true) {
    try {
      setIsLoading(true);
      resetError();

      if (isNil(activeTeam)) {
        throw new Error('Active team not found');
      }

      // Remove first
      if (profilesToBeRemoved.length > 0) {
        const result = await teamService.removeProfilesFromTeam(
          activeTeam.id,
          profilesToBeRemoved.map((p) => p.id),
        );

        if (result.isSuccess) {
          if (showToast) {
            toastService.success(`${profilesToBeRemoved.map((p) => p.name).join(', ')} removed successfully`);
          }

          profilesToBeRemoved.forEach((p) => {
            removeProfileFromTeam(p.id);
          });
          onClose();
        } else {
          throw new Error(result.message);
        }
      }

      if (profilesToBeAdded.length > 0) {
        const result = await teamService.addProfilesToTeam(
          activeTeam?.id as number,
          profilesToBeAdded.map((p) => p.id),
        );

        if (result.isSuccess) {
          if (showToast) {
            toastService.success(`${profilesToBeAdded.map((p) => p.name).join(', ')} added successfully`);
          }

          await refetchUser();

          updateGleapIdentity();
          onClose();
        } else {
          let message = 'Something went wrong. Please try again later.';
          if (result.message && !isEmpty(result.message)) {
            message += ' Reason: ' + result.message;
          }

          setError(message);
        }
      }
    } catch (error) {
      console.error(error);
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancelProfileRemove = () => {
    setRemoveProfileConfirmationDialogOpen(false);
  };
  const handleConfirmProfileRemove = () => {
    setRemoveProfileConfirmationDialogOpen(false);
    handleAddRemoveProfiles();
  };

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  // Slow loading
  const [slowLoading, setSlowLoading] = useState(false); // for showing the info alert after 5s
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isLoadingProfiles) {
      // Start a timeout when we begin loading
      timerRef.current = setTimeout(() => {
        setSlowLoading(true);
      }, 5000);
    } else {
      // Clear timeout, reset slowLoading if the profiles are loaded
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setSlowLoading(false);
    }

    // Cleanup on unmount or re-render
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isLoadingProfiles]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <DialogTitle>Manage Profiles</DialogTitle>

        <DialogContent>
          {isError && (
            <Alert className="mb-2" severity="error">
              {errorMessage}
            </Alert>
          )}

          {slowLoading && !isError && (
            <Alert className="mb-2" severity="info">
              It appears Amazon is taking it&apos;s time to return all your available profiles. Please hold.
            </Alert>
          )}

          {!isError && (
            <div style={{ minHeight: '90vh' }}>
              <div className="flex flex-col justify-stretch gap-y-3">
                {!isError && (
                  <ProfileList
                    profiles={profiles}
                    onProfileSelectionChange={onProfileSelectionChange}
                    isLoadingProfiles={isLoadingProfiles}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </div>
          )}
        </DialogContent>

        {!isError &&
          profiles.length > 0 &&
          (activeTeam?.adlabsPlan === PlanType.PRO_PLAN ||
            (activeTeam?.adlabsPlan === PlanType.FREE_PLAN &&
              profiles.filter((p) => p.isSelected).length > FREE_PLAN_CAPABILITIES.maxProfiles)) && (
            <div className="mt-4 mx-6 text-xs flex items-end flex-col ">
              {activeTeam?.isOnLegacyProfilesBasedProPlan && (
                <div className="grid grid-cols-2 gap-y-1 mt-1 max-w-96">
                  <div>Add {profilesToBeAddedCount} Profiles Per Month</div>
                  <strong className="text-right">{priceIncreaseLegacy}</strong>

                  <div>Remove {profilesToBeRemovedCount} Profiles Per Month </div>
                  <strong className="text-right">{priceDecreaseLegacy}</strong>

                  <div>Your New Monthly Total</div>
                  <strong className="text-right">{newMonthlyLegacyPrice}</strong>
                </div>
              )}
              <div className="mt-2 text-gray-500">Charges will be prorated based on your current billing cycle.</div>
            </div>
          )}
        <DialogActions className="mb-2 px-4">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            className="w-52"
            variant="contained"
            color="primary"
            onClick={onConfirmClicked}
            loading={isLoading}
            disabled={isLoading || isLoadingProfiles}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="xs" open={removeProfileConfirmationDialogOpen}>
        <DialogTitle>Confirm changes</DialogTitle>
        <DialogContent>
          <div>
            <Typography variant="subtitle1" fontWeight={600}>
              Are you sure you want to remove following profiles?
            </Typography>
            <ul className="mt-0">
              {profilesToBeRemoved.map((profile) => (
                <li key={profile.id}>
                  <Typography variant="body2">{profile.name}</Typography>
                </li>
              ))}
            </ul>
          </div>

          {profilesToBeAdded.length > 0 && (
            <div className="mt-4 ml-0">
              <Typography variant="subtitle1" fontWeight={600}>
                Are you sure you want to add following profiles?
              </Typography>
              <ul className="mt-0">
                {profilesToBeAdded.map((profile) => (
                  <li key={profile.id}>
                    <Typography variant="body2">{profile.name}</Typography>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {activeTeam?.adlabsPlan === PlanType.PRO_PLAN && (
            <div className="mt-4 text-xs flex flex-col">
              {activeTeam?.isOnLegacyProfilesBasedProPlan && (
                <div className="grid grid-cols-2 gap-y-1 mt-1 max-w-96">
                  <div>Remove {profilesToBeRemoved.length} Profile Per Month </div>
                  <strong className="text-right">{priceDecreaseLegacy}</strong>

                  {profilesToBeAdded.length > 0 && (
                    <>
                      <div>Add {profilesToBeAdded.length} Profile Per Month </div>
                      <strong className="text-right">{priceIncreaseLegacy}</strong>
                    </>
                  )}

                  <div>Your New Monthly Total</div>
                  <strong className="text-right">{newMonthlyLegacyPrice}</strong>
                </div>
              )}
              <div className="mt-2 text-gray-500">Charges will be prorated based on your current billing cycle.</div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" autoFocus onClick={handleCancelProfileRemove}>
            Cancel
          </Button>
          <Button onClick={handleConfirmProfileRemove}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        onBeforeUpgradePlan={onBeforeUpgradePlan}
        returnURLPath={Routes.PROFILES}
        headerText="Upgrade to Pro to add more than 3 profiles"
      ></PaywallModal>
    </>
  );
}
