import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { AlFilterModel, getDefaultSearchTermsFilters } from '@/components/filter-builder/models/AlFilterModel';
import MetricsContainer from '@/components/metrics/MetricsContainer';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { VideoUrl } from '@/config/urls';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import useMetricChartTablePageVariables from '@/hooks/useMetricChartTablePageVariables';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { getErrorMessage } from '@/modules/application/utils';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import {
  invalidateProfile_negativeTargetingQueryKeys,
  negativeTargetingService,
} from '@/modules/negative-targets/api/negative-targets-service';
import { CreateNegativesParamsModal } from '@/modules/negative-targets/components/add-negatives/CreateNegativesParamsModal';
import { CreateNegativeModel } from '@/modules/negative-targets/models/CreateNegativeModel';
import { CreateNegativeWithSelectedSearchTermModel } from '@/modules/negative-targets/models/CreateNegativePreviewModel';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { invalidateProfile_targetingWithTimelineQueryKeys } from '@/modules/targeting/api/targets-service';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { ContextKey, DEFAULT_GRID_TOGGLES } from '@/types/context-shared';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { CreateNegativesPreviewModal } from '../../negative-targets/components/add-negatives/CreateNegativesPreviewModal';
import {
  createSearchTermsWithTimelineQueryKey,
  invalidateProfile_searchTermsWithTimelineQueryKeys,
  searchTermsService,
} from '../api/search-terms-service';
import SearchTermsFilterBar from '../components/SearchTermsFilterBar';
import SearchTermsSelectionActionsBar from '../components/SearchTermsSelectionActionsBar';
import { keywordHarvestingService } from '../components/keyword-harvesting/api/keyword-harvesting-service';
import { KeywordHarvestingParamsModal } from '../components/keyword-harvesting/components/KeywordHarvestingParamsModal';
import { KeywordHarvestingPreviewModal } from '../components/keyword-harvesting/components/KeywordHarvestingPreviewModal';
import { HarvestingParams, KeywordHarvestingModel } from '../components/keyword-harvesting/models/KeywordHarvestingModel';
import { KeywordHarvestingPreviewDataRow } from '../components/keyword-harvesting/models/KeywordHarvestingPreviewDataRow';
import SearchTermsTable from '../components/search-terms-table/SearchTermsTable';
import { CreateNegativesParams, SelectedSearchTerm } from '../models/SearchTermModel';

const SearchTermsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const { ModalComponent: navigationModal, setBlock } = useNavigationConfirmationModal();
  usePaywallManager({
    route: Routes.SEARCH_TERMS,
    message: 'Upgrade to Pro to Access the Search Terms Tool Suite',
    videoUrl: VideoUrl.KW_HARVESTING,
    canUsePage: (team) => team.subscriptionPlan.canUseSearchTermsPage,
  });

  const pageVariables = useMetricChartTablePageVariables({
    contextKey: ContextKey.SEARCH_TERMS,
    metricsUserSettingKey: UserSettingKey.VISIBLE_METRICS_SEARCH_TERMS,
    defaultFilters: getDefaultSearchTermsFilters(),
    gridToggles: DEFAULT_GRID_TOGGLES,
  });

  const [selectedSearchTerms, setSelectedSearchTerms] = useState<SelectedSearchTerm[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const searchTermsTableGridApiRef = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedSearchTerms([]);
    if (searchTermsTableGridApiRef.current && !searchTermsTableGridApiRef.current.isDestroyed()) {
      searchTermsTableGridApiRef.current.deselectAll();
    }
  }

  const searchTermsWithTimelineQueryKey = createSearchTermsWithTimelineQueryKey(activeProfile?.id, pageVariables.filters);
  const {
    data: searchTermsWithTimeline,
    isLoading: isSearchTermsRowDataLoading,
    isError: isSearchTermsLoadingError,
    error: searchTermsLoadingError,
    isFetching,
  } = useQuery({
    queryKey: searchTermsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await searchTermsService.getSearchTermsWithTimeline(pageVariables.filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading search terms ' + JSON.stringify(result));
      }
    },
    enabled: pageVariables.isFiltersEnabled && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching search terms', isFetching);

  function onSearchTermsTableGridReady(params: GridReadyEvent) {
    searchTermsTableGridApiRef.current = params.api;
  }

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.SEARCH_TERMS_TUTORIAL_DISPLAY_COUNT,
  );

  // ADD NEGATIVES PARAMS MODAL
  const [isCreateNegativesParamsModalOpen, setIsCreateNegativesParamsModalOpen] = useState(false);

  const onCreateNegativesParamsClicked = () => {
    setIsCreateNegativesParamsModalOpen(true);
  };

  const onCreateNegativesParamsConfirm = (createNegativesParams: CreateNegativesParams) => {
    setIsCreateNegativesParamsModalOpen(false);

    setNegativeTargetsToAdd(
      CreateNegativeWithSelectedSearchTermModel.fromSelectedSearchTermArrayAndParams(selectedSearchTerms, createNegativesParams),
    );

    setIsCreateNegativesPreviewModalOpen(true);
  };

  // ADD NEGATIVES MODAL
  const [isCreateNegativesPreviewModalOpen, setIsCreateNegativesPreviewModalOpen] = useState(false);
  const [negativeTargetsToAdd, setNegativeTargetsToAdd] = useState<CreateNegativeWithSelectedSearchTermModel[]>([]);

  const [isSendingNegativeTargets, setIsSendingNegativeTargets] = useState(false);
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [applyResponse, setApplyResponse] = useState<UpdateResponseDTO>();

  async function onApplyAddingNegativeTargets(negativeTargets: CreateNegativeModel[]) {
    setIsSendingNegativeTargets(true);
    try {
      const response = await negativeTargetingService.createNegativeTargets(negativeTargets, FlowType.SEARCH_TERMS);

      // TODO: similar logic is in many places. Refactor into one function?
      if (response.isSuccess) {
        setApplyResponse({ responseErrorMsg: null, payload: response.payload.created });

        if (activeProfile) {
          invalidateProfile_negativeTargetingQueryKeys(queryClient, activeProfile.id);
        }
      } else {
        setApplyResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
      }
    } catch (error) {
      console.error('error');
      setApplyResponse({ responseErrorMsg: `Error adding negative targets: ${getErrorMessage(error)}`, payload: undefined });
    } finally {
      setUpdateResponseModalOpen(true);
      setIsSendingNegativeTargets(false);
    }
  }

  // KEYWORD HARVESTING PARAMS MODAL
  const [isKeywordHarvestingParamsModalOpen, setIsKeywordHarvestingParamsModalOpen] = useState(false);

  // KEYWORD HARVESTING MODAL
  const [isKeywordHarvestingModalOpen, setIsKeywordHarvestingModalOpen] = useState(false);
  const [harvestingData, setHarvestingData] = useState<KeywordHarvestingModel | null>(null);
  const [isLoadingHarvestingData, setIsRequestingHarvestingData] = useState(false);
  const [isSendingHarvestingData, setIsSendingHarvestingData] = useState(false);

  // Triggered by harvesting params modal
  function onPreviewClicked(harvestingParams: HarvestingParams, filters: AlFilterModel[]) {
    const fetchPreviewResults = async () => {
      try {
        setIsRequestingHarvestingData(true);

        const response = await keywordHarvestingService.getKeywordHarvestingPreview(selectedSearchTerms, harvestingParams, filters);
        if (response.isSuccess) {
          setHarvestingData(response.payload);
          setIsKeywordHarvestingModalOpen(true);
        } else {
          toastService.error('Error loading harvesting data ' + JSON.stringify(response));
        }
      } catch (error) {
        toastService.error('Error loading harvesting data');
        console.error(error);
      } finally {
        setIsRequestingHarvestingData(false);
      }
    };
    fetchPreviewResults();
  }

  function onKeywordHarvestingModalClose() {
    setIsKeywordHarvestingModalOpen(false);
    setHarvestingData(null);
  }

  async function onSendHarvestingData(harvestingPreviewSelection: KeywordHarvestingPreviewDataRow[]) {
    setIsSendingHarvestingData(true);
    onKeywordHarvestingModalClose();
    try {
      const response = await keywordHarvestingService.applyHarvestingData(harvestingPreviewSelection);

      if (response.isSuccess) {
        setApplyResponse({ responseErrorMsg: null, payload: response.payload.created });

        if (activeProfile) {
          invalidateProfile_negativeTargetingQueryKeys(queryClient, activeProfile.id);
          invalidateProfile_targetingWithTimelineQueryKeys(queryClient, activeProfile.id);
          invalidateProfile_searchTermsWithTimelineQueryKeys(queryClient, activeProfile.id);
        }
        setUpdateResponseModalOpen(true);
        if (searchTermsTableGridApiRef.current && !searchTermsTableGridApiRef.current.isDestroyed()) {
          searchTermsTableGridApiRef.current.deselectAll();
        }
      } else {
        setApplyResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
        setUpdateResponseModalOpen(true);
      }
    } catch (error) {
      toastService.error('Error sending harvesting data');
      console.error(error);
    } finally {
      setIsSendingHarvestingData(false);
    }
  }

  useEffect(() => {
    if (isCreateNegativesPreviewModalOpen || isKeywordHarvestingModalOpen) {
      setBlock(true);
    } else {
      setBlock(false);
    }
  }, [isCreateNegativesPreviewModalOpen, isKeywordHarvestingModalOpen]);

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  return (
    <>
      <PageLayout showFullscreen={isTableExpanded}>
        {!isTableExpanded && (
          <PageLayoutTopBar
            header={
              <div className="flex flex-row items-center gap-4">
                {t('search_terms')}
                <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                  How to Harvest Keywords & Products Targets
                </Button>
              </div>
            }
            actions={
              <div className="flex flex-row items-center gap-2">
                {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
                <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
                <TeamSelect />
                <ProfileSyncSelectButton disableFilters={pageVariables.disableFilters} />{' '}
              </div>
            }
          />
        )}

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
            {!isTableExpanded && (
              <div className="mt-2">
                <AlErrorBoundary>
                  <MetricsContainer
                    metricValues={searchTermsWithTimeline?.metrics}
                    isLoading={isSearchTermsRowDataLoading}
                    isError={isSearchTermsLoadingError}
                    error={searchTermsLoadingError}
                    visibleMetrics={pageVariables.visibleMetrics}
                    setVisibleMetrics={pageVariables.setVisibleMetrics}
                    showComparison={pageVariables.gridToggles.comparisonUnit != 'hidden'}
                  />
                </AlErrorBoundary>
              </div>
            )}

            {!isTableExpanded && (
              <div className="my-2">
                <AlErrorBoundary>
                  <MetricTimelineChart
                    visibleMetrics={pageVariables.visibleMetrics}
                    timelineData={searchTermsWithTimeline?.timeline}
                    isLoading={isSearchTermsRowDataLoading}
                    isError={isSearchTermsLoadingError}
                    error={searchTermsLoadingError}
                  />
                </AlErrorBoundary>
              </div>
            )}

            <AlErrorBoundary>
              <SearchTermsFilterBar
                withTimeline={searchTermsWithTimeline}
                pageVariables={pageVariables}
                gridApiRef={searchTermsTableGridApiRef}
                isExpanded={isTableExpanded}
                onExpandTable={toggleTableExpanded}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <SearchTermsTable
                withTimeline={searchTermsWithTimeline}
                isLoading={isSearchTermsRowDataLoading}
                selectedSearchTerms={selectedSearchTerms}
                setSelectedSearchTerms={setSelectedSearchTerms}
                searchTermsLoadingErrorMessage={searchTermsLoadingError instanceof Error ? searchTermsLoadingError.message : ''}
                isSearchTermsLoadingError={isSearchTermsLoadingError}
                pageVariables={pageVariables}
                onGridReadyCallback={onSearchTermsTableGridReady}
                noTopBorderRadius={true}
                isExpanded={isTableExpanded}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <KeywordHarvestingParamsModal
                isOpen={isKeywordHarvestingParamsModalOpen}
                onClose={() => setIsKeywordHarvestingParamsModalOpen(false)}
                selectedSearchTerms={selectedSearchTerms}
                onPreviewClicked={onPreviewClicked}
                pageVariables={pageVariables}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <KeywordHarvestingPreviewModal
                isOpen={isKeywordHarvestingModalOpen}
                onClose={onKeywordHarvestingModalClose}
                onApplyChanges={onSendHarvestingData}
                filtersUsed={pageVariables.filters}
                keywordHarvestingData={harvestingData}
              />
            </AlErrorBoundary>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <SearchTermsSelectionActionsBar
          selectedSearchTerms={selectedSearchTerms}
          setSelectedSearchTerms={setSelectedSearchTerms}
          totalSearchTermsCount={searchTermsWithTimeline?.searchTerms ? searchTermsWithTimeline.searchTerms.length : 0}
          onDiscardClicked={clearSelections}
          onCreateNegativesParamsClicked={onCreateNegativesParamsClicked}
          searchTermsTableGridApiRef={searchTermsTableGridApiRef}
          onHarvestClicked={() => setIsKeywordHarvestingParamsModalOpen(true)}
        />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <CreateNegativesParamsModal
          isOpen={isCreateNegativesParamsModalOpen}
          onClose={() => setIsCreateNegativesParamsModalOpen(false)}
          selectedSearchTerms={selectedSearchTerms}
          onCreateNegativesParamsConfirm={onCreateNegativesParamsConfirm}
        />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <CreateNegativesPreviewModal
          isOpen={isCreateNegativesPreviewModalOpen}
          onClose={() => setIsCreateNegativesPreviewModalOpen(false)}
          negativeTargetsToAdd={negativeTargetsToAdd}
          onApplyAddingNegativeKeywords={onApplyAddingNegativeTargets}
        />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <UpdateResponseModal
          isOpen={updateResponseModalOpen}
          onClose={() => setUpdateResponseModalOpen(false)}
          updateResponse={applyResponse}
        />
      </AlErrorBoundary>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.KW_HARVESTING}
        title={'How to Harvest Keywords & Products Targets'}
      />

      <LoadingOverlay isVisible={isLoadingHarvestingData} message="Reaping the harvest..." />
      <LoadingOverlay isVisible={isSendingNegativeTargets || isSendingHarvestingData} message="Sending new data to Amazon..." />
      {navigationModal}
    </>
  );
};

export default SearchTermsPage;
