import { isEmpty, isNil } from 'lodash-es';

export class SellingPartnerGetParamsModel {
  public spapiOauthCode: string | null;
  public state: string | null;
  public sellingPartnerId: string | null;

  constructor(args: SellingPartnerGetParamsArguments) {
    this.spapiOauthCode = args.spapiOauthCode;
    this.state = args.state;
    this.sellingPartnerId = args.sellingPartnerId;
  }

  public isValid(): boolean {
    return (
      !isNil(this.spapiOauthCode) &&
      !isEmpty(this.spapiOauthCode) &&
      !isNil(this.state) &&
      !isEmpty(this.state) &&
      !isNil(this.sellingPartnerId) &&
      !isEmpty(this.sellingPartnerId)
    );
  }

  public isVendor(): boolean {
    return !!this.state?.includes('vendor');
  }

  clone(): SellingPartnerGetParamsModel {
    return new SellingPartnerGetParamsModel({
      spapiOauthCode: this.spapiOauthCode,
      state: this.state,
      sellingPartnerId: this.sellingPartnerId,
    });
  }
}

export interface SellingPartnerGetParamsArguments {
  spapiOauthCode: string | null;
  state: string | null;
  sellingPartnerId: string | null;
}
