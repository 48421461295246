import { CampaignGroupFilterModel, OperatorType } from '@/components/filter-builder/models/AlFilterModel';
import { NavPayload } from '@/router/NavPayload';
import { Routes } from '@/router/router-paths';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

const useCampaignGroupsNavigation = () => {
  const navigate = useNavigate();

  const navigateToPageWithCampaignGroupFilter = useCallback((route: Routes, campaignGroupId: number) => {
    const filter = new CampaignGroupFilterModel([], {
      conditions: [
        {
          values: [campaignGroupId],
          operator: OperatorType.IN,
        },
      ],
    });

    const payload: NavPayload = {
      filters: [filter],
    };

    navigate(route, { state: payload });
  }, []);

  return {
    navigateToPageWithCampaignGroupFilter,
  };
};

export default useCampaignGroupsNavigation;
