import { useState, JSX } from 'react';
import { ConfirmationModal } from './ConfirmationModal';

export const useConfirmationModal = ({
  questionText,
  onConfirm,
  questionTitle,
  confirmButtonText,
  cancelButtonText,
  closeOnConfirm = true,
}: {
  questionText: string | JSX.Element;
  questionTitle: string;
  onConfirm: (id?: number) => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  closeOnConfirm?: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmId, setConfirmId] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = (id?: number) => {
    setIsModalOpen(true);
    setConfirmId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setConfirmId(undefined);
  };

  const handleConfirmAction = () => {
    onConfirm(confirmId);
    if (closeOnConfirm) {
      setIsModalOpen(false);
    }
    setConfirmId(undefined);
  };

  const ModalComponent = (
    <ConfirmationModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onConfirm={handleConfirmAction}
      questionText={questionText}
      questionTitle={questionTitle}
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
      isLoading={isLoading}
    />
  );

  return { ModalComponent, handleOpenModal, setIsLoading, setIsModalOpen };
};
