import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupBySelect, { TableWidgetGroupByOptionSelectOption } from '../../forms/GroupBySelect';
import ColumnVisibilitySelect from './ColumnVisibilitySelect';
import { ColumnConfiguration, TableWidgetConfiguration } from './TableWidgetConfiguration';
import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import TableWidgetFilters from './TableWidgetFilters';
import ProfileSelect from '../../forms/ProfileSelect';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';

interface TableWidgetConfigurationFormProps {
  configuration: TableWidgetConfiguration;
  id: string;
}

const TableWidgetConfigurationForm: FunctionComponent<TableWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const [localConfiguration, setLocalConfiguration] = useState<TableWidgetConfiguration>(cloneDeep(configuration));

  useEffect(() => {
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration]);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      prev.title = e.currentTarget?.value;

      return cloneDeep(prev);
    });
    setModifiedConfiguration(localConfiguration);
  };

  const handleEntityTypeChange = (entityType: '' | EntityType) => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      prev.entityType = entityType;

      return cloneDeep(prev);
    });
    setModifiedConfiguration(localConfiguration);
  };

  const handleCampaignGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      prev.campaignGroupByValue = groupByOption.value;
      prev.campaignGroupByCategory = groupByOption.category;

      return cloneDeep(prev);
    });
  };

  const handleTargetsGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      prev.targetsGroupByValue = groupByOption.value;
      prev.targetsGroupByCategory = groupByOption.category;

      return cloneDeep(prev);
    });
  };

  const handlePlacementGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.placementGroupByValue = groupByOption.value;
      newConfig.placementGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleProductGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.productGroupByValue = groupByOption.value;
      newConfig.productGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleMetricColumnsChange = (metricColumnSettings: ColumnConfiguration[]) => {
    setLocalConfiguration((prev) => {
      prev.metricColumns = metricColumnSettings;

      return cloneDeep(prev);
    });
  };

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  if (!localConfiguration) return null;

  return (
    <>
      <TextField label="Name" value={localConfiguration.title ?? ''} onChange={handleTitleChange} />

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.SEARCH_TERM]}
      />

      {localConfiguration.entityType === EntityType.CAMPAIGN && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.campaignGroupByValue}
          onChange={handleCampaignGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {localConfiguration.entityType === EntityType.TARGET && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.targetsGroupByValue}
          onChange={handleTargetsGroupByChange}
          dataGroupType={DataGroupType.TARGET}
        />
      )}

      {localConfiguration.entityType === EntityType.PLACEMENT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.placementGroupByValue}
          onChange={handlePlacementGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {localConfiguration.entityType === EntityType.PRODUCT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.productGroupByValue}
          dataGroupType={DataGroupType.PRODUCT}
          onChange={handleProductGroupByChange}
        />
      )}

      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption">Select the metrics to display</Typography>
        <ColumnVisibilitySelect value={localConfiguration.metricColumns ?? []} onChange={handleMetricColumnsChange} />
      </div>

      <TableWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default TableWidgetConfigurationForm;
