import { FunctionComponent, useState, useEffect } from 'react';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { planService } from '../api/plan.service';
import { AlDate } from '@/lib/date/AlDate';
import { PlanType } from '../types/SubscriptionPlan';
import { Routes } from '@/router/router-paths';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { useUserContext } from '@/modules/users';

const PlanUpgradeBanner: FunctionComponent = () => {
  const { refetchUser } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeTeam?.isOnLegacyProfilesBasedProPlan) {
      const interval = setInterval(() => {
        const now = AlDate.now();
        const endOfYear = AlDate.now().endOf('year');
        const days = Math.floor(now.diff(endOfYear, 'days'));
        const hours = Math.floor(now.diff(endOfYear, 'hours') % 24);
        const minutes = Math.floor(now.diff(endOfYear, 'minutes') % 60);
        const seconds = Math.floor(now.diff(endOfYear, 'seconds') % 60);

        setTimeRemaining({ days, hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [activeTeam]);

  const { ModalComponent, handleOpenModal } = useConfirmationModal({
    questionText: (
      <span>
        <span>
          Upgrading to AdLabs Pro gives access to Search Term Reports, Keyword Harvesting, Negative Targeting, Organic Sales and also includes
          all future releases (custom dashboards, dayparting, etc.).
          <br />
          <br />
          Sign up today to get <b>50% off for life</b>!
          <br />
          <br />
          You cannot downgrade to AdLabs Legacy after upgrading.
        </span>
      </span>
    ),

    onConfirm: onUpgradeFromLegacyPlanClicked,
    questionTitle: 'Upgrade to the New Pro Plan',
    confirmButtonText: 'Upgrade',
    cancelButtonText: 'Cancel',
  });

  async function onUpgradeFromLegacyPlanClicked() {
    try {
      setIsLoading(true);

      const upgradeFromLegacyResponse = await planService.upgradeFromLegacyPlan();
      if (upgradeFromLegacyResponse.isSuccess) {
        await refetchUser();
        toastService.success('You have successfully upgraded to the new plan');
      } else {
        toastService.error(`Something went wrong. Please try again later. ${upgradeFromLegacyResponse.message}`);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onUpgradeClicked() {
    try {
      if (activeTeam?.isOnLegacyProfilesBasedProPlan) {
        handleOpenModal();
        return;
      }

      setIsLoading(true);

      const upgradeResponse = await planService.getCheckoutLinkForPlan(PlanType.PRO_PLAN, Routes.BILLING);
      if (upgradeResponse.isSuccess) {
        // Navigate to checkout page
        window.location.href = upgradeResponse.payload.session_url;
      } else {
        toastService.error(`Something went wrong. Please try again later. ${upgradeResponse.message}`);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  const renderBannerText = () => {
    if (activeTeam?.isOnLegacyProfilesBasedProPlan) {
      return (
        <>
          <div className="text-orange-400 font-bold">
            Your Legacy plan expires in {timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s.
          </div>
          <div className="ml-2 hover:underline cursor-pointer" onClick={onUpgradeClicked}>
            Upgrade now to <span className="">get more features</span> and lock in{' '}
            <span className="font-extrabold text-orange-400">50% off for life!</span>
          </div>
        </>
      );
    }

    if (!activeTeam?.hasProPlan) {
      return (
        <>
          <div className="text-green-400">
            🎅 <span className="font-bold">The year is almost over</span>
            <span className="mx-2 text-white">—</span>
          </div>
          <div className=" hover:underline cursor-pointer" onClick={onUpgradeClicked}>
            Upgrade before year-end and enjoy <span className="font-extrabold text-green-400">50% off for life!</span>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div className="fixed top-0 left-1/2 transform -translate-x-1/2 w-full flex items-center justify-between py-1 bg-gray-800 text-white">
        <div className="pl-2 flex items-center justify-center text-center w-full font-medium">{renderBannerText()}</div>
      </div>
      {ModalComponent}
    </>
  );
};

export default PlanUpgradeBanner;
