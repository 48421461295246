import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { isValidASIN } from '@/modules/application/amazon-utils';
import { SelectedEntityForDataGroup } from '@/modules/data-groups/models/DataItem';
import { NegativeMatchType } from '@/modules/negative-targets/api/negative-targets-contracts';
import { CampaignAdType, CostType, EnabledPausedArchivedState, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { DTO } from '@/types/dto-wrapper';
import { isEmpty } from 'lodash-es';
import { NegativeTypeShort, SearchTermWithMetricsWithPreviousDTO } from '../api/search-terms-contracts';

export class SearchTermModel extends DTO<SearchTermWithMetricsWithPreviousDTO> {
  constructor(data: SearchTermWithMetricsWithPreviousDTO) {
    super(data);
  }

  public get id(): string {
    let et;
    if (this.dto.et === TargetEntityType.KEYWORD) {
      et = 'kw';
    } else if (this.dto.et === TargetEntityType.PRODUCT_TARGET) {
      et = 'pt';
    }
    return this.dto.ei + et + this.dto.s;
  }

  public get searchTerm(): string {
    return this.dto.s;
  }

  public get entityType(): TargetEntityType {
    return this.dto.et;
  }

  public get entityId(): string {
    return this.dto.ei;
  }

  public get bid(): number {
    return this.dto.b;
  }

  public get matchType(): MatchType {
    return this.dto.mt;
  }

  public get targeting(): string {
    return this.dto.t;
  }

  public get portfolioName(): string {
    return this.dto.p;
  }

  public get adGroupName(): string {
    return this.dto.agn;
  }

  public get adGroupState(): string {
    return this.dto.ags;
  }

  public get campaignId(): string {
    return this.dto.ci;
  }

  public get adGroupId(): string {
    return this.dto.ai;
  }

  public get campaignName(): string {
    return this.dto.cn;
  }

  public get campaignAdType(): CampaignAdType {
    return this.dto.ct;
  }

  public get campaignState(): EnabledPausedArchivedState {
    return this.dto.cs;
  }

  public get targetState(): EnabledPausedArchivedState {
    return this.dto.ts;
  }

  public get campaignCostType(): CostType {
    return isEmpty(this.dto.co) ? CostType.NONE : this.dto.co;
  }

  public get groupName(): string {
    return this.dto.gn;
  }

  public get campaignIsVideo(): boolean {
    return this.dto.v ?? false;
  }

  public get targetingType(): TargetingType {
    return this.dto.tt;
  }

  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get units(): MetricData {
    return this.dto.units;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.sales;
  }

  public get sso(): MetricData {
    return this.dto.sso;
  }

  public get sss(): MetricData {
    return this.dto.sss;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get actc(): MetricData {
    return this.dto.actc;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public get campaignDataItemIds(): number[] {
    return this.dto.cd ?? [];
  }

  public get targetingDataItemIds(): number[] {
    return this.dto.td ?? [];
  }

  public get searchTermDataItemIds(): number[] {
    return this.dto.sd ?? [];
  }

  public get searchTermWordCount(): number | null {
    if (isValidASIN(this.searchTerm)) {
      return null;
    }

    const searchTermWithoutPlus = this.searchTerm?.replace(/\+/g, ' ');
    return searchTermWithoutPlus?.split(' ').length ?? 0;
  }

  public get isHarvested(): boolean {
    return this.dto.h ?? false;
  }

  public get negatives(): null | NegativeTypeShort[] {
    return this.dto.ng;
  }

  public get hasNegatives(): boolean {
    return this.negatives != null && this.negatives.length > 0;
  }

  public static fromResponse(dto: SearchTermWithMetricsWithPreviousDTO): SearchTermModel {
    return new SearchTermModel(dto);
  }

  public static fromResponseArray(dtos: SearchTermWithMetricsWithPreviousDTO[] | null): SearchTermModel[] {
    return dtos ? dtos.map(SearchTermModel.fromResponse) : [];
  }
}

export interface SelectedSearchTerm extends SelectedEntityForDataGroup {
  searchTerm: string;
  entityType: TargetEntityType;
  campaignName: string;
  campaignAdType: CampaignAdType;
  adGroupName: string;
  targeting: string;
  campaignId: string;
  adGroupId: string;
  targetingType: TargetingType;
  matchType: MatchType;
}

// TODO: same interface as CampaignMappingNegatives
export interface CreateNegativesParams {
  campaignNegativeExact: boolean;
  campaignNegativePhrase: boolean;
  campaignNegativeProductTarget: boolean;
  adGroupNegativeExact: boolean;
  adGroupNegativePhrase: boolean;
  adGroupNegativeProductTarget: boolean;
}

export const CREATE_NEGATIVES_DEFAULT_PARAMS: CreateNegativesParams = {
  campaignNegativeExact: false,
  campaignNegativePhrase: false,
  campaignNegativeProductTarget: false,
  adGroupNegativeExact: false,
  adGroupNegativePhrase: false,
  adGroupNegativeProductTarget: false,
};

export const createNegativesParamKeyToNegativeMatchType: Record<keyof CreateNegativesParams, NegativeMatchType> = {
  campaignNegativeExact: NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT,
  campaignNegativePhrase: NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE,
  campaignNegativeProductTarget: NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET,
  adGroupNegativeExact: NegativeMatchType.AD_GROUP_NEGATIVE_EXACT,
  adGroupNegativePhrase: NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE,
  adGroupNegativeProductTarget: NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET,
};
