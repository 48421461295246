import { FunctionComponent, useEffect, useState } from 'react';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import { useTheme } from '@mui/material';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';
import { LoadingButton } from '@mui/lab';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { SpendType } from '../types/wizard_step_state.enum';
import { isNil } from 'lodash-es';
import { useUserContext, userService } from '@/modules/users';
import { useNavigate } from 'react-router';
import { Routes } from '@/router/router-paths';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { HookEvent } from '@/modules/users/api/users/users.service';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const RegistrationStepAlmostTherePage: FunctionComponent = () => {
  const {
    state,
    teamId: registrationTeamID,
    userId: registrationUserID,
    phone: registrationPhone,
    averageAdSpend,
  } = useRegistrationContext();

  const { user, setUser, refetchUser } = useUserContext();
  const { activeTeam, setActiveTeam } = useActiveTeamContext();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (!user && registrationUserID) {
      fetchAndSetUser(registrationUserID);
    } else if (user && registrationTeamID) {
      setActiveTeam({ teamId: registrationTeamID });
    }
  }, [user, registrationTeamID]);

  async function fetchAndSetUser(userId: number) {
    const userResponse = await userService.getUserById(userId);
    if (userResponse.isSuccess) {
      setUser(userResponse.payload);
    }
  }

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isNil(activeTeam?.preferredUpdateTime) && !isSubmitting) {
      setIsContinueEnabled(true);
    } else {
      setIsContinueEnabled(false);
    }
  }, [activeTeam]);

  function onContinueClicked() {
    setIsSubmitting(true);

    try {
      userService.sendNewUserDataToHook({
        event: HookEvent.ALMOST_THERE,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        firebaseId: user?.firebaseId ?? state.user?.firebaseId ?? '',
        name: user?.name ?? '' + (user?.surname ? ' ' + user?.surname : ''),
        email: user?.email ?? state.user?.email ?? '',
      });

      if (averageAdSpend == SpendType.LessThanTenThousand || !isUSorCanadaPhone(registrationPhone ?? user?.phone)) {
        navigate(Routes.GETTING_STARTED);
      } else {
        navigate(Routes.REGISTER_BOOK_CALL);
      }

      return;
    } catch (error) {
      console.error(error);
    }
  }
  const isUSorCanadaPhone = (phone: string | undefined): boolean => {
    if (isNil(phone)) {
      return false;
    }

    // Remove any non-digit characters for easier comparison
    const cleanedPhone = phone.replace(/\D/g, '');

    // Check if the number starts with country code +1 (for US and Canada)
    // and ensure it is exactly 11 digits long (1 for country code + 10 for number)
    return cleanedPhone.startsWith('1') && cleanedPhone.length === 11;
  };

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center pt-10">
        <RegistrationWizardPageTitle>Almost There!</RegistrationWizardPageTitle>
        <div className="mt-10 w-96">
          <div className="flex flex-col gap-y-3">
            <div className="mt-4 text-center font-semibold text-slate-600">
              We update your data once a day automatically. You can always manually update whenever you&apos;d like
            </div>

            <div className="flex justify-center">
              <PreferredTimePicker onSuccess={() => refetchUser()} />
            </div>

            <span className="flex items-center ">
              <InfoIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.info.light }} />
              We recommend setting the time 30-60 minutes before you start your day
            </span>

            <LoadingButton
              variant="contained"
              size="large"
              color="primary"
              className="mt-6"
              onClick={onContinueClicked}
              disabled={!isContinueEnabled}
              loading={isSubmitting}
            >
              Take Me to AdLabs
            </LoadingButton>
          </div>
        </div>
      </div>
    </RegistrationWizardPage>
  );
};

export default RegistrationStepAlmostTherePage;
