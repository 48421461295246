export enum CommonMetricField {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  SAME_SKU_ORDERS = 'sso',
  SAME_SKU_SALES = 'sss',
  ACOS = 'acos',
  ACTC = 'actc',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
  UNITS = 'units',
}

export enum SellerMetricField {
  SELLER_ACOS = 'seller_acos', // Average Cost Of Total Sales - Total Advertising Spend / Total Sales
  // SELLER_ACTC = 'seller_actc', // Sessions (clicks) / orders
  SELLER_AD_SALES_OF_TOTAL = 'seller_ad_sales_of_total',
  SELLER_AOV = 'seller_aov',
  SELLER_ASP = 'seller_asp', // Total average selling price - Total sales / total units
  SELLER_CLICKS = 'seller_clicks',
  SELLER_CPA = 'seller_cpa',
  SELLER_CVR = 'seller_cvr', // Total conversion rate - Total orders / Total clicks (sessions)
  SELLER_ORDERS = 'seller_orders',
  SELLER_ORG_SALES = 'seller_org_sales',
  SELLER_ORG_TRAFFIC = 'seller_org_traffic',
  SELLER_ROAS = 'seller_roas',
  SELLER_SALES = 'seller_sales',
  SELLER_UNIT_SESS = 'seller_unit_sess', // Units per session (UPS) - total units / total clicks (sessions)
  SELLER_UNIT_VIEW = 'seller_unit_view',
  SELLER_UNITS = 'seller_units',
  SELLER_VIEWS = 'seller_views', // Total Page Views
}

export enum VendorMetricField {
  VENDOR_ACOS = 'vendor_acos',
  VENDOR_AD_SALES_OF_TOTAL = 'vendor_ad_sales_of_total',
  VENDOR_ASP = 'vendor_asp',
  VENDOR_CUSTOMER_RETURNS = 'vendor_customer_returns',
  VENDOR_VIEWS = 'vendor_glance_views', //  Changed from "Glance Views" to Total Page Views
  VENDOR_SALES = 'vendor_ordered_revenue', // aka Changed from "Ordered Revenue" to Total Sales
  VENDOR_UNITS = 'vendor_ordered_units', // Changed from "Ordered Units" to Total Units
  VENDOR_ORG_SALES = 'vendor_org_sales', // Ordered Revenue - Total Ad Sales
  VENDOR_RETURN_RATE = 'vendor_return_rate',
  VENDOR_ROAS = 'vendor_roas',
  VENDOR_SHIPPED_COGS = 'vendor_shipped_cogs',
  VENDOR_SHIPPED_REVENUE = 'vendor_shipped_revenue',
  VENDOR_SHIPPED_UNITS = 'vendor_shipped_units',
  VENDOR_UNIT_VIEW = 'vendor_unit_view', // UnitsPerView = units / pageviews
}

export enum ProfileStatsSellerMetricField {
  SELLER_UNITS_REFUNDED = 'seller_units_refunded',
  SELLER_UNITS_REFUND_RATE = 'seller_units_refund_rate',
}

export type CommonWithSellerAndVendorMetricField = CommonMetricField | SellerMetricField | VendorMetricField;

export type MetricField = CommonMetricField | SellerMetricField | VendorMetricField | ProfileStatsSellerMetricField;

export type MetricAggregates = Record<MetricField, { current: number; previous?: number }>;

export function stringToSellerMetricFieldString(key: string): string {
  return 'seller_' + key;
}

export function stringToVendorMetricFieldString(key: string): string {
  return 'vendor_' + key;
}

export function isValidMetricFieldKey(key: string): boolean {
  return (
    Object.values(CommonMetricField).includes(key as CommonMetricField) ||
    Object.values(SellerMetricField).includes(key as SellerMetricField) ||
    Object.values(VendorMetricField).includes(key as VendorMetricField) ||
    Object.values(ProfileStatsSellerMetricField).includes(key as ProfileStatsSellerMetricField)
  );
}
