import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Dimension from './Dimension';
import { CSSProperties, FunctionComponent } from 'react';
import { DragAndDropContainer } from '../types/DragAndDropContainer';
import { Paper, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
interface DimensionSortableProps {
  id: string;
  label: string;
  container: DragAndDropContainer;
}

export const DimensionSortableStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(40%, -40%)',
  borderRadius: '50%',
};

const DimensionSortable: FunctionComponent<DimensionSortableProps> = ({ id, label, container }) => {
  const { setNodeRef, attributes, listeners, transition, transform, isDragging } = useSortable({ id: id });

  if (transform) {
    transform.scaleX = 1;
    transform.scaleY = 1;
  }
  const buttonStyles: CSSProperties = {
    transition,
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.3 : 1,
    display: 'inline-block',
    cursor: 'move',
  };

  return (
    <div ref={setNodeRef} style={buttonStyles} {...attributes} {...listeners}>
      {/* Visible dummy, click is detected in parent */}
      <Paper variant="outlined" style={{ ...DimensionSortableStyles, opacity: isDragging ? 0 : 1 }}>
        <IconButton size="small" style={{ padding: 4 }}>
          {container == DragAndDropContainer.HIDDEN ? <AddIcon fontSize="inherit" /> : <RemoveIcon fontSize="inherit" />}
        </IconButton>
      </Paper>
      <Dimension label={label} isDragging={isDragging} />
    </div>
  );
};

export default DimensionSortable;
