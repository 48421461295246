import AlGrid, { DEFAULT_GRID_OPTIONS, GRID_DEFAULT_SORTING_ORDER } from '@/components/grid/AlGrid';
import { AvatarCellRenderer, IAvatarCellRendererParams } from '@/components/grid/cells/AvatarCellRenderer';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import EditableLinkCallbackCellRenderer, {
  IEditableLinkCallbackCellRendererParams,
} from '@/components/grid/cells/EditableLinkCallbackCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { useEditTeam } from '@/components/modals/delete-confirmation-modal/useEditTeamModal';
import { MuiColorVariant } from '@/config/theme/color.type';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { sleep } from '@/lib/api/api-utils';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { Delete, Edit, InfoOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CellValueChangedEvent, GridReadyEvent } from 'ag-grid-community';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toastService } from '@/services/toast.service';
import { RoleKey } from '../../api/teams.contracts';
import { teamService } from '../../api/teams.service';
import { useActiveTeamContext } from '../../contexts/ActiveTeamContext';
import { TeamMemberModel } from '../../types/TeamMemberModel';
import { MAX_TEAM_NAME_LENGTH, TeamModel } from '../../types/TeamModel';
import { CreateNewTeamDialog } from '../CreateNewTeamDialog';
import { teamsTableDefaultColumnState } from './teams-table.default-column-state';

const TeamsTable = () => {
  const [isCreateNewTeamDialogOpen, setIsCreateNewTeamDialogOpen] = useState(false); // state to control the popover
  const { user, isLoading, refetchUser } = useUserContext();
  const { formatDateAsTimeWithTimezoneNoSeconds, formatCurrency } = useFormatting();
  const { activeTeam, setActiveTeam } = useActiveTeamContext();
  const navigate = useNavigate();

  // CREATE TEAM
  const createButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const onCreateNewTeamClicked = () => {
    setIsCreateNewTeamDialogOpen(true);
  };

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.TEAMS_TABLE_COLUMN_STATE,
    teamsTableDefaultColumnState(),
  );

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  // UPDATE TEAM
  const { mutate: updateTeamName } = useMutation({
    mutationFn: ({ teamId, teamName }: { teamId: number; teamName: string }) => teamService.updateTeamName(teamId, teamName),

    onSuccess: (res, { teamName }) => {
      if (res.isSuccess) {
        toastService.success(`Team name updated to '${teamName}' successfully`);
        refetchUser();
      } else {
        toastService.error(`Team name update failed: ${res.message}`);
      }
    },
  });

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<TeamModel>) => {
    updateTeamName({
      teamId: event.data.id,
      teamName: event.data.name,
    });
  }, []);

  // DELETE TEAM
  const onDeleteClicked = (params: TeamModel) => {
    setDeletePendingTeamId(params.id ?? null);
    openDeleteConfirmationModal();
  };

  const [deletePendingTeamId, setDeletePendingTeamId] = useState<number | null>(null);

  const onDeleteConfirmed = async () => {
    if (user?.teams.length === 1) {
      toastService.error('You cannot delete your last team');
      return;
    }

    if (deletePendingTeamId) {
      const team = user?.teams.find((team) => team.id === deletePendingTeamId);
      if (team) {
        if (team.profiles.length > 0) {
          toastService.error(`Team cannot be deleted because it has ${team.profiles.length} profiles. Please remove the profiles first.`);
        } else {
          deleteTeam(deletePendingTeamId);
          await sleep(200); // wait for the popover close animation to finish, otherwise name will flash 'undefined'
          setDeletePendingTeamId(null);
        }
      }
    }
  };

  const [isLoadingDeleteTeam, setIsLoadingDeleteTeam] = useState(false);

  async function deleteTeam(teamId: number) {
    try {
      setIsLoadingDeleteTeam(true);

      const res = await teamService.deleteTeams([teamId]);

      if (res.isSuccess) {
        toastService.success(`Team deleted successfully`);

        if (teamId == activeTeam?.id) {
          // If current active team is deleted select first team as active team
          if (user) {
            const availableTeam = user.teams.find((team) => team.id !== teamId);
            if (availableTeam) {
              setActiveTeam({ teamId: availableTeam.id });
            }
          }
        }

        refetchUser();
      } else {
        toastService.error(`Team deletion failed: ${res.message}`);
      }
    } catch (error) {
      setIsLoadingDeleteTeam(false);
      console.error(error);
      toastService.error(`Team deletion failed`);
    }
  }

  const deletePendingTeam = user?.teams?.find((data) => data.id === deletePendingTeamId);
  const deleteConfirmationHeaderText = `Delete team '${deletePendingTeam?.name}'?`;
  const deleteConfirmationQuestion = 'Are you sure?';
  const { ModalComponent: DeleteConfirmationModal, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    questionText: deleteConfirmationQuestion,
    headerText: deleteConfirmationHeaderText,
    onDelete: onDeleteConfirmed,
  });

  // Function to determine if the user is the owner of the team
  const isUserOwnerOfTeam = (members: TeamMemberModel[] | undefined) => {
    if (isNil(members)) {
      return false;
    }

    return members.some((member) => member.user.id == user?.id && member.role === RoleKey.OWNER);
  };

  const [editTeamModel, setEditTeamModel] = useState<TeamModel>();
  const { ModalComponent: EditTeamModal, handleOpenModal: openEditTeamModal } = useEditTeam(editTeamModel);
  const onEditMembersClicked = (team: TeamModel | undefined) => {
    if (team) {
      setEditTeamModel(team);
      openEditTeamModal();
    }
  };

  async function onTeamNameClicked(rowData: TeamModel) {
    try {
      if (!rowData.id) {
        return;
      }
      setActiveTeam({ teamId: rowData.id });
      await sleep(50); // wait for 50 ms before navigate
      navigate(Routes.PROFILES);
    } catch (error) {
      console.error(error);
      toastService.error('Unable to navigate to Manage Profiles. Please try again later.');
    }
  }

  const columnDefs: ColDef<TeamModel>[] = useMemo(() => {
    const colDefs: ColDef<TeamModel>[] = [
      {
        colId: ColumnId.ORGANIZATION_NAME,
        headerName: 'Organization',
        width: 130,
        field: 'organizationName',
      },
      {
        colId: ColumnId.TEAM_NAME,
        headerName: 'Name',
        field: 'name',
        width: 400,
        editable: (params) => isUserOwnerOfTeam(params.data?.members),
        cellRenderer: EditableLinkCallbackCellRenderer, //disable if user is not owner of the team
        cellRendererParams: (params: ICellRendererParams): IEditableLinkCallbackCellRendererParams<TeamModel> => {
          return {
            ...params,
            isEditable: isUserOwnerOfTeam(params.data?.members),
            editTooltip: `Click to rename '${params.data.name}'`,
            linkTooltip: `Click to view '${params.data.name}'`,
            hideEditTooltip: !isUserOwnerOfTeam(params.data?.members),
            callback: onTeamNameClicked,
          };
        },
        valueParser: (params) => {
          const newName = params.newValue.trim();

          if (newName.length > MAX_TEAM_NAME_LENGTH) {
            toastService.error(`Team name must be less than ${MAX_TEAM_NAME_LENGTH} characters`);
            return params.oldValue; // Reject the change
          } else if (newName.length === 0) {
            toastService.error('Team name cannot be empty');
            return params.oldValue; // Reject the change
          } else {
            return newName;
          }
        },
      },
      {
        colId: ColumnId.TEAM_OWNER,
        headerName: 'Team Owner',
        width: 130,
        valueGetter: (params) => params.data?.members.find((member) => member.role === RoleKey.OWNER)?.user?.email,
      },
      {
        colId: ColumnId.AMAZON_AUTH,
        headerName: 'Amazon Auth',
        width: 130,
        valueGetter: (params) => params.data?.amazonAccount?.authEmail ?? '',
      },
      {
        colId: ColumnId.TEAM_PLAN,
        headerName: 'Plan',
        field: 'adlabsPlan',
        width: 100,
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<TeamModel>): IChipArrayCellRendererParams => {
          let color: MuiColorVariant = MuiColorVariant.Success;
          let value = 'FREE';

          if (params.data?.isOnLegacyProfilesBasedProPlan) {
            color = MuiColorVariant.Secondary;
            value = 'PRO (Legacy)';
          } else if (params.data?.hasProPlan) {
            color = MuiColorVariant.Primary;
            value = 'PRO';
          }

          return {
            chipArrayChips: [{ color, value }],
          };
        },
      },
      {
        colId: ColumnId.AD_SPEND,
        headerName: 'MTD Ad Spend',
        width: 130,
        field: 'spend',
        valueFormatter: (params) => formatCurrency(params.value, { customCurrencyCode: 'USD' }),
      },
      {
        colId: ColumnId.ESTIMATED_MONTHLY_AD_SPEND,
        headerName: 'Est. Monthly Ad Spend',
        width: 130,
        field: 'estimatedMonthlySpend',
        valueFormatter: (params) => formatCurrency(params.value, { customCurrencyCode: 'USD' }),
      },
      {
        colId: ColumnId.ESTIMATED_COST,
        headerName: 'Est. Pro Plan Cost',
        width: 130,
        field: 'estimatedCost',
        valueFormatter: (params) => formatCurrency(params.value, { customCurrencyCode: 'USD' }),
      },
      {
        colId: ColumnId.TEAM_PROFILES_COUNT,
        headerName: '# of profiles',
        type: 'numericColumn',
        width: 130,
        valueGetter: (params) => params.data?.profiles.length,
      },
      {
        colId: ColumnId.TEAM_PREFERRED_UPDATE_TIME,
        headerName: 'Daily sync time',
        field: 'preferredUpdateTime',
        width: 200,
        valueFormatter: (params) => (params.value ? formatDateAsTimeWithTimezoneNoSeconds(params.value) : 'Not Set'),
        cellClass: (params) => {
          if (!params.value) {
            return 'text-gray-400';
          } else {
            return '';
          }
        },
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        minWidth: 220,
        cellRenderer: ButtonGroupCellRenderer,
        cellRendererParams: (params: ICellRendererParams): IButtonGroupCellRendererParams => {
          if (!isUserOwnerOfTeam(params.data?.members)) {
            return { buttons: [] };
          }

          return {
            buttons: [
              <RowActionButton
                key="edit"
                text="Members"
                color="default"
                isDisabled={isLoadingDeleteTeam}
                tooltipText={`Manage members of '${params.data?.name}'`}
                isLoading={isLoadingDeleteTeam}
                onClick={() => onEditMembersClicked(params.data)}
                icon={<Edit />}
              ></RowActionButton>,
              <RowActionButton
                key="delete"
                text="Delete"
                isLoadingText="Deleting..."
                color="red"
                isDisabled={isLoadingDeleteTeam}
                tooltipText={`Delete '${params.data.name}'`}
                isLoading={isLoadingDeleteTeam}
                onClick={() => onDeleteClicked(params.data)}
                icon={<Delete />}
              ></RowActionButton>,
            ],
          };
        },
      },
      {
        colId: ColumnId.TEAM_MEMBERS,
        headerName: 'Members',
        width: 100,
        cellRenderer: AvatarCellRenderer,
        cellRendererParams: (params: ICellRendererParams<TeamModel>): IAvatarCellRendererParams => {
          return {
            members: params.data?.members ?? ([] as TeamMemberModel[]),
          };
        },
      },
    ];

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  const customGridOptions: GridOptions<TeamModel> = {
    ...DEFAULT_GRID_OPTIONS,
    getRowId: (params) => params.data.id.toString(),
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
      headerComponent: DefaultHeaderRenderer,
      sortingOrder: GRID_DEFAULT_SORTING_ORDER,
    },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
  };

  const onGridReady = (params: GridReadyEvent<TeamModel>) => {
    setColumnStateGridApi(params.api);
  };

  return (
    <>
      <div className="flex flex-row mb-4 gap-x-2">
        <Button ref={createButtonRef} onClick={onCreateNewTeamClicked} variant="contained" startIcon={<AddIcon />}>
          Create New Team
        </Button>
        <div className="flex-grow">{/* Filler div */}</div>
        <span className="ml-2 flex items-center gap-2">
          <InfoOutlined />
          Create additional teams to manage multiple Amazon auth accounts
        </span>
      </div>

      <AlGrid
        colDefs={columnDefs}
        rowData={user?.teams}
        gridOptions={customGridOptions}
        isLoading={isLoading}
        onGridReadyCallback={onGridReady}
        onCellValueChanged={onCellValueChanged}
      />

      <CreateNewTeamDialog
        setIsOpen={setIsCreateNewTeamDialogOpen}
        isOpen={isCreateNewTeamDialogOpen}
        onSuccess={() => {}}
        onError={() => {}}
      />
      {DeleteConfirmationModal}
      {EditTeamModal}
    </>
  );
};

export default TeamsTable;
