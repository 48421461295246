import { useExportToExcel } from '@/modules/data-management/hooks/useExportToExcel';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { toastService } from '@/services/toast.service';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { utils } from 'xlsx';
import { BiddingMethod } from '../api/campaign-mapping-contracts';
import { CampaignMappingModel } from '../models/CampaignMappingModel';

interface DownloadCampaignMappingsButtonProps {
  rowData: CampaignMappingModel[] | undefined;
}

type ExcelDataRow = [
  string, // sourceCampaignName,
  string, // sourceAdGroupName,
  string, // destinationCampaignName,
  string, // destinationAdGroupName,
  BiddingMethod, // biddingMethod,
  string, // biddingMethodValue,
  string, // bidFloor,
  string, // bidCeiling,
  'x' | '', // matchTypes.includes(MatchType.EXACT),
  'x' | '', // matchTypes.includes(MatchType.PHRASE),
  'x' | '', // matchTypes.includes(MatchType.BROAD),
  'x' | '', // matchTypes.includes(MatchType.INDIVIDUAL),
  'x' | '', // matchTypes.includes(MatchType.EXACT),
  'x' | '', // adGroupNegativeExact,
  'x' | '', // adGroupNegativePhrase,
  'x' | '', // adGroupNegativeProductTarget,
  'x' | '', // campaignNegativeExact,
  'x' | '', // campaignNegativePhrase,
  'x' | '', // campaignNegativeProductTarget,
  string, // createdAt,
  string, // updatedAt,
];

type ExcelDataRowObject = {
  sourceCampaignName: string;
  sourceAdGroupName: string;
  destinationCampaignName: string;
  destinationAdGroupName: string;
  biddingMethod: BiddingMethod;
  biddingMethodValue: string;
  bidFloor: string;
  bidCeiling: string;
  exactMatch: 'x' | '';
  phraseMatch: 'x' | '';
  broadMatch: 'x' | '';
  individualProductTarget: 'x' | '';
  expandedProductTarget: 'x' | '';
  adGroupNegativeExact: 'x' | '';
  adGroupNegativePhrase: 'x' | '';
  adGroupNegativeProductTarget: 'x' | '';
  campaignNegativeExact: 'x' | '';
  campaignNegativePhrase: 'x' | '';
  campaignNegativeProductTarget: 'x' | '';
  createdAt: string;
  updatedAt: string;
};

const DownloadCampaignMappingsButton: FunctionComponent<DownloadCampaignMappingsButtonProps> = ({ rowData }) => {
  const { exportToExcel } = useExportToExcel();

  const generateAndDownloadExcel = () => {
    if (!rowData) {
      return;
    }

    // Define the header rows
    const header1 = [
      'Source',
      '',
      'Destination',
      '',
      'Starting Bid',
      '',
      '',
      '',
      'Harvest Match Type',
      '',
      '',
      '',
      '',
      'Negate from Source Campaign?',
      '',
      '',
      'Negate from Source Ad Group?',
      '',
      '',
      'Extra',
      '',
    ];
    const header2 = [
      'Campaign Name',
      'Ad Group Name',
      'Campaign Name',
      'Ad Group Name',
      'Starting Bid Method',
      'Custom Value',
      'Bid Floor (Optional)',
      'Bid Ceiling (Optional)',
      'Exact',
      'Phrase',
      'Broad',
      'Indiv. PT',
      'Expand. PT',
      'Camp. Neg. Exact',
      'Camp. Neg. Phrase',
      'Camp. Neg. PT',
      'Neg. Exact',
      'Neg. Phrase',
      'Neg. PT',
      'Created Date',
      'Updated At',
    ];

    // Convert the data array to worksheet format
    const dataRows: ExcelDataRow[] = [];
    for (const mapping of rowData) {
      // mapping is nested structure, flatten it and merge rows where all of the params (floor, ceiling etc) are the same
      const pendingDataRowsParamsToRowRecord: Record<string, ExcelDataRowObject[]> = {};
      for (const innerMapping of mapping.inner) {
        // Rows that can be merged have the same key, store under the same key and merge later
        const innerMappingKey = `${innerMapping.biddingMethod}-${innerMapping.biddingMethodValue}-${innerMapping.bidFloor}-${innerMapping.bidCeiling}`;
        if (!pendingDataRowsParamsToRowRecord[innerMappingKey]) {
          pendingDataRowsParamsToRowRecord[innerMappingKey] = [];
        }

        // Create unnested individual row objects where mapping and inner are flattened together
        // Store them in a Record where under the same key we have all the rows that can be merged
        pendingDataRowsParamsToRowRecord[innerMappingKey].push({
          sourceCampaignName: mapping.sourceCampaignName,
          sourceAdGroupName: mapping.sourceAdGroupName,
          destinationCampaignName: mapping.destinationCampaignName,
          destinationAdGroupName: mapping.destinationAdGroupName,
          biddingMethod: innerMapping.biddingMethod,
          biddingMethodValue:
            innerMapping.biddingMethodValue == 0 || innerMapping.biddingMethodValue == null ? '' : innerMapping.biddingMethodValue.toString(),
          bidFloor: innerMapping.bidFloor == 0 || innerMapping.bidFloor == null ? '' : innerMapping.bidFloor.toString(),
          bidCeiling: innerMapping.bidCeiling == 0 || innerMapping.bidCeiling == null ? '' : innerMapping.bidCeiling.toString(),
          exactMatch: mapping.matchTypes.includes(MatchType.EXACT) ? 'x' : '',
          phraseMatch: mapping.matchTypes.includes(MatchType.PHRASE) ? 'x' : '',
          broadMatch: mapping.matchTypes.includes(MatchType.BROAD) ? 'x' : '',
          individualProductTarget: mapping.matchTypes.includes(MatchType.INDIVIDUAL) ? 'x' : '',
          expandedProductTarget: mapping.matchTypes.includes(MatchType.EXPANDED) ? 'x' : '',
          campaignNegativeExact: mapping.campaignNegativeExact ? 'x' : '',
          campaignNegativePhrase: mapping.campaignNegativePhrase ? 'x' : '',
          campaignNegativeProductTarget: mapping.campaignNegativeProductTarget ? 'x' : '',
          adGroupNegativeExact: mapping.adGroupNegativeExact ? 'x' : '',
          adGroupNegativePhrase: mapping.adGroupNegativePhrase ? 'x' : '',
          adGroupNegativeProductTarget: mapping.adGroupNegativeProductTarget ? 'x' : '',
          createdAt: mapping.createdAt,
          updatedAt: mapping.updatedAt,
        });
      }

      const mergedRows = mergeMatchDataRowsWithSameParams(pendingDataRowsParamsToRowRecord);

      dataRows.push(...mergedRows);
    }

    function mergeMatchDataRowsWithSameParams(pendingDataRows: Record<string, ExcelDataRowObject[]>): ExcelDataRow[] {
      const mergedRows: ExcelDataRow[] = [];
      for (const key in pendingDataRows) {
        const rowsToMerge = pendingDataRows[key];

        const mergedRow: ExcelDataRow = [
          rowsToMerge[0].sourceCampaignName, // parent level items are all the same, take from first object
          rowsToMerge[0].sourceAdGroupName,
          rowsToMerge[0].destinationCampaignName,
          rowsToMerge[0].destinationAdGroupName,
          rowsToMerge[0].biddingMethod, // params are the same - the whole reason for merging, take from first
          rowsToMerge[0].biddingMethodValue,
          rowsToMerge[0].bidFloor,
          rowsToMerge[0].bidCeiling,
          rowsToMerge.some((row) => row.exactMatch === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.phraseMatch === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.broadMatch === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.individualProductTarget === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.expandedProductTarget === 'x') ? 'x' : '',

          rowsToMerge.some((row) => row.campaignNegativeExact === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.campaignNegativePhrase === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.campaignNegativeProductTarget === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.adGroupNegativeExact === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.adGroupNegativePhrase === 'x') ? 'x' : '',
          rowsToMerge.some((row) => row.adGroupNegativeProductTarget === 'x') ? 'x' : '',
          rowsToMerge.reduce(
            (earliest, obj) => (new Date(obj.createdAt) < new Date(earliest) ? obj.createdAt : earliest),
            rowsToMerge[0].createdAt,
          ),
          rowsToMerge.reduce(
            (latest, obj) => (new Date(obj.updatedAt) > new Date(latest) ? obj.updatedAt : latest),
            rowsToMerge[0].updatedAt,
          ),
        ];

        mergedRows.push(mergedRow);
      }
      return mergedRows;
    }

    // Combine headers and data
    const worksheetData = [header1, header2, ...dataRows];

    // Create worksheet from data
    const worksheet = utils.aoa_to_sheet(worksheetData);

    // Merge cells for the first row
    worksheet['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }, // Source
      { s: { r: 0, c: 2 }, e: { r: 0, c: 3 } }, // Destination
      { s: { r: 0, c: 4 }, e: { r: 0, c: 7 } }, // Starting Bid
      { s: { r: 0, c: 8 }, e: { r: 0, c: 12 } }, // Harvest Match Type
      { s: { r: 0, c: 13 }, e: { r: 0, c: 15 } }, // Negate from Source Ad Group?
      { s: { r: 0, c: 16 }, e: { r: 0, c: 18 } }, // Negate from Source Campaign?
      { s: { r: 0, c: 19 }, e: { r: 0, c: 20 } }, // Extra
    ];

    // Create a new workbook and append the worksheet
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Campaign Mappings');

    // Export the workbook
    exportToExcel(workbook);
  };

  const initGenerationAndDownload = () => {
    try {
      generateAndDownloadExcel();
    } catch (error) {
      console.error(error);
      toastService.error('Failed to generate and download campaign mappings: ' + error);
    }
  };

  return (
    <Button startIcon={<FileDownloadIcon />} variant="outlined" onClick={initGenerationAndDownload}>
      Download As XLSX
    </Button>
  );
};

export default DownloadCampaignMappingsButton;
