import { FilterAlt } from '@mui/icons-material';
import { FunctionComponent } from 'react';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';

interface DashboardWidgetFilterIndicatorProps {
  visible: boolean;
  widgetId: string;
}

const DashboardWidgetFilterIndicator: FunctionComponent<DashboardWidgetFilterIndicatorProps> = ({ visible, widgetId }) => {
  const { editingWidgetId } = useDashboardContextValue((context) => ({
    editingWidgetId: context.editingWidgetId,
  }));

  const isEditMode = editingWidgetId === widgetId;

  if (isEditMode) {
    return null;
  }

  return <div className="pt-2 pr-2 opacity-45 text-md">{visible && <FilterAlt fontSize="inherit" />}</div>;
};

export default DashboardWidgetFilterIndicator;
