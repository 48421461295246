import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useActiveTeamContext } from '../teams/contexts/ActiveTeamContext';
import { AlFetchCache } from './AlFetchCache';

let fetchCacheSingleton: AlFetchCache | null = null;

const useAlFetchCache = () => {
  const { activeProfileIdChange, activeProfile, activeTeam } = useActiveTeamContext();
  const queryClient = useQueryClient();

  const fetchCache = getFetchCache();

  function getQueryClient() {
    return queryClient;
  }

  function getFetchCache() {
    if (!fetchCacheSingleton) {
      fetchCacheSingleton = new AlFetchCache(getQueryClient, activeProfile?.id, activeTeam?.id);
    }

    return fetchCacheSingleton;
  }

  useEffect(() => {
    if (!(activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id)) {
      return;
    }

    fetchCache.setActiveProfileId(activeProfile.id);
  }, [activeProfileIdChange]);

  useEffect(() => {
    fetchCache.setActiveTeamId(activeTeam?.id);
  }, [activeTeam?.id]);

  return {
    fetchCache,
  };
};

export default useAlFetchCache;
