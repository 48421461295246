import { UnitType } from '@/components/metrics/MetricsConfig';

import { AlDate } from '@/lib/date/AlDate';
import { AlFetchCache } from '@/modules/al-fetch-cache/AlFetchCache';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { AmazonBrandsBidOptimization, PlacementType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { ContextKey } from '@/types/context-shared';
import { isNumber } from 'lodash-es';
import { FilterConditionDTO, FilterDTO } from '../api/filters-contracts';
import { FilterKey } from '../types/FilterKey';
import {
  AcosFilterModel,
  AcotsFilterModel,
  AdGroupFilterModel,
  AdGroupNameFilterModel,
  AdGroupNameNotFilterModel,
  AdGroupStateFilterModel,
  AdSalesOfTotalFilterModel,
  AlFilterModel,
  AovFilterModel,
  AspFilterModel,
  BidAdjustmentLastOptimizedAtFilterModel,
  BidAdjustmentTypeFilterModel,
  BidFilterModel,
  BidOptimizationFilterModel,
  BidStrategyFilterModel,
  BiddingMethodFilterModel,
  BooleanType,
  BudgetFilterModel,
  CampaignAdTypeFilterModel,
  CampaignDataGroupItemFilterModel,
  CampaignEndDateFilterModel,
  CampaignFilterModel,
  CampaignGroupFilterModel,
  CampaignGroupNameFilterModel,
  CampaignGroupNameNotFilterModel,
  CampaignLastOptimizedAtFilterModel,
  CampaignNameFilterModel,
  CampaignNameNotFilterModel,
  CampaignStartDateFilterModel,
  CampaignStateFilterModel,
  ClicksFilterModel,
  ComparisonDateFilterModel,
  CostTypeFilterModel,
  CpaFilterModel,
  CpcFilterModel,
  CpmFilterModel,
  CreatedAtFilterModel,
  CreativeTypeFilterModel,
  CtrFilterModel,
  CustomerReturnsFilterModel,
  CvrFilterModel,
  DateFilterModel,
  DeltaFilterModel,
  DestinationAdGroupNameFilterModel,
  DestinationAdGroupNameNotFilterModel,
  DestinationCampaignAdTypeFilterModel,
  DestinationCampaignNameFilterModel,
  DestinationCampaignNameNotFilterModel,
  EmptyFilterModel,
  EntityTypeFilterModel,
  FilterArguments,
  FilterParamsWithTeamProfiles,
  ImpressionsFilterModel,
  LogicalOperatorType,
  MatchTypeFilterModel,
  MultiAdGroupsEnabledFilterModel,
  NegativeKeywordMatchTypeFilterModel,
  NegativeTargetLevelFilterModel,
  NegativeTargetingExactMatchFilterModel,
  NegativeTargetingFilterModel,
  NegativeTargetingNotFilterModel,
  OptimizationReasonFilterModel,
  VendorTotalSalesFilterModel,
  VendorTotalUnitsFilterModel,
  OrdersFilterModel,
  OrganicSalesFilterModel,
  OrganicTrafficFilterModel,
  PlacementTypeFilterModel,
  PortfolioFilterModel,
  ProductAsinFilterModel,
  ProductAvailabilityFilterModel,
  ProductBrandFilterModel,
  ProductCategoryFilterModel,
  ProductDataGroupItemFilterModel,
  ProductParentAsinFilterModel,
  ProductSkuFilterModel,
  ProductTitleFilterModel,
  ProductTitleNotFilterModel,
  ProfileFilterModel,
  ReturnRateFilterModel,
  RoasFilterModel,
  RpcFilterModel,
  SalesFilterModel,
  SameSkuOrdersFilterModel,
  SameSkuSalesFilterModel,
  SearchTermDataGroupItemFilterModel,
  SearchTermExactMatchFilterModel,
  SearchTermFilterModel,
  SearchTermHarvestedFilterModel,
  SearchTermNegatedFilterModel,
  SearchTermNotFilterModel,
  ShippedCogsFilterModel,
  ShippedRevenueFilterModel,
  ShippedUnitsFilterModel,
  SourceAdGroupNameFilterModel,
  SourceAdGroupNameNotFilterModel,
  SourceCampaignAdTypeFilterModel,
  SourceCampaignNameFilterModel,
  SourceCampaignNameNotFilterModel,
  SpendFilterModel,
  TargetDataGroupItemFilterModel,
  TargetLastOptimizedAtFilterModel,
  TargetStateFilterModel,
  TargetTypeFilterModel,
  TargetingExactMatchFilterModel,
  TargetingFilterModel,
  TargetingNotFilterModel,
  TargetingTypeFilterModel,
  TeamFilterModel,
  TotalAovFilterModel,
  TotalClicksFilterModel,
  TotalCpaFilterModel,
  TotalCvrFilterModel,
  TotalOrdersFilterModel,
  TotalPageViewsFilterModel,
  TotalRoasFilterModel,
  TotalSalesFilterModel,
  TotalUnitsFilterModel,
  UnitsFilterModel,
  UnitsPerPageViewFilterModel,
  UpsFilterModel,
  VendorTotalPageViewsFilterModel,
  ActcFilterModel,
} from './AlFilterModel';

interface FilterFactory {
  createFilterModelFromDTO(
    dto: FilterDTO,
    fetchCache: AlFetchCache,
    options?: { contextKey?: string; teamProfiles?: TeamProfile[] },
  ): AlFilterModel;
}

// TODO: Use record utility type to avoid missing filter keys
class FilterFactoryImp implements FilterFactory {
  createFilterModelFromDTO(
    dto: FilterDTO,
    fetchCache: AlFetchCache,
    options?: { contextKey?: string; teamProfiles?: TeamProfile[] },
  ): AlFilterModel {
    switch (dto.key as FilterKey) {
      case FilterKey.DATE:
        return new DateFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions.map((cond) => {
            return {
              values: cond.values.map((value) => AlDate.parse(value.toString()).toDefaultFormat()),
              operator: cond.operator,
            };
          }),
        });

      case FilterKey.COMPARE_DATE:
        return new ComparisonDateFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions.map((cond) => {
            return {
              values: cond.values.map((value) => AlDate.parse(value.toString()).toDefaultFormat()),
              operator: cond.operator,
            };
          }),
        });

      case FilterKey.TARGET_STATE:
        return createFilterWithTransformedConditions(dto, TargetStateFilterModel);

      case FilterKey.TARGET_LAST_OPTIMIZED_AT:
        return createFilterWithTransformedConditions(dto, TargetLastOptimizedAtFilterModel);

      case FilterKey.PORTFOLIO_ID:
        return new PortfolioFilterModel({
          portfolios: [],
          args: {
            logicalOperator: dto.logical_operator as LogicalOperatorType,
            conditions: dto.conditions,
          },
        });

      case FilterKey.BID:
        return createFilterWithTransformedConditions(dto, BidFilterModel);

      case FilterKey.BIDDING_METHOD:
        return createFilterWithTransformedConditions(dto, BiddingMethodFilterModel);

      case FilterKey.CAMPAIGN_AD_TYPE: {
        const excludeDisplayAndTV = options?.contextKey == ContextKey.SEARCH_TERMS;

        return new CampaignAdTypeFilterModel(excludeDisplayAndTV, {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });
      }

      case FilterKey.CAMPAIGN_ID:
        return new CampaignFilterModel({
          campaigns: [],
          args: {
            logicalOperator: dto.logical_operator as LogicalOperatorType,
            conditions: dto.conditions,
          },
          teamProfiles: options?.teamProfiles,
        });

      case FilterKey.CAMPAIGN_NAME:
        return createFilterWithTransformedConditions(dto, CampaignNameFilterModel);

      case FilterKey.SOURCE_CAMPAIGN_NAME:
        return createFilterWithTransformedConditions(dto, SourceCampaignNameFilterModel);

      case FilterKey.DESTINATION_CAMPAIGN_NAME:
        return createFilterWithTransformedConditions(dto, DestinationCampaignNameFilterModel);

      case FilterKey.CAMPAIGN_NAME_NOT:
        return createFilterWithTransformedConditions(dto, CampaignNameNotFilterModel);

      case FilterKey.SOURCE_CAMPAIGN_NAME_NOT:
        return createFilterWithTransformedConditions(dto, SourceCampaignNameNotFilterModel);

      case FilterKey.DESTINATION_CAMPAIGN_NAME_NOT:
        return createFilterWithTransformedConditions(dto, DestinationCampaignNameNotFilterModel);

      case FilterKey.SEARCH_TERM:
        return createFilterWithTransformedConditions(dto, SearchTermFilterModel);

      case FilterKey.SEARCH_TERM_NOT:
        return createFilterWithTransformedConditions(dto, SearchTermNotFilterModel);

      case FilterKey.SEARCH_TERM_EXACT_MATCH:
        return createFilterWithTransformedConditions(dto, SearchTermExactMatchFilterModel);

      case FilterKey.SEARCH_TERM_NEGATED:
        return createFilterWithTransformedConditions(dto, SearchTermNegatedFilterModel);

      case FilterKey.SEARCH_TERM_HARVESTED:
        return createFilterWithTransformedConditions(dto, SearchTermHarvestedFilterModel);

      case FilterKey.CAMPAIGN_GROUP_NAME:
        return createFilterWithTransformedConditions(dto, CampaignGroupNameFilterModel);

      case FilterKey.CAMPAIGN_GROUP_NAME_NOT:
        return createFilterWithTransformedConditions(dto, CampaignGroupNameNotFilterModel);

      case FilterKey.CAMPAIGN_GROUP: {
        return new CampaignGroupFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,

          // condition values are saved as strings in local storage as the use the  toDTO() function
          conditions: dto.conditions.map((condition) => {
            return {
              ...condition,
              values: condition.values.map((value) => (isNumber(value) ? value : parseInt(value))),
            };
          }),
        });
      }

      case FilterKey.MULTI_AD_GROUPS_ENABLED:
        return createFilterWithTransformedConditions(dto, MultiAdGroupsEnabledFilterModel);

      case FilterKey.AD_GROUP_ID:
        return new AdGroupFilterModel([], fetchCache, {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.AD_GROUP_NAME:
        return createFilterWithTransformedConditions(dto, AdGroupNameFilterModel);

      case FilterKey.SOURCE_AD_GROUP_NAME:
        return createFilterWithTransformedConditions(dto, SourceAdGroupNameFilterModel);

      case FilterKey.DESTINATION_AD_GROUP_NAME:
        return createFilterWithTransformedConditions(dto, DestinationAdGroupNameFilterModel);

      case FilterKey.AD_GROUP_NAME_NOT:
        return createFilterWithTransformedConditions(dto, AdGroupNameNotFilterModel);

      case FilterKey.AD_GROUP_STATE:
        return createFilterWithTransformedConditions(dto, AdGroupStateFilterModel);

      case FilterKey.SOURCE_AD_GROUP_NAME_NOT:
        return createFilterWithTransformedConditions(dto, SourceAdGroupNameNotFilterModel);

      case FilterKey.DESTINATION_AD_GROUP_NAME_NOT:
        return createFilterWithTransformedConditions(dto, DestinationAdGroupNameNotFilterModel);

      case FilterKey.SOURCE_CAMPAIGN_AD_TYPE:
        return createFilterWithTransformedConditions(dto, SourceCampaignAdTypeFilterModel);

      case FilterKey.DESTINATION_CAMPAIGN_AD_TYPE:
        return createFilterWithTransformedConditions(dto, DestinationCampaignAdTypeFilterModel);

      case FilterKey.CAMPAIGN_STATE: {
        return createFilterWithTransformedConditions(dto, CampaignStateFilterModel);
      }

      case FilterKey.TARGETING_TYPE:
        return createFilterWithTransformedConditions(dto, TargetingTypeFilterModel);

      case FilterKey.TARGET_TYPE:
        return createFilterWithTransformedConditions(dto, TargetTypeFilterModel);

      case FilterKey.CAMPAIGN_LAST_OPTIMIZED_AT:
        // TODO IS changed into BETWEEN
        return createFilterWithTransformedConditions(dto, CampaignLastOptimizedAtFilterModel);

      case FilterKey.BUDGET:
        return createFilterWithTransformedConditions(dto, BudgetFilterModel);

      case FilterKey.CREATIVE_TYPE:
        return createFilterWithTransformedConditions(dto, CreativeTypeFilterModel);

      case FilterKey.COST_TYPE:
        return createFilterWithTransformedConditions(dto, CostTypeFilterModel);

      case FilterKey.PLACEMENT_TYPE: {
        // See also PlacementTypeFilterModel
        const conditions = dto.conditions.map((cond) => {
          return {
            operator: cond.operator,
            values: cond.values.filter((v) => v != PlacementType.OTHER && v != PlacementType.DETAIL_PAGE),
          } as FilterConditionDTO;
        });

        return new PlacementTypeFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions,
        });
      }

      case FilterKey.BID_ADJUSTMENT:
        return createFilterWithTransformedConditions(dto, BidAdjustmentTypeFilterModel);

      case FilterKey.BID_ADJUSTMENT_LAST_OPTIMIZED_AT:
        return createFilterWithTransformedConditions(dto, BidAdjustmentLastOptimizedAtFilterModel);

      case FilterKey.BID_OPTIMIZATION:
        // custom conditions
        dto.conditions.forEach((cond) => {
          if (cond.values[0] == BooleanType.TRUE) {
            cond.values[0] = AmazonBrandsBidOptimization.AUTO;
          } else if (cond.values[0] == BooleanType.FALSE) {
            cond.values[0] = AmazonBrandsBidOptimization.MANUAL;
          }
        });

        return createFilterWithTransformedConditions(dto, BidOptimizationFilterModel);

      case FilterKey.BID_STRATEGY:
        return createFilterWithTransformedConditions(dto, BidStrategyFilterModel);

      case FilterKey.CAMPAIGN_START_DATE:
        return createFilterWithTransformedConditions(dto, CampaignStartDateFilterModel);

      case FilterKey.CAMPAIGN_END_DATE:
        return createFilterWithTransformedConditions(dto, CampaignEndDateFilterModel);

      case FilterKey.IMPRESSIONS:
        return createFilterWithTransformedConditions(dto, ImpressionsFilterModel);

      case FilterKey.CLICKS:
        return createFilterWithTransformedConditions(dto, ClicksFilterModel);

      case FilterKey.ORDERS:
        return createFilterWithTransformedConditions(dto, OrdersFilterModel);

      case FilterKey.UNITS:
        return createFilterWithTransformedConditions(dto, UnitsFilterModel);

      case FilterKey.CTR:
        return createFilterWithTransformedConditions(dto, CtrFilterModel);

      case FilterKey.CVR:
        return createFilterWithTransformedConditions(dto, CvrFilterModel);

      case FilterKey.CPC:
        return createFilterWithTransformedConditions(dto, CpcFilterModel);

      case FilterKey.SPEND:
        return createFilterWithTransformedConditions(dto, SpendFilterModel);

      case FilterKey.SALES:
        return createFilterWithTransformedConditions(dto, SalesFilterModel);

      case FilterKey.ACOS:
        return createFilterWithTransformedConditions(dto, AcosFilterModel);

      case FilterKey.ACTC:
        return createFilterWithTransformedConditions(dto, ActcFilterModel);

      case FilterKey.ROAS:
        return createFilterWithTransformedConditions(dto, RoasFilterModel);

      case FilterKey.RPC:
        return createFilterWithTransformedConditions(dto, RpcFilterModel);

      case FilterKey.CPA:
        return createFilterWithTransformedConditions(dto, CpaFilterModel);

      case FilterKey.AOV:
        return createFilterWithTransformedConditions(dto, AovFilterModel);

      case FilterKey.CPM:
        return createFilterWithTransformedConditions(dto, CpmFilterModel);

      case FilterKey.DELTA:
        return createFilterWithTransformedConditions(dto, DeltaFilterModel);

      case FilterKey.REASON:
        return createFilterWithTransformedConditions(dto, OptimizationReasonFilterModel);

      case FilterKey.ENTITY_TYPE:
        return createFilterWithTransformedConditions(dto, EntityTypeFilterModel);

      case FilterKey.MATCH_TYPE:
        return new MatchTypeFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TARGETING:
        return createFilterWithTransformedConditions(dto, TargetingFilterModel);

      case FilterKey.TARGETING_NOT:
        return createFilterWithTransformedConditions(dto, TargetingNotFilterModel);

      case FilterKey.TARGETING_EXACT_MATCH:
        return createFilterWithTransformedConditions(dto, TargetingExactMatchFilterModel);

      case FilterKey.NEGATIVE_TARGETING:
        return createFilterWithTransformedConditions(dto, NegativeTargetingFilterModel);

      case FilterKey.NEGATIVE_TARGETING_NOT:
        return createFilterWithTransformedConditions(dto, NegativeTargetingNotFilterModel);

      case FilterKey.NEGATIVE_TARGETING_EXACT_MATCH:
        return createFilterWithTransformedConditions(dto, NegativeTargetingExactMatchFilterModel);

      case FilterKey.CAMPAIGN_DATA_GROUP_ITEM:
        return new CampaignDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.TARGET_DATA_GROUP_ITEM:
        return new TargetDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SEARCH_TERM_DATA_GROUP_ITEM:
        return new SearchTermDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CREATED_AT:
        return createFilterWithTransformedConditions(dto, CreatedAtFilterModel);

      case FilterKey.NEGATIVE_KEYWORD_MATCH_TYPE:
        return createFilterWithTransformedConditions(dto, NegativeKeywordMatchTypeFilterModel);

      case FilterKey.NEGATIVE_TARGETING_LEVEL:
        return createFilterWithTransformedConditions(dto, NegativeTargetLevelFilterModel);

      case FilterKey.PRODUCT_TITLE:
        return createFilterWithTransformedConditions(dto, ProductTitleFilterModel);

      case FilterKey.PRODUCT_TITLE_NOT:
        return createFilterWithTransformedConditions(dto, ProductTitleNotFilterModel);

      case FilterKey.PRODUCT_ASIN:
        return createProfilesDependentFilterWithTransformedConditions(dto, ProductAsinFilterModel, options?.teamProfiles);

      case FilterKey.PRODUCT_PARENT_ASIN:
        return createProfilesDependentFilterWithTransformedConditions(dto, ProductParentAsinFilterModel, options?.teamProfiles);

      case FilterKey.PRODUCT_SKU:
        return createProfilesDependentFilterWithTransformedConditions(dto, ProductSkuFilterModel, options?.teamProfiles);

      case FilterKey.PRODUCT_CATEGORY:
        return createProfilesDependentFilterWithTransformedConditions(dto, ProductCategoryFilterModel, options?.teamProfiles);

      case FilterKey.PRODUCT_BRAND:
        return createProfilesDependentFilterWithTransformedConditions(dto, ProductBrandFilterModel, options?.teamProfiles);

      case FilterKey.PRODUCT_DATA_GROUP_ITEM:
        return new ProductDataGroupItemFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PRODUCT_AVAILABILITY:
        return createFilterWithTransformedConditions(dto, ProductAvailabilityFilterModel);

      case FilterKey.ACOTS:
        return createFilterWithTransformedConditions(dto, AcotsFilterModel);

      // case FilterKey.TOTAL_ACTC:
      //   return createFilterWithTransformedConditions(dto, TotalActcFilterModel);

      case FilterKey.ASP:
        return createFilterWithTransformedConditions(dto, AspFilterModel);

      case FilterKey.TCPA:
        return createFilterWithTransformedConditions(dto, TotalCpaFilterModel);

      case FilterKey.TROAS:
        return createFilterWithTransformedConditions(dto, TotalRoasFilterModel);

      case FilterKey.TAOV:
        return createFilterWithTransformedConditions(dto, TotalAovFilterModel);

      case FilterKey.AD_SALES_OF_TOTAL:
        return createFilterWithTransformedConditions(dto, AdSalesOfTotalFilterModel);

      case FilterKey.UPPW:
        return createFilterWithTransformedConditions(dto, UnitsPerPageViewFilterModel);

      case FilterKey.ORGANIC_SALES:
        return createFilterWithTransformedConditions(dto, OrganicSalesFilterModel);

      case FilterKey.ORGANIC_TRAFFIC:
        return createFilterWithTransformedConditions(dto, OrganicTrafficFilterModel);

      case FilterKey.TOTAL_VIEWS:
        return createFilterWithTransformedConditions(dto, TotalPageViewsFilterModel);

      case FilterKey.TOTAL_UNITS:
        return createFilterWithTransformedConditions(dto, TotalUnitsFilterModel);

      case FilterKey.UPS:
        return createFilterWithTransformedConditions(dto, UpsFilterModel);

      case FilterKey.TCVR:
        return createFilterWithTransformedConditions(dto, TotalCvrFilterModel);

      case FilterKey.TOTAL_CLICKS:
        return createFilterWithTransformedConditions(dto, TotalClicksFilterModel);

      case FilterKey.TOTAL_ORDERS:
        return createFilterWithTransformedConditions(dto, TotalOrdersFilterModel);

      case FilterKey.TOTAL_SALES:
        return createFilterWithTransformedConditions(dto, TotalSalesFilterModel);

      case FilterKey.SAME_SKU_ORDERS:
        return createFilterWithTransformedConditions(dto, SameSkuOrdersFilterModel);

      case FilterKey.SAME_SKU_SALES:
        return createFilterWithTransformedConditions(dto, SameSkuSalesFilterModel);

      case FilterKey.TEAM:
        return new TeamFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.PROFILE:
        return new ProfileFilterModel([], {
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.GLANCE_VIEWS:
        return new VendorTotalPageViewsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.CUSTOMER_RETURNS:
        return new CustomerReturnsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ORDERED_REVENUE:
        return new VendorTotalSalesFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.ORDERED_UNITS:
        return new VendorTotalUnitsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SHIPPED_COGS:
        return new ShippedCogsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SHIPPED_REVENUE:
        return new ShippedRevenueFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.SHIPPED_UNITS:
        return new ShippedUnitsFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      case FilterKey.RETURN_RATE:
        return new ReturnRateFilterModel({
          logicalOperator: dto.logical_operator as LogicalOperatorType,
          conditions: dto.conditions,
        });

      default:
        // TODO: should we ever return empty?
        return new EmptyFilterModel();
    }
  }
}

// Singleton pattern
export const filterFactory = new FilterFactoryImp();

function createFilterWithTransformedConditions<T extends AlFilterModel>(
  dto: FilterDTO,
  model: { new (args?: FilterArguments): T; unitType?: UnitType },
): T {
  let conditions = dto.conditions;
  if (model.unitType == UnitType.PERCENTAGE && dto.key != FilterKey.BID_ADJUSTMENT) {
    conditions = conditionValuesToPercentage(dto.conditions);
  }

  return new model({
    logicalOperator: dto.logical_operator as LogicalOperatorType,
    conditions,
  });
}

function createProfilesDependentFilterWithTransformedConditions<T extends AlFilterModel>(
  dto: FilterDTO,
  model: { new (params?: FilterParamsWithTeamProfiles): T; unitType?: UnitType },
  teamProfiles?: TeamProfile[],
): T {
  let conditions = dto.conditions;
  if (model.unitType == UnitType.PERCENTAGE && dto.key != FilterKey.BID_ADJUSTMENT) {
    conditions = conditionValuesToPercentage(dto.conditions);
  }

  return new model({
    teamProfiles,
    args: {
      logicalOperator: dto.logical_operator as LogicalOperatorType,
      conditions,
    },
  });
}

function conditionValuesToPercentage(conditions: FilterConditionDTO[]) {
  return conditions.map((condition) => {
    return {
      ...condition,
      values: condition.values.map((value) => ratioToPercentage(value)),
    };
  });
}

function ratioToPercentage(value: number | string): string {
  if (isNumber(value)) {
    return (value * 100).toString();
  }
  return (parseFloat(value) * 100).toString();
}
