import { filtersService } from '@/components/filter-builder/api/filters-service';
import useFilters, { extractDateFiltersFromFilters } from '@/components/filter-builder/hooks/useFilters';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { GridToggles } from '@/components/grid/types';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { NavPayload } from '@/router/NavPayload';
import { MetricsGraphTablePageContext, PageConfig } from '@/types/context-shared';
import { isEmpty, isEqual } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import useMetricsInContext from './useMetricsInContext';

const useMetricChartTablePageVariables = (pageConfig: PageConfig): MetricsGraphTablePageContext => {
  const location = useLocation();
  const navPayload = location.state as NavPayload;

  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();

  // FILTERS
  const { fetchCache } = useAlFetchCache();

  const mergePagePayloadFiltersWithExistingDateFiltersIfPayload = useCallback(
    (existingFilters: AlFilterModel[]) => {
      if (!navPayload?.filters) return existingFilters;

      const payloadKeys = new Set(navPayload.filters.map((f) => f.key));

      // Don't add date filters which are already in the payload: allow optionally override existing date filters with nav filters
      const dateFilters = extractDateFiltersFromFilters(existingFilters).filter((f) => !payloadKeys.has(f.key));
      return [...navPayload.filters, ...dateFilters];
    },
    [navPayload?.filters],
  );

  const [filters, setFiltersInternal] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render

    const savedFilters = filtersService.loadProfileFilters(
      pageConfig.contextKey,
      activeProfile?.id ?? '',
      pageConfig.defaultFilters,
      fetchCache,
    );
    return mergePagePayloadFiltersWithExistingDateFiltersIfPayload(savedFilters);
  });

  function isFiltersEnabledFunc(filters: AlFilterModel[]) {
    return !isEmpty(filters);
  }

  const isFiltersEnabled = isFiltersEnabledFunc(filters);
  function disableFilters() {
    setFilters([]);
  }

  function setFilters(newFilters: AlFilterModel[] | ((prevFilters: AlFilterModel[]) => AlFilterModel[])) {
    setFiltersInternal((prevFilters) => {
      // Wrap filter setting while maintaining access to previous filters
      const updatedFilters = typeof newFilters === 'function' ? newFilters(prevFilters) : newFilters;

      // Empty filter array (doesn't contain even date filters) is a special state indicating that no api calls should be made - should not save those
      if (isFiltersEnabledFunc(updatedFilters) && activeProfile?.id) {
        filtersService.saveProfileFilters(pageConfig.contextKey, activeProfile.id, updatedFilters);
      }

      return updatedFilters; // Update state
    });
  }

  const { setFilterValue, setFilterValues } = useFilters({ filters, setFilters });

  const { visibleMetrics, setVisibleMetrics } = useMetricsInContext(pageConfig.metricsUserSettingKey, pageConfig.availableMetrics);

  const [gridToggles, setGridToggles] = useState<GridToggles>(pageConfig.gridToggles);

  const isMountedRef = useRef(false);
  const isMounted = isMountedRef.current;

  useEffect(() => {
    if (!isMounted) return;

    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(
        pageConfig.contextKey,
        activeProfile?.id,
        pageConfig.defaultFilters,
        fetchCache,
      );

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return {
    filters,
    setFilters,
    setFilterValue,
    setFilterValues,
    visibleMetrics,
    setVisibleMetrics,
    gridToggles,
    setGridToggles,
    isFiltersEnabled,
    disableFilters,
    availableMetrics: pageConfig.availableMetrics,
  };
};

export default useMetricChartTablePageVariables;
