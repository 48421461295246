import { SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY } from '@/modules/users/api/users/users.contracts';
import { CountryCode, getSortedCountryCodes } from '@/modules/users/types/CountryCode';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Button, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { isEmpty, isNil } from 'lodash-es';
import { useState } from 'react';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';

export interface SellingPartnerAuthorizationDialogProps {
  open: boolean;
  onClose: () => void;
  getAuthUrl: (market: string) => Promise<string>;
  isVendorCentral?: boolean;
}

export function SellingPartnerAuthorizationDialog({
  onClose,
  open,
  getAuthUrl,
  isVendorCentral = false,
}: SellingPartnerAuthorizationDialogProps) {
  const { activeTeam } = useActiveTeamContext();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGetAuthUrlLoading, setIsGetAuthUrlLoading] = useState(false);
  const [countryCode, setCountryCode] = useState<string>(CountryCode.US);

  const handleClose = () => {
    onClose();
  };

  function setError(message: string) {
    setIsError(true);
    setErrorMessage(message);
  }

  function resetError() {
    setIsError(false);
    setErrorMessage('');
  }

  async function onAuthorizeClicked() {
    resetError();

    if (isNil(activeTeam)) {
      setError('Active team not found');
      return;
    }

    try {
      setIsGetAuthUrlLoading(true);
      localStorage.setItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY, countryCode);
      const authUrl = await getAuthUrl(countryCode);

      if (isEmpty(authUrl)) {
        setIsError(true);
        setErrorMessage('Something went wrong. Auth url is empty. Please try again later.');
      } else {
        window.open(authUrl, '_blank'); // Open the URL in a new tab
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsGetAuthUrlLoading(false);
    }
  }

  const priorityOrder = [CountryCode.US, CountryCode.UK, CountryCode.CA, CountryCode.MX];
  const availableCountryCodes = getSortedCountryCodes(priorityOrder);

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'xs'}>
        <DialogTitle>{`Connect ${isVendorCentral ? 'Vendor' : 'Seller'} Central: Select Region`}</DialogTitle>

        <DialogContent>
          {isError && (
            <Alert className="mb-4" severity="error">
              {errorMessage}
            </Alert>
          )}

          <Autocomplete
            options={availableCountryCodes}
            value={countryCode}
            onChange={(event, newValue) => {
              if (newValue) {
                setCountryCode(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Region" />}
          />
        </DialogContent>

        <DialogActions className="mb-2 px-4">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            className="w-52"
            variant="contained"
            color="primary"
            onClick={onAuthorizeClicked}
            loading={isGetAuthUrlLoading}
            disabled={isGetAuthUrlLoading}
          >
            Authorize
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
