import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { getDefaultPlacementsFilters } from '@/components/filter-builder/models/AlFilterModel';
import MetricsContainer from '@/components/metrics/MetricsContainer';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { VideoUrl } from '@/config/urls';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import useMetricChartTablePageVariables from '@/hooks/useMetricChartTablePageVariables';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { useUserContext } from '@/modules/users/contexts/UserContext';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { ContextKey, DEFAULT_GRID_TOGGLES } from '@/types/context-shared';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import { createPlacementsWithTimelineQueryKey, placementsService } from '../api/placements-service';
import PlacementsFilterBar from '../components/PlacementsFilterBar';
import PlacementsSelectionActionsBar from '../components/PlacementsSelectionActionsBar';
import PlacementsTable from '../components/placements-table/PlacementsTable';
import { SelectedPlacement } from '../models/PlacementsModel';

const PlacementsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { refetchUser } = useUserContext();

  const pageVariables = useMetricChartTablePageVariables({
    contextKey: ContextKey.PLACEMENTS,
    metricsUserSettingKey: UserSettingKey.VISIBLE_METRICS_PLACEMENTS,
    defaultFilters: getDefaultPlacementsFilters(),
    gridToggles: DEFAULT_GRID_TOGGLES,
  });

  usePaywallManager({
    route: Routes.PLACEMENTS,
    message: 'Upgrade to Pro to Analyze Campaign Placement Performance',
    videoUrl: VideoUrl.PLACEMENTS,
    canUsePage: (team) => team.subscriptionPlan.canUsePlacementsPage,
  });

  const [selectedPlacements, setSelectedPlacements] = useState<SelectedPlacement[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const placementsTableGridApiRef = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedPlacements([]);
    if (placementsTableGridApiRef.current && !placementsTableGridApiRef.current.isDestroyed()) {
      placementsTableGridApiRef.current.deselectAll();
    }
  }

  const placementsWithTimelineQueryKey = createPlacementsWithTimelineQueryKey(activeProfile?.id, pageVariables.filters);
  const {
    data: placementsWithTimeline,
    isLoading: isPlacementsRowDataLoading,
    isError: isPlacementsLoadingError,
    error: placementsLoadingError,
    isFetching,
  } = useQuery({
    queryKey: placementsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await placementsService.getPlacementsWithTimeline(pageVariables.filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading Placements ' + JSON.stringify(result));
      }
    },
    enabled: pageVariables.isFiltersEnabled && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching placements', isFetching);

  function onPlacementsTableGridReady(params: GridReadyEvent) {
    placementsTableGridApiRef.current = params.api;
  }

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(UserSettingKey.PLACEMENTS_TUTORIAL_DISPLAY_COUNT);

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  return (
    <>
      <PageLayout showFullscreen={isTableExpanded}>
        {!isTableExpanded && (
          <PageLayoutTopBar
            header={
              <div className="flex flex-row items-center gap-4">
                {t('placements')}
                <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                  How to Use Placements
                </Button>
              </div>
            }
            actions={
              <div className="flex flex-row items-center gap-2">
                {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
                <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
                <TeamSelect />
                <ProfileSyncSelectButton disableFilters={pageVariables.disableFilters} />{' '}
              </div>
            }
          ></PageLayoutTopBar>
        )}

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
            {!isTableExpanded && (
              <div className="mt-2">
                <AlErrorBoundary>
                  <MetricsContainer
                    metricValues={placementsWithTimeline?.metrics}
                    isLoading={isPlacementsRowDataLoading}
                    isError={isPlacementsLoadingError}
                    error={placementsLoadingError}
                    visibleMetrics={pageVariables.visibleMetrics}
                    setVisibleMetrics={pageVariables.setVisibleMetrics}
                    showComparison={pageVariables.gridToggles.comparisonUnit != 'hidden'}
                  />
                </AlErrorBoundary>
              </div>
            )}

            {!isTableExpanded && (
              <div className="my-2">
                <AlErrorBoundary>
                  <MetricTimelineChart
                    visibleMetrics={pageVariables.visibleMetrics}
                    timelineData={placementsWithTimeline?.timeline}
                    isLoading={isPlacementsRowDataLoading}
                    isError={isPlacementsLoadingError}
                    error={placementsLoadingError}
                  />
                </AlErrorBoundary>
              </div>
            )}

            <AlErrorBoundary>
              <PlacementsFilterBar
                withTimeline={placementsWithTimeline}
                pageVariables={pageVariables}
                gridApiRef={placementsTableGridApiRef}
                isExpanded={isTableExpanded}
                onExpandTable={toggleTableExpanded}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <PlacementsTable
                withTimeline={placementsWithTimeline}
                isLoading={isPlacementsRowDataLoading}
                selectedPlacements={selectedPlacements}
                setSelectedPlacements={setSelectedPlacements}
                PlacementsLoadingErrorMessage={placementsLoadingError instanceof Error ? placementsLoadingError.message : ''}
                isPlacementsLoadingError={isPlacementsLoadingError}
                pageVariables={pageVariables}
                onGridReadyCallback={onPlacementsTableGridReady}
                noTopBorderRadius={true}
                isExpanded={isTableExpanded}
              />
            </AlErrorBoundary>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <PlacementsSelectionActionsBar
          selectedPlacements={selectedPlacements}
          totalPlacementsCount={placementsWithTimeline?.placements ? placementsWithTimeline.placements.length : 0}
          onDiscardClicked={clearSelections}
        />
      </AlErrorBoundary>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.PLACEMENTS}
        title={'How to Use the Placements Tab'}
      />
    </>
  );
};

export default PlacementsPage;
