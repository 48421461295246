import React from 'react';
import { DndContext, DragOverlay, useSensor, useSensors, PointerSensor, DragStartEvent, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import { SortablePlaceholder } from './SortablePlaceholder';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ColumnConfiguration } from './column-categories';

interface SortableColumnListProps {
  columns: ColumnConfiguration[];
  onReorder: (updatedColumns: ColumnConfiguration[]) => void;
}

export const SortableColumnList: React.FC<SortableColumnListProps> = ({ columns, onReorder }) => {
  const [activeId, setActiveId] = React.useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      setActiveId(null);
      return;
    }

    const draggedColumn = columns.find((col) => col.columnID === active.id);
    const sourceGroup = columns.some((col) => col.columnID === active.id && col.pinned) ? 'pinned' : 'unpinned';
    const targetGroup =
      over.id === 'pinned-placeholder' || columns.some((col) => col.columnID === over.id && col.pinned) ? 'pinned' : 'unpinned';

    // Prevent moving locked columns to invalid positions
    if (draggedColumn?.lockPinned && targetGroup === 'unpinned') {
      setActiveId(null);
      return;
    }

    if (sourceGroup === targetGroup) {
      // Reorder within the same group
      const groupColumns = columns.filter((col) => (sourceGroup === 'pinned' ? col.pinned : !col.pinned));
      const oldIndex = groupColumns.findIndex((col) => col.columnID === active.id);
      const newIndex = groupColumns.findIndex((col) => col.columnID === over.id);

      const reorderedGroup = arrayMove(groupColumns, oldIndex, newIndex);

      const updatedColumns = [
        ...(sourceGroup === 'pinned' ? reorderedGroup : columns.filter((col) => col.pinned)),
        ...(sourceGroup === 'unpinned' ? reorderedGroup : columns.filter((col) => !col.pinned)),
      ];

      onReorder(updatedColumns);
    } else {
      // Move between groups
      const updatedColumns = [...columns];
      const draggedColumnIndex = updatedColumns.findIndex((col) => col.columnID === active.id);
      const draggedColumn = updatedColumns.splice(draggedColumnIndex, 1)[0];
      draggedColumn.pinned = targetGroup === 'pinned';

      if (over.id === 'pinned-placeholder') {
        updatedColumns.unshift(draggedColumn);
      } else if (over.id === 'unpinned-placeholder') {
        updatedColumns.push(draggedColumn);
      } else {
        const targetIndex = updatedColumns.findIndex((col) => col.columnID === over.id);
        updatedColumns.splice(targetIndex, 0, draggedColumn);
      }

      onReorder(updatedColumns);
    }

    setActiveId(null);
  };

  const handleRemove = (columnID: string) => {
    const updatedColumns = columns.map((col) => (col.columnID === columnID ? { ...col, isSelected: false } : col));

    onReorder(updatedColumns);
  };

  const pinnedColumns = columns.filter((col) => col.pinned && col.isSelected);
  const unpinnedColumns = columns.filter((col) => !col.pinned && col.isSelected);

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <div className="flex flex-col gap-0.5 pb-4">
        {/* Pinned Group */}
        <div className="px-3 pt-2 pb-2 border-b border-fuchsia-200 bg-fuchsia-50 bg-opacity-60 gap-y-1.5 flex flex-col">
          <div className="text-xs font-bold uppercase text-fuchsia-900 mb-1">Pinned</div>
          <SortableContext items={pinnedColumns.length > 0 ? pinnedColumns.map((item) => item.columnID) : ['pinned-placeholder']}>
            {pinnedColumns.length === 0 ? (
              <SortablePlaceholder id="pinned-placeholder" title="Drag columns here to Pin" />
            ) : (
              pinnedColumns.map((item) => (
                <SortableItem
                  key={item.columnID}
                  id={item.columnID}
                  title={item.title}
                  onRemove={() => handleRemove(item.columnID)}
                  className={item.headerClassName}
                  lockPosition={item.lockPosition}
                  lockvisible={item.lockVisible}
                />
              ))
            )}
          </SortableContext>
        </div>

        {/* Unpinned Group */}
        <div className="px-3 py-2 gap-y-1.5 flex flex-col">
          <div className="text-xs font-bold uppercase text-gray-500 mb-1">Unpinned</div>
          <SortableContext items={unpinnedColumns.length > 0 ? unpinnedColumns.map((item) => item.columnID) : ['unpinned-placeholder']}>
            {unpinnedColumns.length === 0 ? (
              <SortablePlaceholder
                id="unpinned-placeholder"
                title="Select a column to add them to the table"
                textClassName="text-gray-500"
                bgClassName="bg-gray-100"
                borderClassName="border-gray-300"
              />
            ) : (
              unpinnedColumns.map((item) => (
                <SortableItem
                  key={item.columnID}
                  id={item.columnID}
                  title={item.title}
                  onRemove={() => handleRemove(item.columnID)}
                  className={item.headerClassName}
                />
              ))
            )}
          </SortableContext>
        </div>
      </div>

      <DragOverlay>
        {activeId ? (
          <div className="flex items-center bg-primary-200 border-dashed border-primary-400 border px-2 rounded h-8 cursor-move">
            <DragIndicatorIcon fontSize="small" className="pr-1 text-gray-400" />
            <span className="text-sm font-medium pl-2">{columns.find((item) => item.columnID === activeId)?.title}</span>
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
