import { SelectChangeEvent } from '@mui/material';
import { DateRange, DateRangePicker, DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { isNil } from 'lodash-es';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { AlDate, AlDateRawType } from '../../../lib/date/AlDate';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel, FilterCondition, LogicalOperatorType, OperatorType } from '../models/AlFilterModel';
import { DateOperatorType } from '../types/Operators';
import { DateOperatorSelect } from './DateOperatorSelect';

const DEFAULT_START_DATE_LABEL = 'Start Date';
const DEFAULT_END_DATE_LABEL = 'End Date';

interface FilterDateSelectProps {
  filter: AlFilterModel;
  isOperatorDisabled?: boolean;
}
export const FilterDateSelect: FunctionComponent<FilterDateSelectProps> = ({ filter, isOperatorDisabled = false }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [startDateLabel, setStartDateLabel] = useState(DEFAULT_START_DATE_LABEL);
  const [endDateLabel, setEndDateLabel] = useState(DEFAULT_END_DATE_LABEL);

  // TODO: Merge start and end condition + logical operator to one object
  const [startDate, setStartDate] = useState<AlDate | null>(
    filter.conditions && filter.conditions?.length > 0 ? AlDate.parse(filter.conditions[0].values[0].toString()) : null,
  );
  const [startDateOperator, setStartDateOperator] = useState<OperatorType>(
    filter.conditions && filter.conditions?.length > 0 ? filter.conditions[0].operator : filter.defaultConditionOperators[0],
  );

  const [endDate, setEndDate] = useState<AlDate | null>(
    filter.conditions && filter.conditions?.length > 1 ? AlDate.parse(filter.conditions[1].values[0].toString()) : null,
  );
  const [endDateOperator, setEndDateOperator] = useState<OperatorType>(
    filter.conditions && filter.conditions?.length > 1 ? filter.conditions[1].operator : filter.defaultConditionOperators[1],
  );

  const [logicalOperator, setLogicalOperator] = useState<LogicalOperatorType>(filter.logicalOperator ?? LogicalOperatorType.AND);

  let initialDateOperator = DateOperatorType.BETWEEN;
  switch (filter.conditions ? filter.conditions[0].operator : OperatorType.GREATER_THAN_OR_EQUAL) {
    case OperatorType.GREATER_THAN_OR_EQUAL:
      initialDateOperator = DateOperatorType.BETWEEN;
      break;
    case OperatorType.LESS_THAN:
      initialDateOperator = DateOperatorType.BEFORE;
      break;
    case OperatorType.GREATER_THAN:
      initialDateOperator = DateOperatorType.AFTER;
      break;
    case OperatorType.EQUAL:
      initialDateOperator = DateOperatorType.EQUAL;
      break;
    case OperatorType.IS_NULL:
      initialDateOperator = DateOperatorType.NEVER;
      break;
    default:
      initialDateOperator = DateOperatorType.BETWEEN;
      break;
  }

  const [defaultDateOperator, setDefaultDateOperator] = useState<DateOperatorType | null>();
  if (isNil(defaultDateOperator)) {
    setDefaultDateOperator(initialDateOperator);
  }

  const [dateOperator, setDateOperator] = useState<DateOperatorType>(initialDateOperator);

  const handleOperatorChange = (event: SelectChangeEvent) => {
    const operator = event.target.value as DateOperatorType;
    setDateOperator(operator);

    if (operator == DateOperatorType.BETWEEN) {
      setStartDateOperator(OperatorType.GREATER_THAN_OR_EQUAL);
      setEndDateOperator(OperatorType.LESS_THAN_OR_EQUAL);
      setLogicalOperator(LogicalOperatorType.AND);
      setStartDateLabel(DEFAULT_START_DATE_LABEL);
      setEndDateLabel(DEFAULT_END_DATE_LABEL);
    } else if (operator == DateOperatorType.BEFORE) {
      setStartDateOperator(OperatorType.LESS_THAN);
      setLogicalOperator(LogicalOperatorType.AND);
      setStartDateLabel('Before Date');
      setEndDate(null);
    } else if (operator == DateOperatorType.AFTER) {
      setStartDateOperator(OperatorType.GREATER_THAN);
      setLogicalOperator(LogicalOperatorType.AND);
      setStartDateLabel('After Date');
      setEndDate(null);
    } else if (operator == DateOperatorType.EQUAL) {
      setStartDateOperator(OperatorType.EQUAL);
      setLogicalOperator(LogicalOperatorType.AND);
      setStartDateLabel('Date');
      setEndDate(null);
    } else if (operator == DateOperatorType.NEVER) {
      setStartDateOperator(OperatorType.IS_NULL);
      setLogicalOperator(LogicalOperatorType.AND);
      setEndDate(null);
    }
  };

  const handleStartDateChange = (value: AlDateRawType | null) => {
    const wrappedValue = value ? AlDate.fromRaw(value) : null;
    if (dateOperator == DateOperatorType.AFTER) {
      setStartDate(wrappedValue?.endOf('day') ?? null);
    } else {
      setStartDate(wrappedValue?.startOf('day') ?? null);
    }
  };

  const handleDateRangeChange = (dateRange: DateRange<AlDateRawType>) => {
    setStartDate(AlDate.fromRaw(dateRange[0])?.startOf('day') ?? null);
    setEndDate(AlDate.fromRaw(dateRange[1])?.endOf('day') ?? null);
  };

  useEffect(() => {
    const conditions: FilterCondition[] = [];

    conditions.push({
      values: [!isNil(startDate) ? startDate.toDefaultFormat() : ''],
      operator: startDateOperator,
    });

    if (!isNil(endDate)) {
      conditions.push({
        values: [endDate.toDefaultFormat()],
        operator: endDateOperator,
      });
    }

    filter.logicalOperator = logicalOperator;
    filter.conditions = conditions;
    setFilterValue(filter);
  }, [startDate, endDate, startDateOperator, endDateOperator]);

  return (
    <div className="flex flex-row gap-2">
      <div className={'w-28'}>
        {defaultDateOperator && (
          <DateOperatorSelect
            label={'Operator'}
            isDisabled={isOperatorDisabled}
            filterKey={filter.key}
            defaultValue={defaultDateOperator}
            handleChange={handleOperatorChange}
          />
        )}
      </div>
      {dateOperator == DateOperatorType.BETWEEN && (
        <div className="mt-2 flex-1">
          <DateRangePicker
            defaultValue={[startDate?.toRaw() ?? null, endDate?.toRaw() ?? null]}
            slotProps={{
              textField: ({ position }) => ({
                label: position === 'start' ? startDateLabel : endDateLabel,
              }),
            }}
            onChange={handleDateRangeChange}
            disableFuture
          />
        </div>
      )}

      {dateOperator != DateOperatorType.BETWEEN && dateOperator != DateOperatorType.NEVER && (
        <div className="flex-1">
          <DesktopDatePicker
            label={startDateLabel}
            value={startDate?.toRaw() ?? null}
            open={isOpen}
            onChange={handleStartDateChange}
            className="w-40"
            disableFuture
            onClose={() => {
              setIsOpen(false);
            }}
            onAccept={() => {
              setIsOpen(false);
            }}
            slotProps={{
              textField: () => ({
                onClick: () => {
                  setIsOpen(true);
                },
              }),
            }}
          />
        </div>
      )}
    </div>
  );
};
