import InfoMessage from '@/components/feedback/InfoMessage';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import AlSelect from '@/components/form/AlSelect';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import PopoverLikePopper from '@/components/PopoverLikePopper';
import { useTranslation } from '@/lib';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, SelectChangeEvent } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent, RefObject, useState } from 'react';
import {
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignUpdateDTO,
  EnabledPausedArchivedState,
} from '../api/campaign/campaign-contracts';
import { campaignService, createCampaignsWithTimelineQueryKey } from '../api/campaign/campaign-service';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import useCampaignWarnings from '../hooks/useCampaignWarnings';
import { NO_CHANGE, NoChange } from '../types/no_change';

const ARCHIVE_COUNT_TO_SHOW_WARNING = 1;

interface CampaignBulkActionsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedCampaigns: SelectedCampaignDTO[];
  isOpen: boolean;
  onClose: () => void;
  filters: AlFilterModel[];
}

const CampaignBulkEditPopover: FunctionComponent<CampaignBulkActionsPopoverProps> = ({
  selectedCampaigns,
  isOpen,
  onClose,
  buttonRef,
  filters,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();
  const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);

  // Post update modal
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [updatedCampaignsResponse, setUpdateCampaignsResponse] = useState<UpdateResponseDTO>();

  const STATE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: EnabledPausedArchivedState.ENABLED, label: 'Enabled' },
    { value: EnabledPausedArchivedState.PAUSED, label: 'Paused' },
    { value: EnabledPausedArchivedState.ARCHIVED, label: 'Archived' },
  ];

  const BID_STRATEGY_TYPE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: BidStrategyType.LEGACY_FOR_SALES, label: t(`enums.bid_strategy.${BidStrategyType.LEGACY_FOR_SALES}`) },
    { value: BidStrategyType.AUTO_FOR_SALES, label: t(`enums.bid_strategy.${BidStrategyType.AUTO_FOR_SALES}`) },
    { value: BidStrategyType.MANUAL, label: t(`enums.bid_strategy.${BidStrategyType.MANUAL}`) },
    //{ value: BidStrategyType.RULE_BASED, label: t(`enums.bid_strategy.${BidStrategyType.RULE_BASED}`) },
  ];

  const BID_OPTIMIZATION = [
    { value: NO_CHANGE, label: 'No change' },
    { value: AmazonBrandsBidOptimization.AUTO, label: 'Auto' },
    { value: AmazonBrandsBidOptimization.MANUAL, label: 'Manual' },
  ];

  const [campaignState, setCampaignState] = useState<EnabledPausedArchivedState | NoChange>(NO_CHANGE);
  const [campaignBiddingStrategy, setCampaignBiddingStrategy] = useState<BidStrategyType | NoChange>(NO_CHANGE);
  const [bidOptimization, setBidOptimization] = useState<AmazonBrandsBidOptimization | NoChange>(NO_CHANGE);
  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);

  function hasCampaignEnded(campaign: SelectedCampaignDTO) {
    return !isNil(campaign.endDate) && new Date(campaign.endDate) < new Date();
  }

  const [pendingCampaignUpdates, setPendingCampaignUpdates] = useState<CampaignUpdateDTO[]>([]);

  const { ModalComponent: archivingManyCampaignsModalComponent, handleOpenModal: handleArchivingManyCampaignsModalHandleOpen } =
    useDeleteConfirmation({
      questionText: `We want to double confirm, are you sure you want to archive ${pendingCampaignUpdates.length} ${pendingCampaignUpdates.length == 1 ? 'campaign' : 'campaigns'}? This action cannot be undone.`,
      headerText: 'Archiving Campaigns',
      onDelete: () => {
        applyCampaignUpdates(pendingCampaignUpdates);
      },
      confirmButtonText: `Confirm Archiving ${pendingCampaignUpdates.length} Campaigns`,
    });

  function handleApplyChanges() {
    setIsApplyInProgress(true);

    const filteredSelectedCampaigns = selectedCampaigns.filter(
      (campaign) => campaign.state !== EnabledPausedArchivedState.ARCHIVED && !hasCampaignEnded(campaign),
    );

    const campaignUpdates: CampaignUpdateDTO[] = filteredSelectedCampaigns.map((campaign) => {
      const update: CampaignUpdateDTO = {
        campaign_id: campaign.id,
      };

      if (campaignState !== NO_CHANGE) {
        update.state = campaignState as EnabledPausedArchivedState;
      }

      if (campaignBiddingStrategy !== NO_CHANGE) {
        update.dynamic_bid_strategy = campaignBiddingStrategy as BidStrategyType;
      }

      if (bidOptimization !== NO_CHANGE) {
        update.bid_optimization = bidOptimization as AmazonBrandsBidOptimization;
      }

      return update as CampaignUpdateDTO;
    });

    if (campaignState === EnabledPausedArchivedState.ARCHIVED && campaignUpdates.length >= ARCHIVE_COUNT_TO_SHOW_WARNING) {
      setPendingCampaignUpdates(campaignUpdates);
      handleArchivingManyCampaignsModalHandleOpen();
    } else {
      applyCampaignUpdates(campaignUpdates);
    }
    onClose();
    setIsApplyInProgress(false);
  }

  async function applyCampaignUpdates(campaignUpdates: CampaignUpdateDTO[]) {
    const response = await campaignService.updateCampaigns(campaignUpdates, FlowType.CAMPAIGN_OPTIMIZER);

    if (response.isSuccess) {
      setUpdateCampaignsResponse({ responseErrorMsg: null, payload: [response?.payload] });
    } else {
      setIsApplyInProgress(false);
      setUpdateCampaignsResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
    }
    setUpdateResponseModalOpen(true);

    // Reset all fields
    setCampaignState(NO_CHANGE);
    setCampaignBiddingStrategy(NO_CHANGE);

    queryClient.invalidateQueries({
      queryKey: campaignsWithTimelineQueryKey,
    });
    onClose();
    setIsApplyInProgress(false);
  }

  function handleCampaignStateChange(event: SelectChangeEvent<EnabledPausedArchivedState | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignState(value);
    } else {
      setCampaignState(value as EnabledPausedArchivedState);
    }
  }

  function onBidStrategyTypeChange(event: SelectChangeEvent<BidStrategyType | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignBiddingStrategy(value);
    } else {
      setCampaignBiddingStrategy(value as BidStrategyType);
    }
  }

  function handleBidOptimizationChange(event: SelectChangeEvent<AmazonBrandsBidOptimization | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setBidOptimization(value);
    } else {
      setBidOptimization(value as AmazonBrandsBidOptimization);
    }
  }

  const { warnings, productCampaignsCount, brandCampaignsCount, endedArchivedCampaignsCount } = useCampaignWarnings({
    selectedCampaigns,
    campaignState,
    campaignBiddingStrategy,
    bidOptimization,
  });

  return (
    <>
      <PopoverLikePopper open={isOpen} anchorEl={buttonRef.current} onClose={onClose}>
        <DialogTitle id="edit-selection-modal-title">Edit Selected Campaigns</DialogTitle>

        <DialogContent className="space-y-8">
          <span>
            You have selected {selectedCampaigns.length} {selectedCampaigns.length == 1 ? 'Campaign' : 'Campaigns'} to edit.
          </span>

          <AlSelect
            label={'Campaign State'}
            value={campaignState}
            options={STATE_OPTIONS}
            disabled={selectedCampaigns.length == endedArchivedCampaignsCount}
            onChange={handleCampaignStateChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
          />
          <AlSelect
            label={'(SP) Campaign Bidding Strategy'}
            value={campaignBiddingStrategy}
            options={BID_STRATEGY_TYPE_OPTIONS}
            onChange={onBidStrategyTypeChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            helperWarningText={
              campaignBiddingStrategy === BidStrategyType.AUTO_FOR_SALES
                ? 'AdLabs works best with Fixed Bids or Dynamic Down only'
                : undefined
            }
            disabled={productCampaignsCount == 0 || selectedCampaigns.length == endedArchivedCampaignsCount} // bid strategy is only for sponsored products
          />
          <AlSelect
            label={'(SB) Amazon Bid Optimization'}
            value={bidOptimization}
            options={BID_OPTIMIZATION}
            onChange={handleBidOptimizationChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            disabled={brandCampaignsCount == 0 || selectedCampaigns.length == endedArchivedCampaignsCount}
            helperWarningText={
              bidOptimization === AmazonBrandsBidOptimization.AUTO ? 'Cannot modify placements if bid optimization turned on' : undefined
            }
          />
        </DialogContent>

        <Divider />
        {warnings.length > 0 && (
          <div className="flex flex-col mx-5">
            {warnings.map((warning, index) => (
              <InfoMessage key={index} text={warning} />
            ))}
          </div>
        )}

        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isApplyInProgress}
            onClick={handleApplyChanges}
            disabled={campaignState === NO_CHANGE && campaignBiddingStrategy === NO_CHANGE && bidOptimization === NO_CHANGE}
          >
            Apply Changes
          </LoadingButton>
        </DialogActions>
      </PopoverLikePopper>

      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={updatedCampaignsResponse}
      />

      {archivingManyCampaignsModalComponent}
    </>
  );
};

export default CampaignBulkEditPopover;
