import { Fragment, FunctionComponent } from 'react';
import { List, ListSubheader, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ColumnConfigurationCategory, ColumnCategoryID, ColumnGroupID, ColumnConfigurationGroup } from './column-categories';

interface MetricCategoryListProps {
  categoryGroups: ColumnConfigurationCategory[];
  selectedColumnGroupID: ColumnGroupID;
  onCategorySelect: (
    category: ColumnConfigurationCategory,
    selectedColumnGroupID: ColumnGroupID,
    columnGroups: ColumnConfigurationGroup[],
  ) => void;
}

const MetricCategoryList: FunctionComponent<MetricCategoryListProps> = ({ categoryGroups, selectedColumnGroupID, onCategorySelect }) => {
  return (
    <List>
      {/* Hardcode the 'All' option */}
      <ListItemButton
        selected={selectedColumnGroupID === ColumnGroupID.ALL}
        onClick={() => onCategorySelect(categoryGroups[0], ColumnGroupID.ALL, categoryGroups[0].columnGroups)}
        className="py-0"
      >
        <ListItemText
          primary="All"
          slotProps={{ primary: { className: `text-sm ${selectedColumnGroupID === ColumnGroupID.ALL ? 'text-primary-500' : 'text-black'}` } }}
        />
        <ListItemIcon>
          <div className="flex items-center justify-end w-full">
            <ChevronRightIcon />
          </div>
        </ListItemIcon>
      </ListItemButton>
      {categoryGroups.map((category) => (
        <Fragment key={category.id}>
          {category.id !== ColumnCategoryID.ALL && (
            <>
              <ListSubheader className="pb-1.5 text-gray-400 leading-none uppercase text-xxs font-bold tracking-wider pt-4">
                {category.title}
              </ListSubheader>
              {category.columnGroups.map((group) => (
                <ListItemButton
                  key={group.id}
                  selected={selectedColumnGroupID === group.id}
                  onClick={() => onCategorySelect(category, group.id, category.columnGroups)}
                  className="py-0"
                >
                  <ListItemText
                    primary={group.title}
                    slotProps={{
                      primary: { className: `text-sm ${selectedColumnGroupID === group.id ? 'text-primary-500' : 'text-black'}` },
                    }}
                  />
                  <ListItemIcon>
                    <div className="flex items-center justify-end w-full">
                      <ChevronRightIcon />
                    </div>
                  </ListItemIcon>
                </ListItemButton>
              ))}
            </>
          )}
        </Fragment>
      ))}
    </List>
  );
};

export default MetricCategoryList;
