import ExportGridButton from '@/components/buttons/ExportGridButton';
import FormatterToggleButton from '@/components/buttons/FormatterToggleButton';
import SortByToggleButton from '@/components/buttons/SortByToggleButton';
import UnitToggleButton from '@/components/buttons/UnitToggleButton';
import { ColDef, ColGroupDef, GridApi } from 'ag-grid-enterprise';
import { ComparisonUnit, FormatterType, GridToggles, SortByUnit } from '../types';
import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import ColumnConfigurationButton from './ColumnConfigurationButton';
import { isNil, isString } from 'lodash-es';
import { COLUMN_CONFIGURATION_METADATA, ColumnConfiguration } from './column-categories.tsx';
import { ColumnId } from '../columns/columns.enum';

interface GridToolsProps<T> {
  gridToggles: GridToggles;
  setGridToggles: Dispatch<SetStateAction<GridToggles>>;
  gridApiRef: MutableRefObject<GridApi<T> | undefined | null>;
  overlayColumnGrouping?: boolean;
}

const GridTools = <T,>({ gridToggles, setGridToggles, gridApiRef, overlayColumnGrouping = false }: GridToolsProps<T>) => {
  const [selectedColumns, setSelectedColumns] = useState<ColumnConfiguration[]>([]);

  // Type guard to check if the column is a ColDef
  function isColDef(column: ColDef | ColGroupDef): column is ColDef {
    return 'field' in column || 'valueGetter' in column || 'headerName' in column;
  }

  const updateSelectedColumns = () => {
    if (gridApiRef.current) {
      const columns = gridApiRef.current.getColumnDefs();
      if (columns) {
        const updatedColumns = columns.filter(isColDef).map<ColumnConfiguration>((column) => ({
          title: column.headerName || '',
          headerClassName: isString(column.headerClass) ? column.headerClass : '',
          headerTooltip: column.headerTooltip || '',
          pinned: column.pinned,
          isSelected: !column.hide || false,
          columnID: column.colId as ColumnId,
          tooltip: COLUMN_CONFIGURATION_METADATA[column.colId as ColumnId]?.tooltip || undefined,
          lockPinned: column.lockPinned || false,
          lockPosition: column.lockPosition || false,
          lockVisible: column.lockVisible || false,
        }));
        setSelectedColumns(updatedColumns);
      }
    }
  };

  const handleSelectedColumnsChange = (newColumnOrderAndVisibility: ColumnConfiguration[]) => {
    if (!isNil(gridApiRef) && !isNil(gridApiRef.current) && gridApiRef.current.applyColumnState) {
      const currentCols = gridApiRef.current.getColumnState();
      // sort based on the order of the new columns
      const newColumns = newColumnOrderAndVisibility.map((newColumn) => {
        const currentCol = currentCols.find((col) => col.colId === newColumn.columnID);
        if (currentCol) {
          currentCol.pinned = newColumn.pinned;
          currentCol.hide = !newColumn.isSelected;

          return currentCol;
        }
        return {
          colId: newColumn.columnID,
          pinned: newColumn.pinned,
          hide: !newColumn.isSelected,
        };
      });

      gridApiRef.current.applyColumnState({ state: newColumns, applyOrder: true });
    }
  };

  const handleComparisonUnitChange = (newValue: ComparisonUnit) => {
    setGridToggles((previous) => ({ ...previous, comparisonUnit: newValue }));
  };

  const handleSortChange = (newValue: SortByUnit) => {
    setGridToggles((previous) => ({ ...previous, sortByUnit: newValue }));
  };

  const handleFormatterChange = (newValue: FormatterType) => {
    setGridToggles((previous) => ({ ...previous, formatterType: newValue }));
  };

  return (
    <div className={`absolute -bottom-[37px] right-2 justify-between z-10 flex gap-x-4 bg-gray-50 ${overlayColumnGrouping && 'left-0'}`}>
      <div className="flex-1"></div>
      <div className="flex gap-x-4 items-center">
        {gridToggles.comparisonUnit && (
          <UnitToggleButton handleComparisonUnitChange={handleComparisonUnitChange} comparisonUnit={gridToggles.comparisonUnit} />
        )}
        {gridToggles.sortByUnit && gridToggles.comparisonUnit && (
          <SortByToggleButton
            handleSortChange={handleSortChange}
            sortBy={gridToggles.sortByUnit}
            comparisonUnit={gridToggles.comparisonUnit}
          />
        )}
        {gridToggles.formatterType && (
          <FormatterToggleButton handleFormatterChange={handleFormatterChange} formatterType={gridToggles.formatterType} />
        )}
        <ExportGridButton gridApiRef={gridApiRef} />

        <ColumnConfigurationButton
          onSelectedColumnsChange={handleSelectedColumnsChange}
          selectedColumns={selectedColumns}
          onColumnConfigurationModalOpen={updateSelectedColumns}
        />
      </div>
    </div>
  );
};

export default GridTools;
