import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, ReactNode, useState } from 'react';

type IdToCountRecord = {
  [id: string]: number;
};

interface ShowXTimesAlertByIdProps {
  id: string | undefined;
  timesToDisplay: number;
  title: ReactNode;
  content: ReactNode;
  userSettingKey: UserSettingKey;
  isAlertEnabled?: boolean; // optional extra conditions, when this is false the alert will not be shown and the count will not be incremented
}

// Map of id to count
const ShowXTimesAlertById: FunctionComponent<ShowXTimesAlertByIdProps> = ({
  id,
  timesToDisplay,
  title,
  content,
  userSettingKey,
  isAlertEnabled = true,
}) => {
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();
  const [idToCountRecord, setIdToCountRecord] = useState(fetchIdToCountRecord());
  const [hasClosedThisSession, setHasClosedThisSession] = useState(false);

  function fetchIdToCountRecord() {
    const idToCountRecord = getUserSettingValueByKey<IdToCountRecord>(userSettingKey);
    return idToCountRecord || {};
  }

  function updateCount(newCount: number) {
    if (!id) {
      return;
    }

    setIdToCountRecord((prevIdToCountRecord) => {
      const updatedRecord = {
        ...prevIdToCountRecord,
        [id]: newCount,
      };

      upsertUserSetting(userSettingKey, updatedRecord);

      return updatedRecord;
    });
  }

  function onClose() {
    // Increment the count and update the setting
    const newCount = (currentCount || 0) + 1;
    updateCount(newCount);

    setHasClosedThisSession(true);
  }

  const currentCount = id ? (idToCountRecord[id] ?? 0) : 0;
  const isShowingAlert = !isNil(id) && isAlertEnabled && currentCount < timesToDisplay && !hasClosedThisSession;

  return isShowingAlert ? (
    <Alert
      className="py-4 mt-2"
      severity="info"
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  ) : null;
};

export default ShowXTimesAlertById;
