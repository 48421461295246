import DropdownButton, { DropdownButtonItem } from '@/components/buttons/DropdownButton';
import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { useTheming } from '@/config/theme/useTheming';
import { ColorModeContext } from '@/modules/application';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { CreateNegativesDirectModal } from '@/modules/negative-targets/components/add-negatives/CreateNegativesDirectModal';
import BulkCreateTargetsModal from '@/modules/targeting/components/BulkCreateTargetsModal';
import { ThemeProvider } from '@emotion/react';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { FunctionComponent, SetStateAction, useContext, useState } from 'react';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';

interface CampaignBulkCreateDropdownButtonProps {
  selectedCampaigns: SelectedCampaignDTO[];
}

const CampaignBulkCreateDropdownButton: FunctionComponent<CampaignBulkCreateDropdownButtonProps> = ({ selectedCampaigns }) => {
  const [isCreateNegativesDirectModalOpen, setIsCreateNegativesDirectModalOpen] = useState(false);
  const [isCreateTargetsModalOpen, setIsCreateTargetsModalOpen] = useState(false);

  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [applyResponse, setApplyResponse] = useState<UpdateResponseDTO>();

  const items: DropdownButtonItem[] = [
    {
      label: 'Add Targets',
      onClick: () => setIsCreateTargetsModalOpen(true),
      startIcon: <ModeStandbyIcon />,
    },
    {
      label: 'Add Negative Targets',
      onClick: () => setIsCreateNegativesDirectModalOpen(true),
      startIcon: <PlaylistRemoveIcon />,
    },
  ];

  function onApplied(applyResponse: SetStateAction<UpdateResponseDTO | undefined>) {
    setApplyResponse(applyResponse);
    setUpdateResponseModalOpen(true);
  }

  const preset = selectedCampaigns.length > 0 ? { sourceCampaignIds: selectedCampaigns.map((campaign) => campaign.id) } : undefined;

  // Theming, because it's inside OppositeModeOverlayBar, the theme needs to be opposite again
  const { mode } = useContext(ColorModeContext);
  const { colorMode } = useTheming();
  return (
    <>
      <DropdownButton size="small" items={items} startIcon={<NoteAddIcon />}>
        Bulk Add Targets
      </DropdownButton>
      <ColorModeContext.Provider
        value={{ toggleColorMode: colorMode.toggleColorMode, mode: mode === 'light' ? 'dark' : 'light', isDarkMode: mode === 'light' }}
      >
        <ThemeProvider theme={mode === 'light' ? DARK_THEME : BASE_THEME}>
          {isCreateNegativesDirectModalOpen && (
            <CreateNegativesDirectModal
              isOpen={isCreateNegativesDirectModalOpen}
              onClose={() => setIsCreateNegativesDirectModalOpen(false)}
              onApplied={onApplied}
              flowType={FlowType.CAMPAIGN_OPTIMIZER}
              preset={preset}
            />
          )}

          {isCreateTargetsModalOpen && (
            <BulkCreateTargetsModal
              isOpen={isCreateTargetsModalOpen}
              onClose={() => setIsCreateTargetsModalOpen(false)}
              onApplied={onApplied}
              preset={preset}
            />
          )}

          {updateResponseModalOpen && (
            <UpdateResponseModal
              isOpen={updateResponseModalOpen}
              onClose={() => setUpdateResponseModalOpen(false)}
              updateResponse={applyResponse}
            />
          )}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default CampaignBulkCreateDropdownButton;
