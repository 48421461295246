import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

// TODO: move away from here to campaign group own module
export function generateCampaignGroupDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.GROUP_NAME, hide: false, pinned: 'left' },
    { colId: ColumnId.ACTIONS, hide: false, pinned: 'left' },
    { colId: ColumnId.GROUP_TACOS, hide: false, pinned: false },
    { colId: ColumnId.GROUP_PRESET, hide: false, pinned: false },
    { colId: ColumnId.GROUP_BID_CEILING, hide: false, pinned: false },
    { colId: ColumnId.GROUP_BID_FLOOR, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_GROUP_COUNT, hide: false, pinned: false },
    //{ colId: ColumnId.GROUP_OPTIMIZATION_ENABLED, hide: true, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.ACTC, hide: true, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.UNITS, hide: false, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
  ];
}
