import { ColumnId } from '@/components/grid/columns/columns.enum';
import { WithFiltersGridContext } from '@/components/grid/types';
import { GridApi } from 'ag-grid-enterprise';
import { MutableRefObject, useEffect, useMemo } from 'react';
import { AlFilterModel } from '../models/AlFilterModel';
import { getStartEndDateFromFilters } from './useFilters';

interface UseFiltersInGridContextProps {
  filters: AlFilterModel[];
  filterDependentColumns: ColumnId[];
  gridApiRef: MutableRefObject<GridApi | undefined>;
  gridContextRef: MutableRefObject<WithFiltersGridContext | undefined>;
}

const useFiltersInGridContext = ({ filters, filterDependentColumns, gridApiRef, gridContextRef }: UseFiltersInGridContextProps) => {
  // Currently doesn't add all filters to the context, only the needed current end date
  const filterCurrentStartEndDate = useMemo(() => getStartEndDateFromFilters(filters), [filters]);

  useEffect(() => {
    if (!gridContextRef?.current || !filterCurrentStartEndDate) return;

    gridContextRef.current.filterCurrentStartEndDate = filterCurrentStartEndDate;
    gridApiRef.current?.refreshCells({ columns: filterDependentColumns, force: true });
  }, [filterCurrentStartEndDate]);

  function onGridReadyForFiltersInGridContext() {
    if (!gridContextRef.current) return;
    Object.assign(gridContextRef.current, {
      filterCurrentStartEndDate: filterCurrentStartEndDate,
    });
  }

  return { filterCurrentStartEndDate, onGridReadyForFiltersInGridContext };
};

export default useFiltersInGridContext;
