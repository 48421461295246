import FilterBarCard from '@/components/cards/FilterBarCard';
import FilterControls from '@/components/filter-builder/FilterControls';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { TargetingFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import GridTools from '@/components/grid/components/GridTools';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import ExpandToggleButton from '@/modules/application/components/ExpandToggleButton';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import useAvailableTargetingFilters from '@/modules/campaigns/hooks/useAvailableTargetingFilters';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, MutableRefObject } from 'react';
import { TargetsWithTimeline } from '../api/targets-contracts';

interface TargetingFilterBarProps {
  withTimeline: TargetsWithTimeline | undefined;
  gridApiRef: MutableRefObject<GridApi<unknown> | null>;
  onExpandTable: () => void;
  isExpanded: boolean;
  pageVariables: MetricsGraphTablePageContext;
}

const TargetingFilterBar: FunctionComponent<TargetingFilterBarProps> = ({
  withTimeline,
  gridApiRef,
  isExpanded,
  onExpandTable,
  pageVariables,
}) => {
  const { filters, setFilters, setFilterValue, setFilterValues, gridToggles, setGridToggles } = pageVariables;
  const { getComparisonDataMissingMessage } = useComparisonMissing({});

  const { availableTargetingFilters: availableFilters } = useAvailableTargetingFilters();
  const defaultFilterKey = FilterKey.TARGETING;

  return (
    <FilterBarCard>
      <SearchBox
        filterKey={FilterKey.TARGETING}
        newFilterModel={() => new TargetingFilterModel()}
        placeholder="Search Target"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <FilterControls filters={filters} setFilters={setFilters} availableFilters={availableFilters} defaultFilterKey={defaultFilterKey} />

      <div className="flex flex-grow"></div>
      <div className="flex h-full justify-center items-center gap-x-3">
        <ExpandToggleButton isExpanded={isExpanded} onExpandToggled={onExpandTable} />

        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={getComparisonDataMissingMessage(withTimeline?.isComparisonDataMissing ?? false)}
        />
      </div>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </FilterBarCard>
  );
};

export default TargetingFilterBar;
