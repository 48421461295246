import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignGroupsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useTranslation } from '@/lib/i18n/useTranslate';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import CampaignGroupsActions from '@/modules/optimizer/components/campaign-groups/CampaignGroupsActions';
import CampaignGroupsFilterBar from '@/modules/optimizer/components/campaign-groups/CampaignGroupsFilterBar';
import CampaignGroupsTable from '@/modules/optimizer/components/campaign-groups/CampaignGroupsTable';
import { CampaignGroupsProvider } from '@/modules/optimizer/contexts/CampaignGroupsContext';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { GridApi, GridReadyEvent } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import ProfileSyncSelectButton from '../../optimizer/ProfileSyncSelectButton';
import UpgradeSubscriptionButton from '../../teams/components/UpgradeSubscriptionButton';
import { campaignService, createCampaignGroupsWithMetricsQueryKey } from '../api/campaign/campaign-service';
import { CampaignGroupModel } from '../api/campaign/models/CampaignGroupModel';
import CampaignGroupsSelectionActionsBar from '../components/campaign-groups/CampaignGroupsSelectionActionsBar';

const CampaignGroupsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { fetchCache } = useAlFetchCache();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const [selectedCampaignGroups, setSelectedCampaignGroups] = useState<CampaignGroupModel[]>([]);

  const [filters, setFilters] = useState<AlFilterModel[]>(() =>
    filtersService.loadProfileFilters(ContextKey.CAMPAIGN_GROUPS, activeProfile?.id ?? '', getDefaultCampaignGroupsFilters(), fetchCache),
  );

  // GRID
  const gridApi = useRef<GridApi | null>(null);

  function onGridReady(params: GridReadyEvent) {
    gridApi.current = params.api;
  }

  // MAIN GRID DATA
  const {
    data: campaignGroupsWithMetricData,
    isLoading,
    refetch: refetchCampaignMetricGroups,
  } = useQuery({
    queryKey: createCampaignGroupsWithMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await campaignService.getGroupsWithMetrics(filters);

      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('optimization_groups')}
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <CampaignGroupsProvider
          filters={filters}
          setFilters={setFilters}
          selectedCampaignGroups={selectedCampaignGroups}
          setSelectedCampaignGroups={setSelectedCampaignGroups}
          totalCampaignGroupsCount={campaignGroupsWithMetricData?.campaignGroups?.filter((cg) => cg.id != 0).length ?? 0}
        >
          <ErrorBoundary>
            <CampaignGroupsActions campaignGroupsWithMetrics={campaignGroupsWithMetricData} refetch={refetchCampaignMetricGroups} />
          </ErrorBoundary>

          <ErrorBoundary>
            <CampaignGroupsFilterBar campaignGroupsWithMetrics={campaignGroupsWithMetricData} gridApiRef={gridApi} />
          </ErrorBoundary>

          <ErrorBoundary>
            <CampaignGroupsTable
              withMetricsResponse={campaignGroupsWithMetricData}
              refetch={refetchCampaignMetricGroups}
              onGridReadyCallback={onGridReady}
              isLoading={isLoading}
              noTopBorderRadius={true}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <CampaignGroupsSelectionActionsBar refetch={refetchCampaignMetricGroups} />
          </ErrorBoundary>
        </CampaignGroupsProvider>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default CampaignGroupsPage;
