import { useLayoutContext } from '@/contexts/LayoutContext';
import PaymentPastDueBanner from '@/modules/plans/components/PaymentPastDueBanner';
import TrialEndingBanner from '@/modules/plans/components/TrialEndingBanner';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { FunctionComponent, useEffect } from 'react';
import { Outlet } from 'react-router';
import { MainMenu } from '../components/MainMenu';
import { MAIN_MENU_ITEMS } from '../configuration/main-menu.configuration';
import { useUserContext } from '@/modules/users';
import { AlDate } from '@/lib/date/AlDate';
import PlanUpgradeBanner from '@/modules/plans/components/PlanUpgradeBanner';

const DAYS_BEFORE_TRIAL_ENDS_TO_DISPLAY_BANNER = 14;

export const ApplicationLayout: FunctionComponent = () => {
  const { user } = useUserContext();

  if (!user) {
    return null;
  }

  const { setIsDisplayingBanner, isDisplayingBanner } = useLayoutContext();
  const { activeTeam } = useActiveTeamContext();

  const daysBeforeTrialEnds = activeTeam?.daysBeforeTrialEnd;
  const isPastDue = activeTeam?.isPastDue;

  useEffect(() => {
    if (activeTeam?.isPastDue) {
      setIsDisplayingBanner(true);
    } else if (
      activeTeam &&
      activeTeam.isTrialing &&
      activeTeam?.daysBeforeTrialEnd <= DAYS_BEFORE_TRIAL_ENDS_TO_DISPLAY_BANNER &&
      !activeTeam.hasDefaultPaymentMethod
    ) {
      setIsDisplayingBanner(true);
    } else if (AlDate.now().year === 2024 && (activeTeam?.isOnLegacyProfilesBasedProPlan || !activeTeam?.hasProPlan)) {
      setIsDisplayingBanner(true);
    } else {
      setIsDisplayingBanner(false);
    }
  }, [daysBeforeTrialEnds, activeTeam, setIsDisplayingBanner, isPastDue]);

  const renderBanner = () => {
    if (isPastDue) {
      return <PaymentPastDueBanner />;
    } else if (
      activeTeam &&
      activeTeam.isTrialing &&
      activeTeam?.daysBeforeTrialEnd &&
      activeTeam?.daysBeforeTrialEnd <= DAYS_BEFORE_TRIAL_ENDS_TO_DISPLAY_BANNER &&
      !activeTeam.hasDefaultPaymentMethod
    ) {
      return <TrialEndingBanner daysBeforeTrialEnds={activeTeam?.daysBeforeTrialEnd} />;
    } else if (AlDate.now().year === 2024 && (activeTeam?.isOnLegacyProfilesBasedProPlan || !activeTeam?.hasProPlan)) {
      return <PlanUpgradeBanner />;
    }
    return null;
  };

  return (
    <div className="w-full">
      <div className="fixed right-0 left-0 top-0 z-50">{isDisplayingBanner && renderBanner()}</div>
      <div className={`flex w-full bg-slate-200 dark:bg-gray-900 ${isDisplayingBanner ? 'pt-7' : ''}`}>
        <MainMenu mainMenuItemGroups={MAIN_MENU_ITEMS} />
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
