import { getSeparatedValuesFromText, truncateString } from '@/modules/application/utils';
import { Autocomplete, AutocompleteInputChangeReason, Chip, TextField, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { ClipboardEvent, KeyboardEvent, FunctionComponent, SyntheticEvent, useState } from 'react';
import { toastService } from '@/services/toast.service';

interface AlMultiTextInputProps {
  label: string;
  tags: string[];
  setTags: (tags: string[]) => void;
}

export const AlMultiTextInput: FunctionComponent<AlMultiTextInputProps> = ({ label, tags, setTags }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      const inputValueTrimmed = inputValue.trim();
      if (isValueValid(inputValueTrimmed)) {
        setTags([...tags, inputValueTrimmed]);
        setInputValue('');
        event.preventDefault(); // Prevent form submission if inside a form
      }
    } else if (event.key === 'Backspace' && !inputValue) {
      setTags(tags.slice(0, -1));
    }
  };

  const handleBlur = () => {
    if (isValueValid(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const isValueValid = (value: string): boolean => {
    if (isEmpty(value)) {
      return false;
    }

    const isTagDuplicate = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
    if (isTagDuplicate) {
      toastService.error(value + ' is already in the list');
    }

    return !isTagDuplicate;
  };

  const handleDelete = (indexToDelete: number) => {
    setTags(tags.filter((_, index) => index !== indexToDelete));
  };

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the default paste behavior
    const newTags = getSeparatedValuesFromText(event.clipboardData.getData('Text'), tags);

    setTags([...new Set([...tags, ...newTags])]); // Combine new and existing tags, removing duplicates
    setInputValue(''); // Clear the input field after processing the paste
  };

  return (
    <Autocomplete
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          placeholder="Type the phrase and press [ENTER] to add it to the list (or paste rows) "
          slotProps={{
            inputLabel: { shrink: true },
          }}
        />
      )}
      id={'input-multi-text-input'}
      clearIcon={false}
      options={[]}
      freeSolo
      multiple
      value={tags} // Setting value to the existing tags
      className="w-100"
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      clearOnBlur
      renderTags={(value) =>
        value.map((tag, index) => (
          <Tooltip key={index} title={tag}>
            <Chip className="m-0.5" size="small" label={truncateString(tag)} onDelete={() => handleDelete(index)} />
          </Tooltip>
        ))
      }
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onPaste={handlePaste}
    />
  );
};
