import { BidLimitChangeUnitType, MaxChange } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { BidCeilingType } from '@/modules/optimizer/types/BidCeilingType';
import { BidFloorType } from '@/modules/optimizer/types/BidFloorType';
import { SmartBidCeilingType } from '@/modules/optimizer/types/SmartBidCeilingType';
import { round } from 'lodash-es';
import { CampaignGroupDTO } from '../campaign-contracts';
import { CampaignGroupMetricsModel } from './CampaignGroupMetricsModel';

export class CampaignGroupModel {
  public id: number;
  public name: string;
  public tacos?: number;
  public preset?: OptimizationPreset;
  public bidCeiling?: MaxChange;
  public bidFloor?: number;
  public totalCampaigns: number;
  public maxBidIncrease?: MaxChange;
  public maxBidDecrease?: MaxChange;
  public placementMaxIncrease?: number;
  public placementMaxDecrease?: number;
  public bidCeilingOff?: boolean;
  public bidFloorOff?: boolean;
  public bidMaxIncreaseOff?: boolean;
  public bidMaxDecreaseOff?: boolean;
  public placementMaxIncreaseOff?: boolean;
  public placementMaxDecreaseOff?: boolean;
  public optimizationEnabled: boolean;

  constructor(args: CampaignGroupArguments) {
    this.id = args.id;
    this.name = args.name;
    this.tacos = args.tacos;
    this.preset = args.preset;
    this.bidCeiling = args.bidCeiling;
    this.bidFloor = args.bidFloor;
    this.totalCampaigns = args.totalCampaigns;
    this.maxBidIncrease = args.maxBidIncrease;
    this.maxBidDecrease = args.maxBidDecrease;
    this.placementMaxIncrease = args.placementMaxIncrease;
    this.placementMaxDecrease = args.placementMaxDecrease;
    this.bidCeilingOff = args.bidCeilingOff;
    this.bidFloorOff = args.bidFloorOff;
    this.bidMaxIncreaseOff = args.bidMaxIncreaseOff;
    this.bidMaxDecreaseOff = args.bidMaxDecreaseOff;
    this.placementMaxIncreaseOff = args.placementMaxIncreaseOff;
    this.placementMaxDecreaseOff = args.placementMaxDecreaseOff;
    this.optimizationEnabled = args.optimizationEnabled;
  }

  public static fromResponseArray(dtos: CampaignGroupDTO[]): CampaignGroupModel[] {
    return dtos.map(
      (dto) =>
        new CampaignGroupModel({
          id: dto.id,
          name: dto.name,
          tacos: dto.tacos,
          preset: dto.preset ?? OptimizationPreset.NOT_SET,
          bidCeiling: dto.bid_ceiling,
          bidFloor: dto.bid_floor,
          totalCampaigns: dto.total_campaigns,
          maxBidIncrease: dto.bid_max_increase,
          maxBidDecrease: dto.bid_max_decrease,
          placementMaxIncrease: dto.placement_max_increase,
          placementMaxDecrease: dto.placement_max_decrease,
          bidCeilingOff: dto.bid_ceiling_off,
          bidFloorOff: dto.bid_floor_off,
          bidMaxIncreaseOff: dto.bid_max_increase_off,
          bidMaxDecreaseOff: dto.bid_max_decrease_off,
          placementMaxIncreaseOff: dto.placement_max_increase_off,
          placementMaxDecreaseOff: dto.placement_max_decrease_off,
          optimizationEnabled: !dto.no_optimize,
        }),
    );
  }

  public static fromCampaignGroupMetricsModel(metricsModel: CampaignGroupMetricsModel): CampaignGroupModel {
    return new CampaignGroupModel({
      id: metricsModel.id,
      name: metricsModel.name,
      tacos: metricsModel.tacos,
      preset: metricsModel.preset,
      bidCeiling: metricsModel.bidCeiling,
      bidFloor: metricsModel.bidFloor,
      totalCampaigns: metricsModel.totalCampaigns,
      maxBidIncrease: metricsModel.maxBidIncrease,
      maxBidDecrease: metricsModel.maxBidDecrease,
      placementMaxIncrease: metricsModel.placementMaxIncrease,
      placementMaxDecrease: metricsModel.placementMaxDecrease,
      bidCeilingOff: metricsModel.bidCeilingOff,
      bidFloorOff: metricsModel.bidFloorOff,
      bidMaxIncreaseOff: metricsModel.bidMaxIncreaseOff,
      bidMaxDecreaseOff: metricsModel.bidMaxDecreaseOff,
      placementMaxIncreaseOff: metricsModel.placementMaxIncreaseOff,
      placementMaxDecreaseOff: metricsModel.placementMaxDecreaseOff,
      optimizationEnabled: metricsModel.optimizationEnabled,
    });
  }
}

export interface CampaignGroupArguments {
  id: number;
  name: string;
  tacos?: number;
  preset?: OptimizationPreset;
  bidCeiling?: MaxChange;
  bidFloor?: number;
  totalCampaigns: number;
  maxBidIncrease?: MaxChange;
  maxBidDecrease?: MaxChange;
  placementMaxIncrease?: number;
  placementMaxDecrease?: number;
  bidCeilingOff?: boolean;
  bidFloorOff?: boolean;
  bidMaxIncreaseOff?: boolean;
  bidMaxDecreaseOff?: boolean;
  placementMaxIncreaseOff?: boolean;
  placementMaxDecreaseOff?: boolean;
  optimizationEnabled: boolean;
}

export const emptyUnAssignedCampaignGroup = new CampaignGroupModel({
  id: 0,
  name: 'Unassigned',
  tacos: 0,
  preset: OptimizationPreset.NOT_SET,
  bidCeiling: undefined,
  bidFloor: undefined,
  totalCampaigns: 0,
  optimizationEnabled: false,
});

export const getDefaultValues = (campaignGroup?: CampaignGroupModel) => {
  let bidCeilingType = BidCeilingType.NOT_SET;
  let smartBidCeilingType = SmartBidCeilingType.TARGET_CPC_1X;

  if (campaignGroup?.bidCeilingOff) {
    bidCeilingType = BidCeilingType.OFF;
  } else if (campaignGroup?.bidCeiling) {
    bidCeilingType = BidCeilingType.MANUAL;
    if (campaignGroup.bidCeiling.unit == BidLimitChangeUnitType.TIMES_CPC) {
      bidCeilingType = BidCeilingType.SMART;
      if (campaignGroup.bidCeiling.value == 2) {
        smartBidCeilingType = SmartBidCeilingType.TARGET_CPC_2X;
      } else if (campaignGroup.bidCeiling.value == 3) {
        smartBidCeilingType = SmartBidCeilingType.TARGET_CPC_3X;
      }
    }
  }

  return {
    groupName: campaignGroup?.name ?? '',
    tacos: campaignGroup?.tacos ? round(campaignGroup.tacos * 100, 4).toString() : '',
    prioritization: campaignGroup?.preset ?? OptimizationPreset.NOT_SET,
    bidCeilingType: bidCeilingType,
    smartBidCeilingType: smartBidCeilingType,
    bidFloorType: campaignGroup?.bidFloorOff ? BidFloorType.OFF : campaignGroup?.bidFloor ? BidFloorType.MANUAL : BidFloorType.NOT_SET,
    bidCeiling: campaignGroup?.bidCeiling ? campaignGroup.bidCeiling.value.toString() : '',
    bidFloor: campaignGroup?.bidFloor?.toString() ?? '',
    optimizationEnabled: campaignGroup?.optimizationEnabled ?? true,
  };
};
