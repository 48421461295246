import { FunctionComponent } from 'react';
import { CommonMetricField, CommonWithSellerAndVendorMetricField } from '../metrics/types/MetricField';
import { COMMON_METRICS } from '../metrics/MetricsConfig';
import AlAutoComplete, { AlAutoCompleteOption } from './AlAutoComplete';
import { EntityType } from '@/modules/dashboards/components/forms/EntityTypeSelect';

interface MetricSelectProps {
  entityType: EntityType;
  value: CommonWithSellerAndVendorMetricField | '';
  onChange: (value: CommonWithSellerAndVendorMetricField | '') => void;
  helperText?: string;
}

const MetricSelect: FunctionComponent<MetricSelectProps> = ({ value, onChange, helperText }) => {
  const handleChange = (option: AlAutoCompleteOption | null) => {
    if (option) {
      onChange(option.id as CommonMetricField);
    }
  };

  const metricConfigurations = COMMON_METRICS;

  // TODO
  // if (entityType === EntityType.PROFILE) {
  //   metricConfigurations = { ...COMMON_METRICS, ...PROFILE_STATS_SELLER_METRICS };
  // }

  const options: { label: string; id: CommonMetricField }[] = Object.values(metricConfigurations).map((metric) => ({
    label: metric.title,
    id: metric.key as CommonMetricField,
  }));

  return (
    <AlAutoComplete
      placeHolderText={'Select Metric'}
      textFieldLabel={'Select Metric'}
      helperText={helperText}
      currentValue={options.find((option) => option.id === value) ?? options[0]}
      options={options}
      updateValueWithOption={(option: AlAutoCompleteOption | null) => handleChange(option)}
    />
  );
};

export default MetricSelect;
