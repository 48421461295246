import { filtersService } from '@/components/filter-builder/api/filters-service';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { getDefaultCampaignMappingFilters } from '@/components/filter-builder/models/AlFilterModel';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { ContextKey, ContextWithFilters, FiltersPropsWithChildren, defaultFiltersContext } from '@/types/context-shared';
import { isEqual } from 'lodash-es';
import { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';

const CampaignMappingContext = createContext<ContextWithFilters>({
  ...defaultFiltersContext,
});

export const CampaignMappingProvider: FunctionComponent<FiltersPropsWithChildren> = ({ filters, setFilters, children }) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();
  const { fetchCache } = useAlFetchCache();

  const [isMounted, setIsMounted] = useState(false);

  const { setFilterValue, setFilterValues } = useFilters({ filters, setFilters });

  //TODO: review
  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(ContextKey.CAMPAIGN_MAPPING, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(
        ContextKey.CAMPAIGN_MAPPING,
        activeProfile?.id,
        getDefaultCampaignMappingFilters(),
        fetchCache,
      );

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <CampaignMappingContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
      }}
    >
      {children}
    </CampaignMappingContext.Provider>
  );
};

export const useCampaignMappingContext = (): ContextWithFilters => {
  const context = useContext(CampaignMappingContext);
  if (!context) {
    throw new Error('useCampaignMappingContext must be used within a CampaignMappingProvider');
  }
  return context;
};
