import { ColumnId } from '@/components/grid/columns/columns.enum';
import { getAllFilteredLeafRowNodes } from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { METRICS, UnitType, getMetricColumns, getMetricConfigByColId } from '@/components/metrics/MetricsConfig';
import { calculatedFieldDependencies } from '@/components/metrics/models/CommonMetricsModel';
import { MetricData } from '@/components/metrics/types/MetricData';
import { MetricAggregates, MetricField } from '@/components/metrics/types/MetricField';
import { CurrencyRatesModel } from '@/modules/currency/models/CurrencyRatesModel';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { GridApi, IAggFuncParams, IRowNode } from 'ag-grid-enterprise';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { ProfilesStatsGridContext } from '../components/profile-stats-table/ProfilesStatsTable';
import { ProfileWithMetricsModel } from '../models/ProfileWithMetricsModel';

interface UseProfileStatsMetricColumnAggregatesProps {
  gridApiRef: MutableRefObject<GridApi<ProfileWithMetricsModel> | undefined>;
  gridContextRef: MutableRefObject<ProfilesStatsGridContext | undefined>;
  aggDataCurrencyCode: CurrencyCode; // Not dynamic as it's not in the context
  rowData: ProfileWithMetricsModel[] | undefined;
  conversionRatesModel: CurrencyRatesModel | undefined;
}

const useProfileStatsMetricColumnAggregates = ({
  gridApiRef,
  gridContextRef,
  aggDataCurrencyCode,
  rowData,
  conversionRatesModel: conversionRatesModelExternal,
}: UseProfileStatsMetricColumnAggregatesProps) => {
  const { getCalculatedMetricAggDataForMetricData } = useColDefsFunctions();

  // Triggers metricColumnAggregates recalculation
  const [updateHeaderTrigger, setUpdateHeaderTrigger] = useState(0);
  function updateHeaderAggregates() {
    if (!gridApiRef.current || !gridContextRef.current) return;
    setUpdateHeaderTrigger((prev) => prev + 1);
  }

  // Main agg function used by both header agg and row agg
  const getMetricColumnAggregatesForNodes = (
    nodes: IRowNode<ProfileWithMetricsModel>[],
    conversionRatesModel: CurrencyRatesModel,
    selectedCurrency: CurrencyCode,
    metricFieldsToGetAggData?: Set<MetricField>,
  ): Record<string, MetricData> | undefined => {
    if (!gridApiRef.current || !conversionRatesModel) return;

    // Temp record to make searching faster (instead of .find)
    const colIdToConfigRecord = Object.values(METRICS).reduce(
      (acc, metricConfig) => {
        acc[metricConfig.colId] = metricConfig;
        return acc;
      },
      {} as Record<string, (typeof METRICS)[keyof typeof METRICS]>,
    );

    // Aggregated metric accumulator container
    const aggData: Record<string, MetricData> = {};

    const seenProfileIds = new Set<string>();

    // Non-calculated metrics
    for (const node of nodes) {
      if (!node.data || !gridApiRef.current) return;

      // Don't aggregate same profile twice (same profile is present in multiple teams)
      const profileId = node.data.id;
      if (seenProfileIds.has(profileId)) {
        continue;
      } else {
        seenProfileIds.add(profileId);
      }

      for (const colId of getMetricColumns()) {
        const field = gridApiRef.current.getColumnDef<ProfileWithMetricsModel>(colId)?.field;

        // Field can be undefined: not all metrics are used in this table
        if (!field) continue;

        const config = colIdToConfigRecord[colId];
        if (config.isCalculatedMetric) continue;

        // If provided, only include specified metrics (single col agg calc with dependencies)
        if (metricFieldsToGetAggData && !metricFieldsToGetAggData.has(config.key)) {
          continue;
        }

        // Specific cell value in grid (from data not via cell valueGetter)
        const fieldValue = node.data[field as keyof ProfileWithMetricsModel] as MetricData | undefined;
        if (!fieldValue) continue;

        // Aggregate (sum up) non-calculated metrics
        let fieldCurrentValue = fieldValue[0];
        let fieldPreviousValue = fieldValue[1];

        if (config.unitType === UnitType.CURRENCY) {
          fieldCurrentValue = conversionRatesModel.convertValueFromTo(fieldValue[0], node.data.currencyCode, selectedCurrency);
          fieldPreviousValue = conversionRatesModel.convertValueFromTo(fieldValue[1], node.data.currencyCode, selectedCurrency);
        }

        const accumulatorValue = aggData[config.key] ?? [0, 0];
        const newValue: MetricData = [accumulatorValue[0] + fieldCurrentValue, accumulatorValue[1] + fieldPreviousValue];

        aggData[config.key] = newValue;
      }
    }

    // Data not there yet
    if (Object.keys(aggData).length === 0) return;

    // Once non-calculated metrics are summed up, find calculated metrics
    for (const colId of getMetricColumns()) {
      const config = colIdToConfigRecord[colId];
      if (metricFieldsToGetAggData && !metricFieldsToGetAggData.has(config.key)) {
        continue;
      }

      const field = gridApiRef.current.getColumnDef<ProfileWithMetricsModel>(colId)?.field;
      if (!config.isCalculatedMetric || !field) continue;

      const newValue = getCalculatedMetricAggDataForMetricData(config.key, aggData);
      aggData[config.key] = newValue;
    }
    return aggData;
  };

  function getWholeFilteredGridMetricColumnAggregates() {
    if (!gridApiRef.current || !gridContextRef.current) return;

    const conversionRatesModel = gridContextRef.current.conversionRatesModel;
    if (!conversionRatesModel) return;

    const selectedCurrency = gridContextRef.current.selectedCurrency ?? CurrencyCode.USD;
    const aggData = getMetricColumnAggregatesForNodes(getAllFilteredLeafRowNodes(gridApiRef.current), conversionRatesModel, selectedCurrency);
    if (!aggData) return;

    return Object.fromEntries(
      Object.entries(aggData).map(([key, value]) => [key, { current: value[0], previous: value[1] }]),
    ) as MetricAggregates;
  }

  function profileStatsMetricAggFunc(params: IAggFuncParams<ProfileWithMetricsModel>) {
    const conversionRatesModel = (params.context as ProfilesStatsGridContext).conversionRatesModel;
    if (!conversionRatesModel) return [0, 0];

    const columnId = params.colDef?.colId as ColumnId;
    const metricField = columnId ? getMetricConfigByColId(columnId)?.key : null;

    if (!metricField) {
      return [0, 0];
    }

    // Only include nodes that pass the current filter
    const rowNodeIdsAfterFilteringSet = new Set(getAllFilteredLeafRowNodes(params.api).map((node) => node.id));
    const allLeavesAfterFiltering = params.rowNode.allLeafChildren?.filter((node) => {
      return rowNodeIdsAfterFilteringSet.has(node.id);
    });
    if (!allLeavesAfterFiltering || allLeavesAfterFiltering.length === 0) return [0, 0];

    // Optimization: Create a list of cols for which need to get the agg data - non-calculated metrics just self, calculated metrics self + dependencies
    const metricFieldsToGetAggData = new Set<MetricField>([metricField, ...(calculatedFieldDependencies[metricField] ?? [])]);

    const selectedCurrency = (params.context as ProfilesStatsGridContext).selectedCurrency ?? CurrencyCode.USD;
    const aggDataForSelected = getMetricColumnAggregatesForNodes(
      allLeavesAfterFiltering,
      conversionRatesModel,
      selectedCurrency,
      metricFieldsToGetAggData,
    );
    return aggDataForSelected?.[metricField] ?? [0, 0];
  }

  function updateAggregates() {
    if (!conversionRatesModelExternal || !rowData || !gridApiRef.current || gridApiRef.current.isDestroyed()) return;
    updateHeaderAggregates();
    gridApiRef.current?.refreshClientSideRowModel('aggregate');
  }

  useEffect(() => {
    if (!conversionRatesModelExternal) return;
    updateAggregates();

    if (gridContextRef.current) {
      gridContextRef.current.conversionRatesModel = conversionRatesModelExternal;
    }
  }, [conversionRatesModelExternal, aggDataCurrencyCode]);

  useEffect(() => {
    updateAggregates();
  }, [rowData, conversionRatesModelExternal]);

  const metricColumnAggregates = useMemo(() => getWholeFilteredGridMetricColumnAggregates(), [updateHeaderTrigger, rowData]);
  const { onGridReadyForMetricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  function onGridReadyForMetricColumnAggregatesProfileStats() {
    if (!gridApiRef.current || !gridContextRef.current) return;

    if (conversionRatesModelExternal) {
      gridContextRef.current.conversionRatesModel = conversionRatesModelExternal;
    }
    onGridReadyForMetricColumnAggregates();

    updateAggregates();
  }

  return {
    metricColumnAggregates,
    onGridReadyForMetricColumnAggregates: onGridReadyForMetricColumnAggregatesProfileStats,
    profileStatsMetricAggFunc,
  };
};

export default useProfileStatsMetricColumnAggregates;
