import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';
import { CommonMetricField, CommonWithSellerAndVendorMetricField } from '@/components/metrics/types/MetricField';
import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { EntityType } from '../../forms/EntityTypeSelect';
import { GroupByTime } from '../../forms/GroupByTimeSelect';

export class ComboGraphWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'Combo Graph';
  isConfigured: boolean = false;
  selectedMetrics: CommonWithSellerAndVendorMetricField[] = [CommonMetricField.SALES];
  groupByTime = GroupByTime.DAY;
  entityType = EntityType.CAMPAIGN;
  profilesIds: string[] = [];

  campaignFilters: FilterDTO[] = [];
  placementFilters: FilterDTO[] = [];
  targetFilters: FilterDTO[] = [];
  productFilters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
